import { Add, Edit } from "@mui/icons-material";
import {
  Button,
  FormControl,
  IconButton,
  InputAdornment,
  ListSubheader,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
} from "@mui/material";
import { all } from "axios";
import React, { useEffect, useMemo } from "react";
import { useState } from "react";
import { Trash } from "react-bootstrap-icons";
import { toast } from "react-toastify";
import { getSalaryHeads } from "../../../../services/AdminService";
import { getLoginUserDetails } from "../../../../utils/Utils";
import { green } from "@mui/material/colors";
import { useTranslation } from "react-i18next";

export const SalaryTable = ({
  salaryInfoList,
  setSalaryInfoList,
  gpm,
  salaryBrkUps,
  // setCtc,
}) => {
  const loginUserDetails = getLoginUserDetails();
  const { t } = useTranslation();
  const [salaryHeadList, setSalaryHeadList] = useState([]);
  const [allTimeFormData, setAllTimeFormData] = useState({
    salaryHead: "",
    percent: "",
    grossOrBasic: null,
    amount: 0,
    monthly: 0,
    annual: 0,
    editMode: true,
  });

  const [calculatedSalaryBasiId, setCalculatedSalaryBasiId] = useState([]);
  const [calculatedSalaryBreakup, setCalculatedSalaryBreakup] = useState([]);

  const computeSalaryBreakupInfo = () => {
    let prevTotalSalaryAmount = 0;
    let temp = [...salaryBrkUps];
    //
    // const gpmWithoutComma = gpm ? gpm.replace(/,/g, "") : 0;
    while (prevTotalSalaryAmount < salaryBrkUps.length) {
      console.log("dashboard value=", prevTotalSalaryAmount);
      for (let i = 0; i < temp.length; i++) {
        let headItem = temp[i];
        const calculationBasisHeadId = headItem.pcOnSalaryHeadId;
        const basisHeadObj = salaryBrkUps.find(
          (salaryHead) => salaryHead.salaryHeadId === calculationBasisHeadId
        );

        temp[i] = {
          ...headItem,
          basisHeadObj,
        };
        prevTotalSalaryAmount++;
      }
      // return;
    }

    console.log("basic head obj= ", temp);
    setCalculatedSalaryBreakup(temp);
    setSalaryInfoList(temp);
    // return temp;
  };

  useEffect(() => {
    if (salaryBrkUps && salaryBrkUps.length > 0) {
      computeSalaryBreakupInfo();
    }
  }, [salaryBrkUps]);

  useEffect(() => {
    if (!salaryBrkUps || (salaryBrkUps && salaryBrkUps.length === 0)) {
      //
      let prevTotalSalaryAmount = 0;
      let temp = [...salaryHeadList];
      const gpmWithoutComma = gpm ? gpm.replace(/,/g, "") : 0;
      while (prevTotalSalaryAmount < salaryHeadList.length) {
        console.log("dashboard value=", prevTotalSalaryAmount);
        for (let i = 0; i < temp.length; i++) {
          let headItem = temp[i];
          const calculationBasisHeadId = headItem.calculationBasisHeadId;
          let parentamount = null;
          if (headItem.amount !== null || headItem.amount !== undefined) {
            if (calculationBasisHeadId == 0) {
              parentamount =
                (parseFloat(gpmWithoutComma) * headItem.calculationPercentage) /
                100;
              prevTotalSalaryAmount++;
            }
            const basisHeadObj = temp.find(
              (salaryHead) => salaryHead.salaryHeadId === calculationBasisHeadId
            );
            if (basisHeadObj && basisHeadObj.amount >= 0) {
              parentamount =
                (basisHeadObj.amount * headItem.calculationPercentage) / 100;
              if (isNaN(parentamount)) {
                parentamount = 0;
              }

              prevTotalSalaryAmount++;
            }
            temp[i] = {
              ...headItem,
              basisHeadObj,
            };
            if (parentamount !== null) {
              temp[i] = {
                ...headItem,
                basisHeadObj,
                amount: parseFloat(parentamount.toFixed(2)),
                annual: parseFloat((parentamount * 12).toFixed(2)),
              };
            }
          }
        }
        // return;
      }

      console.log("basic head obj= ", temp);
      setCalculatedSalaryBasiId(temp);
      setSalaryInfoList(temp);
    }
  }, [salaryHeadList, gpm]);

  useEffect(() => {
    getSalaryHeads(loginUserDetails.orgId).then((response) => {
      if (response.data) {
        setSalaryHeadList(response.data);
      }
    });
  }, []);

  const initializeAllTimeFormData = () => {
    setAllTimeFormData({
      salaryHead: "",
      percent: "",
      grossOrBasic: null,
      amount: 0,
      monthly: 0,
      annual: 0,
      editMode: true,
    });
  };

  const calculateMonthlyTotal = () => {
    let infoList = [];
    if (salaryBrkUps && salaryBrkUps.length > 0) {
      infoList = [...salaryBrkUps];
    } else {
      infoList = [...calculatedSalaryBasiId];
    }
    let total = 0;
    if (!isNaN(allTimeFormData.amount)) {
      total += allTimeFormData.amount;
    }
    for (let i = 0; i < infoList.length; i++) {
      if (salaryBrkUps && salaryBrkUps.length > 0) {
        total += infoList[i].monthly;
      } else {
        total += infoList[i].amount;
      }
    }

    return total;
  };

  const calculateAnnualTotal = () => {
    let total = 0;
    let infoList = [];
    //
    if (salaryBrkUps && salaryBrkUps.length > 0) {
      infoList = [...salaryBrkUps];
    } else {
      infoList = [...calculatedSalaryBasiId];
    }
    if (!isNaN(allTimeFormData.annual)) {
      total += allTimeFormData.annual;
    }
    for (let i = 0; i < infoList.length; i++) {
      if (salaryBrkUps && salaryBrkUps.length > 0) {
        total += infoList[i].yearly;
      } else {
        total += infoList[i].annual;
      }
    }
    // setCtc(total);
    return total;
  };

  const handleUpdateSalaryItem = (index) => {
    const temp = [...salaryInfoList];
    let isValid = true;
    if (temp[index].salaryHead === "") {
      toast.error(t("please_select_salary_head"));
      isValid = false;
    }
    if (temp[index].percent === "") {
      toast.error(t("please_select_percent"));
      isValid = false;
    }
    if (!temp[index].grossOrBasic) {
      toast.error(t("please_select_gross_or_basic"));
      isValid = false;
    }
    if (!temp[index].amount) {
      toast.error(t("please_select_amount"));
      isValid = false;
    }
    if (isValid) {
      temp[index].editMode = false;
      setSalaryInfoList(temp);
    }
  };

  return (
    <div className="indentFormtable autoHeight">
      <div className="indtableResponsive">
        <table>
          <thead>
            <tr>
              <th className="salaryHead">
                <span>Salary Head</span>
                {/* <IconButton
                                  onClick={() => handelOpenSalaryHeadModel()}
                                  >
                                  <Add />
                                </IconButton> */}
              </th>
              <th>Salary Head Group</th>
              <th className="width250">Enter %</th>
              <th className="tright">Monthly Amount</th>
              {/* <th className="tright">Monthly</th> */}
              <th className="tright">Annually</th>
              {(!salaryBrkUps ||
                (salaryBrkUps && salaryBrkUps.length === 0)) && (
                <th className="width100"></th>
              )}
            </tr>
          </thead>
          <tbody>
            {(!salaryBrkUps || (salaryBrkUps && salaryBrkUps.length === 0)) &&
              calculatedSalaryBasiId &&
              calculatedSalaryBasiId.map((salaryInfo, index) => {
                if (!salaryInfo.editMode) {
                  return (
                    <tr>
                      <td>
                        <div class="textOnly">{salaryInfo.salaryHeadName}</div>
                      </td>
                      <td>
                        <div class="textOnly">{salaryInfo.salaryHeadGroup}</div>
                      </td>
                      <td>
                        <div class="textOnly">{`${
                          salaryInfo.calculationPercentage || 0
                        } % of ${
                          salaryInfo.basisHeadObj &&
                          salaryInfo.basisHeadObj.salaryHeadName
                            ? salaryInfo.basisHeadObj.salaryHeadName
                            : "Gross Per Month(GPM)"
                        }`}</div>
                      </td>
                      <td>
                        <div className="formElement ">
                          <FormControl className="formControl">
                            <TextField
                              hiddenLabel
                              // label="Cost To Company (CTC)"
                              placeholder="Ex: 300000"
                              variant="outlined"
                              className="formTextFieldArea"
                              value={salaryInfo.amount}
                              onChange={(e) => {
                                const temp = [...calculatedSalaryBasiId];
                                let amount = parseInt(e.target.value);
                                if (isNaN(amount)) {
                                  amount = 0;
                                }
                                temp[index].amount = amount;
                                temp[index].monthly = parseInt(amount);
                                temp[index].annual = parseInt(amount) * 12;
                                setCalculatedSalaryBasiId(temp);
                              }}
                            />
                          </FormControl>
                        </div>
                      </td>
                      {/* <td className="tright">
                        <div class="textOnly">{salaryInfo.monthly}</div>
                      </td> */}
                      <td className="tright">
                        <div class="textOnly">{salaryInfo.annual}</div>
                      </td>
                      <td className="actionTDBtn">
                        {/* <div className="centerpopos">
                          <IconButton
                            className="margAuto"
                            onClick={(e) => {
                              const temp = [...salaryInfoList];
                              temp[index].editMode = true;
                              setSalaryInfoList(temp);
                            }}
                          >
                            <Edit />
                          </IconButton>
                        </div> */}
                        <div className="centerpopos">
                          <IconButton
                            className="margAuto"
                            onClick={(e) => {
                              const temp = [...salaryInfoList];
                              temp.splice(index, 1);
                              setSalaryInfoList(temp);
                            }}
                          >
                            <Trash />
                          </IconButton>
                        </div>
                      </td>
                    </tr>
                  );
                } else {
                  return (
                    <>
                      <tr>
                        <td>
                          <div className="formElement">
                            <FormControl className="formControl">
                              {/* <InputLabel id="SelectsalaryHead">
                                      Salary Head
                                    </InputLabel> */}
                              <Select
                                hiddenLabel
                                labelId="SelectsalaryHead"
                                id="Select-salaryHead"
                                value={salaryInfo.salaryHead}
                                // label="Salary Head"
                                onChange={(e) => {
                                  console.log("salary head =", e.target.value);
                                  const temp = [...salaryInfoList];
                                  temp[index].salaryHead = e.target.value;
                                  setSalaryInfoList(temp);
                                }}
                                className="formInputField"
                                variant="outlined"
                              >
                                <ListSubheader className="ListSubheader">
                                  A. Gross Per Month (GPM)
                                </ListSubheader>
                                <MenuItem value="basic">Basic</MenuItem>
                                <MenuItem value="hra">HRA</MenuItem>
                                <MenuItem value="ta">TA</MenuItem>

                                <ListSubheader className="ListSubheader">
                                  B. Annual Payments
                                </ListSubheader>
                                <MenuItem value="LTA">LTA</MenuItem>
                                <MenuItem value="Bonus/Ex-Gratia">
                                  Bonus/ Ex-Gratia
                                </MenuItem>
                                <MenuItem value="mr">
                                  Medical Reimbursement
                                </MenuItem>
                                <ListSubheader className="ListSubheader">
                                  C. Retirals
                                </ListSubheader>
                                <MenuItem value="PF">Provident Fund</MenuItem>

                                <ListSubheader className="ListSubheader">
                                  D. Affiliation Perks
                                </ListSubheader>
                                <MenuItem value="Gratuity">Gratuity</MenuItem>
                                <MenuItem value="ESIC"> ESIC</MenuItem>
                              </Select>
                            </FormControl>
                          </div>
                        </td>
                        <td>
                          <div className="frmText">
                            <div className="formElement">
                              <FormControl className="formControl">
                                <OutlinedInput
                                  className="formInputField outlineInput"
                                  value={salaryInfo.percent}
                                  onChange={(e) => {
                                    console.log("percent= ", e.target.value);
                                    let data = parseFloat(e.target.value);
                                    if (
                                      !isNaN(data) &&
                                      data >= 0 &&
                                      data <= 100
                                    ) {
                                      const temp = [...salaryInfoList];
                                      temp[index].percent = data;
                                      setSalaryInfoList(temp);
                                    }
                                  }}
                                  endAdornment={
                                    <InputAdornment position="start">
                                      %
                                    </InputAdornment>
                                  }
                                />
                              </FormControl>
                            </div>
                            <span>of</span>
                            <div className="formElement">
                              <FormControl className="formControl">
                                <Select
                                  hiddenLabel
                                  labelId="SelectPercentageOf"
                                  id="Select-PercentageOf"
                                  value={salaryInfo.grossOrBasic}
                                  onChange={(e) => {
                                    console.log("changein");
                                    const temp = [...salaryInfoList];
                                    temp[index].grossOrBasic = e.target.value;
                                    setSalaryInfoList(temp);
                                  }}
                                  className="formInputField"
                                  variant="outlined"
                                >
                                  <MenuItem value="gross">Gross</MenuItem>
                                  <MenuItem value="basic">Basic</MenuItem>
                                </Select>
                              </FormControl>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="formElement ">
                            <FormControl className="formControl">
                              <TextField
                                hiddenLabel
                                // label="Cost To Company (CTC)"
                                placeholder="Ex: 300000"
                                variant="outlined"
                                className="formTextFieldArea"
                                value={salaryInfo.amount}
                                onChange={(e) => {
                                  const temp = [...salaryInfoList];
                                  let amount = parseInt(e.target.value);
                                  if (isNaN(amount)) {
                                    amount = 0;
                                  }
                                  temp[index].amount = amount;
                                  temp[index].monthly = parseInt(amount);
                                  temp[index].annual = parseInt(amount) * 12;
                                  setSalaryInfoList(temp);
                                }}
                              />
                            </FormControl>
                          </div>
                        </td>
                        {/* <td className="tright">
                          <div class="textOnly">{salaryInfo.monthly}</div>
                        </td> */}
                        <td className="tright">
                          <div class="textOnly">{salaryInfo.annual}</div>
                        </td>
                        <td className="actionTDBtn">
                          <Button
                            className="addNRwBtn"
                            variant="contained"
                            // startIcon={<Add />}
                            onClick={handleUpdateSalaryItem(index)}
                          >
                            Update
                          </Button>
                        </td>
                      </tr>
                    </>
                  );
                }
              })}

            {calculatedSalaryBreakup &&
              calculatedSalaryBreakup.map((salaryComponent) => (
                <>
                  <tr>
                    <td>
                      <div class="textOnly">
                        {salaryComponent.salaryHeadName}
                      </div>
                    </td>
                    <td>
                      <div class="textOnly">
                        {salaryComponent.salaryHeadGroupName}
                      </div>
                    </td>
                    <td>
                      <div class="textOnly">{`${salaryComponent.pc} % of 
          
          ${
            salaryComponent.basisHeadObj &&
            salaryComponent.basisHeadObj.salaryHeadName
              ? salaryComponent.basisHeadObj.salaryHeadName
              : "Gross Per Month(GPM)"
          }
                      `}</div>
                    </td>
                    <td className="tright">
                      <div class="textOnly">{salaryComponent.monthly}</div>
                    </td>
                    {/* <td className="tright">
                        <div class="textOnly">{salaryInfo.monthly}</div>
                      </td> */}
                    <td className="tright">
                      <div class="textOnly">{salaryComponent.yearly}</div>
                    </td>
                  </tr>
                </>
              ))}
            {/* <tr>
              <td>
                <div className="formElement">
                  <FormControl className="formControl">
                    
                    <Select
                      hiddenLabel
                      labelId="SelectsalaryHead"
                      id="Select-salaryHead"
                      value={allTimeFormData.salaryHead}
                      // label="Salary Head"
                      onChange={(e) => {
                        console.log("salary head =", e.target.value);
                        const temp = { ...allTimeFormData };
                        temp.salaryHead = e.target.value;
                        setAllTimeFormData(temp);
                      }}
                      className="formInputField"
                      variant="outlined"
                    >
                      <ListSubheader className="ListSubheader">
                        A. Gross Per Month (GPM)
                      </ListSubheader>
                      <MenuItem value="basic">Basic</MenuItem>
                      <MenuItem value="hra">HRA</MenuItem>
                      <MenuItem value="ta">TA</MenuItem>

                      <ListSubheader className="ListSubheader">
                        B. Annual Payments
                      </ListSubheader>
                      <MenuItem value="LTA">LTA</MenuItem>
                      <MenuItem value="Bonus/Ex-Gratia">
                        Bonus/ Ex-Gratia
                      </MenuItem>
                      <MenuItem value="mr">Medical Reimbursement</MenuItem>
                      <ListSubheader className="ListSubheader">
                        C. Retirals
                      </ListSubheader>
                      <MenuItem value="PF">Provident Fund</MenuItem>

                      <ListSubheader className="ListSubheader">
                        D. Affiliation Perks
                      </ListSubheader>
                      <MenuItem value="Gratuity">Gratuity</MenuItem>
                      <MenuItem value="ESIC"> ESIC</MenuItem>
                    </Select>
                  </FormControl>
                </div>
              </td>
              <td>
                <div className="frmText">
                  <div className="formElement">
                    <FormControl className="formControl">
                      <OutlinedInput
                        className="formInputField outlineInput"
                        value={allTimeFormData.percent}
                        onChange={(e) => {
                          console.log("percent= ", e.target.value);
                          let data = parseFloat(e.target.value);
                          if (!isNaN(data) && data >= 0 && data <= 100) {
                            const temp = { ...allTimeFormData };
                            temp.percent = data;
                            setAllTimeFormData(temp);
                          }
                        }}
                        endAdornment={
                          <InputAdornment position="start">%</InputAdornment>
                        }
                      />
                    </FormControl>
                  </div>
                  <span>of</span>
                  <div className="formElement">
                    <FormControl className="formControl">
                      <Select
                        hiddenLabel
                        labelId="SelectPercentageOf"
                        id="Select-PercentageOf"
                        value={allTimeFormData.grossOrBasic}
                        onChange={(e) => {
                          console.log("changein");
                          const temp = { ...allTimeFormData };
                          temp.grossOrBasic = e.target.value;
                          setAllTimeFormData(temp);
                        }}
                        className="formInputField"
                        variant="outlined"
                      >
                        <MenuItem value="gross">Gross</MenuItem>
                        <MenuItem value="basic">Basic</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                </div>
              </td>
              <td>
                <div className="formElement ">
                  <FormControl className="formControl">
                    <TextField
                      hiddenLabel
                      // label="Cost To Company (CTC)"
                      placeholder="Ex: 300000"
                      variant="outlined"
                      className="formTextFieldArea"
                      value={allTimeFormData.amount}
                      onChange={(e) => {
                        const temp = { ...allTimeFormData };
                        let amount = parseInt(e.target.value);
                        if (isNaN(amount)) {
                          amount = 0;
                        }
                        temp.amount = amount;
                        temp.monthly = parseInt(amount);
                        temp.annual = parseInt(amount) * 12;
                        setAllTimeFormData(temp);
                      }}
                    />
                  </FormControl>
                </div>
              </td>
              <td className="tright">
                <div class="textOnly">{allTimeFormData.annual}</div>
              </td>
              <td className="actionTDBtn">
                <Button
                  className="addNRwBtn"
                  variant="contained"
                  startIcon={<Add />}
                  onClick={handleAddSalaryItem}
                >
                  Add
                </Button>
              </td>
            </tr> */}
          </tbody>
          <tfoot>
            <tr>
              <td colSpan={3} className="tright">
                <div class="textOnly strong">Total</div>
              </td>
              <td className="tright">
                <div class="textOnly">{calculateMonthlyTotal()}</div>
              </td>
              <td className="tright">
                <div class="textOnly">{calculateAnnualTotal()}</div>
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  );
};
