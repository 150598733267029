import React, { useEffect, useState } from "react";
import DashboardServices from "../../../../services/DashboardServices";
import { useDispatch } from "react-redux";
import {
  pushLinkTask,
  setTaskModalData,
} from "../../../../redux/reducers/taskTab";
import { getLoginUserDetails } from "../../../../utils/Utils";
import { toast } from "react-toastify";

export const LinkedTaskModule = ({ data, handleLinkTaskClick }) => {
  const [linkTaskTree, setLinkTaskTree] = useState(null);
  const dispatch = useDispatch();
  const loginUserDetail = getLoginUserDetails();

  const listToTree = (arr) => {
    let map = {},
      node,
      res = [],
      i;

    for (i = 0; i < arr.length; i += 1) {
      map[arr[i].taskId] = i;
    }

    for (i = 0; i < arr.length; i += 1) {
      node = arr[i];
      if (node && node.parTaskId && node.parTaskId !== "") {
        const mapIndex = map[node.parTaskId];
        if (mapIndex) {
          if (arr[mapIndex].children) {
            arr[mapIndex].children.push(node);
          } else {
            arr[mapIndex].children = [node];
          }
        } else {
          res.push(node);
        }
      } else {
        res.push(node);
      }
    }

    const compareById = (a, b) => a.taskId - b.taskId;

    return res.sort(compareById);
  };

  useEffect(() => {
    if (data.taskLink) {
      DashboardServices.getLinkTasks(
        data.taskId,
        loginUserDetail.userId,
        "T"
      ).then((response) => {
        console.log();
        // const tree = buildTree(response.data);
        // console.log("tree = ", tree);
        const linkTaskTree = listToTree(response.data);
        setLinkTaskTree(linkTaskTree);
      });
    } else {
      setLinkTaskTree(null);
    }
  }, [data]);

  // const handleLinkTaskClick = async (task) => {
  //   // console.log("task id =", taskId);
  //   const values = await DashboardServices.getTaskById(task.taskId);
  //   dispatch(pushLinkTask(data));
  //   dispatch(setTaskModalData({ ...task, ...values.data }));
  // };

  const renderLinkTask = (taskObj) => {
    return (
      <>
        <ol className="listOfLinkTask">
          {taskObj &&
            taskObj.length > 0 &&
            taskObj.map((task) => (
              <>
                <li
                  className="linkTskInd"
                  // onClick={(e) => {
                  //   e.stopPropagation();
                  //   if (task.message && task.message !== "") {
                  //     toast.error(task.message);
                  //     return;
                  //   }
                  //   handleLinkTaskClick(task);
                  // }}
                >
                  <span className="linkTskNm">{task.taskName}</span>
                  {task.children &&
                    task.children.length > 0 &&
                    renderLinkTask(task.children)}
                </li>
              </>
            ))}
        </ol>
      </>
    );
  };

  return (
    <>
      {linkTaskTree && (
        <div className="taskElementGrp">
          <div className="tskElemHeding">Link Task</div>
          <div className="tskElemntInfo">
            {linkTaskTree && renderLinkTask(linkTaskTree)}
          </div>
        </div>
      )}
    </>
  );
};
