import { DescriptionOutlined } from "@mui/icons-material";
import { Tooltip } from "@mui/material";
import dayjs from "dayjs";
import React, { useState } from "react";
import GoodsAndServicesFormPreview from "./GoodsAndServicesFormPreview";
import DaTaFormPreview from "./DaTaFormPreview";
import { useEffect } from "react";
import { ManPowerPreview } from "./ManPowerPreview";
import { StatutoryPreview } from "./StatutoryPreview";
import { ReimbursementPreview } from "./ReimbursementPreview";
import { getExpenseApprover } from "../../../../../services/AdminService";
import { getLoginUserDetails } from "../../../../../utils/Utils";
import DateUtils from "../../../../../utils/DateUtils";

export const FormPreview = ({
  commonData,
  formData,
  tableDataList,
  DATA_data,
  costCenterList,
  billToShipToList,
  repeatUntil,
  manpowerList,
  statutoryList,
  reimbursementList,
  budgetHeadList,
}) => {
  useEffect(() => {
    console.log("Form data=", formData);
    console.log("cost center list", costCenterList);
  }, [formData, costCenterList]);

  const getCostCenterName = () => {
    let filtered = "";
    if (costCenterList && formData) {
      filtered = costCenterList.filter((center) => {
        return center.id == formData.costCenter;
      });
      if (filtered && filtered.length > 0) {
        return filtered[0].name;
      } else {
        return "";
      }
    }
    return "";
  };

  const getBudgetHeadName = (headId) => {
    const filtered = budgetHeadList.find((item) => {
      return item.id === headId;
    });
    if (filtered) {
      return filtered.head;
    } else {
      return "";
    }
  };

  const findBillToShipToName = (centerId) => {
    const centerList = billToShipToList.filter(
      (center) => center.id === centerId
    );
    let centerName = "---";
    if (centerList && centerList.length > 0) {
      centerName = `${centerList[0].unitName}-${centerList[0].city}-${centerList[0].countryName}`;
    }
    return centerName;
  };
  return (
    <>
      <div className="elementFormContainer previewSpace ">
        <div class="taskElementGrpRow">
          <div class="tskElemHeding">Expenditure</div>
          <div class="taskContractNm">
            {formData.expenditureType === "Reimbursement"
              ? "Reimbursement"
              : "Proposed Expenditure"}
          </div>
        </div>
        <div class="taskElementGrpRow">
          <div class="tskElemHeding">Expenditure for</div>
          <div class="taskContractNm">
            {formData.ExpenditureGoods === "goods"
              ? "Goods"
              : formData.ExpenditureGoods === "services"
              ? "Services"
              : formData.ExpenditureGoods}
          </div>
        </div>
        <div class="taskElementGrpRow">
          <div class="tskElemHeding">Expenditure type</div>
          <div class="taskContractNm">
            {formData.ceoe === "OperatingExpenditure"
              ? "Operating Expenditure"
              : "Capital Expenditure"}
          </div>
        </div>
        {/* <div class="taskElementGrpRow">
              <div class="tskElemHeding">Item type</div>
              <div class="taskContractNm">{formData.Tangible} </div>
            </div> */}

        {formData.expenditureType !== "Reimbursement" &&
          (formData.ExpenditureGoods === "goods" ||
            formData.ExpenditureGoods === "services") &&
          formData.ProcessDirect === "Direct" && (
            <>
              <div class="taskElementGrpRow">
                <div class="tskElemHeding">Transaction type</div>
                <div class="taskContractNm">{formData.ProcessDirect} </div>
              </div>
              <div class="taskElementGrpRow">
                <div class="tskElemHeding">Direct Purchase Reason</div>
                <div class="taskContractNm">
                  {formData.directPurchaseReason}{" "}
                </div>
              </div>
            </>
          )}
        <div class="taskElementGrpRow">
          <div class="tskElemHeding">Recurring Expenditure</div>
          <div class="taskContractNm">{formData.recurring ? "Yes" : "No"}</div>
        </div>
        {formData.recurring && (
          <>
            <div class="taskElementGrpRow">
              <div class="tskElemHeding">Recurring Period</div>
              <div class="taskContractNm">{formData.recurringPeriod} </div>
            </div>
            <div class="taskElementGrpRow">
              <div class="tskElemHeding">Repeat until Date</div>
              <div class="taskContractNm">
                {DateUtils.getDateInDDMMYYYY(repeatUntil)}
              </div>
            </div>
            <div class="taskElementGrpRow">
              <div class="tskElemHeding">Recurring document</div>
              <div class="taskContractNm">
                <div className="inlineDoc">
                  {formData.recurringDocument &&
                    formData.recurringDocument.fileName}
                  {/* {formData.recurringDocument &&
                    formData.recurringDocument.map((item) => (
                      <div className="elemntFile">
                        <Tooltip
                          title="Repeat Document"
                          arrow
                          className="elmFileTolTip"
                        >
                          <span className="elemntFilIoc">
                            <DescriptionOutlined />
                          </span>
                          <span className="elemntFilNm">{item.fileName}</span>
                        </Tooltip>
                      </div>
                    ))} */}
                </div>
              </div>
            </div>
          </>
        )}

        <div class="taskElementGrpRow">
          <div class="tskElemHeding">Purpose</div>
          <div class="taskContractNm">{formData.purpose}</div>
        </div>
        <div class="taskElementGrpRow">
          <div class="tskElemHeding">Contract</div>
          <div class="taskContractNm">{formData.contract}</div>
        </div>
        <div class="taskElementGrpRow">
          <div class="tskElemHeding">Target Delivery Date</div>
          <div class="taskContractNm">
            {DateUtils.getDateInDDMMYYYY(formData.expectedDate)}
          </div>
        </div>
        <div class="taskElementGrpRow">
          <div class="tskElemHeding">Cost Center</div>
          <div class="taskContractNm">
            {getCostCenterName(formData.costCenter)}
          </div>
        </div>
        {formData.ExpenditureGoods === "goods" &&
          formData.expenditureType !== "Reimbursement" && (
            <>
              <div class="taskElementGrpRow">
                <div class="tskElemHeding">Bill to</div>
                <div class="taskContractNm">
                  {findBillToShipToName(formData.billTo)}
                </div>
              </div>
              <div class="taskElementGrpRow">
                <div class="tskElemHeding">Ship To</div>
                <div class="taskContractNm">
                  {findBillToShipToName(formData.shipTo)}
                </div>
              </div>
            </>
          )}

        {(formData.ExpenditureGoods === "goods" ||
          formData.ExpenditureGoods === "services") &&
          formData.expenditureType !== "Reimbursement" && (
            <GoodsAndServicesFormPreview
              tableDataList={tableDataList}
              ExpenditureGoods={formData.ExpenditureGoods}
            />
          )}

        {formData.ExpenditureGoods === "Manpower" && (
          <ManPowerPreview manpowerList={manpowerList} />
        )}

        {formData.ExpenditureGoods === "StatutoryInsurancePayments" && (
          <StatutoryPreview statutoryList={statutoryList} />
        )}

        {formData.ExpenditureGoods === "DA/TA" &&
          formData.expenditureType === "Reimbursement" && (
            <DaTaFormPreview DATA_data={DATA_data} />
          )}

        {(formData.ExpenditureGoods === "goods" ||
          formData.ExpenditureGoods === "services") &&
          formData.expenditureType === "Reimbursement" && (
            <ReimbursementPreview reimbursementList={reimbursementList} />
          )}

        {formData.ExpenditureGoods === "advance" && (
          <>
            <div class="taskElementGrpRow">
              <div class="tskElemHeding">Advance</div>
              <div class="taskContractNm">{formData.advance}</div>
            </div>
            <div class="taskElementGrpRow">
              <div class="tskElemHeding">Expected Cost</div>
              <div class="taskContractNm">{formData.expectedCost}</div>
            </div>
            <div class="taskElementGrpRow">
              <div class="tskElemHeding">Budget Head</div>
              <div class="taskContractNm">
                {getBudgetHeadName(formData.budgetHead)}
              </div>
            </div>
          </>
        )}

        <div class="taskElementGrpRow">
          <div class="tskElemHeding">Approval Request Will Be Sent To</div>
          <div class="taskContractNm">{formData.expenseApprover.userName}</div>
        </div>
      </div>
    </>
  );
};
