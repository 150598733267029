import React, { useState, useEffect } from "react";
import {
  CheckCircle,
  DownloadForOffline,
  Translate,
} from "@mui/icons-material";
import { Autocomplete, Button, IconButton, TextField } from "@mui/material";
import { XLg } from "react-bootstrap-icons";
import {
  getSubtitlesOfMeeting,
  getBcp47LanguageCodeNameOfUser,
  getOrgImageBase64,
  getParticipantsOfEvent,
} from "../../../../../../services/VideoConferenceService";
import { jwtDecode } from "jwt-decode";
import { toast } from "react-toastify";
import { publicUrl } from "../../../../../../constants/url_provider";
import jsPDF from "jspdf";
import "jspdf-autotable";
import AppUtils from "../../../../../../utils/AppUtils";
import DateUtils from "../../../../../../utils/DateUtils";
import { GOOGLE_TRANSLATE_SUPPORTED_LANG } from "../../../../../../constants/const_values";
import { chatAI } from "../../../../../../services/MeetingService";
import { CustomModal } from "../../../../../task/view/component/Modals/CustomModal";
import ZoyelRTERnd from "../../../../../RTE/zoyelRTERnd";

const TranscriptionDetails = ({
  handleMenuCloseButton,
  selEvent,
  roomNumber,
}) => {
  const [loading, setLoading] = useState(false);
  const [subtitles, setSubtitles] = useState([]);
  const [targetLanguage, setTargetLanguage] = useState(null);

  const userDetails = jwtDecode(localStorage.getItem("token"));
  const [intervalId, setIntervalId] = useState(null);
  const [refresh, setRefresh] = useState(0);
  const [downloading, setDownloading] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [showMom, setShowMom] = useState(false);

  const [rteHtml, setRteHtml] = useState("");
  const [rteRawJson, setRteRawJson] = useState(null);
  const [imageList, setImageList] = useState([]);
  const [rteText, setRteText] = useState("");

  // const languages = [
  //   { label: "English", languageCode: "en" },
  //   { label: "Bengali", languageCode: "bn" },
  //   { label: "French", languageCode: "fr" },
  //   { label: "Spanish", languageCode: "es" },
  //   { label: "Arabic", languageCode: "ar" },
  // ];

  console.log("selEvent", selEvent);

  const languages = GOOGLE_TRANSLATE_SUPPORTED_LANG;

  console.log("languages", languages);
  console.log("languages[0]", languages[0]);

  const [value, setValue] = useState(languages[0]);

  React.useEffect(() => {
    // console.log("TranscriptionContent UseEffect", props.meetingFileUploaded);
    setLoading(true);
    getSubtitlesOfMeeting(roomNumber).then(async (response) => {
      console.log(response.data);
      if (response.data) {
        if (localStorage.getItem("meetingSubs")) {
          console.log(
            "1",
            localStorage.getItem("meetingSubs"),
            localStorage.getItem("ORG_ID")
          );
          let tempList = JSON.parse(localStorage.getItem("meetingSubs"));
          if (response.data.length > 0) {
            for (
              let index = tempList.length;
              index < response.data.length;
              index++
            ) {
              const subDto = response.data[index];
              tempList.push(subDto);
            }
            setSubtitles(tempList);
          }
        } else {
          setSubtitles(response.data);
        }

        if (localStorage.getItem("sub-lang")) {
          setTargetLanguage(localStorage.getItem("sub-lang"));
          let langObj = {
            languageCode: localStorage.getItem("sub-lang"),
            languageName: localStorage.getItem("sub-langName"),
          };
          setValue(langObj);
          //   localStorage.setItem("sub-lang", "en-US");
        } else {
          await getBcp47LanguageCodeNameOfUser(userDetails.userId).then(
            (response) => {
              if (response && response.data && response.data.length > 0) {
                // for (let i = 0; i < response.data.length; i++) {
                //   response.data[i].selected = true;
                //   spokenLanguagesTemp.push(response.data[i]);
                //   spokenLanguagesCode.push(response.data[i].id);
                // }
                let isEnglishPresent = false;
                for (let index = 0; index < response.data.length; index++) {
                  const langDto = response.data[index];
                  if (langDto.name.substring(0, 2).toLowerCase() === "en") {
                    isEnglishPresent = true;
                  }
                }
                if (isEnglishPresent) {
                  setTargetLanguage("en-US");
                  localStorage.setItem("sub-lang", "en-US");
                  localStorage.setItem("sub-langName", "English");
                  let langObj = {
                    languageCode: localStorage.getItem("sub-lang"),
                    languageName: localStorage.getItem("sub-langName"),
                  };
                  setValue(langObj);
                } else {
                  setTargetLanguage(response.data[0].id);
                  localStorage.setItem("sub-lang", response.data[0].id);
                  localStorage.setItem("sub-langName", response.data[0].name);
                  let langObj = {
                    languageCode: localStorage.getItem("sub-lang"),
                    languageName: localStorage.getItem("sub-langName"),
                  };
                  setValue(langObj);
                }
              } else {
                setTargetLanguage("en-US");
                localStorage.setItem("sub-lang", "en-US");
                localStorage.setItem("sub-langName", "English");
                let langObj = {
                  languageCode: localStorage.getItem("sub-lang"),
                  languageName: localStorage.getItem("sub-langName"),
                };
                setValue(langObj);
              }
            }
          );
        }
      }
      setLoading(false);
    });
  }, [roomNumber]);

  // useEffect(() => {
  //   setLoading(true);
  //   getSubtitlesOfMeeting(roomNumber).then(async (response) => {
  //     if (response.data) {
  //       let tempList = JSON.parse(localStorage.getItem("meetingSubs")) || [];
  //       if (response.data.length > tempList.length) {
  //         tempList = [...tempList, ...response.data.slice(tempList.length)];
  //         setSubtitles(tempList);
  //       } else {
  //         setSubtitles(response.data);
  //       }

  //       const storedLang = localStorage.getItem("sub-lang");
  //       if (storedLang) {
  //         setTargetLanguage(storedLang);
  //         setValue(languages.find((lang) => lang.languageCode === storedLang));
  //       } else {
  //         const langResponse = await getBcp47LanguageCodeNameOfUser(
  //           userDetails.userId
  //         );
  //         if (
  //           langResponse &&
  //           langResponse.data &&
  //           langResponse.data.length > 0
  //         ) {
  //           const englishLang = langResponse.data.find(
  //             (lang) => lang.name.substring(0, 2).toLowerCase() === "en"
  //           );
  //           const defaultLang = englishLang || langResponse.data[0];
  //           setTargetLanguage(defaultLang.id);
  //           localStorage.setItem("sub-lang", defaultLang.id);
  //           localStorage.setItem("sub-langName", defaultLang.name);
  //           setValue({
  //             languageCode: defaultLang.id,
  //             languageName: defaultLang.name,
  //           });
  //         }
  //       }
  //     }
  //     setLoading(false);
  //   });
  // }, [roomNumber, userDetails.userId, languages]);

  useEffect(() => {
    let tempIntervalId = setInterval(() => {
      refreshSubtitlesTimer();
    }, 5000);
    setIntervalId(tempIntervalId);
    return () => {
      clearInterval(tempIntervalId);
    };
  }, [roomNumber]);
  const translateSubtitles = async () => {
    setLoading(true);
    console.log("translateSubtitles UseEffect", targetLanguage);
    let tempSubList = [];
    for (let index = 0; index < subtitles.length; index++) {
      let subDto = subtitles[index];
      console.log("translateSubtitles UseEffect", subDto);
      if (
        targetLanguage.substring(0, 2).toLowerCase() !==
        subDto.languageCode.toLowerCase().substring(0, 2)
      ) {
        let translatedText = await AppUtils.googleTranslate(
          subDto.transcript,
          subDto.languageCode,
          targetLanguage
        );
        subDto.transcript = translatedText;
        subDto.languageCode = targetLanguage;
      }
      tempSubList.push(subDto);
    }
    setSubtitles(tempSubList);
    setRefresh(refresh + 1);
    setLoading(false);
    const jsonString = JSON.stringify(tempSubList);
    localStorage.setItem("meetingSubs", jsonString);
  };

  const translateText = async (id, text, fromLang) => {
    let translatedText = await AppUtils.googleTranslate(
      text,
      fromLang,
      targetLanguage
    );
    console.log("translateText--->>", id);
    // subtitles.forEach((subDto) => {
    //   if (subDto.id === id) {
    //     console.log("Here--->>", subDto);
    //     subDto.transcript = text;
    //     subDto.languageCode = targetLanguage;
    //   }
    // });
    await setSubtitles((prevData) => {
      const newData = [...prevData];
      newData[id].transcript = translatedText;
      newData[id].languageCode = targetLanguage;
      return newData;
    });
    let tempList = subtitles;
    const jsonString = JSON.stringify(tempList);
    localStorage.setItem("meetingSubs", jsonString);
    // setRefresh(refresh + 1);
  };

  const refreshSubtitles = () => {
    setLoading(true);
    let isNewSubPresent = false;
    getSubtitlesOfMeeting(roomNumber).then(async (response) => {
      let tempSubs = JSON.parse(localStorage.getItem("meetingSubs")) || [];
      if (response.data && response.data.length > tempSubs.length) {
        isNewSubPresent = true;
        tempSubs = [...tempSubs, ...response.data.slice(tempSubs.length)];
      }
      if (isNewSubPresent) {
        toast.success(`Subtitles loaded successfully`, {
          position: toast.POSITION.TOP_RIGHT,
        });
        setSubtitles(tempSubs);
      } else {
        toast.info(`No More Subtitles Present`, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
      setLoading(false);
    });
  };

  const refreshSubtitlesTimer = () => {
    let isNewSubPresent = false;
    getSubtitlesOfMeeting(roomNumber).then(async (response) => {
      let tempSubs = JSON.parse(localStorage.getItem("meetingSubs")) || [];
      if (response.data && response.data.length > tempSubs.length) {
        isNewSubPresent = true;
        tempSubs = [...tempSubs, ...response.data.slice(tempSubs.length)];
      }
      if (isNewSubPresent) {
        setSubtitles(tempSubs);
      }
    });
  };

  const translateAll = () => {
    translateSubtitles();
  };

  // const generatePDF = async (data) => {
  //   setDownloading(true);
  //   toast.info(`Downloading transcriptions please wait`, {
  //     position: toast.POSITION.TOP_RIGHT,
  //   });
  //   const response = await getOrgImageBase64(userDetails.orgId);
  //   const responseParticipants = await getParticipantsOfEvent(selEvent.eventId);
  //   const dateExported = new Date();
  //   try {
  //     const doc = new jsPDF();

  //     const fontUrl = publicUrl + "/zoyel_elements/fonts/ArialUnicodeMS.ttf";
  //     const fontName = "NotoSans";
  //     const fontOptions = {
  //       url: fontUrl,
  //       name: fontName,
  //       fontStyle: "normal",
  //       fontWeight: "normal",
  //     };
  //     doc.addFont(
  //       fontOptions.url,
  //       fontOptions.name,
  //       fontOptions.fontStyle,
  //       fontOptions.fontWeight
  //     );

  //     doc.addImage(response.data, "PNG", 15, 5, 15, 15);

  //     doc.setFontSize(16);

  //     const headerText = `Meeting Transcript of ${selEvent.eventTitle}`;
  //     const footerTextLeft = `Transcript exported at ${DateUtils.getDateInDDMMYYYYHH12MMSSMonthName(
  //       dateExported
  //     )}`;
  //     const footerTextRight = userDetails.orgName; // Assuming userDetails contains orgName

  //     doc.text(headerText, doc.internal.pageSize.getWidth() / 2, 15, {
  //       align: "center",
  //     });

  //     doc.text(`Description: ${selEvent.eventDescription}`, 15, 40, {
  //       fontSize: 10,
  //     });
  //     doc.text(
  //       `Start Time: ${DateUtils.getDateInDDMMYYYYHH12MM(
  //         selEvent.eventStartTime
  //       )}`,
  //       15,
  //       50,
  //       { fontSize: 10 }
  //     );
  //     doc.text(
  //       `End Time: ${DateUtils.getDateInDDMMYYYYHH12MM(selEvent.eventEndTime)}`,
  //       15,
  //       60,
  //       { fontSize: 10 }
  //     );
  //     doc.text(
  //       `Participants: ${responseParticipants.data
  //         .map((obj) => obj.userName)
  //         .join(", ")}`,
  //       15,
  //       70,
  //       { fontSize: 10 }
  //     );

  //     // Add dotted line
  //     const lineY = 25;
  //     const lineWidth = doc.internal.pageSize.getWidth() - 20;
  //     const lineSpacing = 2;
  //     doc.setDrawColor(0);
  //     doc.setLineDash([lineSpacing]);
  //     doc.line(15, lineY, 5 + lineWidth, lineY);

  //     const tableRows = [];
  //     data.forEach((obj) => {
  //       const dataRow = [
  //         DateUtils.getDateInDDMMYYYYHH12MMSSMonthName(obj.audioStartTime),
  //         obj.userName,
  //         obj.transcript,
  //       ];
  //       tableRows.push(dataRow);
  //     });

  //     // Add table
  //     const tableColumnHeaders = ["Time", "Speaker Name", "Speaker Text"];

  //     doc.autoTable({
  //       startY: 80,
  //       head: [tableColumnHeaders],
  //       body: tableRows,
  //       theme: "grid",
  //       headStyles: {
  //         fillColor: "#8f95eb",
  //       },
  //     });

  //     // Get the number of pages
  //     const pageCount = doc.internal.getNumberOfPages();

  //     // Add footer to each page
  //     for (let i = 1; i <= pageCount; i++) {
  //       doc.setPage(i);
  //       doc.setFontSize(10);
  //       doc.setTextColor("#000000");
  //       doc.text(footerTextLeft, 15, doc.internal.pageSize.getHeight() - 10, {
  //         align: "left",
  //       });
  //       doc.text(
  //         footerTextRight,
  //         doc.internal.pageSize.getWidth() - 15,
  //         doc.internal.pageSize.getHeight() - 10,
  //         { align: "right" }
  //       );
  //     }

  //     // Save the PDF
  //     doc.save(
  //       `${roomNumber}_${DateUtils.getDateInddmmyy_hhmmss(dateExported)}.pdf`
  //     );
  //     setDownloading(false);
  //     toast.success(`Transcriptions Downloaded`, {
  //       position: toast.POSITION.TOP_RIGHT,
  //     });
  //   } catch (error) {
  //     console.error("Error generating PDF:", error);
  //     setDownloading(false);
  //   }
  // };

  const generatePDF = async (data) => {
    console.log("generatePDF- selEvent", selEvent);
    setDownloading(true);
    toast.info(`Downloading transcriptions please wait`, {
      position: toast.POSITION.TOP_RIGHT,
    });
    const fontUrl = publicUrl + "/zoyel_elements/fonts/ArialUnicodeMS.ttf";
    const fontName = "NotoSans";

    const fontOptions = {
      url: fontUrl,
      name: fontName,
      fontStyle: "normal",
      fontWeight: "normal",
    };

    const doc = new jsPDF();

    doc.addFont(
      fontOptions.url,
      fontOptions.name,
      fontOptions.fontStyle,
      fontOptions.fontWeight
    );

    doc.setFont(fontOptions.name);
    const tableColumnHeaders = ["Time", "Speaker Name", "Speaker Text"];
    const tableRows = [];

    const tableHeaderColor = "#8f95eb";
    const headerColor = "#000000";

    const dateExported = new Date();
    const headerText = `Meeting Transcript of ${selEvent.eventTitle}`;
    const footerTextLeft = `Transcript exported at ${DateUtils.getDateInDDMMYYYYHH12MMSSMonthName(
      dateExported
    )}`;
    const footerTextRight = userDetails.orgName;
    const response = await getOrgImageBase64(userDetails.orgId);
    const responseParticipants = await getParticipantsOfEvent(selEvent.eventId);

    const listItems = [
      `Description: ${selEvent.eventDescription}`,
      `Start Time: ${DateUtils.getDateInDDMMYYYYHH12MMSSMonthName(
        selEvent.eventStartTime
      )}`,
      `End Time: ${DateUtils.getDateInDDMMYYYYHH12MMSSMonthName(
        selEvent.eventEndTime
      )}`,
      `Participants: ${responseParticipants.data
        .map((obj) => obj.userName)
        .join(", ")}`,
    ];

    data.forEach((obj) => {
      const dataRow = [
        DateUtils.getDateInDDMMYYYYHH12MMSSMonthName(obj.audioStartTime),
        obj.userName,
        obj.transcript,
      ];
      tableRows.push(dataRow);
    });

    const cellWidth = 40;
    const cellHeight = 5;
    const startY = 40;

    doc.addImage(response.data, "PNG", 15, 5, 15, 15);

    doc.setFontSize(16);
    doc.setTextColor(headerColor);
    doc.text(headerText, doc.internal.pageSize.getWidth() / 2, 15, {
      align: "center",
    });

    const lineY = 25;
    const lineWidth = doc.internal.pageSize.getWidth() - 20;
    const lineSpacing = 2;
    doc.setDrawColor(0);
    doc.setLineDash([lineSpacing]);
    doc.line(15, lineY, 5 + lineWidth, lineY);

    const listItemX = 15;
    const listItemY = 30;
    const listItemSpacing = 1;

    listItems.forEach((item, index) => {
      const listItemPosY = listItemY + index * (cellHeight + listItemSpacing);
      doc.setFontSize(10);
      doc.setTextColor("#000000");
      doc.text(item, listItemX, listItemPosY, {
        maxWidth: 180,
        align: "left",
        baseline: "top",
        angle: 0,
      });
    });

    doc.setLineDash([0]);
    const tableStartY =
      listItemY + listItems.length * (cellHeight + listItemSpacing) + 10;

    doc.autoTable({
      head: [tableColumnHeaders],
      body: tableRows,
      startY: tableStartY,
      theme: "grid",
      styles: {
        font: fontOptions.name,
        fontStyle: "normal",
      },
      headStyles: {
        fillColor: tableHeaderColor,
      },
    });

    const pageCount = doc.internal.getNumberOfPages();

    for (let i = 1; i <= pageCount; i++) {
      doc.setPage(i);
      doc.setFontSize(10);
      doc.setTextColor("#000000");
      doc.text(footerTextLeft, 15, doc.internal.pageSize.getHeight() - 10, {
        align: "left",
      });
      doc.text(
        footerTextRight,
        doc.internal.pageSize.getWidth() - 15,
        doc.internal.pageSize.getHeight() - 10,
        { align: "right" }
      );
    }

    doc.save(
      `${roomNumber}_${DateUtils.getDateInddmmyy_hhmmss(dateExported)}.pdf`
    );
    setDownloading(false);
  };

  // const generateMOM = () => {
  //   getSubtitlesOfMeeting(roomNumber).then((response) => {
  //     if (response.data && response.data.length > 0) {
  //       let tempString = response.data
  //         .map(
  //           (item) =>
  //             `${DateUtils.getDateInDDMMYYYYHH12MMSSMonthName(
  //               item.audioStartTime
  //             )} [${item.userName}]  ${item.transcript}`
  //         )
  //         .join(",");

  //       tempString =
  //         tempString + ", this is the transcript,generate MOM,Please ";

  //       const reqDto = {
  //         model: "gpt-3.5-turbo",
  //         messages: [
  //           {
  //             role: "user",
  //             content: tempString,
  //           },
  //         ],
  //       };

  //       chatAI(reqDto).then((res) => {
  //         console.log(res.data);
  //         const msg = res.data.choices[0].message.content;
  //         setRteText(msg);
  //         setShowMom(true);
  //       });
  //     } else {
  //       toast.error("Could not generate MOM");
  //     }
  //   });
  // };

  // const generateMomPdf = async () => {
  //   const fontUrl = publicUrl + "/zoyel_elements/fonts/ArialUnicodeMS.ttf";
  //   const fontName = "NotoSans";

  //   const fontOptions = {
  //     url: fontUrl,
  //     name: fontName,
  //     fontStyle: "normal",
  //     fontWeight: "normal",
  //   };

  //   const doc = new jsPDF();
  //   doc.addFont(
  //     fontOptions.url,
  //     fontOptions.name,
  //     fontOptions.fontStyle,
  //     fontOptions.fontWeight
  //   );

  //   doc.setFont(fontOptions.name);

  //   const dateExported = new Date();
  //   const headerText = `Meeting MOM of ${selEvent.eventTitle}`;
  //   const footerTextLeft = `MOM exported at ${DateUtils.getDateInDDMMYYYYHH12MMSSMonthName(
  //     dateExported
  //   )}`;

  //   const footerTextRight = userDetails.orgName;
  //   const response = await getOrgImageBase64(userDetails.orgId);
  //   const responseParticipants = await getParticipantsOfEvent(selEvent.eventId);

  //   const listItems = [
  //     `Description: ${selEvent.eventDescription}`,
  //     `Start Time: ${DateUtils.getDateInDDMMYYYYHH12MMSSMonthName(
  //       selEvent.eventStartTime
  //     )}`,
  //     `End Time: ${DateUtils.getDateInDDMMYYYYHH12MMSSMonthName(
  //       selEvent.eventEndTime
  //     )}`,
  //     `Participants: ${responseParticipants.data
  //       .map((obj) => obj.userName)
  //       .join(", ")}`,
  //   ];

  //   const cellWidth = 40;
  //   const cellHeight = 5;
  //   const startY = 40;

  //   doc.addImage(response.data, "PNG", 15, 5, 15, 15);

  //   const headerColor = "#000000";
  //   doc.setFontSize(16);
  //   doc.setTextColor(headerColor);
  //   doc.text(headerText, doc.internal.pageSize.getWidth() / 2, 15, {
  //     align: "center",
  //   });

  //   const lineY = 25;
  //   const lineWidth = doc.internal.pageSize.getWidth() - 20;
  //   const lineSpacing = 2;
  //   doc.setDrawColor(0);
  //   doc.setLineDash([lineSpacing]);
  //   doc.line(15, lineY, 5 + lineWidth, lineY);

  //   const listItemX = 15;
  //   const listItemY = 30;
  //   const listItemSpacing = 1;

  //   listItems.forEach((item, index) => {
  //     const listItemPosY = listItemY + index * (cellHeight + listItemSpacing);
  //     doc.setFontSize(10);
  //     doc.setTextColor("#000000");
  //     doc.text(item, listItemX, listItemPosY, {
  //       maxWidth: 180,
  //       align: "left",
  //       baseline: "top",
  //       angle: 0,
  //     });
  //   });

  //   doc.setLineDash([0]);

  //   // const options = {
  //   //   html2canvas: { scale: 2 },
  //   //   jsPDF: { unit: "pt", format: "a4" },
  //   // };

  //   // Use html2pdf plugin
  //   // doc.html(rteHtml, options).then(() => {
  //   //   // Save the PDF
  //   //   doc.save("document.pdf");
  //   // });

  //   const marginLeft = 10;
  //   const marginTop = 64;
  //   const maxLineWidth = 180;
  //   const splitText = doc.splitTextToSize(rteText, maxLineWidth);

  //   doc.text(splitText, marginLeft, marginTop);

  //   const pageCount = doc.internal.getNumberOfPages();
  //   for (let i = 1; i <= pageCount; i++) {
  //     doc.setPage(i);
  //     doc.setFontSize(10);
  //     doc.setTextColor("#000000");
  //     doc.text(footerTextLeft, 15, doc.internal.pageSize.getHeight() - 10, {
  //       align: "left",
  //     });
  //     doc.text(
  //       footerTextRight,
  //       doc.internal.pageSize.getWidth() - 15,
  //       doc.internal.pageSize.getHeight() - 10,
  //       { align: "right" }
  //     );
  //   }

  //   doc.save(
  //     `${roomNumber}_${DateUtils.getDateInddmmyy_hhmmss(dateExported)}.pdf`
  //   );

  //   setDownloading(false);
  // };

  return (
    <>
      <div className="meetRightHeader">
        <div className="metRtTitle">Meeting Transcription</div>
        <div className="metRtAction">
          <IconButton
            aria-label="Close"
            onClick={() => handleMenuCloseButton()}
          >
            <XLg />
          </IconButton>
        </div>
      </div>

      <div className="MeetingTranscriptionContainer">
        <div className="Meeting_Transcription">
          <div className="transcripHeader">
            <Autocomplete
              className="transcripAutoComplete"
              disablePortal
              id="transcripAutoComplete"
              options={languages}
              value={value}
              onChange={(event, newValue) => {
                setTargetLanguage(newValue?.languageCode);
                localStorage.setItem("sub-lang", newValue?.languageCode);
                localStorage.setItem("sub-langName", newValue?.languageName);
                setValue(newValue);
              }}
              inputValue={inputValue}
              onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
              }}
              getOptionLabel={(option) => option?.languageName}
              renderInput={(params) => (
                <TextField
                  {...params}
                  hiddenLabel
                  placeholder="Select Translate Language"
                />
              )}
            />

            <Button
              className="transAllBtn"
              startIcon={<Translate />}
              onClick={() => {
                translateAll();
              }}
            >
              Translate All
            </Button>

            {/* <Button
              className="transAllBtn"
              onClick={() => {
                generateMOM();
              }}
            >
              Generate MOM
            </Button> */}
          </div>
          <div className="transcriptionContainArea">
            <ul className="tcUl custom-scrollbar">
              {subtitles &&
                targetLanguage &&
                subtitles.map((subDto, index) => {
                  return (
                    <li className="tcLi" key={subDto.id}>
                      <span>
                        {DateUtils.getDateInDDMMYYYYHH12MMSSMonthName(
                          subDto.audioStartTime
                        )}
                        :
                      </span>
                      <span>&nbsp;&nbsp;[{subDto.userName}]&nbsp;&nbsp;</span>
                      <span>{subDto.transcript}</span>
                      {targetLanguage &&
                        subDto.languageCode.substring(0, 2).toLowerCase() !==
                          targetLanguage.substring(0, 2).toLowerCase() && (
                          <span>
                            <button
                              className="btnTranslate"
                              onClick={() =>
                                translateText(
                                  index,
                                  subDto.transcript,
                                  subDto.languageCode
                                )
                              }
                            >
                              Translate
                            </button>
                          </span>
                        )}
                    </li>
                  );
                })}
              {subtitles && subtitles.length === 0 && "No Subtitles Present"}
              <span className="loadMoreSpn">
                <Button
                  onClick={() => {
                    refreshSubtitles();
                  }}
                  className="loadMoreBtn"
                >
                  {subtitles && subtitles.length === 0
                    ? "Load Subtitles"
                    : "Load More"}
                </Button>
              </span>
            </ul>
          </div>
        </div>
      </div>

      <div className="MeetRightFooter">
        <div className="meetRightFootBtnGrp">
          <Button
            className="downloadTranscriptionBtn"
            startIcon={<DownloadForOffline />}
            onClick={() => {
              generatePDF(subtitles);
            }}
            disabled={downloading}
          >
            {downloading ? "Please wait.." : "Download"}
          </Button>
          <Button
            className="cancleTransBtn"
            variant="outlined"
            onClick={() => handleMenuCloseButton()}
          >
            Close
          </Button>
        </div>
      </div>

      {/* {showMom && (
        <CustomModal
          modalTitle="Update MOM"
          // style={{ zIndex: 10001 }}
          closeModal={() => {
            setShowMom(false);
          }}
          className={"width90vwModel"}
        >
          <div>
            <ZoyelRTERnd
              setRteHtml={setRteHtml}
              setRteText={setRteText}
              setImageList={setImageList}
              imageList={imageList}
              placeholder={"Write about your product here..."}
              rteRawJson={rteRawJson}
              rteText={rteText}
              setRteRawJson={setRteRawJson}
            />
            <Button
              onClick={() => {
                console.log("Creating Pdf");
                generateMomPdf();
              }}
            >
              Download Pdf
            </Button>
          </div>
        </CustomModal>
      )} */}
    </>
  );
};

export default TranscriptionDetails;
