import React, { useMemo, useState } from "react";
import ReactDatePicker from "react-datepicker";

export const ZoyelDatepicker = ({
  selectedDateTime,
  handleChange,
  ...restProps
}) => {
  const range = (start, end, step) => {
    const tempList = [];
    let i = start;
    for (let i = start; i <= end; i += step) {
      tempList.push(i);
    }
    return tempList;
  };

  const getYear = (date) => {
    return date.getYear();
  };

  const today = new Date();
  const [years, setYears] = useState(range(1990, getYear(today) + 10, 1));

  // const years = range(1990, getYear(today) + 10, 1);
  // const years = [1990, 1991, 1992];

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  return (
    <div style={{ height: "150px" }}>
      <ReactDatePicker
        showIcon
        // toggleCalendarOnIconClick
        selected={selectedDateTime}
        onChange={(date) => {
          handleChange(date);
        }}
        renderCustomHeader={({
          date,
          changeYear,
          changeMonth,
          decreaseMonth,
          increaseMonth,
          prevMonthButtonDisabled,
          nextMonthButtonDisabled,
        }) => {
          return (
            <div
              style={{
                margin: 10,
                display: "flex",
                justifyContent: "center",
              }}
            >
              {/* Hello0 Man */}
              <button
                onClick={decreaseMonth}
                disabled={prevMonthButtonDisabled}
              >
                {"<"}
              </button>
              <select
                value={getYear(date)}
                onChange={({ target: { value } }) => changeYear(value)}
              >
                {years.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>
              <select
                value={months[date.getMonth()]}
                onChange={({ target: { value } }) =>
                  changeMonth(months.indexOf(value))
                }
              >
                {months.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>

              <button
                onClick={increaseMonth}
                disabled={nextMonthButtonDisabled}
              >
                {">"}
              </button>
            </div>
          );
        }}
        {...restProps}
      />
    </div>
  );
};
