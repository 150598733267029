import React, { useState } from "react";
import FilterCard from "../cards/FilterCard";
import MessageCard from "../cards/MessageCard";
import NoDataFoundCard from "../cards/NoDataFoundCard";
import TaskCard from "../cards/TaskCard";
import EmailCard from "../cards/EmailCard";
import MeetingCard from "../cards/MeetingCard";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import LoaderSmall from "../../../../../utils/LoaderSmall";

export default function AllMenuComponent({
  dataList,
  loading,
  setLoading,
  selectedData,
  onClick,
  openRightMail,
  setEventData,
}) {
  const unreadMessages = useSelector(
    (state) => state.dashboardMail.unreadMessages
  );
  const [activeMsgId, setActiveMsgId] = useState(null);
  const taskList = useSelector((state) => state.task.taskList);

  const renderDashboardCardAccordingType = (item) => {
    switch (item.type) {
      case "CHAT":
        return (
          <MessageCard
            data={item}
            active={selectedData && selectedData.id === item.id}
            onClick={onClick}
          />
        );
      case "EVENT":
        var minutesDifference =
          (new Date(item.eventEndTime) - new Date()) / (1000 * 60);

        if (minutesDifference > 0) {
          return (
            <MeetingCard
              data={item}
              active={selectedData && selectedData.id === item.id}
              onClick={onClick}
              setEventData={(data) => setEventData(data)}
            />
          );
        } else {
          return <></>;
        }

      case "TASK":
        return (
          <TaskCard
            task={item}
            active={selectedData && selectedData.id === item.id}
            onClick={onClick}
          />
        );

      default:
        return <></>;
    }
  };

  return (
    <>
      <div className="mnuCompoContainer">
        {/* <FilterCard /> */}

        <div className="mnuCompoIncomingInstanse">
          {loading && <LoaderSmall />}
          {dataList && dataList.length > 0 && (
            <>
              {dataList.map((item) => renderDashboardCardAccordingType(item))}
            </>
          )}

          {/* <MeetingCard /> */}
          {unreadMessages &&
            unreadMessages.map((mail) => (
              <EmailCard
                mail={mail}
                openRightMail={openRightMail}
                setDataNull={() => {
                  onClick(null);
                }}
                active={activeMsgId}
                setActiveMsgId={setActiveMsgId}
              />
            ))}

          {(!dataList || dataList.length == 0) &&
            (!unreadMessages || unreadMessages.length === 0) && (
              <NoDataFoundCard />
            )}
        </div>
      </div>
    </>
  );
}
