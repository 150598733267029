import { Add, ExpandMoreRounded } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Button,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { Trash } from "react-bootstrap-icons";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";
import { getHealthDto, getFilteredList } from "../consultationUtil";
import DashboardServices from "../../../../../../../../services/DashboardServices";
import { useTranslation } from "react-i18next";

export default function PatientFamilyHistory({
  updateData,
  data,
  previousInfo,
}) {
  const { t } = useTranslation();
  const [selectRelationship, setSelectRelationship] = useState("");
  const [accordionExpanded, setAccordionExpanded] = useState(true);

  const [selectedDisease, setSelectedDisease] = useState(null);
  const [diseaseInputString, setDiseaseInputString] = useState("");
  const [diseaseList, setDiseaseList] = useState([]);
  const [userFamilyDiseaseHistoryList, setUserFamilyDiseaseHistoryList] =
    useState([]);
  const [relationshipList, setRelationshipList] = useState([]);

  const initializeForm = () => {
    setSelectedDisease(null);
    setDiseaseInputString("");
    setSelectRelationship("");
  };

  const [userPrevFamilyHistory, setUserPrevFamilyHistory] = useState(null);

  const suggestedDiseaseList = useMemo(() => {
    // const userFamilyDiseaseIdList=userFamilyDiseaseHistoryList(item=>item.disease.id)

    // let filteredDiseaseList = diseaseList.filter((procedure) => {
    //   const alreadyTaken = userFamilyDiseaseIdList.some(
    //     (item) => item === procedure.id
    //   );
    //   return !alreadyTaken;
    // });

    const filteredList = getFilteredList(
      diseaseList,
      diseaseInputString,
      "diseaseName"
    );
    return filteredList.slice(0, 10);
  }, [diseaseInputString, diseaseList]);

  const familyDiseaseHistoryString = useMemo(() => {
    const str =
      userFamilyDiseaseHistoryList.length > 0
        ? userFamilyDiseaseHistoryList
            .map(
              (item) =>
                `${item.prev ? item.diseaseName : item.disease.diseaseName} (${
                  item.relationShip
                })`
            )
            .join(", ")
        : "";
    return str;
  }, [userFamilyDiseaseHistoryList]);

  useEffect(() => {
    if (data && data.familyHistory) {
      setUserFamilyDiseaseHistoryList(data.familyHistory);
    }

    getHereditaryDiseases();
    getRelationships();
  }, []);

  useEffect(() => {
    if (previousInfo && diseaseList && relationshipList) {
      const { hereditaryDiseases } = previousInfo;

      setUserPrevFamilyHistory(
        hereditaryDiseases?.map((item) => ({ ...item, previouslyAdded: true }))
      );
      updateData({ prevFamilyDisease: hereditaryDiseases });
    }
  }, [previousInfo, diseaseList, relationshipList]);

  const getHereditaryDiseases = () => {
    const diseasetring = localStorage.getItem("heriditaryDisease");
    if (diseasetring) {
      setDiseaseList(JSON.parse(diseasetring));
      return;
    }

    const reqDto = getHealthDto();
    DashboardServices.getHereditaryDiseases(reqDto).then((response) => {
      if (response.data) {
        setDiseaseList(response.data);
        localStorage.setItem(
          "heriditaryDisease",
          JSON.stringify(response.data)
        );
      }
    });
  };

  const getRelationships = () => {
    const diseasetring = localStorage.getItem("relationshipList");
    if (diseasetring) {
      setRelationshipList(JSON.parse(diseasetring));
      return;
    }

    const reqDto = getHealthDto();
    DashboardServices.getRelationships(reqDto).then((response) => {
      if (response.data) {
        setRelationshipList(response.data);
        localStorage.setItem("relationshipList", JSON.stringify(response.data));
      }
    });
  };

  const handleChangeRelationship = (event) => {
    setSelectRelationship(event.target.value);
  };
  return (
    <>
      <Accordion
        className="docInputElmntAcod"
        expanded={accordionExpanded}
        onChange={() => {
          setAccordionExpanded((prev) => !prev);
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreRounded />}
          aria-controls="panel1-content"
          id="panel1-header"
          className="acodHeader"
        >
          <div className="tskElemHeding">
            {t("relevant_family_history")}:
            {!accordionExpanded && <span>{familyDiseaseHistoryString}</span>}
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <div className="complaintList">
            <ul>
              {userFamilyDiseaseHistoryList &&
                userFamilyDiseaseHistoryList.map((item, index) => {
                  return (
                    <li>
                      <div className="complstDtlGrp">
                        <div className="complstDtl  ">
                          <div className="compntName">
                            {item.prev
                              ? item.diseaseName
                              : item.disease.diseaseName}
                          </div>
                          <div className="compinfoGRP">
                            <div className="compSeverity">
                              <span>{t("relationship")}:</span>
                              <span className="data">
                                {item.prev
                                  ? item.relationship
                                  : item.relationShip}
                              </span>
                            </div>
                          </div>
                        </div>
                        {!item.previouslyAdded && (
                          <IconButton
                            className="removeItemBtn"
                            onClick={() => {
                              const temp = [...userFamilyDiseaseHistoryList];
                              temp.splice(index, 1);
                              updateData({ familyHistory: temp });
                              setUserFamilyDiseaseHistoryList(temp);
                            }}
                          >
                            <Trash />
                          </IconButton>
                        )}
                      </div>
                    </li>
                  );
                })}

              {userPrevFamilyHistory &&
                userPrevFamilyHistory.map((item, index) => {
                  return (
                    <li>
                      <div className="complstDtlGrp">
                        <div className="complstDtl  ">
                          <div className="compntName">{item.diseaseName}</div>
                          <div className="compinfoGRP">
                            <div className="compSeverity">
                              <span>{t("relationship")}:</span>
                              <span className="data">{item.relationship}</span>
                            </div>
                          </div>
                        </div>
                        {!item.previouslyAdded && (
                          <IconButton
                            className="removeItemBtn"
                            onClick={() => {
                              const temp = [...userPrevFamilyHistory];
                              temp.splice(index, 1);
                              updateData({ prevFamilyDisease: temp });
                              setUserPrevFamilyHistory(temp);
                            }}
                          >
                            <Trash />
                          </IconButton>
                        )}
                      </div>
                    </li>
                  );
                })}

              {/* add new symptoms */}
              <li>
                <div className="addNewformGrp">
                  <div className="formElement">
                    <FormControl className="formControl">
                      {/* <TextField
                        // hiddenLabel
                        label="Family Disease History"
                        autoComplete="off"
                        placeholder="Enter Disease"
                        variant="outlined"
                        className="formTextFieldArea"
                      /> */}

                      <Autocomplete
                        freeSolo
                        className="formAutocompleteField"
                        variant="outlined"
                        value={selectedDisease}
                        options={suggestedDiseaseList}
                        inputValue={diseaseInputString}
                        onChange={(e, newValue) => {
                          setSelectedDisease(newValue);
                        }}
                        onInputChange={(e, value, reason) => {
                          if (e && reason === "input") {
                            setDiseaseInputString(e.target.value);
                          } else if (reason === "reset") {
                            setDiseaseInputString(value);
                          } else if (reason === "clear") {
                            setDiseaseInputString(value);
                          }
                          // else {
                          //   setAllergicFoodSearchString("");
                          // }
                        }}
                        getOptionLabel={(option) => option.diseaseName}
                        renderOption={(props, item) => {
                          return (
                            <li {...props} key={item.id}>
                              {item.diseaseName}
                            </li>
                          );
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            className="formAutoComInputField autocompFildPlaceholder"
                            placeholder={t("family_disease_history")}
                            InputProps={{
                              ...params.InputProps,
                            }}
                          />
                        )}
                      />
                    </FormControl>
                  </div>
                  <div className="formElement mxW-150">
                    <FormControl className="formControl">
                      <InputLabel id="severity-select-label">
                        {t("relationship")}
                      </InputLabel>
                      <Select
                        labelId="severity-select-label"
                        id="severity-select"
                        value={selectRelationship}
                        label={t("relationship")}
                        onChange={handleChangeRelationship}
                        className="formInputField"
                        variant="outlined"
                      >
                        <MenuItem value={null}>
                          {" -- Select Relationship --"}
                        </MenuItem>
                        {relationshipList &&
                          relationshipList.map((item) => {
                            return <MenuItem value={item}>{item}</MenuItem>;
                          })}
                        {/* <MenuItem value="Mother"> Mother </MenuItem>
                        <MenuItem value="Sister"> Sister </MenuItem>
                        <MenuItem value="Self">Self </MenuItem>
                        <MenuItem value="Son">Son </MenuItem>
                        <MenuItem value="Daughter">Daughter </MenuItem>
                        <MenuItem value="Spouse">Spouse </MenuItem> */}
                      </Select>
                    </FormControl>
                  </div>

                  <Button
                    className="dfultPrimaryBtn "
                    startIcon={<Add />}
                    // onClick={() => {
                    //   const temp = [...userFamilyDiseaseHistoryList];
                    //   temp.splice(index, 1);
                    //   setUserAllergicFoodList(temp);
                    // }}

                    onClick={() => {
                      if (!diseaseInputString?.trim()) {
                        toast.error(t("please_provide_family_disease_name"));
                        return;
                      }
                      if (!selectRelationship) {
                        toast.error(t("please_select_relationship"));
                        return;
                      }
                      let tempFamilyHistory = null;

                      const tempIndex = userPrevFamilyHistory?.findIndex(
                        (item) =>
                          item.diseaseName === diseaseInputString &&
                          item.relationship === selectRelationship
                      );

                      if (tempIndex != -1) {
                        toast.error("Record already exist");
                        return;
                      }

                      const tempIndex2 =
                        userFamilyDiseaseHistoryList?.findIndex((item) => {
                          // ;

                          return (
                            item.disease.diseaseName === diseaseInputString &&
                            item.relationShip === selectRelationship
                          );
                        });

                      if (tempIndex2 != -1) {
                        toast.error("Record already exist");
                        return;
                      }

                      if (!tempFamilyHistory) {
                        tempFamilyHistory = {
                          id: uuidv4(),
                          diseaseName: diseaseInputString,
                          locallyAdded: true,
                        };
                      }
                      setUserFamilyDiseaseHistoryList((prev) => {
                        const temp = [
                          ...prev,
                          {
                            disease: tempFamilyHistory,
                            relationShip: selectRelationship,
                          },
                        ];

                        updateData({ familyHistory: temp });
                        return temp;
                      });
                      initializeForm();
                    }}
                  >
                    {t("add")}
                  </Button>
                </div>
              </li>
            </ul>
          </div>
        </AccordionDetails>
      </Accordion>
    </>
  );
}
