import { Add, Close } from "@mui/icons-material";
import {
  Autocomplete,
  Button,
  Checkbox,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { Bookmark, Trash, XLg } from "react-bootstrap-icons";
import { useTranslation } from "react-i18next";
import DashboardServices from "../../../../../services/DashboardServices";
import {
  getFilteredList,
  getHealthDto,
  MAX_SUGGESTED_RESULT,
} from "../../../../scheduler/view/component/meetings/view/consultation/consultationUtil";
import { toast } from "react-toastify";

export default function CreateSTP({ handelCloseAddNewSTP }) {
  const { t } = useTranslation();
  const [selectDiseas, setSelectDiseas] = useState("");
  const [invAplyGender, setInvAplyGenders] = useState("");
  const [selectInvestigation, setSelectInvestigation] = useState("");
  const [selectCompound, setSelectCompound] = useState("");
  const [selectForm, setSelectForm] = useState("");
  const [selectMinDose, setSelectMinDose] = useState("");
  const [selectMaxDose, setSelectMaxDose] = useState("");

  const [selectDuration, setSelectDuration] = useState("");
  const [selectFrequency, setSelectFrequency] = useState("");
  const [selectParameter, setSelectParameter] = useState("");
  const [selectLifestyle, setSelectLifestyle] = useState("");
  const [selectProcedure, setSelectProcedure] = useState("");
  const [diseaseList, setDiseaseList] = useState([]);
  const [investigationList, setInvestigationList] = useState([]);
  const [userDiseaseList, setUserDiseaseList] = useState([]);
  const [userInvestigationList, setUserInvestigationList] = useState([]);

  const [selectedInvestigation, setSelectedInvestigation] = useState(null);
  const [investigationSearchString, setInvestigtionSearchString] = useState("");

  const [selectedDisease, setSelectedDisease] = useState(null);
  const [diseaseInputString, setDiseaseInputString] = useState("");

  const [selectedLifeStyle, setSelectedLifeStyle] = useState(null);
  const [lifeStyleString, setLifeStyleString] = useState("");
  const [lifeStyleList, setLifeStyleList] = useState([]);
  const [recommandedLifeStyle, setRecomandedLifeStyle] = useState([]);

  const [monitorNames, setMonitorNames] = useState([]);
  const [parameterMonitor, setParameterMonitor] = useState([]);

  const [upperAgeLimit, setUpperAgeLimit] = useState({
    year: "",
    month: "",
    day: "",
  });

  const handleChangeUpperAgeLimit = (event) => {
    const { name, value } = event.target;
    setUpperAgeLimit((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const initializeInvestigation = () => {
    setSelectedInvestigation(null);
    setInvestigtionSearchString("");
    setInvAplyGenders("null");
  };

  const initializeLifeStyle = () => {
    setLifeStyleString("");
    setSelectedLifeStyle(null);
  };

  const getDiseases = () => {
    const diseasetring = localStorage.getItem("diseaseList");
    if (diseasetring) {
      setDiseaseList(JSON.parse(diseasetring));
      return;
    }

    const reqDto = getHealthDto();
    DashboardServices.getDiseases(reqDto).then((response) => {
      if (response.data) {
        setDiseaseList(response.data);
        localStorage.setItem("diseaseList", JSON.stringify(response.data));
      }
    });
  };
  const getLifeStyles = () => {
    const lifeStyleString = localStorage.getItem("lifeStyleList");
    if (lifeStyleString) {
      setLifeStyleList(JSON.parse(lifeStyleString));
      return;
    }
    const reqDto = getHealthDto();
    DashboardServices.getLifeStyles(reqDto).then((response) => {
      if (response.data) {
        setLifeStyleList(response.data);
        localStorage.setItem("lifeStyleList", JSON.stringify(response.data));
      }
    });
  };

  const handleChangeparameterMonitor = (event) => {
    const {
      target: { value },
    } = event;
    setParameterMonitor(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };
  const computeMonitors = () => {
    let investigationList = [];
    let vitalsList = [];
    if (userInvestigationList) {
      investigationList = userInvestigationList.map(
        (item) => item.investigation.testName
      );
    }
    const vitalNameMaps = {
      TEMPERATURE: "Temperature",
      BP: "Blood Pressure",
      HEART_RATE: "Heart Rate",
      RESPIRATION_RATE: "Respiration Rate",
      SPO2: "SPO2",
    };
    const vitalNames = [
      "TEMPERATURE",
      "BP",
      "HEART_RATE",
      "RESPIRATION_RATE",
      "SPO2",
    ];
    vitalsList = vitalNames.map((item) => vitalNameMaps[item]);

    setMonitorNames([...vitalsList, ...investigationList]);
  };

  useEffect(() => {
    computeMonitors();
  }, [userInvestigationList]);

  const getMedicalTestList = () => {
    const symptomString = localStorage.getItem("medicalTestList");
    if (symptomString) {
      setInvestigationList(JSON.parse(symptomString));
      return;
    }

    const reqDto = getHealthDto();

    DashboardServices.getMedicalTestList(reqDto).then((response) => {
      if (response.data) {
        setInvestigationList(response.data);
        localStorage.setItem("medicalTestList", JSON.stringify(response.data));
      }
    });
  };

  // const suggestedInvestigation = useMemo(() => {
  //   const userIdInvestigationList = userInvestigationList.map(
  //     (item) => item.testId
  //   );

  //   let filteredInvestigation = processedTestList.filter((disease) => {
  //     const alreadyTaken = userIdInvestigationList.some(
  //       (item) => item === disease.testId
  //     );
  //     return !alreadyTaken;
  //   });

  //   const filteredList = getFilteredList(
  //     filteredInvestigation,
  //     investigationSearchString,
  //     "testName"
  //   );
  //   return filteredList.slice(0, MAX_SUGGESTED_RESULT);
  // }, [investigationSearchString, processedTestList, userInvestigationList]);

  const years = Array.from(new Array(100), (_, index) => index + 0); // Years from 1920 to 2020
  const months = Array.from(new Array(12), (_, index) => index + 1); // Months 1 to 12
  const days = Array.from(new Array(31), (_, index) => index + 1); // Days 1 to 31

  const handleChangselectProcedure = (event) => {
    setSelectProcedure(event.target.value);
  };
  const handleChangselectLifestyle = (event) => {
    setSelectLifestyle(event.target.value);
  };
  const handleChangselectParameter = (event) => {
    setSelectParameter(event.target.value);
  };
  const handleChangselectDuration = (event) => {
    setSelectDuration(event.target.value);
  };
  const handleChangselectFrequency = (event) => {
    setSelectFrequency(event.target.value);
  };

  const handleChangselectMinDose = (event) => {
    setSelectMinDose(event.target.value);
  };
  const handleChangselectMaxDose = (event) => {
    setSelectMaxDose(event.target.value);
  };
  const handleChangeSelectDiseas = (event) => {
    setSelectDiseas(event.target.value);
  };
  const handleChangselectForm = (event) => {
    setSelectForm(event.target.value);
  };

  const handleChangselectCompound = (event) => {
    setSelectCompound(event.target.value);
  };

  const handleChangeinvAplyGender = (event) => {
    setInvAplyGenders(event.target.value);
  };

  const handleChangselectInvestigation = (event) => {
    setSelectInvestigation(event.target.value);
  };

  useEffect(() => {
    getDiseases();
    getMedicalTestList();
    getLifeStyles();
  }, []);

  // const suggestedLifeStyleList = useMemo(() => {
  //   const userLifeStyleIdList = recommandedLifeStyle.map((item) => item.id);

  //   let filteredDietList = lifeStyleList.filter((lifeStyle) => {
  //     const alreadyTaken = userLifeStyleIdList.some(
  //       (item) => item === lifeStyle.id
  //     );
  //     return !alreadyTaken;
  //   });

  //   const filteredList = getFilteredList(
  //     filteredDietList,
  //     lifeStyleString,
  //     "lifeStyle"
  //   );
  //   return filteredList.slice(0, MAX_SUGGESTED_RESULT);
  // }, [lifeStyleString, lifeStyleList, recommandedLifeStyle]);

  const suggestedDiseaseList = useMemo(() => {
    const userDiseaseIdList = userDiseaseList.map((item) => item.diseaseObj.id);

    const filteredDiseaseList = diseaseList.filter((disease) => {
      const alreadyTaken = userDiseaseIdList.some(
        (item) => item === disease.id
      );
      return !alreadyTaken;
    });

    const filteredList = getFilteredList(
      filteredDiseaseList,
      diseaseInputString,
      "diseaseName"
    );
    return filteredList.slice(0, MAX_SUGGESTED_RESULT);
  }, [diseaseInputString, diseaseList, userDiseaseList]);

  return (
    <>
      <div className="flotingAreaContainer">
        <div className="flotingAreaClose">
          <div className="facHeader">Add New STP</div>
          <IconButton
            className="CloseBtn"
            onClick={() => handelCloseAddNewSTP()}
          >
            <XLg />
          </IconButton>
        </div>

        <div className="elementFormContainer">
          <div className="tskElemHeding mb-8">Disease with ICD11</div>
          <div className="formElement ">
            <FormControl className="formControl">
              {/* <InputLabel id="demo-simple-select-label">
                Select Disease
              </InputLabel> */}
              {/* <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={selectDiseas}
                label="Select Disease"
                onChange={handleChangeSelectDiseas}
                className="formInputField"
                variant="outlined"
              >
                <MenuItem value="d1">Disease one</MenuItem>
                <MenuItem value="d2">Disease two</MenuItem>
              </Select> */}

              <Autocomplete
                freeSolo
                className="formAutocompleteField"
                variant="outlined"
                value={selectedDisease}
                options={suggestedDiseaseList}
                inputValue={diseaseInputString}
                onChange={(e, newValue) => {
                  setSelectedDisease(newValue);
                }}
                onInputChange={(e, value, reason) => {
                  // if (e && e.target) {
                  //   setDiseaseInputString(e.target.value);
                  // }
                  setDiseaseInputString(value);
                  // else {
                  //   setSymptomSearchString("");
                  // }
                }}
                getOptionLabel={(option) => option.diseaseName}
                renderOption={(props, item) => {
                  return (
                    <li {...props} key={item.id}>
                      {item.diseaseName}
                    </li>
                  );
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    className="formAutoComInputField autocompFildPlaceholder"
                    label={t("disease_name")}
                    placeholder={"Search Disease"}
                    required
                    InputProps={{
                      ...params.InputProps,
                    }}
                  />
                )}
              />
            </FormControl>
          </div>

          {/* start Investigation */}
          <div className="tskElemHeding mb-8">Investigation</div>
          <div className="formElementGrp mb-8">
            <div className="formElement">
              <FormControl className="formControl">
                {/* <InputLabel id="selectInv">Investigation</InputLabel> */}
                {/* <Select
                  required
                  labelId="selectInv"
                  id="select_Inv"
                  value={selectInvestigation}
                  label="Investigation"
                  onChange={handleChangselectInvestigation}
                  className="formInputField"
                  variant="outlined"
                >
                  <MenuItem value="inv1">Investigation one</MenuItem>
                  <MenuItem value="inv2">Investigation two</MenuItem>
                </Select> */}

                <Autocomplete
                  // freeSolo
                  className="formAutocompleteField"
                  variant="outlined"
                  value={selectedInvestigation}
                  options={investigationList}
                  inputValue={investigationSearchString}
                  onChange={(e, newValue) => {
                    setSelectedInvestigation(newValue);
                    if (newValue) {
                      const { gender } = newValue;
                      setInvAplyGenders(gender);
                    }
                  }}
                  onInputChange={(e, value, reason) => {
                    setInvestigtionSearchString(value);
                  }}
                  getOptionLabel={(option) => option.testName}
                  renderOption={(props, item) => {
                    return (
                      <li
                        {...props}
                        key={item.id}
                        // className={
                        //   item.isAvailableInCenter ? "available" : ""
                        // }
                      >
                        {item.testName}
                        {/* {`(${item.gender})`} */}
                        {item.isAvailableInCenter && (
                          <Bookmark className="centAvlTest" />
                        )}
                      </li>
                    );
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      className="formAutoComInputField autocompFildPlaceholder"
                      placeholder={t("search_investigations")}
                      label={"Investigations"}
                      required
                      InputProps={{
                        ...params.InputProps,
                      }}
                    />
                  )}
                />
              </FormControl>
            </div>
            <div className="formElement mxW-100">
              <FormControl className="formControl">
                <InputLabel id="demo-simple-select-label">Gender</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={invAplyGender}
                  label="Gender"
                  onChange={handleChangeinvAplyGender}
                  className="formInputField"
                  variant="outlined"
                >
                  <MenuItem value="M">Male</MenuItem>
                  <MenuItem value="F">Female</MenuItem>
                  <MenuItem value="C">Other</MenuItem>
                </Select>
              </FormControl>
            </div>

            <Button
              startIcon={<Add />}
              className="dfultPrimaryBtn"
              onClick={() => {
                let isValid = true;
                if (!selectedInvestigation) {
                  toast.error("please select investigation");
                  isValid = false;
                  // return
                }
                if (!invAplyGender) {
                  toast.error("please select gender");
                  isValid = false;
                }
                if (!isValid) {
                  return;
                }

                const temp = {
                  investigation: selectedInvestigation,
                  gender: invAplyGender,
                };
                const tempIvn = [...userInvestigationList];
                tempIvn.push(temp);
                setUserInvestigationList(tempIvn);
                initializeInvestigation();
              }}
            >
              Add
            </Button>
          </div>
          <div className="invAddedListGrp">
            {userInvestigationList?.map((item, index) => {
              return (
                <div className="invAddedIndv">
                  <div className="invAddName">
                    {item?.investigation?.testName}
                  </div>
                  <IconButton
                    onClick={() => {
                      const temp = [...userInvestigationList];
                      temp.splice(index, 1);
                      setUserInvestigationList(temp);
                    }}
                  >
                    <Close />
                  </IconButton>
                </div>
              );
            })}

            {/* <div className="invAddedIndv">
              <div className="invAddName">
                Blood Glucose (Sugar) - Fasting (C)
              </div>
              <IconButton>
                <Close />
              </IconButton>
            </div> */}
          </div>
          {/* End Investigation */}

          {/* start medication */}
          <div className="tskElemHeding mb-8">Medication</div>
          <div className="formElement">
            <FormControl className="formControl">
              <TextField
                // hiddenLabel
                label="Regime"
                required
                autoComplete="off"
                variant="outlined"
                className="formTextFieldArea"
              />
            </FormControl>
          </div>

          <div className="medicationGroup">
            <div className="formElementGrp">
              <div className="formElement">
                <FormControl className="formControl">
                  <InputLabel id="selectComp">Select Compound</InputLabel>
                  <Select
                    required
                    labelId="selectComp"
                    id="select_Comp"
                    value={selectCompound}
                    label="Select Compound"
                    onChange={handleChangselectCompound}
                    className="formInputField"
                    variant="outlined"
                  >
                    <MenuItem value="comp1">Compound one</MenuItem>
                    <MenuItem value="comp2">Compound two</MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div className="formElement width13">
                <FormControl className="formControl">
                  <InputLabel id="selectComp">Form</InputLabel>
                  <Select
                    required
                    labelId="selectForm"
                    id="select_Form"
                    value={selectForm}
                    label="Form"
                    onChange={handleChangselectForm}
                    className="formInputField"
                    variant="outlined"
                  >
                    <MenuItem value="oral">Oral</MenuItem>
                    <MenuItem value="ing">Ing</MenuItem>
                  </Select>
                </FormControl>
              </div>
            </div>
            <div className="formElementGrp">
              <div className="formElement upperAgelimit_row">
                <FormControl className="formControl">
                  <InputLabel id="age-limit-label">
                    Upper Age Limit Year
                  </InputLabel>

                  <Select
                    labelId="age-limit-label"
                    id="year-select"
                    name="year"
                    label="Upper Age Limit year"
                    value={upperAgeLimit.year}
                    onChange={handleChangeUpperAgeLimit}
                    displayEmpty
                    fullWidth
                    className="formInputField"
                    variant="outlined"
                  >
                    {years.map((year) => (
                      <MenuItem key={year} value={year}>
                        {year}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <FormControl className="formControl">
                  <InputLabel id="age-limit-month">Month</InputLabel>
                  <Select
                    labelId="month-select-month"
                    id="month-select"
                    name="month"
                    label=" Month"
                    value={upperAgeLimit.month}
                    onChange={handleChangeUpperAgeLimit}
                    displayEmpty
                    fullWidth
                    className="formInputField"
                    variant="outlined"
                  >
                    {months.map((month) => (
                      <MenuItem key={month} value={month}>
                        {month}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <FormControl className="formControl">
                  <InputLabel id="age-limit-days">Days</InputLabel>
                  <Select
                    labelId="day-select-days"
                    id="day-select"
                    name="day"
                    label="Days"
                    value={upperAgeLimit.day}
                    onChange={handleChangeUpperAgeLimit}
                    displayEmpty
                    fullWidth
                    className="formInputField"
                    variant="outlined"
                  >
                    {days.map((day) => (
                      <MenuItem key={day} value={day}>
                        {day}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            </div>
            <div className="formElementGrp">
              <div className="formElement upperAgelimit_row">
                <FormControl className="formControl">
                  <InputLabel id="age-limit-label">
                    Lower Age Limit Year
                  </InputLabel>

                  <Select
                    labelId="age-limit-label"
                    id="year-select"
                    name="year"
                    label="Upper Age Limit year"
                    value={upperAgeLimit.year}
                    onChange={handleChangeUpperAgeLimit}
                    displayEmpty
                    fullWidth
                    className="formInputField"
                    variant="outlined"
                  >
                    {years.map((year) => (
                      <MenuItem key={year} value={year}>
                        {year}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <FormControl className="formControl">
                  <InputLabel id="age-limit-month">Month</InputLabel>
                  <Select
                    labelId="month-select-month"
                    id="month-select"
                    name="month"
                    label="Month"
                    value={upperAgeLimit.month}
                    onChange={handleChangeUpperAgeLimit}
                    displayEmpty
                    fullWidth
                    className="formInputField"
                    variant="outlined"
                  >
                    {months.map((month) => (
                      <MenuItem key={month} value={month}>
                        {month}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <FormControl className="formControl">
                  <InputLabel id="age-limit-days">Days</InputLabel>
                  <Select
                    labelId="day-select-days"
                    id="day-select"
                    name="day"
                    label="Days"
                    value={upperAgeLimit.day}
                    onChange={handleChangeUpperAgeLimit}
                    displayEmpty
                    fullWidth
                    className="formInputField"
                    variant="outlined"
                  >
                    {days.map((day) => (
                      <MenuItem key={day} value={day}>
                        {day}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            </div>
            <div className="formElementGrp">
              <div className="formElement">
                <FormControl className="formControl">
                  <InputLabel id="selectMinDose">Min Dose</InputLabel>
                  <Select
                    required
                    labelId="selectMinDose"
                    id="select_minDose"
                    value={selectMinDose}
                    label="Min Dose"
                    onChange={handleChangselectMinDose}
                    className="formInputField"
                    variant="outlined"
                  >
                    <MenuItem value="min1">min dose one</MenuItem>
                    <MenuItem value="min2">max dose two</MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div className="formElement">
                <FormControl className="formControl">
                  <InputLabel id="selectmaxDose">Max Dose</InputLabel>
                  <Select
                    required
                    labelId="selectmaxDose"
                    id="select_maxDose"
                    value={selectMaxDose}
                    label="Max Dose"
                    onChange={handleChangselectMaxDose}
                    className="formInputField"
                    variant="outlined"
                  >
                    <MenuItem value="min1">min dose one</MenuItem>
                    <MenuItem value="min2">max dose two</MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div className="formElement">
                <FormControl className="formControl">
                  <InputLabel id="selectDuration">Duration</InputLabel>
                  <Select
                    required
                    labelId="selectDuration"
                    id="select_Duration"
                    value={selectDuration}
                    label="Duration"
                    onChange={handleChangselectDuration}
                    className="formInputField"
                    variant="outlined"
                  >
                    <MenuItem value="1day">1 Day</MenuItem>
                    <MenuItem value="2day">2 Day</MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div className="formElement">
                <FormControl className="formControl">
                  <InputLabel id="selectFrequency">Frequency</InputLabel>
                  <Select
                    required
                    labelId="selectFrequency"
                    id="select_Frequency"
                    value={selectFrequency}
                    label="Frequency"
                    onChange={handleChangselectFrequency}
                    className="formInputField"
                    variant="outlined"
                  >
                    <MenuItem value="bid">BID</MenuItem>
                    <MenuItem value="tid">TID</MenuItem>
                  </Select>
                </FormControl>
              </div>
            </div>
            <Button className="dfultPrimaryBtn" startIcon={<Add />}>
              Add Medication
            </Button>
          </div>

          <div className="stpMedicationList">
            <ul>
              <li>
                <div className="regimeDtl">
                  <div className="regimeName">
                    Acute, Helicobacter Pylori Associated (H Pylori Eradication
                    Therapy - Triple Therapy [PPI AND AMOXICILLIN OR
                    METRONIDAZOLE])
                  </div>
                  <div className="rmvRegim">Remove</div>
                </div>
                <div className="medStpwthRmv">
                  <div className="medRecStpGrp">
                    <div className="medRecStpInd">
                      <span>Compound:</span>DEFERASIROX
                    </div>
                    <div className="medRecStpInd">
                      <span>Form:</span>Tablet
                    </div>
                    <div className="medRecStpInd">
                      <span>Upper Age:</span>50Y 6M 10D
                    </div>
                    <div className="medRecStpInd">
                      <span>Lower Age:</span>18Y 5M 30D
                    </div>
                    <div className="medRecStpInd">
                      <span>Min Dose:</span>1 mg/kg
                    </div>
                    <div className="medRecStpInd">
                      <span>Max Dose:</span>3 mg/kg
                    </div>
                    <div className="medRecStpInd">
                      <span>Duration:</span>10 Days
                    </div>
                    <div className="medRecStpInd">
                      <span>Frequency:</span>BID
                    </div>
                  </div>
                  <IconButton>
                    <Trash />
                  </IconButton>
                </div>
                <div className="medStpwthRmv">
                  <div className="medRecStpGrp">
                    <div className="medRecStpInd">
                      <span>Compound:</span>DEFERASIROX
                    </div>
                    <div className="medRecStpInd">
                      <span>Form:</span>Tablet
                    </div>
                    <div className="medRecStpInd">
                      <span>Upper Age:</span>50Y 6M 10D
                    </div>
                    <div className="medRecStpInd">
                      <span>Lower Age:</span>18Y 5M 30D
                    </div>
                    <div className="medRecStpInd">
                      <span>Min Dose:</span>1 mg/kg
                    </div>
                    <div className="medRecStpInd">
                      <span>Max Dose:</span>3 mg/kg
                    </div>
                    <div className="medRecStpInd">
                      <span>Duration:</span>10 Days
                    </div>
                    <div className="medRecStpInd">
                      <span>Frequency:</span>BID
                    </div>
                  </div>
                  <IconButton>
                    <Trash />
                  </IconButton>
                </div>
              </li>
              <li>
                <div className="regimeDtl">
                  <div className="regimeName">
                    Presumptive, At Risk Of Stress Gastritis (Anti-Secretory
                    Agents: Preventive Therapy)
                  </div>
                  <div className="rmvRegim">Remove</div>
                </div>
                <div className="medStpwthRmv">
                  <div className="medRecStpGrp">
                    <div className="medRecStpInd">
                      <span>Compound:</span>DEFERASIROX
                    </div>
                    <div className="medRecStpInd">
                      <span>Form:</span>Tablet
                    </div>
                    <div className="medRecStpInd">
                      <span>Upper Age:</span>50Y 6M 10D
                    </div>
                    <div className="medRecStpInd">
                      <span>Lower Age:</span>18Y 5M 30D
                    </div>
                    <div className="medRecStpInd">
                      <span>Min Dose:</span>1 mg/kg
                    </div>
                    <div className="medRecStpInd">
                      <span>Max Dose:</span>3 mg/kg
                    </div>
                    <div className="medRecStpInd">
                      <span>Duration:</span>10 Days
                    </div>
                    <div className="medRecStpInd">
                      <span>Frequency:</span>BID
                    </div>
                  </div>
                  <IconButton>
                    <Trash />
                  </IconButton>
                </div>
              </li>
            </ul>
          </div>
          {/* end medication */}

          {/* Start Parameter to be Monitor */}
          <div className="tskElemHeding mb-8">Parameter to be Monitor</div>
          <div className="formElementGrp mb-8">
            <div className="formElement">
              <FormControl className="formControl">
                <InputLabel id="selectparam">Select parameter</InputLabel>
                <Select
                  labelId="selectparam"
                  id="select_param"
                  multiple
                  value={parameterMonitor}
                  renderValue={(selected) => ""}
                  label="Select parameter"
                  onChange={handleChangeparameterMonitor}
                  className="formInputField"
                  variant="outlined"
                >
                  {monitorNames.map((name) => (
                    <MenuItem key={name} value={name}>
                      <Checkbox checked={parameterMonitor.indexOf(name) > -1} />
                      <ListItemText primary={name} />
                    </MenuItem>
                  ))}
                  {/* <MenuItem value="param1">Parameter one</MenuItem>
                  <MenuItem value="param2">Parameter two</MenuItem> */}
                </Select>
              </FormControl>
            </div>

            <Button startIcon={<Add />} className="dfultPrimaryBtn">
              Add
            </Button>
          </div>
          <div className="invAddedListGrp">
            {parameterMonitor?.map((item) => {
              return (
                <div className="invAddedIndv">
                  <div className="invAddName">{item}</div>
                  <IconButton>
                    <Close />
                  </IconButton>
                </div>
              );
            })}

            {/* <div className="invAddedIndv">
              <div className="invAddName">Respiration Rate</div>
              <IconButton>
                <Close />
              </IconButton>
            </div>
            <div className="invAddedIndv">
              <div className="invAddName">SPO2</div>
              <IconButton>
                <Close />
              </IconButton>
            </div> */}
          </div>
          {/* end Parameter to be Monitor */}

          {/* Start lifestyle */}
          <div className="tskElemHeding mb-8">Lifestyle Recommendations</div>
          <div className="formElementGrp mb-8">
            <div className="formElement">
              <FormControl className="formControl">
                {/* <InputLabel id="selectparam">Select Lifestyle</InputLabel> */}
                {/* <Select
                  labelId="selectparam"
                  id="select_Lifestyle"
                  value={selectLifestyle}
                  label="Select Lifestyle"
                  onChange={handleChangselectLifestyle}
                  className="formInputField"
                  variant="outlined"
                >
                  <MenuItem value="Lifestyle1">Lifestyle one</MenuItem>
                  <MenuItem value="Lifestyle2">Lifestyle two</MenuItem>
                </Select> */}
                <Autocomplete
                  freeSolo
                  className="formAutocompleteField"
                  variant="outlined"
                  value={selectedLifeStyle}
                  options={lifeStyleList}
                  inputValue={lifeStyleString}
                  onChange={(e, newValue) => {
                    setSelectedLifeStyle(newValue);
                  }}
                  onInputChange={(e, value) => {
                    setLifeStyleString(value);
                    // else {
                    //   setSymptomSearchString("");
                    // }
                  }}
                  getOptionLabel={(option) => option.lifeStyle}
                  renderOption={(props, item) => {
                    return (
                      <li {...props} key={item.id}>
                        {item.lifeStyle}
                      </li>
                    );
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      className="formAutoComInputField autocompFildPlaceholder"
                      placeholder={"Search lifestyle"}
                      label={t("life_style_name")}
                      required
                      InputProps={{
                        ...params.InputProps,
                      }}
                    />
                  )}
                />
              </FormControl>
            </div>

            <Button
              startIcon={<Add />}
              className="dfultPrimaryBtn"
              onClick={() => {
                if (!lifeStyleString || lifeStyleString === "") {
                  toast.error(t("please_provide_lifestyle"));
                  return;
                }
                let tempLifeStyle = selectedLifeStyle;

                setRecomandedLifeStyle((prev) => {
                  const temp = [...prev, tempLifeStyle];
                  return temp;
                });
                initializeLifeStyle();
              }}
            >
              Add
            </Button>
          </div>
          <div className="invAddedListGrp">
            {recommandedLifeStyle?.map((item, index) => {
              return (
                <div className="invAddedIndv">
                  <div className="invAddName">{item?.lifeStyle}</div>
                  <IconButton
                    onClick={() => {
                      const temp = [...recommandedLifeStyle];
                      temp.splice(index, 1);
                      setRecomandedLifeStyle(temp);
                    }}
                  >
                    <Close />
                  </IconButton>
                </div>
              );
            })}
          </div>
          {/* end lifestyle */}

          {/* start procedure */}
          <div className="tskElemHeding mb-8">Procedure</div>
          <div className="formElementGrp mb-8">
            <div className="formElement">
              <FormControl className="formControl">
                <InputLabel id="selectProcedure">Select Procedure</InputLabel>
                <Select
                  required
                  labelId="selectProcedure"
                  id="select_Procedure"
                  value={selectProcedure}
                  label="Select Procedure"
                  onChange={handleChangselectProcedure}
                  className="formInputField"
                  variant="outlined"
                >
                  <MenuItem value="pros1">Procedure one</MenuItem>
                  <MenuItem value="pros2">Procedure two</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div className="formElement mxW-100">
              <FormControl className="formControl">
                <InputLabel id="demo-simple-select-label">Gender</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={invAplyGender}
                  label="Gender"
                  onChange={handleChangeinvAplyGender}
                  className="formInputField"
                  variant="outlined"
                >
                  <MenuItem value="male">Male</MenuItem>
                  <MenuItem value="female">Female</MenuItem>
                  <MenuItem value="other">Other</MenuItem>
                </Select>
              </FormControl>
            </div>

            <Button startIcon={<Add />} className="dfultPrimaryBtn">
              Add
            </Button>
          </div>
          <div className="invAddedListGrp">
            <div className="invAddedIndv">
              <div className="invAddName">Cholesterol (C)</div>
              <IconButton>
                <Close />
              </IconButton>
            </div>
            <div className="invAddedIndv">
              <div className="invAddName">Acid Phosphatase - Serum (C)</div>
              <IconButton>
                <Close />
              </IconButton>
            </div>
            <div className="invAddedIndv">
              <div className="invAddName">Bilirubin (C)</div>
              <IconButton>
                <Close />
              </IconButton>
            </div>
            <div className="invAddedIndv">
              <div className="invAddName">C-Reactive Protein (CRP)</div>
              <IconButton>
                <Close />
              </IconButton>
            </div>
            <div className="invAddedIndv">
              <div className="invAddName">Amylase (M)</div>
              <IconButton>
                <Close />
              </IconButton>
            </div>
            <div className="invAddedIndv">
              <div className="invAddName">Human chorionic gonadotropin (F)</div>
              <IconButton>
                <Close />
              </IconButton>
            </div>
            <div className="invAddedIndv">
              <div className="invAddName">
                Blood Glucose (Sugar) - Fasting (C)
              </div>
              <IconButton>
                <Close />
              </IconButton>
            </div>
          </div>
          {/* End procedure */}
        </div>

        <div className="elementFooter">
          <div className="formBtnElement">
            <Button className="dfultPrimaryBtn">{t("save")}</Button>

            <Button
              className="dfultDarkSecondaryBtn"
              onClick={() => handelCloseAddNewSTP()}
            >
              {t("cancel")}
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}
