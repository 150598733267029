import React, { useEffect, useMemo, useRef, useState } from "react";
import DashboardServices from "../../../../services/DashboardServices";
import { toast } from "react-toastify";
import { PatchCheck, PersonFillAdd, Send, Trash } from "react-bootstrap-icons";
import {
  Box,
  Button,
  FormControl,
  Grid,
  IconButton,
  TextField,
  Tooltip,
} from "@mui/material";
import GoogleContacts from "react-google-contacts";
import { getLoginUserDetails } from "../../../../utils/Utils";
import {
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  useReactTable,
  Column,
  getPaginationRowModel,
} from "@tanstack/react-table";

export const ContactsFunctional = ({
  register,
  handleSubmit,
  errors,
  setError,
  reset,
}) => {
  const [data, setData] = useState([
    {
      id: "606",
      userId: null,
      contactName: "kf",
      contactEmailId: "tajog91395@larland.com",
      contactUserId: "AHL000000000661",
      inviteLink: "07606",
      inviteDate: "2023-05-03 12:38:41.214161+05:30",
      meetingId: null,
      inviteStatus: "P",
      accountExists: null,
    },
  ]);
  const [contactDtls, setContactDtls] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showMobAddContacts, setShowMobAddContacts] = useState(false);
  const [personDtls, setPersonDtls] = useState();
  const [columnFilters, setColumnFilters] = useState([]);
  const userDetails = getLoginUserDetails();

  const ColumnFilter = ({ column }) => {
    const { getFilterValue, id, setFilterValue } = column;
    const filterValue = getFilterValue();

    return (
      // <TextField
      //   size="small"
      //   className="modelTextFild"
      //   label="Search..."
      //   value={filterValue || ""}
      //   onChange={(e) => {
      //     setFilterValue(e.target.value || undefined);
      //     setFilter(id, e.target.value || undefined);
      //   }}
      //   variant="outlined"
      //   multiline
      //   fullWidth
      // />

      <input
        value={filterValue || ""}
        onChange={(e) => {
          setFilterValue(e.target.value || undefined);
          setFilter(id, e.target.value || undefined);
        }}
        placeholder={`Search...`}
      />
    );
  };

  const columns = useMemo(() => {
    const colArray = [
      {
        header: "Contact Name",
        accessorKey: "contactName",
        Filter: ColumnFilter,
        filterFn: "includesString",
      },

      {
        header: "Email Id",
        accessorKey: "contactEmailId",
        Filter: ColumnFilter,
        filterFn: "includesString",
      },
      {
        header: "Added/Not Added",
        enableColumnFilter: false,
        accessorFn: (row) => row,

        cell: (info) => {
          const cellValue = info.getValue();

          return (
            <Tooltip
              title={
                cellValue &&
                cellValue.inviteStatus === "P" &&
                cellValue.inviteLink != null
                  ? `Last Invite (${new Date(cellValue.inviteDate)})`
                  : ""
              }
            >
              <>
                {cellValue && cellValue.inviteStatus === "P" && "Not Added"}
                {cellValue && cellValue.inviteStatus === "A" && (
                  <>
                    <span className="addedRTG">
                      Added <PatchCheck />
                    </span>
                  </>
                )}
                {cellValue && cellValue.inviteStatus === "P" && (
                  <Button
                    startIcon={<Send />}
                    className="primaryBtn inviteBtn"
                    onClick={() => {
                      // console.log("Header-->", props);
                      sendInvite(cellValue && cellValue);
                    }}
                    sx={{
                      cursor:
                        cellValue && cellValue.inviteStatus === "P"
                          ? "pointer"
                          : "default",
                    }}
                  >
                    {cellValue && cellValue.inviteLink == null && "Send Invite"}
                    {cellValue &&
                      cellValue.inviteLink != null &&
                      `Send Invite Again`}
                  </Button>
                )}
              </>
            </Tooltip>
          );
        },
      },
      {
        header: "Delete",
        accessorFn: (row) => row,
        enableColumnFilter: false,
        cell: (info) => {
          return (
            <>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "100%",
                }}
              >
                <IconButton
                  className="trashIconBtn"
                  onClick={() => {
                    deleteContact(info.getValue());
                  }}
                >
                  <Trash />
                </IconButton>
              </div>
            </>
          );
        },
      },
    ];

    return colArray;
  }, []);

  const [filters, setFilters] = useState({});

  const setFilter = (columnId, value) => {
    setFilters((prev) => ({ ...prev, [columnId]: value }));
  };
  const [tokenClient, setTokenClient] = useState(null);
  const gapiInited = useRef(null);
  const gisInited= useRef(null);

  const filteredData = useMemo(() => {
    let filtered = data;

    Object.keys(filters).forEach((columnId) => {
      const filterValue = filters[columnId];
      if (filterValue) {
        filtered = filtered.filter((row) =>
          String(row[columnId])
            .toLowerCase()
            .includes(filterValue.toLowerCase())
        );
      }
    });
    return filtered;
  }, [filters, data]);

  const table = useReactTable({
    data: filteredData,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  });

  const toggleMobaddcontct = () => {
    setShowMobAddContacts((prev) => !prev);
  };

  useEffect(() => {
    document.getElementById('authorize_button').style.visibility = 'hidden';
    const script1 = document.createElement("script");
    script1.async = true;
    script1.defer = true;
    script1.src = "https://apis.google.com/js/api.js";
    script1.onload = gapiLoaded;

    const script2 = document.createElement("script");
    script2.async = true;
    script2.defer = true;
    script2.src = "https://accounts.google.com/gsi/client";
    script2.onload = gisLoaded;

    document.body.appendChild(script1);
    document.body.appendChild(script2);

    DashboardServices.getContacts(userDetails.userId).then((response) => {
      console.log(response.data);
      if (response.data) {
        setData(response.data);
      } else {
        setData([]);
      }
    });
  }, []);

  const gapiLoaded = () => {
    window.gapi.load("client", initializeGapiClient);
  };

  const gisLoaded = () => {
    const tokenClient2 = window.google.accounts.oauth2.initTokenClient({
      client_id:"791849580758-l63om9okcfi3q6dnk8nk14avkll33i2p.apps.googleusercontent.com",
      scope: "https://www.googleapis.com/auth/contacts.readonly",
      callback: "",
    });
    setTokenClient(tokenClient2);
    gisInited.current=true;
    maybeEnableButtons();
  };

  const maybeEnableButtons=()=> {
    if (gapiInited.current && gisInited.current) {
      document.getElementById('authorize_button').style.visibility = 'visible';
    }
  };

  const buttonStyle = {
    visibility: 'visible',
    color: '#00adef',
    borderRadius: '4px',
    border: '1px solid #00adef',
    height: '40px',
    cursor: 'pointer'
  };

  // const listConnectionNames = async () => {
  //   let response;
  //   try {
  //     // Fetch first 10 files
  //     response = await window.gapi.client.people.people.connections.list({
  //       resourceName: "people/me",
  //       personFields: "names,emailAddresses",
  //     });
  //   } catch (err) {
  //     console.log(err.message)
  //     return;
  //   }
  //   console.log(response);
  //   const connections = response.result.connections;
  //   if (connections) {
  //     const ContactsValue = connections.map(person => ({
  //       title: person.names?.[0]?.displayName || 'No name found',
  //       email: person.emailAddresses?.[0]?.value || 'No email found'
  //     }));
  //     console.log('ContactsValue' , ContactsValue);
  //   } else {
  //     console.log('No connections found.');
  //   }
  // };

  const listConnectionNames = async () => {
    let response;
   
    try {
      response = await window.gapi.client.people.otherContacts.list({
        // pageSize: 10,
        readMask: 'names,emailAddresses'
      });
    } catch (err) {
      console.log(err.message);
      return;
    }

    const connections = response.result.otherContacts;
    console.log('connections', connections);

    if (connections) {
      const ContactsValue = connections.map(person => ({
        title: person.names?.[0]?.displayName || 'No name found',
        email: person.emailAddresses?.[0]?.value || 'No email found'
      }));
      console.log('ContactsValue', ContactsValue);
      responseCallback(ContactsValue);
    } else {
      console.log('No connections found.');
    }
  };


  const handleAuthClick = () => {
    tokenClient.callback = async (resp) => {
      if (resp.error !== undefined) {
        throw resp;
      }
      document.getElementById("authorize_button").innerText = "Import Google Contacts";
      await listConnectionNames();
    };

    if (window.gapi.client.getToken() === null) {
      // Prompt the user to select a Google Account and ask for consent to share their data
      // when establishing a new session.
      tokenClient.requestAccessToken({ prompt: "consent" });
    } else {
      // Skip display of account chooser and consent dialog for an existing session.
      tokenClient.requestAccessToken({ prompt: "" });
    }
  };

  const initializeGapiClient = async () => {
    await window.gapi.client.init({
      apiKey: "AIzaSyDGoQ1iMyXQXpMH-uK-d3N_ttXA3DWlOCU",
      // discoveryDocs: [
      //   "https://www.googleapis.com/discovery/v1/apis/gmail/v1/rest",
      //   "https://www.googleapis.com/discovery/v1/apis/people/v1/rest",
      // ],
      discoveryDocs: ["https://people.googleapis.com/$discovery/rest?version=v1"]
      // discoveryDocs:["https://www.googleapis.com/discovery/v1/apis/people/v1/rest"]
    });

     await window.gapi.client.load("people", "v1");

    gapiInited.current=true;
    maybeEnableButtons();
  };

  const responseCallback = (response) => {
    console.log(response);
    toast.success("Importing contacts from google, please do not close", {
      position: toast.POSITION.TOP_RIGHT,
    });
    if (response && response.length > 0) {
      let reqDto = [];
      response.forEach((contact) => {
        console.log(contact);
        let contactDto = {
          contactEmailId: contact.email,
          contactName: contact.title,
        };
        reqDto.push(contactDto);
      });
      DashboardServices.addContacts(reqDto, userDetails.userId).then(
        (response) => {
          console.log(response.data);
          if (
            response.data.returnValue === "1" ||
            response.data.returnValue === "2"
          ) {
            DashboardServices.getContacts(userDetails.userId).then(
              (response) => {
                console.log(response.data);
                setData(response.data);

                toast.success("Google Contacts imported Successfully", {
                  position: toast.POSITION.TOP_RIGHT,
                });
              }
            );
          } else {
            toast.error("Please try again", {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        }
      );
    }
  };

  const responseCallbackFailure = (response) => {
    console.log(response);
  };

  const isValidEmail = (email) => {
    return /\S+@\S+\.\S+/.test(email);
  };

  const onSubmitContactAdd = (data) => {
    console.log(data);
    let isFormValid = true;
    let emailId = data.contactEmailId.toLowerCase().trim();
    if (data.contactName === "") {
      setError("contactName", {
        type: "custom",
        message: "Field required",
      });
      isFormValid = false;
    }

    if (emailId === "") {
      setError("contactEmailId", {
        type: "custom",
        message: "Field required",
      });
      isFormValid = false;
    }
    if (!isValidEmail(emailId)) {
      setError("contactEmailId", {
        type: "custom",
        message: "Invalid Mail Id",
      });
      isFormValid = false;
    }

    if (emailId === userDetails.userEmailId) {
      setError("contactEmailId", {
        type: "custom",
        message: "Same as current login id",
      });
      isFormValid = false;
    }

    if (!isFormValid) {
      setLoading(false);
      return;
    }
    setLoading(true);

    let reqDto = [];
    let contactDto = {
      contactEmailId: emailId,
      contactName: data.contactName,
    };
    reqDto.push(contactDto);
    console.log(reqDto);
    // return;

    DashboardServices.addContacts(reqDto, userDetails.userId).then(
      (response) => {
        console.log("addContacts", response.data);
        if (response.data.returnValue === "1") {
          reset({
            contactEmailId: "",
            contactName: "",
          });
          DashboardServices.getContacts(userDetails.userId).then(
            async (response) => {
              console.log("addContacts", response.data);
              let contactToSendInvite = null;
              // setData(response.data);
              response.data.forEach((contact) => {
                if (
                  contact.contactEmailId.toLowerCase().trim() ===
                  data.contactEmailId.toLowerCase().trim()
                ) {
                  contactToSendInvite = contact;
                }
              });
              await sendInvite(contactToSendInvite);
              setData(response.data);
              setLoading(false);
              // toast.success("Contact added successfully", {
              //   position: toast.POSITION.TOP_RIGHT,
              // });
            }
          );
        } else if (response.data.returnValue === "2") {
          toast.info("Contact exists already", {
            position: toast.POSITION.TOP_RIGHT,
          });
          setLoading(false);
        } else {
          toast.error("Please try again", {
            position: toast.POSITION.TOP_RIGHT,
          });
          setLoading(false);
        }
      }
    );
  };

  const sendInvite = async (data) => {
    console.log("sendInvite", data);
    await DashboardServices.sendInvite(data, userDetails.userId).then(
      (response) => {
        console.log(response.data);

        DashboardServices.getContacts(userDetails.userId).then((response) => {
          console.log(response.data);
          // setData(response.data);
          setData(response.data);

          toast.success("Invite sent successfully", {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
      }
    );
  };

  const deleteContact = (data) => {
    console.log("deleteContact", data);
    // return;
    DashboardServices.deleteContacts(userDetails.userId, data.id).then(
      (response) => {
        console.log(response.data);
        DashboardServices.getContacts(userDetails.userId).then((response) => {
          console.log(response.data);
          setData(response.data);

          toast.success("Contact deleted", {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
      }
    );
  };

  return (
    <>
      <div className="contacts">
        <Box component={"form"}>
          <Grid container spacing={2} mb={2}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <div className="addContactGroup">
                <div className="addContactElementGrp hideMobile">
                  <div className="formElement with240">
                    <FormControl className="formControl">
                      <TextField
                        error={errors.contactName}
                        helperText={
                          errors.contactName && errors.contactName.message
                        }
                        size="small"
                        {...register("contactName")}
                        className="modelTextFild"
                        label="Contact Name"
                        required
                        variant="outlined"
                        multiline
                        fullWidth
                      />
                    </FormControl>
                  </div>
                  <div className="formElement with240">
                    <FormControl className="formControl">
                      <TextField
                        error={errors.contactEmailId}
                        helperText={
                          errors.contactEmailId && errors.contactEmailId.message
                        }
                        size="small"
                        {...register("contactEmailId")}
                        className="modelTextFild"
                        label="Contact Email Id"
                        variant="outlined"
                        multiline
                        required
                        fullWidth
                        inputProps={{ style: { textTransform: "lowercase" } }}
                      />
                    </FormControl>
                  </div>

                  <div className="addContElementBtn">
                    <Button
                      startIcon={<Send />}
                      className="dfultPrimaryBtn primaryBtn contactsBtn"
                      onClick={handleSubmit(onSubmitContactAdd)}
                      disabled={loading}
                    >
                      {loading && "Please Wait..."}
                      {!loading && "Send Invite"}
                    </Button>
                  </div>
                </div>
                <div className="syncGoogleCont">
                  <Button
                    className="AddContactMobBtn"
                    startIcon={<PersonFillAdd />}
                    onClick={toggleMobaddcontct}
                  >
                    Add Contact
                  </Button>
                  <button id="authorize_button" onClick={handleAuthClick} style={buttonStyle} type="button">
                    Import Google Contacts
                  </button>
                  {/* <GoogleContacts
                    clientId="806760841521-j7f6o10atmhib5da5q498pbkcoprtrgl.apps.googleusercontent.com"
                    buttonText="Import"
                    onSuccess={responseCallback}
                    onFailure={responseCallbackFailure}
                    className="googleContactsGrid"
                  /> */}
                </div>
              </div>
              {showMobAddContacts && (
                <>
                  <div className="addContactElementGrp">
                    <div className="formElement with240">
                      <FormControl className="formControl">
                        <TextField
                          error={errors.contactName}
                          helperText={
                            errors.contactName && errors.contactName.message
                          }
                          size="small"
                          {...register("contactName")}
                          className="modelTextFild"
                          label="Contact Name"
                          required
                          variant="outlined"
                          multiline
                          fullWidth
                        />
                      </FormControl>
                    </div>
                    <div className="formElement with240">
                      <FormControl className="formControl">
                        <TextField
                          error={errors.contactEmailId}
                          helperText={
                            errors.contactEmailId &&
                            errors.contactEmailId.message
                          }
                          size="small"
                          {...register("contactEmailId")}
                          className="modelTextFild"
                          label="Contact Email Id"
                          variant="outlined"
                          multiline
                          required
                          fullWidth
                          inputProps={{
                            style: { textTransform: "lowercase" },
                          }}
                        />
                      </FormControl>
                    </div>

                    <div className="addContElementBtn">
                      <Button
                        startIcon={<Send />}
                        className="dfultPrimaryBtn primaryBtn contactsBtn"
                        onClick={handleSubmit(onSubmitContactAdd)}
                        disabled={loading}
                      >
                        {loading && "Please Wait..."}
                        {!loading && "Send Invite"}
                      </Button>
                    </div>
                  </div>
                </>
              )}
            </Grid>
          </Grid>
        </Box>

        <div className="modResptable">
          {data && data.length > 0 && (
            <table className="-striped -highlight participantsRT">
              <thead style={{ color: "black" }}>
                {table.getHeaderGroups().map((headerGroup) => (
                  <>
                    <tr key={headerGroup.id}>
                      {headerGroup.headers.map((header) => (
                        <th key={header.id}>
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                          {/* <div>
                            {header.column.getCanFilter()
                              ? flexRender(
                                  header.column.columnDef.Filter,
                                  header.getContext()
                                )
                              : null}
                          </div> */}
                        </th>
                      ))}
                    </tr>

                    <tr key={headerGroup.id}>
                      {headerGroup.headers.map((header) => (
                        <th key={header.id}>
                          <div>
                            {header.column.getCanFilter()
                              ? flexRender(
                                  header.column.columnDef.Filter,
                                  header.getContext()
                                )
                              : null}
                          </div>
                        </th>
                      ))}
                    </tr>
                  </>
                ))}
              </thead>
              <tbody style={{ color: "black" }}>
                {table.getRowModel().rows.map((row) => {
                  console.log("row =", row);
                  return (
                    <tr key={row.id}>
                      {row.getVisibleCells().map((cell) => (
                        <td id={cell.id}>
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </td>
                      ))}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          )}

          <div>
            <button onClick={() => table.setPageIndex(0)}>First Page</button>
            <button
              disabled={!table.getCanPreviousPage()}
              onClick={() => table.previousPage()}
            >
              Previous Page
            </button>
            <span style={{ color: "black" }}>
              Page {table.getState().pagination.pageIndex + 1} of{" "}
              {table.getPageCount()}
            </span>
            <button
              disabled={!table.getCanNextPage()}
              onClick={() => table.nextPage()}
            >
              Next Page
            </button>
            <button
              onClick={() => table.setPageIndex(table.getPageCount() - 1)}
            >
              Last Page
            </button>
          </div>
        </div>

        <br />
      </div>
    </>
  );
};
