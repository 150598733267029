import { StarBorderRounded, StarRateRounded } from "@mui/icons-material";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  TextField,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { getLoginUserDetails } from "../../../../../utils/Utils";
import DashboardServices from "../../../../../services/DashboardServices";
import { toast } from "react-toastify";
import { AddTabContext } from "../../../../dashboard/view/DashboardPage";
import { useTranslation } from "react-i18next";

export default function DrFeedback({ feedbackFrom, visitId, tabKey, data }) {
  const { t } = useTranslation();
  const loginUserDetail = getLoginUserDetails();
  const { closeTab } = useContext(AddTabContext);
  const [rating, setRating] = useState(0);
  const [hover, setHover] = useState(0);
  const [feedbackQuestion, setFeedbackQuestion] = useState(null);
  const [heading, setHeading] = useState(null);
  const [remark, setRemark] = useState(null);
  const [visId, setVisId] = useState();

  const renderStars = () => {
    return [...Array(5)].map((star, index) => {
      const ratingValue = index + 1;

      return (
        <label key={index}>
          <input
            type="radio"
            name={t("rating")}
            value={ratingValue}
            onClick={() => setRating(ratingValue)}
            style={{ display: "none" }}
          />
          {ratingValue <= (hover || rating) ? (
            <StarRateRounded
              onMouseEnter={() => setHover(ratingValue)}
              onMouseLeave={() => setHover(0)}
              onClick={() => setRating(ratingValue)}
            />
          ) : (
            <StarBorderRounded
              onMouseEnter={() => setHover(ratingValue)}
              onMouseLeave={() => setHover(0)}
              onClick={() => setRating(ratingValue)}
            />
          )}
        </label>
      );
    });
  };

  useEffect(() => {
    getfeedbackMaster();
    setVisId(visitId);
  }, []);

  const getfeedbackMaster = () => {
    let localStorageKey = "";
    let tempFeedbackFrom = "";

    if (feedbackFrom === "Doctor") {
      localStorageKey = "drFeedbackQuestions";
      tempFeedbackFrom = "DOCTOR";
    } else {
      localStorageKey = "patientFeedbackQuestion";
      tempFeedbackFrom = "PATIENT";
    }

    const diseasetring = localStorage.getItem(localStorageKey);
    if (diseasetring) {
      const feedbackQuestionObj = JSON.parse(diseasetring);
      const heading = Object.keys(feedbackQuestionObj);
      const questions = Object.values(feedbackQuestionObj)?.[0]?.map(
        (item) => ({
          value: item,
          selected: false,
        })
      );
      console.log("Question =", Object.values(feedbackQuestionObj));
      setFeedbackQuestion(questions);
      setHeading(heading);
      return;
    }

    DashboardServices.getfeedbackMaster(
      loginUserDetail.orgId,
      tempFeedbackFrom
    ).then((response) => {
      if (response.data) {
        setFeedbackQuestion(response.data);
        localStorage.setItem(localStorageKey, JSON.stringify(response.data));
      }
    });
  };

  function decodeHTMLEntities(text) {
    const entities = {
      "&#39;": "'",
      "&quot;": '"',
      "&amp;": "&",
      "&lt;": "<",
      "&gt;": ">",
      // Add more entities as needed
    };

    return text.replace(
      /&#39;|&quot;|&amp;|&lt;|&gt;/g,
      (match) => entities[match]
    );
  }

  return (
    <>
      <Box className="feedBackContainerArea" sx={{ bgcolor: "dark.pageBg" }}>
        <Grid container spacing={0} className="feedBackContainArea">
          <Grid item xs={12} sx={{ color: "dark.contrastText" }}>
            <div className="feedBackContainer">
              <div className="feedBackCarea">
                <div className="feedbackHeader">
                  <div className="fbhTxt">
                    {visId ? "Feedback" : "Thank You"}
                  </div>
                </div>

                {visId && (
                  <>
                    <div className="userRating">
                      <div className="fbshTxt">
                      {t("please_provide_a_rating_for_your_overall_experience_with_the_video_consultation")}
                      </div>
                      <div className="reatingStar">{renderStars()}</div>
                      {rating > 0
                        ? `Rating: ${rating} / 5`
                        : "No rating selected"}
                    </div>
                    {rating < 5 && (
                      <>
                        <div className="textleft85">
                        {t("please_select_below_issues_you_faces_during_consultation")}
                        </div>
                        {/* doctor give this feedback  start*/}
                        <div className="feedbackOption">
                          <FormGroup>
                            {feedbackQuestion?.map((item, index) => {
                              return (
                                <>
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={item.selected}
                                        onChange={(e) => {
                                          const temp = [...feedbackQuestion];
                                          temp[index].selected =
                                            e.target.checked;
                                          setFeedbackQuestion(temp);
                                        }}
                                      />
                                    }
                                    label={decodeHTMLEntities(item.value)}
                                  />
                                </>
                              );
                            })}

                            {/* <FormControlLabel
                          control={<Checkbox />}
                          label="The patient's video was occasionally lost."
                        />
                        <FormControlLabel
                          control={<Checkbox />}
                          label="The video occasionally froze."
                        />
                        <FormControlLabel
                          control={<Checkbox />}
                          label="The audio quality was below expectations."
                        />
                        <FormControlLabel
                          control={<Checkbox />}
                          label="The patient's audio was unclear."
                        />
                        <FormControlLabel
                          control={<Checkbox />}
                          label="The patient's audio was intermittently lost."
                        />
                        <FormControlLabel
                          control={<Checkbox />}
                          label="Operator was not following the instructions."
                        />
                        <FormControlLabel
                          control={<Checkbox />}
                          label="The operator's overall communication and liaison were below acceptable standards."
                        /> */}
                          </FormGroup>
                        </div>
                        {/* doctor give this feedback  end*/}

                        {/* Patient Feedback for doctor start */}
                        {/* <div className="feedbackOption">
                      <FormGroup>
                        <FormControlLabel
                          control={<Checkbox />}
                          label="The video quality fell short of expectations."
                        />
                        <FormControlLabel
                          control={<Checkbox />}
                          label="The Doctor's video was occasionally lost."
                        />
                        <FormControlLabel
                          control={<Checkbox />}
                          label="The video occasionally froze."
                        />
                        <FormControlLabel
                          control={<Checkbox />}
                          label="The audio quality was below expectations."
                        />
                        <FormControlLabel
                          control={<Checkbox />}
                          label="The Doctor's audio was unclear."
                        />
                        <FormControlLabel
                          control={<Checkbox />}
                          label="The Doctor's audio was intermittently lost."
                        />
                        <FormControlLabel
                          control={<Checkbox />}
                          label="The Doctor lacked empathy."
                        />
                        <FormControlLabel
                          control={<Checkbox />}
                          label="The Doctor appeared to be in a rush."
                        />
                        <FormControlLabel
                          control={<Checkbox />}
                          label="I was dissatisfied with the resolution provided by the Doctor."
                        />
                        <FormControlLabel
                          control={<Checkbox />}
                          label="Operator was not cooperative."
                        />
                        <FormControlLabel
                          control={<Checkbox />}
                          label="The operator's overall communication and liaison were below acceptable standards."
                        />
                      </FormGroup>
                    </div> */}
                        {/* Patient Feedback for doctor end */}
                      </>
                    )}
                    <div className="formElement userComment">
                      <FormControl className="formControl pRelative">
                        <TextField
                          label={t("please_leave_your_valuable_feedback")}
                          placeholder={t("please_leave_your_valuable_feedback_regarding_your_overall_experience_with_the_video_consultation")}
                          variant="outlined"
                          className="descptionTextFild"
                          multiline
                          rows={3}
                          maxRows={5}
                          value={remark}
                          onChange={(e) => {
                            setRemark(e.target.value);
                          }}
                        ></TextField>
                      </FormControl>
                    </div>
                    <Button
                      className="submitFeedbackBtn"
                      onClick={() => {
                        const filteredFeedback = feedbackQuestion?.filter(
                          (item) => item.selected === true
                        );
                        if (!visId) {
                          return;
                        }
                        if (!rating) {
                          toast.error(t("please_select_rating"));
                          return;
                        }

                        // if (rating === 5 && !remark) {
                        //   toast.error("Please provide remark");
                        //   return;
                        // }

                        // if (
                        //   rating < 5 &&
                        //   (!filteredFeedback ||
                        //     filteredFeedback.length === 0) &&
                        //   !remark
                        // ) {
                        //   toast.error(
                        //     "Please select at least one option below or provide your own feedbackd"
                        //   );
                        //   return;
                        // }

                        let reqDto = {
                          visitId: visId,
                          feedbackFrom: feedbackFrom,
                          userId: loginUserDetail.userId,
                          questions:
                            feedbackQuestion
                              ?.filter((item) => item.selected === true)
                              ?.map((item) => {
                                return item.value;
                              }) || [],
                          rmks: remark || "",
                          rating: rating || 0,
                          feedbackHdr: heading[0] || "",
                          orgId: loginUserDetail.orgId,
                          unitId: loginUserDetail.signleUnitId,
                        };

                        if (feedbackFrom === "Patient") {
                          reqDto.doctorId = data?.doctorId;
                        }

                        console.log("reqDto =", reqDto);
                        // return;
                        DashboardServices.submitFeedback(reqDto).then(
                          (response) => {
                            if (response.data == 1) {
                              toast.success(t("feedback_submitted_successfully"));
                              setFeedbackQuestion(null);
                              setRemark("");
                              setRating(0);
                              setVisId(null);
                              closeTab(tabKey);
                            } else {
                              toast.error(t("feedback_submission_unsuccessful"));
                            }
                          }
                        );
                      }}
                    >
                      {t("submit_your_feedback")}
                    </Button>
                  </>
                )}
              </div>
            </div>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
