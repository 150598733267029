import { Add, Close, CloseOutlined, Edit } from "@mui/icons-material";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Tooltip,
} from "@mui/material";
import React from "react";
import { useState, useEffect } from "react";
import { Trash, XLg } from "react-bootstrap-icons";
import {
  getOrgByOrgId,
  getSalaryConfigMaster,
  addSalaryComponent,
  addComponentCategory,
  getComponentCategories,
  updateCategory,
  deactivateComponentCategory,
  getSalaryComponents
} from "../../../../services/AdminService";
import { jwtDecode } from "jwt-decode";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

export default function CreateSalaryComponent({
  handelCloseNewSalaryComponent,
  refreshSalaryComponentData,
}) {
  const userDetails = jwtDecode(localStorage.getItem("token"));
  const { t } = useTranslation();
  const [orgDetailsData, setOrgDetailsData] = useState(null);
  const [salaryConfigData, setSalaryConfigData] = useState([]);
  const [groupedConfigData, setGroupedConfigData] = useState({});
  const [considerChecked, setConsiderChecked] = useState({});
  const [selectedRadio, setSelectedRadio] = useState({});
  const [loading, setLoading] = useState(false);
  const [salaryComponentData, setSalaryComponentData] = useState([]);

  useEffect(() => {
    fetchgetOrgDetails();
  }, [userDetails.orgId]);

  const fetchgetOrgDetails = () => {
    getOrgByOrgId(userDetails.orgId)
      .then((response) => {
        console.log("getOrgDetails", response.data);
        setOrgDetailsData(response.data || {});
      })
      .catch((error) => {
        console.error("Error fetching getOrgDetails", error);
      });
  };

  console.log("orgDetailsData", orgDetailsData);

  useEffect(() => {
    fetchSalaryComponentData();
  }, [orgDetailsData]);

  const fetchSalaryComponentData = () => {
    if (orgDetailsData && orgDetailsData.countryCode) {
      getSalaryComponents(
        userDetails.orgId,
        userDetails.userId,
        orgDetailsData.countryCode
      )
        .then((response) => {
          console.log("getSalaryComponents", response.data);
          setSalaryComponentData(response.data);
          setLoading(false);
        })
        .catch((error) => {
          console.error("Error fetching getSalaryConfigMaster", error);
          setLoading(false);
        });
    }
  };

  console.log('salaryComponentData' , salaryComponentData);

  useEffect(() => {
    if (orgDetailsData && orgDetailsData.countryCode) {
      getSalaryConfigMaster(orgDetailsData.countryCode)
        .then((response) => {
          console.log("getSalaryConfigMaster", response.data);
          setSalaryConfigData(response.data || []);
          groupSalaryConfigData(response.data || []);
        })
        .catch((error) => {
          console.error("Error fetching getSalaryConfigMaster", error);
        });
    }
  }, [orgDetailsData]);

  console.log("salaryConfigData", salaryConfigData);

  const groupSalaryConfigData = (data) => {
    const groupedData = data.reduce((acc, item) => {
      if (item.radioSelectionName) {
        if (!acc[item.radioSelectionName]) {
          acc[item.radioSelectionName] = [];
        }
        acc[item.radioSelectionName].push(item.rule);
      } else {
        acc[item.rule] = null;
      }
      return acc;
    }, {});
    setGroupedConfigData(groupedData);
  };

  const handleConsiderChange = (event) => {
    const checkboxName = event.target.name;
    const isChecked = event.target.checked;

    setConsiderChecked((prev) => ({
      ...prev,
      [checkboxName]: isChecked,
    }));

    if (isChecked) {
      setSelectedRadio((prev) => ({
        ...prev,
        [checkboxName]: groupedConfigData[checkboxName],
      }));
    } else {
      setSelectedRadio((prev) => ({
        ...prev,
        [checkboxName]: null,
      }));
    }
  };

  const handleChangeRadio = (checkboxName, value) => {
    setSelectedRadio((prev) => ({
      ...prev,
      [checkboxName]: value,
    }));
  };

  const [compCatg, setCompCatg] = useState("");
  const [addCategory, setAddCategory] = useState(false);
  const [periodicity, setPeriodicity] = useState("Monthly");
  const [calculationtype, setCalculationtype] = useState("Flat");
  const [epfContribution, setEpfContribution] = useState("always");
  // const [considerChecked, setConsiderChecked] = useState(false);

  // const handleConsiderChange = (event) => {
  //   setConsiderChecked(event.target.checked);
  //   if (!event.target.checked) {
  //     setEpfContribution(null);
  //   } else {
  //     setEpfContribution("always");
  //   }
  // };

  // const handleChangeEpfContribution = (event) => {
  //   setEpfContribution(event.target.value);
  //   setConsiderChecked(true);
  // };

  const handleChangePeriodicity = (event) => {
    console.log("handleChangePeriodicity", event.target.value);
    setPeriodicity(event.target.value);
  };

  const handleChangeCompCatg = (event) => {
    console.log("handleChangeCompCatg", event.target.value);
    setCompCatg(event.target.value);
  };

  const handleChangecalculationtype = (event) => {
    console.log("handleChangecalculationtype", event.target.value);
    setCalculationtype(event.target.value);
  };

  //////////////////////////////////////////////////////////////

  const [salaryComponentName, setSalaryComponentName] = useState("");

  const handleSalaryComponentNameChange = (event) => {
    console.log("handleSalaryComponentNameChange", event.target.value);
    setSalaryComponentName(event.target.value);
  };

  const [calculationAmount, setCalculationAmount] = useState(null);

  const handleCalculationAmountChange = (event) => {
    console.log("handleCalculationAmountChange", event.target.value);
    const numericValue = event.target.value.replace(/[^0-9]/g, "");
    setCalculationAmount(numericValue);
  };

  const [calculationPercentage, setCalculationPercentage] = useState(null);

  const handleCalculationPercentageChange = (event) => {
    console.log("handleCalculationPercentageChange", event.target.value);
    const numericValue = event.target.value.replace(/[^0-9]/g, "");
    setCalculationPercentage(numericValue);
  };

  const finalSubmit = () => {
    console.log("finalSubmit");
    setLoading(true);

    console.log("considerChecked", considerChecked);
    console.log("selectedRadio", selectedRadio);
    console.log("salaryConfigData", salaryConfigData);

    const configs = salaryConfigData
      .filter((config) => {
        // Check if the config is considered and if it has a radio selection or not
        const isConsidered =
          considerChecked[config.radioSelectionName] ||
          considerChecked[config.rule];
        const radioSelection = selectedRadio[config.radioSelectionName] || null;
        return (
          isConsidered &&
          (config.radioSelectionName
            ? radioSelection === config.rule.toLowerCase().replace(/\s+/g, "")
            : true)
        );
      })
      .map((config) => ({
        salaryComponentConfigId: 0,
        salaryComponentId: 0,
        configId: config.configId,
        rule: config.rule,
        radioSelectionName: config.radioSelectionName || "",
      }));

    if (salaryComponentName.trim() === "") {
      toast.error(t("please_enter_component_name") , {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoading(false);
      return;
    };

      const isComponentNameExists = salaryComponentData.some(
        (component) => component.componentName === salaryComponentName
      );
  
      if (isComponentNameExists) {
        toast.error(t("salary_component_name_is_already_present") , {
          position: toast.POSITION.TOP_RIGHT,
        });
        setLoading(false);
        return;
      }

    if (calculationtype === "% of Basic" || calculationtype === "% of CTC") {
      if (calculationPercentage === null) {
        toast.error(t("please_enter_percentage"), {
          position: toast.POSITION.TOP_RIGHT,
        });
        setLoading(false);
        return;
      }

      if (
        isNaN(calculationPercentage) ||
        calculationPercentage < 0 ||
        calculationPercentage > 100
      ) {
        toast.error(t("please_enter_a_valid_percentage") , {
          position: toast.POSITION.TOP_RIGHT,
        });
        setLoading(false);
        return;
      }
    }

    const reqDto = {
      salaryComponentId: 0,
      componentName: salaryComponentName,
      category: compCatg,
      calculationType: calculationtype,
      flatAmountPc: calculationtype === "Flat" ? calculationAmount : calculationPercentage,
      configs: configs,
      deleteable: "",
      periodicity: periodicity,
      activeFlag: "",
    };

    console.log("reqDto", reqDto);

    // return ;

    addSalaryComponent(userDetails.orgId, userDetails.userId, reqDto)
      .then((response) => {
        console.log("response", response.data);
        setLoading(false);
        if (response.data.returnValue === "1") {
          toast.success(response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          refreshSalaryComponentData();
          setLoading(false);
          setSalaryComponentName("");
          setCompCatg("");
          setPeriodicity("Monthly");
          setCalculationtype("Flat");
          setCalculationAmount(null);
          setCalculationPercentage(null);
          setConsiderChecked({});
          setSelectedRadio({});
          fetchSalaryComponentData();
        } else {
          toast.error(response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          setLoading(false);
        }
      })
      .catch((e) => {
        setLoading(false);
        console.log("error");
      });
  };

  ///////////////////////////////////////////////////Add category/////////////////////////////

  const [addCompCategoryText, setAddCompCategoryText] = useState("");
  const [componetCategyData, setComponetCategyData] = useState([]);
  const [isEditCategyComponent, setIsEditcategyComponent] = useState(false);
  const [idForUpdateCategory, setIdForUpdateCategory] = useState("");
  const [categoryLoading, setCategoryLoading] = useState(false);

  const handleAddCompCatText = (event) => {
    console.log(event.target.value);
    setAddCompCategoryText(event.target.value);
  };

  useEffect(() => {
    fetchComponentCategoryData();
  }, [userDetails.orgId]);

  const fetchComponentCategoryData = () => {
    getComponentCategories(userDetails.orgId).then((response) => {
      console.log("response of get category ", response.data);
      setComponetCategyData(response.data);
    });
  };

  const addComponentCategoryData = () => {
    setCategoryLoading(true);
    console.log("addComponentCategoryData");

    if (addCompCategoryText.trim() === "") {
      toast.error(t("please_enter_category_name"), {
        position: toast.POSITION.TOP_RIGHT,
      });
      setCategoryLoading(false);
      return;
    }

    if (
      componetCategyData.find(
        (item) =>
          item.name.trim().toLowerCase() ===
          addCompCategoryText.trim().toLowerCase()
      )
    ) {
      toast.error(t("the_category_name_already_exists"), {
        position: toast.POSITION.TOP_RIGHT,
      });
      setCategoryLoading(false);
      return;
    }

    const addCompText = addCompCategoryText;

    const slNo = componetCategyData.length + 1;
    console.log("slNo", slNo);

    addComponentCategory(
      userDetails.orgId,
      userDetails.userId,
      slNo,
      addCompText
    )
      .then((response) => {
        console.log("response", response.data);

        if (response.data.returnValue === "1") {
          toast.success(response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          fetchComponentCategoryData();
          setAddCompCategoryText("");
          setCategoryLoading(false);
        } else {
          toast.error(response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          setCategoryLoading(false);
        }
      })
      .catch((error) => {
        console.error("Error adding category:", error);
        toast.error(t("an_error_occurred_while_adding_the_category") , {
          position: toast.POSITION.TOP_RIGHT,
        });
        setCategoryLoading(false);
      });
  };

  const handleCloseComponentCategoryModal = () => {
    setAddCategory(false);
    setAddCompCategoryText("");
    setIsEditcategyComponent(false);
    setIdForUpdateCategory("");
  };

  const handleUpdateCategoryChange = (category) => {
    console.log("category", category);
    setAddCompCategoryText(category.name);
    setIsEditcategyComponent(true);
    setIdForUpdateCategory(category.id);
  };

  const handlCancelEditCategory = () => {
    setAddCompCategoryText("");
    setIsEditcategyComponent(false);
    setIdForUpdateCategory("");
  };

  const handleSubmitCategoryUpdateChange = () => {
    setCategoryLoading(true);
    console.log("handleSubmitCategoryUpdateChange");

    if (addCompCategoryText.trim() === "") {
      toast.error(t("please_enter_category_name"), {
        position: toast.POSITION.TOP_RIGHT,
      });
      setCategoryLoading(false);
      return;
    }

    // if (componetCategyData.find(item => item.name.trim().toLowerCase() === addCompCategoryText.trim().toLowerCase())) {
    //   toast.error("The category name already exists", {
    //       position: toast.POSITION.TOP_RIGHT,
    //   });
    //   setCategoryLoading(false);
    //   return;
    //  };

    const updateCompText = addCompCategoryText;

    const categoryId = idForUpdateCategory;

    console.log("updateCompText", updateCompText);
    console.log("categoryId", categoryId);

    updateCategory(categoryId, userDetails.userId, updateCompText)
      .then((response) => {
        if (response.data === 1) {
          toast.success(t("category_updated_successfully"), {
            position: toast.POSITION.TOP_RIGHT,
          });
          fetchComponentCategoryData();
          setAddCompCategoryText("");
          setIdForUpdateCategory("");
          setIsEditcategyComponent(false);
          setCategoryLoading(false);
        } else {
          toast.error(t("failed_to_update_category"), {
            position: toast.POSITION.TOP_RIGHT,
          });
          setCategoryLoading(false);
        }
      })
      .catch((error) => {
        console.error("Error updating category:", error);
        toast.error(t("an_error_occurred_while_updating_the_category") , {
          position: toast.POSITION.TOP_RIGHT,
        });
        setCategoryLoading(false);
      });
  };

  const deleteComponentCategory = (categoryId) => {
    console.log("deleteComponentCategory");

    deactivateComponentCategory(categoryId, userDetails.userId)
      .then((response) => {
        if (response.data === 1) {
          toast.success(t("category_deleted_successfully"), {
            position: toast.POSITION.TOP_RIGHT,
          });
          fetchComponentCategoryData();
          setAddCompCategoryText("");
          setIdForUpdateCategory("");
          setIsEditcategyComponent(false);
        } else {
          toast.error(t("failed_to_delete_category") , {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch((error) => {
        console.error("Error Deleting category:", error);
        toast.error(t("an_error_occurred_while_deleting_the_category"), {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  /////////////////////////////////////////////////////////////////////////////////////////

  return (
    <>
      <div className="flotingAreaContainer">
        <div className="flotingAreaClose">
          <div className="facHeader">{t("add_salary_component")}</div>
          <IconButton
            className="CloseBtn"
            onClick={() => handelCloseNewSalaryComponent()}
          >
            <XLg />
          </IconButton>
        </div>

        <div className="elementFormContainer payrollEditor">
          {loading ? (
            <div className="meetingVEContainer">
              <div className="center-progress" style={{ height: "65vh" }}>
                <CircularProgress sx={{ marginTop: "180px" }} />
              </div>
            </div>
          ) : (
            <>
              <div className="formElement">
                <FormControl className="formControl">
                  <TextField
                    label={t("component_name")}
                    required
                    variant="outlined"
                    className="formTextFieldArea"
                    value={salaryComponentName}
                    onChange={handleSalaryComponentNameChange}
                  />
                </FormControl>
              </div>

              <div className="elementWithBtn width100p">
                <div className="formElement ">
                  <FormControl className="formControl">
                    <InputLabel id="ComponentCategory">
                    {t("component_category")}
                    </InputLabel>

                    <Select
                      className="formInputField"
                      labelId="ComponentCategory"
                      id="Component-Category"
                      value={compCatg}
                      label={t("component_category")}
                      onChange={handleChangeCompCatg}
                    >
                      {/* <MenuItem value={"Fixed Component"}>Fixed Component</MenuItem>
                  <MenuItem value={"Variable Pay"}>Variable Pay</MenuItem>
                  <MenuItem value={"Retirals"}>Retirals</MenuItem>
                  <MenuItem value={"Benefit"}>Benefit </MenuItem> */}
                      {componetCategyData.map((category) => (
                        <MenuItem key={category.id} value={category.name}>
                          {category.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                {/* <Button
                  startIcon={<Add />}
                  className="dfultPrimaryBtn "
                  onClick={() => setAddCategory(true)}
                >
                  Add
                </Button> */}
                <Tooltip arrow title={t("add_new_component_category")}>
                  <IconButton
                    className="addMrElemBtn"
                    onClick={(e) => setAddCategory(true)}
                  >
                    <Add />
                  </IconButton>
                </Tooltip>
              </div>

              <div className="tskElemHeding mt10">
              {t("periodicity")} <span className="asteric">*</span>
              </div>
              <div className="formElement">
                <FormControl className="formControl">
                  <RadioGroup
                    className="formRadioGroup "
                    name="paytype"
                    value={periodicity}
                    onChange={handleChangePeriodicity}
                  >
                    <FormControlLabel
                      className="formRadioField"
                      control={<Radio />}
                      label={t("monthly")}
                      value="Monthly"
                    />
                    <FormControlLabel
                      className="formRadioField"
                      control={<Radio />}
                      label={t("annually")}
                      value="Annually"
                    />
                  </RadioGroup>
                </FormControl>
              </div>

              <div className="tskElemHeding mt10">
              {t("calculation")} <span className="asteric">*</span>
              </div>

              <div className="formElement">
                <FormControl className="formControl">
                  <RadioGroup
                    className="formRadioGroup "
                    name="paytype"
                    value={calculationtype}
                    onChange={handleChangecalculationtype}
                  >
                    <FormControlLabel
                      className="formRadioField"
                      control={<Radio />}
                      label={t("flat_amount")}
                      value="Flat"
                    />
                    {salaryComponentName.toLowerCase() === "basic" ? (
                      <FormControlLabel
                        className="formRadioField"
                        control={<Radio />}
                        label={t("percentage_of_ctc")}
                        value="% of CTC"
                      />
                    ) : (
                      <FormControlLabel
                        className="formRadioField"
                        control={<Radio />}
                        label={t("percentage_of_basic")}
                        value="% of Basic"
                      />
                    )}
                  </RadioGroup>
                </FormControl>
              </div>
              <div className="formElementGroup ">
                <div className="formElement width50p">
                  <FormControl className="formControl">
                    {calculationtype === "Flat" && (
                      <TextField
                        label={t("enter_amount")}
                        variant="outlined"
                        className="formTextFieldArea"
                        // required
                        value={calculationAmount}
                        onChange={handleCalculationAmountChange}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start" className="prIA">
                              ₹
                            </InputAdornment>
                          ),
                          inputMode: "numeric",
                          pattern: "[0-9]*",
                        }}
                      />
                    )}
                    {/* {calculationtype === "% of Basic" && (
                      <TextField
                        label="Enter Percentage"
                        variant="outlined"
                        className="formTextFieldArea"
                        required
                        value={calculationPercentage}
                        onChange={handleCalculationPercentageChange}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end" className="prIA">
                              %
                            </InputAdornment>
                          ) ,
                          inputMode: "numeric",
                          pattern: "[0-9]*",
                        }}
                      />
                    )} */}
                    {salaryComponentName.toLowerCase() === "basic"
                      ? calculationtype === "% of CTC" && (
                          <TextField
                            label={t("enter_percentage")}
                            variant="outlined"
                            className="formTextFieldArea"
                            required
                            value={calculationPercentage}
                            onChange={handleCalculationPercentageChange}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end" className="prIA">
                                  %
                                </InputAdornment>
                              ),
                              inputMode: "numeric",
                              pattern: "[0-9]*",
                            }}
                          />
                        )
                      : calculationtype === "% of Basic" && (
                          <TextField
                            label={t("enter_percentage")}
                            variant="outlined"
                            className="formTextFieldArea"
                            required
                            value={calculationPercentage}
                            onChange={handleCalculationPercentageChange}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end" className="prIA">
                                  %
                                </InputAdornment>
                              ),
                              inputMode: "numeric",
                              pattern: "[0-9]*",
                            }}
                          />
                        )}
                  </FormControl>
                </div>
              </div>

              <div className="tskElemHeding mt10">
              {t("configuration")}
                {/* <span className="asteric">*</span> */}
              </div>

              <div className="empContListCheck">
                {Object.keys(groupedConfigData).map((key, index) => (
                  <div className="formElement" key={index}>
                    <FormControl className="formControl">
                      <FormGroup className="formRadioGroup gap0DC">
                        <FormControlLabel
                          className="formRadioField"
                          control={
                            <Checkbox
                              checked={considerChecked[key] || false}
                              onChange={handleConsiderChange}
                              name={key}
                            />
                          }
                          label={key}
                        />
                        {groupedConfigData[key] && (
                          <div className="checkboxWithlist">
                            <RadioGroup
                              className="formRadioGroup gap0DC"
                              name={`paytype-${key}`}
                              value={selectedRadio[key] || ""}
                              onChange={(event) =>
                                handleChangeRadio(key, event.target.value)
                              }
                            >
                              {groupedConfigData[key].map((rule, idx) => (
                                <FormControlLabel
                                  key={idx}
                                  className="formRadioField"
                                  control={<Radio />}
                                  label={rule}
                                  value={rule.toLowerCase().replace(/\s+/g, "")}
                                  disabled={!considerChecked[key]}
                                />
                              ))}
                            </RadioGroup>
                          </div>
                        )}
                      </FormGroup>
                    </FormControl>
                  </div>
                ))}
              </div>

              {/* <div className="empContListCheck">
            <div className="formElement">
              <FormControl className="formControl">
                <FormGroup className="formRadioGroup gap0DC">
                  <FormControlLabel
                    className="formRadioField"
                    label="Consider for EPF Contribution"
                    control={
                      <Checkbox
                        checked={considerChecked}
                        onChange={handleConsiderChange}
                      />
                    }
                  />
                  <div className="checkboxWithlist">
                    <RadioGroup
                      className="formRadioGroup gap0DC"
                      name="paytype"
                      value={epfContribution}
                      onChange={handleChangeEpfContribution}
                    >
                      <FormControlLabel
                        className="formRadioField"
                        control={<Radio />}
                        label="Always"
                        value="always"
                        disabled={!considerChecked}
                      />
                      <FormControlLabel
                        className="formRadioField"
                        control={<Radio />}
                        label="Only when PF Wage is less than 15000"
                        value="pfwase15"
                        disabled={!considerChecked}
                      />
                    </RadioGroup>
                  </div>
                </FormGroup>
              </FormControl>
            </div>
          </div>  */}

              {/* <div className="formElement">
            <FormControl className="formControl">
              <FormGroup className="formRadioGroup gap0DC">
                <FormControlLabel
                  className="formRadioField"
                  control={<Checkbox />}
                  label="Consider for ESI Contribution"
                />
              </FormGroup>
            </FormControl>
          </div> */}
            </>
          )}
        </div>

        <div className="elementFooter">
          <div className="formBtnElement">
            <Button
              className="dfultPrimaryBtn"
              onClick={() => finalSubmit()}
              disabled={loading}
            >
              {loading ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                <>{t("save")}</>
              )}
            </Button>
            {!loading && (
              <Button
                className="dfultDarkSecondaryBtn"
                onClick={() => handelCloseNewSalaryComponent()}
              >
                {t("cancel")}
              </Button>
            )}
          </div>
        </div>

        {addCategory && (
          <>
            <Box
              className="ModelBox salaryComponent"
              sx={{ boxShadow: 24, p: 4 }}
            >
              <IconButton
                style={{ position: "absolute", top: 8, right: 8 }}
                onClick={handleCloseComponentCategoryModal}
              >
                <Close />
              </IconButton>
              <div className="addMoreAgendaModel">
                {/* <div className="modelTitle">Add Category</div> */}
                <div className="modelTitle">
                  {isEditCategyComponent
                    ? t("update_component_category")
                    : t("add_component_category")}
                </div>

                <div className="elementFormContainer">
                  <div className="elementWithBtn width100p mt10">
                    <div className="formElement">
                      <FormControl className="formControl">
                        <TextField
                          required
                          className="modelTextFild"
                          label={t("enter_category_name")}
                          variant="outlined"
                          multiline
                          value={addCompCategoryText}
                          onChange={handleAddCompCatText}
                        />
                      </FormControl>
                    </div>
                    <Button
                      startIcon={<Add />}
                      className="dfultPrimaryBtn "
                      onClick={
                        isEditCategyComponent
                          ? handleSubmitCategoryUpdateChange
                          : addComponentCategoryData
                      }
                    >
                      {categoryLoading ? (
                        <CircularProgress size={24} color="inherit" />
                      ) : isEditCategyComponent ? (
                        t("update")
                      ) : (
                        t("add")
                      )}
                      {/* {isEditCategyComponent ? "Update" : "Add"} */}
                    </Button>
                    {isEditCategyComponent && (
                      <Button
                        className="dfultPrimaryBtn "
                        onClick={() => handlCancelEditCategory()}
                      >
                        {t("cancel")}
                      </Button>
                    )}
                  </div>
                  <div className="formElement mt10">
                    <h3>{t("categories")}</h3>
                    {componetCategyData.length === 0 ? (
                      <p>{t("no_categories_found")}</p>
                    ) : (
                      <ul>
                        {componetCategyData.map((category, index) => (
                          <li key={index} className="categoryItem">
                            <span>{category.name}</span>
                            <IconButton
                              onClick={() =>
                                handleUpdateCategoryChange(category)
                              }
                            >
                              <Edit />
                            </IconButton>
                            <IconButton
                              disabled={isEditCategyComponent}
                              onClick={() =>
                                deleteComponentCategory(category.id)
                              }
                            >
                              <Trash />
                            </IconButton>
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
                </div>

                {/* <div className="modActionBtnGrp">
                  <Button
                    className="dfultPrimaryBtn"
                    onClick={
                      isEditCategyComponent
                        ? handleSubmitCategoryUpdateChange
                        : addComponentCategoryData
                    }
                    disabled={categoryLoading}
                  >
                    {categoryLoading ? (
                      <CircularProgress size={24} color="inherit" />
                    ) : (
                      <>Submit</>
                    )}
                  </Button>
                  {!categoryLoading && (
                    <Button
                      className="dfultDarkSecondaryBtn"
                      onClick={handleCloseComponentCategoryModal}
                    >
                      Cancel
                    </Button>
                  )}
                </div> */}
              </div>
            </Box>
          </>
        )}
      </div>
    </>
  );
}
