import {
  ArrowBackIosNew,
  ArrowForwardIos,
  Close,
  Description,
  DescriptionOutlined,
} from "@mui/icons-material";
import { Button, IconButton, Tooltip } from "@mui/material";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Plus, Search, XLg } from "react-bootstrap-icons";
import { toast } from "react-toastify";
import TemplateModal from "../TemplateModal";
import DashboardServices from "../../../../services/DashboardServices";
import { getLoginUserDetails } from "../../../../utils/Utils";
import { useMemo } from "react";
import DocumentTab from "../../../../common/DocumentTab";
import { v4 as uuidv4 } from "uuid";
import DocumentTemplateViewer from "./DocumentTemplateViewer";
import { AddTabContext } from "../../../dashboard/view/DashboardPage";
import { CustomModal } from "./Modals/CustomModal";

export default function TaskTemplates({
  recommendedTemplateList,
  setRecommendedTemplateList,
  tempOfOrgList,
  addedTemplateList,
  setAddedTemplateList,
  closeTemplate,
  mode,
}) {
  const { addTab } = useContext(AddTabContext);
  const loginUserDetail = getLoginUserDetails();
  const [filterString, setFilterString] = useState("");
  const [templateIdDraftMap, setTemplateIdDraftMap] = useState(new Map());
  const [previewFileData, setPreviewFileData] = useState(null);

  const handlePreviewTemplate = (value) => {
    // addTab({
    //   key: uuidv4(),
    //   title: `File_${value.fileDisplayName}`,
    //   content: <DocumentTemplateViewer file={value} />,
    //   isDraggable: true,
    // });
    setPreviewFileData(value);
    // setViewedTemplate(value);
  };
  const handleRemoveSelectedTemplate = (index) => {
    const temp = [...addedTemplateList];
    temp.splice(index, 1);
    console.log("spliced temp", temp);
    setAddedTemplateList(temp);
  };

  const draftTemplate = (viewedTemplate) => {
    // console.log(viewedTemplate);
    // console.log(viewedTemplate.fileName);
    // console.log(viewedTemplate.fileName.split(".")[1]);

    const tFileName = templateIdDraftMap.get(viewedTemplate.templateId);
    let tempFileName = null;
    if (tFileName) {
      tempFileName = tFileName;
    } else {
      tempFileName = `DF_${loginUserDetail.userId}_${
        viewedTemplate.taskMasterId
      }_${new Date().getTime()}.${viewedTemplate.fileName.split(".")[1]}`;
    }

    let reqDto = {
      sourceTemplateFileName: viewedTemplate.fileName,
      sourceTemplateFolderPath: viewedTemplate.fileFolder,
      tempFileName: tempFileName,
    };

    if (tFileName) {
      viewedTemplate.fileName = tempFileName;

      setAddedTemplateList([...addedTemplateList, viewedTemplate]);
      let notaddedTemplateList = recommendedTemplateList.filter(
        (template) => template.templateId !== viewedTemplate.templateId
      );

      setRecommendedTemplateList((prev) => [...prev, notaddedTemplateList]);
      return;
    }

    DashboardServices.draftTemplate(reqDto, loginUserDetail.userId).then(
      (response) => {
        console.log(response.data);
        if (response.data.returnValue === "1") {
          toast.success(`Template Added to Task!`, {
            position: toast.POSITION.TOP_RIGHT,
          });
          viewedTemplate.fileName = tempFileName;

          setAddedTemplateList([...addedTemplateList, viewedTemplate]);
          let notaddedTemplateList = recommendedTemplateList.filter(
            (template) => template.templateId !== viewedTemplate.templateId
          );
          // console.log("viewedTemplate", viewedTemplate);
          // console.log("addedTemplateList", addedTemplateList);
          // console.log("notaddedTemplateList", notaddedTemplateList);
          setRecommendedTemplateList(notaddedTemplateList);
          templateIdDraftMap.set(viewedTemplate.templateId, tempFileName);
        } else {
          toast.error(`Something went wrong!`, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      }
    );
  };

  const getAllFilteredTemplate = useMemo(() => {
    console.log("temp of org list = ", tempOfOrgList);
    const temp = tempOfOrgList.filter((item) =>
      item.fileDisplayName.toLowerCase().includes(filterString.toLowerCase())
    );
    console.log("filtered value =", temp);
    return temp;
  }, [filterString, tempOfOrgList]);
  return (
    <>
      <div className="taskTempaltesPannel">
        <div className="taskTemplatesContainer">
          <div className="taskTempHeaderPanel">
            <div className="tskHedCon">Templates</div>
            <div className="taskActionBtnGrp">
              <div className="taskActBtn closeBtnD10">
                <IconButton
                  aria-label="close"
                  onClick={() => {
                    closeTemplate();
                  }}
                >
                  <Tooltip title="Close" arrow>
                    <XLg />
                  </Tooltip>
                </IconButton>
              </div>
            </div>
          </div>

          <div className="tskTempContainFull">
            <div className="taskContain">
              <div className="taskContainDetail">
                <div className="taskContDtlContainer">
                  {/* Selected Templates start */}
                  {addedTemplateList && addedTemplateList.length > 0 && (
                    <div className="taskElementGrp">
                      <div className="tskElemHeding">Selected Templates</div>

                      <div class="elementFileListedGrp">
                        <div className="horaizonScroll">
                          <div class="elemntFilsgrpTemplate">
                            {addedTemplateList &&
                              addedTemplateList.map((item, index) => (
                                <div
                                  className="elemntFile"
                                  onClick={() => {
                                    console.log("template value= ", item);
                                    setPreviewFileData(item);
                                  }}
                                >
                                  <Tooltip
                                    title={item.fileDisplayName}
                                    arrow
                                    className="elmFileTolTip"
                                  >
                                    <span className="elemntFilIoc">
                                      <DescriptionOutlined />
                                    </span>
                                    <span className="elemntFilNm">
                                      {item.fileDisplayName}
                                    </span>
                                    <div
                                      className="fltrdTgClose"
                                      onClick={(e) =>
                                        handleRemoveSelectedTemplate(index)
                                      }
                                    >
                                      <Close />
                                    </div>
                                  </Tooltip>
                                </div>
                              ))}

                            {/* <div className="elemntFile">
                            <Tooltip
                              title="Requisition-form.doc"
                              arrow
                              className="elmFileTolTip"
                            >
                              <span className="elemntFilIoc">
                                <DescriptionOutlined />
                              </span>
                              <span className="elemntFilNm">
                                Requisition-form.doc
                              </span>
                            </Tooltip>
                          </div> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {/* Selected Templates end */}

                  {/* sugessted templates start */}
                  {recommendedTemplateList &&
                    recommendedTemplateList.length > 0 && (
                      <div className="taskElementGrp">
                        <div className="tskElemHeding">Suggested Templates</div>
                        <div className="suggestTempList">
                          {recommendedTemplateList &&
                            recommendedTemplateList.map((item) => (
                              <div className="sugestTemp">
                                <div className="sugTempNm">
                                  <div className="sugTempIOC">
                                    <Description />
                                  </div>
                                  <div className="sugTemFlNm">
                                    {item.fileDisplayName}
                                  </div>
                                </div>
                                <div className="sugTemBtnGrp">
                                  <Button
                                    className="sugTemButton"
                                    onClick={(e) => {
                                      const targetId = item.templateId;
                                      let temp = [...addedTemplateList];
                                      const present = temp.some(
                                        (obj) => obj.templateId === targetId
                                      );
                                      if (present) {
                                        toast.info("Template already present");
                                      } else {
                                        if (mode === "new") {
                                          draftTemplate(item);
                                        } else {
                                          temp.push(item);
                                          setAddedTemplateList(temp);
                                        }
                                      }
                                    }}
                                  >
                                    Add
                                  </Button>
                                  <Button
                                    className="sugTemButton"
                                    onClick={(e) => {
                                      handlePreviewTemplate(item);
                                    }}
                                  >
                                    Preview
                                  </Button>
                                </div>
                              </div>
                            ))}
                        </div>
                        <div className="viewAllSugTemp">
                          <span>View All</span>
                        </div>
                      </div>
                    )}
                  {/* sugessted templates End */}

                  {/* more templates start */}
                  <div className="taskElementGrp">
                    {((recommendedTemplateList &&
                      recommendedTemplateList.length > 0) ||
                      (addedTemplateList && addedTemplateList.length > 0)) && (
                      <div className="tskElemHeding">More Templates</div>
                    )}
                    <div className="morTaskSearch">
                      <div class="searchUser">
                        <div class="searchIcon">
                          <Search />
                        </div>
                        <input
                          type="text"
                          class="serchInput"
                          placeholder="Search or find by name"
                          onChange={(e) => {
                            console.log("target vaue =", e.target.value);
                            setFilterString(e.target.value);
                          }}
                        />
                      </div>
                      <Button className="srchMrTempBtn">Search</Button>
                    </div>
                    {/* <div className="morAvlTemp">{tempOfOrgList && } Templates available</div> */}

                    <div className="moreTempList">
                      {getAllFilteredTemplate &&
                        getAllFilteredTemplate.map((item) => (
                          <div className="moreTemp">
                            <div className="moreTempNm">
                              <div className="moreTempIOC">
                                <Description />
                              </div>
                              <div className="moreTemFlNm">
                                {item.fileDisplayName}
                              </div>
                            </div>
                            <div className="moreTemBtnGrp">
                              <Button
                                className="moreTemButton"
                                onClick={(e) => {
                                  const targetId = item.templateId;
                                  let temp = [...addedTemplateList];
                                  const present = temp.some(
                                    (obj) => obj.templateId === targetId
                                  );
                                  if (present) {
                                    toast.info("Template already present");
                                  } else {
                                    if (mode === "new") {
                                      draftTemplate(item);
                                    } else {
                                      temp.push(item);
                                      setAddedTemplateList(temp);
                                    }
                                  }
                                }}
                              >
                                Add
                              </Button>
                              <Button
                                className="moreTemButton"
                                onClick={(e) => {
                                  console.log("preview clicked");
                                  handlePreviewTemplate(item);
                                }}
                              >
                                Preview
                              </Button>
                            </div>
                          </div>
                        ))}
                    </div>
                  </div>
                  {/* more templates end */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {previewFileData && (
        <CustomModal
          modalTitle=""
          style={{ zIndex: 10001 }}
          closeModal={() => {
            setPreviewFileData(null);
          }}
          className={"width90vwModel"}
        >
          <div className="modBody">
            <DocumentTemplateViewer file={previewFileData} />,
          </div>
        </CustomModal>
      )}
    </>
  );
}
