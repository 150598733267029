import { Button, Modal, TextField } from "@mui/material";
import React, { useState, useEffect, useRef } from "react";
import {jwtDecode} from "jwt-decode"; 
import { requestOtp, resendOtp, validateOtp } from "../services/AdminService";
import { toast } from "react-toastify";

export default function GettingServiceOtp({ onSubmitOtp, otpServiceId }) {
    const userDetails = jwtDecode(localStorage.getItem("token"));
    const [otpValue, setOtpValue] = useState('');
    const [timeLeft, setTimeLeft] = useState(120);
    const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);
    const [showResendButton, setShowResendButton] = useState(false);
    const [openModal, setOpenModal] = useState(true);
    const [requestIdData, setRequestIdData] = useState("");
    
    // Use ref to store the start time of the countdown
    const countdownStartRef = useRef(Date.now());

    const handleOtpValueChange = (event) => {
        setOtpValue(event.target.value);
    };

    const handleCloseModal = () => {
        setOpenModal(false);
    };

    const handleSubmitOtp = async () => {
        if (otpValue.trim() === "") {
            toast.error("Please Enter OTP", {
                position: toast.POSITION.TOP_RIGHT,
            });
            return;
        }

        toast.info("Validating OTP", {
            position: toast.POSITION.TOP_RIGHT,
        });

        if (otpValue && requestIdData) {
            const reqDto = {
                requestId: requestIdData,
                otp: otpValue,
            };

            try {
                const response = await validateOtp(reqDto);
                if (response.data.returnValue === "1") {
                    toast.success(response.data.message, {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                    onSubmitOtp(true, otpValue, requestIdData);
                    setOpenModal(false);
                } else {
                    toast.error(response.data.message || "Failed to validate OTP", {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                    setOpenModal(true);
                }
            } catch (error) {
                console.error("Error validating OTP", error);
                toast.error("An error occurred while validating OTP", {
                    position: toast.POSITION.TOP_RIGHT,
                });
                setOpenModal(true);
            }
        } else {
            toast.error("OTP value or request ID is missing", {
                position: toast.POSITION.TOP_RIGHT,
            });
        }
    };

    const handleResendOtp = async () => {
        setOtpValue('');
        if (requestIdData) {
            try {
                const response = await resendOtp(userDetails.userId, otpServiceId, requestIdData);
                if (response.data.returnValue === "1") {
                    toast.success(response.data.message, {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                    setShowResendButton(false);
                    setIsSubmitDisabled(false);
                    startCountdown(); // Restart the countdown
                }
            } catch (error) {
                toast.error("Failed to resend OTP");
                console.error(error);
            }
        } else {
            toast.error("Request ID data is missing");
        }
    };

    const startCountdown = () => {
        countdownStartRef.current = Date.now();
        setTimeLeft(120);

        const timer = setInterval(() => {
            const elapsedTime = Math.floor((Date.now() - countdownStartRef.current) / 1000);
            const newTimeLeft = 120 - elapsedTime;

            if (newTimeLeft <= 0) {
                clearInterval(timer);
                setIsSubmitDisabled(true);
                setShowResendButton(true);
                setTimeLeft(0);
            } else {
                setTimeLeft(newTimeLeft);
            }
        }, 1000);

        return () => clearInterval(timer);
    };

    const handleVisibilityChange = () => {
        if (document.visibilityState === "visible") {
            const elapsedTime = Math.floor((Date.now() - countdownStartRef.current) / 1000);
            const newTimeLeft = 120 - elapsedTime;

            if (newTimeLeft <= 0) {
                setIsSubmitDisabled(true);
                setShowResendButton(true);
                setTimeLeft(0);
            } else {
                setIsSubmitDisabled(false);
                setShowResendButton(false);
                setTimeLeft(newTimeLeft);
            }
        }
    };

    useEffect(() => {
        requestOtp(userDetails.userId, otpServiceId).then((response) => {
            setRequestIdData(response.data);
            startCountdown(); // Start the countdown on initial load
        });

        document.addEventListener("visibilitychange", handleVisibilityChange);

        return () => {
            document.removeEventListener("visibilitychange", handleVisibilityChange);
        };
    }, [userDetails.userId, otpServiceId]);

    return (
        <Modal
            open={openModal}
            // onClose={handleCloseModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            <div
                style={{
                    maxWidth: "600px",
                    minHeight: "150px",
                    backgroundColor: "white",
                    padding: "20px",
                    borderRadius: "10px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                }}
            >
                <h2 id="modal-modal-title">
                    Please Enter An OTP that has been sent to your Email
                </h2>
                <TextField
                    id="outlined-basic"
                    variant="outlined"
                    label="Enter Otp"
                    value={otpValue}
                    onChange={handleOtpValueChange}
                    sx={{ border: "1px solid #000000" }}
                />
                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                    }}
                >
                    {showResendButton ? (
                        <Button
                            className="dfultPrimaryBtn"
                            onClick={handleResendOtp}
                        >
                            Resend OTP
                        </Button>
                    ) : (
                        <span>{`Time left: ${timeLeft}s`}</span>
                    )}
                </div>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginTop: "20px",
                    }}
                >
                    <Button
                        className="dfultPrimaryBtn"
                        onClick={handleSubmitOtp}
                        disabled={isSubmitDisabled}
                    >
                        Submit OTP
                    </Button>
                    {/* <Button
                        className="dfultDarkSecondaryBtn"
                        onClick={handleCloseModal}
                    >
                        Cancel
                    </Button> */}
                </div>
            </div>
        </Modal>
    );
}
