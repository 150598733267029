import {
  ArrowBackIosNew,
  ArrowForwardIos,
  DescriptionOutlined,
} from "@mui/icons-material";
import { Button, IconButton, Tooltip } from "@mui/material";
import { Plus } from "iconoir-react";
import React, { useEffect, useMemo } from "react";
import { useContext } from "react";
import DocumentTab from "../../../../common/DocumentTab";
import { useRef } from "react";
import DashboardServices from "../../../../services/DashboardServices";
import { getLoginUserDetails } from "../../../../utils/Utils";
import { useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { AddTabContext } from "../../../dashboard/view/DashboardPage";

const TemplateList = ({ data, handleAddTemplate }) => {
  const loginUserDetail = getLoginUserDetails();
  const { addTab } = useContext(AddTabContext);
  const elementRef = useRef(null);
  const [tempOfOrgList, setTemplateOfOrgList] = useState([]);
  const [arrowDisable, setArrowDisable] = useState(true);
  const filteredTemplateList = useMemo(() => {
    return data.templates.filter(
      (template) =>
        !(template.taskTemplateId === 0 && template.taskDetailId === 0)
    );
  }, [data.templates]);

  const [tempDivWidth, setTempDivWidth] = useState(0);

  useEffect(() => {
    if (elementRef.current) {
      setTempDivWidth(elementRef.current.offsetWidth);
    }
  }, [elementRef]);

  const handleHorizantalScroll = (element, speed, distance, step) => {
    let scrollAmount = 0;
    const slideTimer = setInterval(() => {
      element.scrollLeft += step;
      scrollAmount += Math.abs(step);
      if (scrollAmount >= distance) {
        clearInterval(slideTimer);
      }
      if (element.scrollLeft === 0) {
        setArrowDisable(true);
      } else {
        setArrowDisable(false);
      }
    }, speed);
  };

  const openTemplateInTab = (template) => {
    const tabKey = uuidv4();
    addTab({
      key: tabKey,
      title: `File_${template.fileDisplayName}`,
      content: <DocumentTab file={template} tabKey={tabKey} />,
      isDraggable: true,
    });
  };

  return (
    <>
      <div className="taskElementGrp">
        <div className="tskElemHeding">Template</div>

        <div class="elementFileListedGrp">
          <div className="horaizonScroll">
            <IconButton
              className="goPrevousBtn"
              onClick={() => {
                handleHorizantalScroll(elementRef.current, 25, 200, -10);
              }}
              disabled={arrowDisable}
            >
              <ArrowBackIosNew />
            </IconButton>
            <div className="width100p">
              <div class="elemntFilsgrp" ref={elementRef}>
                {filteredTemplateList &&
                  filteredTemplateList.map((template) => (
                    <div
                      className="elemntFile"
                      onClick={(e) => {
                        openTemplateInTab(template);
                      }}
                    >
                      <Tooltip
                        title={template.fileDisplayName}
                        arrow
                        className="elmFileTolTip"
                      >
                        <span className="elemntFilIoc">
                          <DescriptionOutlined />
                        </span>
                        <span className="elemntFilNm">
                          {template.fileDisplayName}
                        </span>
                      </Tooltip>
                    </div>
                  ))}
              </div>
            </div>
            {tempDivWidth > 370 && (
              <IconButton
                className="goNextBtn"
                onClick={() => {
                  handleHorizantalScroll(elementRef.current, 25, 200, 10);
                }}
              >
                <ArrowForwardIos />
              </IconButton>
            )}
          </div>
          <div class="elmntAddMrFiles">
            <Button
              className="elemntMrFileBtn"
              variant="outlined"
              onClick={handleAddTemplate}
            >
              <Plus />
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default TemplateList;
