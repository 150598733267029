export const enLang = {
  // new dashboard start
  open_setting: "Open Setting",
  accounts: "Accounts",
  scheduler: "Scheduler",
  manage_tasks: "Manage Tasks",
  manage_meetings: "Manage Meetings",
  chat: "Chat",
  drive: "Drive",
  google_search: "Google Search",
  sheets: "Sheets",
  word_processor: "Word Processor",
  slides: "Slides",
  analysis: "Analysis",
  profile: "Profile",
  sign_out: "Sign Out",
  show_hide: "Show/Hide",
  minimize: "Minimize",
  maximize: "Maximize",
  close: "Close",
  meeting: "Meeting",
  pad: "Pad",
  task: "Task",
  pending_task: "Pending Task",
  completed_task: "Completed Task",
  concern: "Concern",
  delayed_task: "Delayed Task",
  under_process: "Under Process",
  create_new_task: "Create New Task",
  choose_department: "Choose Department",
  choose_activity: "Choose Activity",
  activity: "Activity",
  select_contract: "Select Contract",
  description: "Description",
  fill_the_form: "Fill The Form",
  upload: "Upload",
  assign_user: "Assign User",
  select_time: "Select Time",
  select_priority: "Select Priority",
  select_meeting: "Select Meeting",
  select_template: "Select Template",
  assign_user_to_selected_task: "Assign user to selected Task",
  select_date_time: "Select Completion  Date Time",
  added_templates: "Added Templates",
  available_templates: "Available Templates",
  view_template: "View Template",
  full_screen: "Full Screen",
  exit_full_screen: "Exit Full Screen",
  close_template: "Close Template",
  no_files_uploaded: "No Files uploaded",
  my_accounts: "My Accounts",
  notifications: "Notifications",
  opened_files: "Opened Files",
  active_files: "Active Files",
  active_file: "Active File",
  opened: "Opened",
  // new dashboard end

  // user profile start
  change_avtar: "Change Avatar",
  edit: "Edit",
  first_name: "First Name",
  middle_name: "Middle Name",
  last_name: "Last Name",
  cancel: "Cancel",
  save: "Save",
  update_your_photo_and_personal_details:
    "Update Your Photo and Personal Details",
  employee_id: "Employee Id",
  department: "Department",
  designations: "Designations",
  joining_date: "Joining Date",
  reporting_head: "Reporting Head",
  date_of_birth: "Date of Birth",
  martial_status: "Marital Status",
  choose_martial_status: "Choose Marital Status",
  select: "Select",
  single: "Single",
  married: "Married",
  widowed: "Widowed",
  divorced: "Divorced",
  separated: "Separated",
  official_phone_no: "Official Phone No",
  personal_phone_no: "Personal Phone No",
  emergency_phone_no: "Emergency Phone No",
  official_email_id: "Official Email Id",
  personal_email_id: "Personal Email Id",
  password: "Password",
  reset_password: "Reset Password",
  current_password: "Current Password",
  new_password: "New Password",
  confirm_password: "Confirm Password",
  language: "Language",
  select_languages: "Select Languages",
  skills: "Skills",
  add_skills: "Add Skills",
  choose_your_own_avtar: "Choose Your Own Avatar",
  our_pre_biuld_avtar: "Pre Built",
  upload_form_pc: "Upload",
  avtar_by_ai: "AI Avatar",
  ai_avtar_coomiing_soon: " AI Avatar Coming Soon!",
  // user profile end

  // task container start
  please_give_a_comment: "Please Give a Comment",
  please_give_a_concern: "Please Give a Concern",
  your_progress_of_task_cant_not_decrease:
    "Your Progress Of Task Can't not Decrease",
  please_type_your_reason: "Please Type Your Reason",
  please_select_a_user: "Please Select a User",
  no_templates_found: "No Templates Found",
  created_by: "Created By",
  name: "Name",
  work: "Work",
  task: "Task",
  status: "Status",
  detail: "Detail",
  add_concern_here: "Add concern here",
  comments: "Comments",
  view: "View",
  add_link_task: "Add Link Task",
  transfer: "Transfer",
  meetings: "Meetings",
  from: "From",
  templates: "Templates",
  link_task: "Link Task",
  submit: "Submit",
  ok: " OK",
  assignd_to: "Assigned to",
  re_assign: "Re-Assign",
  disagree: "Disagree",
  sign_off: "Sign Off",
  view_attachment: "View Attachment",
  view_task_history: "View Task History",
  reason_for_transfer: "Reason For Transfer",
  manage_your_files: "Manage Your Files",
  upload_document: "Upload Document",
  file_details: "File Details",
  please_enter_subTask_details: "Please Enter SubTask Details",
  please_select_users: "Please Select Users",
  please_enter_task_description: "Please Enter Task Description",
  create_link_task: "Create Link Task",
  linked_task: "Linked Task",
  // task container end

  gender: "Gender",
  job_description: "Job Description",
  timezone: "Timezone",
  current_address: "Current Address",
  permanent_address: "Permanent Address",
  guardian_name: "Guardian Name",
  guardian_relationship: "Guardian Relationship",
  spouse_Name: "Spouse Name",
  blood_groud: "Blood Groud",
  religion: "Religion",
  bank_name: "Bank Name",
  ifsc_code: "IFSC Code",
  bank_branch: "Bank Branch",
  beneficiary_name: "Beneficiary Name",
  bic_code: "BIC Code",
  swift_code: "Swift Code",
  iban_number: "IBAN Number",
  highest_qualification: "Highest Qualification",
  highest_qualification_year: "Highest Qualification Year",
  highest_qualification_institute: "Highest Qualification Institute",
  kra: "KRA",
  experiences: "Experiences",
  govt_ids: "Govt Ids",
  highest_qualification_certificate: "Highest Qualification Certificate",
  fitness_certificate: "Fitness Certificate",
  job_title: "Job Title",
  nature_of_appointment: "NATURE OF APPOINTMENT",
  type_of_position: "TYPE OF POSITION",
  new: "New",
  replacement: "Replacement",
  location_required_for: "LOCATION REQUIRED FOR",
  qualification: "QUALIFICATION",
  reset_form: "Reset Form",
  event: "Event",
  booked_event: "Booked Event",
  canceled_event: "Cancelled Event",
  reschedule_event: "Reschedule Event",
  contract_wise_pending_task: "Contract wise pending task",
  organization_tree: "Organization Tree",
  employee_with: "Employee with",
  most: "Most",
  few: "Few",
  no: "No",
  last_7_days: "Last 7 Days",
  last_30_days: "Last 30 Days",
  last_60_days: "Last 60 Days",
  activity_wise_completed_tasks: "Activity Wise Completed Tasks",
  title_wise_events: "Title Wise Events",
  day_wise_completed_task: "Day Wise Completed Task",
  day_wise_events: "Day Wise Events",
  routine: "Routine",
  important: "Important",
  critical: "Critical",
  timeline: "Timeline",

  please_enter_room_id: "Please enter room Id",
  room_number: "Room Number *",
  todays_meetings: "Todays Meetings",
  join: "Join",
  schedule_meeting: "Schedule Meeting",
  start_instant_meeting: "Start Instant Meeting",

  meeting_details: "Meeting Details",
  please_enter_meeting_topic: "Please enter meeting topic",
  start_meeting: "Start Meeting",
  end_meeting: "End Meeting",

  please_change: "Please change",
  from_first_day_of_week_before_removing_it_from_working_day:
    "from FIrst Day Of Week before removing it from working day",
  title: "Title",
  start_time: "Start Time",
  end_time: "End Time",
  duration: "Duration",
  location: "Location",
  new_event: "New Event",
  new_recurring_event: "New Recurring Event",
  new_meeting: "New Meeting",
  day: "Day",
  week: "Week",
  month: "Month",
  agenda: "Agenda",
  timeline_views: "Timeline Views",
  grouping: "Grouping",
  gridlines: "Gridlines",
  row_auto_height: "Row Auto Height",
  tooltip: "Tooltip",
  allow_multi_drag: "Allow Multi Drag",
  settings: "Settings",
  previous: "Previous",
  next: "Next",
  current_event: "Current Event",
  today: "Today",
  my_calendar: "My Calendar",
  holiday: "Holiday",
  week_off: "Week Off",
  on_leave: "On Leave",

  first_day_of_week: "First Day of Week",
  work_week: "Work Week",
  slot_duration: "Slot Duration",
  select_slot_duration: "Select Slot Duration",
  private_notes: "Private Notes",
  meeting_notes: "Meeting Notes",
  reschedule: "Reschedule",

  add_private_notes: "Add Private Notes",
  add: "Add",

  end_time_should_be_after_start_time: "End time should be after start time",
  please_select_participants: "Please select participants",
  event_title: "Event Title",
  event_description: "Event Description",
  event_location: "Event Location",
  event_start_time: "Event Start Time",
  event_end_time: "Event End Time",
  recurring_event_details: "Recurring Event Details",
  event_days: "Event Days",
  repeat_until: "Repeat Until",
  modify_event: "Modify Event",
  cancel_event: "Cancel Event",
  cancellation_reason: "Cancellation Reason",
  reschedule_reason: "Reschedule Reason",
  event_date: "Event Date",

  file_name: "Enter File Name",
  choose_a_file: "Choose a file",
  please_enter_a_valid_file_name: "Please enter a valid file name!",
  please_enter_a_valid_file_name_special_characters_are_not_allowed:
    "Please enter a valid file name, special characters are not allowed",
  already_exists_replace_file_all_data_will_be_lost:
    "already exists! Replace file, all data will be lost",

  please_enter_your_email: "Please Enter Your Email",
  please_enter_your_password: "Please Enter Your Password",
  invalid_credentials: "Invalid Credentials",
  please_wait_redirecting_to_your_organization_home_page:
    "Please wait, redirecting to your organization home page",
  otp_sent_to_email_id_successfully: "OTP sent to email id successfully",
  please_enter_otp: "Please enter OTP",
  otp_verified_successfully: "OTP verified successfully",
  please_enter_password: "Please enter Password",
  please_enter_confirm_password: "Please enter confirm Password",
  password_and_confirm_password_does_not_match:
    "Password and confirm password does not match",
  password_changed_successfully_redirecting:
    "Password changed successfully, redirecting...",

  lets_get_you: "Let's get you",
  on_the_digital_highway: "on the digital highway",
  login: "Login",
  register: "Register",
  digitize: "DIGITIZE",
  processize: "PROCESSIZE",
  collaborate: "COLLABORATE",
  deliver: "DELIVER",
  create_magic: "Create magic",
  features: "FEATURES",
  virtual_presence: "Virtual Presence",
  create_your_web_presence_with_a_few_clicks_no_need_for_domains_webhosting_or_it_infra:
    "Create your web presence with a few clicks. No need for domains, webhosting or IT Infra",
  organization: "Organization",
  create_your_organization_add_people_assign_departments_functions_and_go_live:
    "Create your organization, add people, assign departments & functions and go live",
  task_management: "Task Management",
  easily_create_tasks_and_monitor_them_remove_management_overheads:
    "Easily create tasks and monitor them. Remove management overheads",
  compliances: "Compliances",
  hold_board_agm_meetings_issue_agenda_take_polls_record_them_keep_track_of_compliances:
    " Hold Board / AGM meetings, issue Agenda, take polls, record them . Keep track of compliances",
  productivity: "Productivity",
  from_word_processor_to_spreadsheet_presenter_all_productivity_tools_built_in_with_drive_and_business_templates:
    "From word processor to spreadsheet & presenter - all productivity tools built in with Drive and business templates",
  collaboration: "Collaboration",
  complete_collaboration_suite_without_subscribing_to_any_other_provider:
    "Complete collaboration suite without subscribing to any other provider",
  security: "Security",
  ensure_data_security_with_best_of_class_network_security_encryption_and_data_safety:
    "Ensure data security with best of class network security, encryption and data safety",
  time_space_language: "Time, space & language",
  with_multi_language_timezone_support_let_work_go_with_teams_clients_and_vendors_across_the_world:
    "With multi language, time zone support, let work go with teams, clients and vendors across the world",
  meetings_phygital: "Meetings - Phygital",
  synchronous_and_asynchronous_meetings_scheduled_and_instant_with_private_rooms_and_webinars:
    "Synchronous and asynchronous meetings - scheduled and instant with private rooms and webinars",
  complete_workflows: "Complete Workflows",
  hr_accounts_finance_business_sales_marketing_customizable_workflows:
    "HR, Accounts, Finance, Business, Sales & Marketing, customizable workflows",
  kill_wastage: "Kill wastage",
  with_task_linked_meetings_and_result_based_task_stop_the_drift_and_save_crucial_time:
    " With task linked meetings and Result based Tasks, stop the drift and save crucial time",
  analytics: "Analytics",
  manage_by_easily_monitoring_what_is_happening_and_allocate_resources_time_money_more_effectively:
    "Manage by easily monitoring what is happening and allocate resources , time & money more effectively",
  api_driven_menus_for_integrating_existing_tools_or_external_mail_messaging_etc_build_on_your_strengths_and_covers_your_gaps:
    "API driven menus for integrating existing tools or external mail, messaging etc. Build on your strengths and covers your gaps",
  goals: "GOALS",
  effectivity: "Effectivity",
  remove_unnecessary_work_and_useless_meetings_with_our_effectivity_tools:
    "Remove unnecessary work and useless meetings with our effectivity tools",
  transcendence: "Transcendence",
  with_ahlans_asynchronous_and_synchronous_collaboration_suite_allow_work_to_happen_with_and_against_time_zones_from_anywhere:
    "With Ahlan's asynchronous and synchronous collaboration suite, allow work to happen with and against time zones from anywhere",
  save_the_climate: "Save the Climate",
  stop_unnecessary_use_of_energy_paper_travel_to_protect_our_world_without_it_our_businesses_do_not_exist:
    "Stop unnecessary use of energy , paper & travel to protect our world. Without it our businesses do not exist",
  get_in_touch: "GET IN TOUCH",
  mailing_address: "Mailing Address",
  email_address: "Email Address",
  phone_number: "Phone Number",
  socials: "Socials",
  please_rotate_your_device: "Please rotate your device",
  we_dont_support_landscape_mode_yet_please_go_back_to_portrait_mode_for_the_best_experience:
    " We don't support landscape mode yet. Please go back to portrait mode for the best experience",
  forgot_password: "Forgot Password",
  dont_have_an_account_sign_up: "Don't have an account? Sign Up",
  proceed: "PROCEED",
  already_have_an_account_sign_in: "Already have an account? Sign in",
  sign_up: "SIGN UP",
  send_otp: "Send OTP",
  submit_otp: "Submit OTP",
  change: "CHANGE",

  //////
  task_information: "Indent Information",
  event_times: "Event Times",
  create_org: "Create Organization",
  please_select_progress: "Please Select Progress",
  confirmation: "Confirmation",
  plese_enter_concern: "Please Enter Concern",
  please_enter_comment: "Please Enter Comment",
  select_your_progress_of_task: "Select Your Progress Of Task",
  recommended_templates: "Recommended Templates",
  more_templates: "More Templates",
  list_of_meetings: "List Of Meetings",
  add_internal_user: "Add Internal User",
  add_external_user: "Add External User",
  attend: "Attend",
  unable_to_attend: "Unable to Attend",
  add_contacts: "Add Contacts",
  admin_dashboard: "Admin Dashboard",
  // create_org: "Create Organization",
  create_and_open_file: "Create and Open File",
  record_audio_note: "Record Audio Note",
  audio_note: "Audio Note",

  // dynamic response message
  something_went_wrong: "Something went wrong !!",
  link_task_not_completed_against_this_task:
    "Link task not completed against this task",
  task_completed_successfully: "Task completed successfully",
  concern_raised_successfully: "Concern Raised Successfully",
  approved_successfully: "Approved successfully",
  task_progress_report_submitted_successfully:
    "Task progress report submitted successfully",
  please_select_contacts: "Please select contacts",
  vendor_partner_added_successfully: "Vendor/Partner added Successfully",
  email_address_already_exists: "Email Address Already Exists",

  //dashboard tooltips
  add_function: "Add Function",
  list_of_function: "List Of Function",
  add_people: "Add People",
  list_of_people: "List Of People",
  add_cost_center: "Add Cost Center",
  add_products: "Add Products",
  list_of_products: "List Of Products",
  add_services: "Add Services",
  list_of_services: "List Of Services",
  service_faq: "Service FAQ",
  add_template: "Add Template",
  list_of_template: "List Of Template",
  add_contracts: "Add Contracts",
  list_of_contracts: "List Of Contracts",
  add_accounts_and_invoices: "Add Accounts and Invoices",
  list_of_accounts_and_invoices: "List of Accounts and Invoices",
  edit_website: "Edit Website",
  drive_details: "Drive Details",
  copy_event_details: "Copy Event Details",
  add_Items: "Add Items",
  list_of_Items: "List of Items",
  verified_items: "Verified Items",
  non_verified_items: "Non Verified Items",
  custom_date: "Custom Date",
  enter_meeting: "Enter Meeting",
  zoyel_assistant: "Zoyel Assistant",
  enter_zoyel_mode: "Enter Zoyel mode",
  remove_notification: "Remove notification",
  back_to_normal_mode: "Back to Normal Mode",
  switch_account: "Switch account",
  max_length_15: "Maximum length is 15",
  physical_meeting: "Physical Meeting",
  start_convenient_meeting: "Start Convenient Meeting",
  select_and_drag_to_set_you_timing: "Select and drag to set the timing",
  approved_by: "Approved By",
  task_has_been_cancelled: "Task has been cancelled",
  task_has_been_closed: "Task has been closed",
  task_has_been_completed: "Task has been completed",
  documents_uploaded: "Documents Uploaded",
  please_fill_the_form_correctly: "Please Fill The Form Correctly",
  document_viewer: "Document Viewer",
  reject: "Reject",
  add_rejection_reason_here: "Add Rejection Reason Here",
  rejected_by: "Rejected By",
  rejection_reason: "Rejection Reason",
  add_cancelation_reason_here: "Add Cancelation Reason Here",
  change_mode: "Change Mode",
  word: "Word",
  assistant: "Assistant",

  menu: "Menu",
  open_main_chat_window: "Open Main Chat Window",
  view_form: "View Form",
  postponed: "Postponed",
  postpone_by: "Postponed By",
  add_postponed_details_here: "Add Postponed Details Here",
  postponed_reason: "Postponed Reason",
  postponed_date: "Postponed Date",
  please_select_correct_date: "Please Select Correct Date",
  postpone_till: "Postpone Till",
  reopen_postpone_task: "Reopen Postpone Task",
  password_changed_successfully: "Password Changed Successfully",
  password_changed_successfully_please_login:
    "Password Changed Successfully, Please login...",

  //////////Analytical Dashboard/////////////
  dashboard: "Dashboard",
  oops: "Oops!!",
  permission_for_dashboard_modules:
    "Permission for Dashboard modules not found, please contact your Administrator",
  attendance: "Attendance",
  budget: "Budget",
  contract: "Contract",
  external_user: "External User",
  no_of_user: "No of User",
  holiday_working_day: "Holiday / Working Day",
  no_of_holiday: "No of Holiday",
  items: "Items",
  leave_rules: "Leave Rules",
  payroll: "Payroll",
  active_employee: "Active Employee",
  tenure: "Tenure",
  employee: "Employee",
  attrition: "Attrition",
  routine_work: "Routine Work",
  add_budget: "Add Budget",
  consolidated: " Consolidated ",
  unit_wise: "Unit wise",
  select_unit: "Select unit",
  budget_name: "Budget Name",
  from_date: "From Date",
  to_date: " To Date",
  capital_expenditure_head: "Capital Expenditure Head",
  operating_expenditure_head: "Operating Expenditure Head",
  add_contract: "Add Contract",
  contract_name: "Contract Name",
  responsible_person_name: "Responsible Person Name",
  contract_type: "Contract Type",
  contract_owner: "Contract Owner",
  attach_file: "Attach File",
  add_external_user: "Add External User",
  select_user_type: "Select User Type",
  vendor: "Vendor",
  partner: "Partner",
  client: "Client",
  adviser: "Adviser",
  board_Member: "Board Member",
  consultant: "Consultant",
  mr: "Mr",
  ms: "Ms",
  mrs: "Mrs",
  miss: "Miss",
  first_name: "First name",
  middle_name: "Middle name",
  last_name: "Last name",
  country_code: "Country Code",
  phone_number: "Phone Number",
  full_address: "Full Address",
  fax_number: "Fax Number",
  gst_number: "GST Number",
  add_holiday: "Add Holiday",
  holiday_name: "Holiday Name",
  holiday_date: "Holiday Date",
  holiday_description_for_email: "Holiday Description for Email",
  attach_image_for_emailer: "Attach Image for Emailer",
  preview: "Preview",
  holiday_emailer_preview: "Holiday Emailer Preview",
  greeting: "Greeting !!!",
  item_type: "Item Type",
  goods: "Goods",
  service: "Service",
  item_name: "Item Name",
  description: "Description",
  category: "Category",
  add_category: "Add Category",
  default_uom: "Default UOM",
  uom: "UOM",
  add_uom: "Add UOM",
  manufacturer: "Manufacturer",
  add_manufacturer: "Add Manufacturer",
  hsn_code: "HSN Code",
  expirable: "Expirable",
  warrantee_guarantee_sla: "Warrantee/Guarantee/SLA",
  tangible: "Tangible",
  intangible: "Intangible",
  category_name: "Category Name",
  add_item_type: "Add Item Type",
  enter_uom: "Enter UOM",
  manufacturer_name: "Manufacturer Name",
  add_leave_rules: "Add Leave Rules",
  select_enter_leave_type: "Select / Enter Leave Type",
  select_gender: "Select Gender",
  employee_status: "Employee Status",
  salary_type: "Salary Type",
  required_documents: "Required Documents",
  example_file_names: "Ex: File One, File Two, etc.",
  this_is_need_for_supportive_documents:
    "This is need for supportive documents, add file names separated by a comma for eg: File One, File Two, etc",
  number_of_leaves: "Number of Leaves",
  Leave_calculation_can_be_done_either_based_on_total_number_of_leaves:
    "Leave calculation can be done either based on Total Number of leaves, leaves accrued monthly or leaves accrued yearly(if  any leave is accrued monthly then enter the value in Leave Accrued Monthly text box and keep the rest of the text boxes Blank i.e. (Total Number of Leaves and Leaves Accrued Yearly blank)",
  leave_accrued_monthly: "Leave Accrued Monthly",
  leave_accrued_yearly: "Leave Accrued Yearly",
  minimum_leaves: "Minimum Leaves",
  minimum_number_of_leaves_an_employee_may_apply_for_a_particular_leave_type:
    "Minimum number of leaves an employee may apply for a particular leave type",
  maximum_leaves: "Maximum Leaves",
  Maximum_number_of_leaves_an_employee_may_apply_at_a_stretch:
    "Maximum number of leaves an employee may apply at a stretch",
  allow_half_day: "Allow Half Day",
  if_half_day_is_applicable_for_this_leave_type:
    "If half day is applicable for this leave type ",
  combination_with_other_leaves: "Combination with Other Leaves",
  other_leave_type_that_may_be_combined_with_this_leave_type_as_per_the_organizational_leave_policy_for_eg_medical_sick_leave_may_be_combined_with_leave_x_leave_Y:
    "Other leave type that may be combined with this leave type as per the organizational leave policy( for eg. Medical / Sick leave may be combined with leave X & leave Y.)",
  minimum_employment_tenure_needed: "Minimum Employment Tenure Needed",
  minimum_number_of_days_an_employee_need_to_be_working_in_the_organization_in_order_to_apply_this_leave_type:
    "Minimum number of days an employee need to be working in the organization in order to apply this leave type",
  prior_leave_request: "Prior Leave Request",
  prior_leave_request_is_to_be_made_by_the_employee_for_applying_for_this_leave_type:
    "Prior leave request  is to be made by the employee for applying for this leave type.For eg. employees need to apply for a particular leave type atleast one day in advance,if leave type doesnot require advance intimation keep the below text box blank. Mininmum number of days regarding advance notice for leaves need to be set as per organization policy. Only the number(numeric value need to entered for this condition)",
  leave_balance_expire_days: "Leave Balance Expire Days",
  if_any_leave_type_has_a_specific_expiry_time_line_organizations_may_add_the_same_in_the_Leave_expiry_field:
    "If any leave type has a specific expiry time line organizations may add the same in the Leave expiry field",
  leaves_before_event: "Leaves Before Event",
  leave_during_pregnancy_and_after_child_birth:
    "For eg.Leave during Pregnancy and After Child Birth, the total number of leaves before and after the event (Pregnancy and After Child Birth) need to be equal to total number of leave allowed for the entire event",
  leaves_after_event: "Leaves After Event",
  leave_during_pregnancy_and_after_child_birth_total:
    "For eg.Leave during Pregnancy and After Child Birth, the total number of leaves before and after the event (Pregnancy and After Child Birth) need to be equal to total number of leave allowed for the entire event",
  carry_forward: "Carry Forward",
  yes: "Yes",
  no: "No",
  maximum_carry_forward: "Maximum Carry Forward",
  applicable_for_leaves_that_can_be_carried_forward_to_the_next_calender_year:
    "Applicable for Leaves that can be carried forward to the next calender year ",
  encashable: "Encashable",
  maximum_encashment_allowed_during_f_f:
    "Maximum Encashment Allowed during F&F",
  maximum_number_of_days_allowed_for_encashment_during_f_f:
    "Maximum number of days allowed for encashment during F&F. Maximum Encashment Allowed during F&F Cannot be more than 3 Digit",
  maximum_number_of_days_allowed_for_encashment:
    "Maximum Number Of Days Allowed For Encashment",
  maximum_number_of_days_allowed_for_encashment_cannot_more_the_three_digit_for_current_employee:
    "Maximum Number Of Days Allowed For Encashment Cannot be More The 3 Digit For Current Employee",
  minimum_leave_balance_needed_after_encashment:
    "Minimum Leave balance needed after Encashment",
  minimum_leave_balance_needed_after_encashment_cannot_more_the_three_digit_for_current_employee:
    "Minimum Leave balance needed after Encashment Cannot More The 3 Digit For Current Employee",
  add_employee: "Add Employee",
  employee_demography: "Employee Demography",
  gender: "Gender",
  male: "Male",
  female: "Female",
  others: "Others",
  official_phone_number: "Official Phone Number",
  employement_demography: "Employement Demography",
  employee_id: "Employee Id",
  employement_status: "Employement Status",
  select_unit: "Select Unit",
  employee_position: "Employee Position",
  primary_position: "Primary Position",
  select_position: "Select Position",
  with_effect_from: "With Effect From",
  additional_position: "Additional Position",
  reporting_head: "Reporting Head",
  shift: "Shift",
  salary_type: "Salary Type",
  add_routine_work: "Add Routine Work",
  exit_full_screen: "Exit Full Screen",
  enter_full_screen: "Enter Full Screen",
  select_activity: "Select Activity",
  shift_name: "Shift Name",
  set_as_default_shift: "Set as Default Shift",
  add_shift: "Add Shift",
  shift_list: "Shift List",
  default_shift: "Default Shift",
  marked_as_default_shift: "Marked as Default Shift",
  do_you_want_to_mark_this_shift_as_the_default_shift:
    "Do you want to mark this shift as the default shift",
  working_days: "Working Days",
  do_you_want_to_edit_this: "Do you want to edit this",
  select_week: "Select Week",
  update: "Update",
  edit_external_user: "Edit External User",
  edit_holiday: "Edit Holiday",
  edit_employee: "Edit Employee",
  edit_leave_rules: "Edit Leave Rules",
  are_you_sure_to_add_this_as_leave_name:
    "Are you sure to Add this as Leave Name",
  from_issued_loi: "From Issued LOI",
  candidate_name: "Candidate Name",
  candidate_demography: "Candidate Demography",
  email_id: "Email Id",
  ex_employe_org_com: "ex: employee@org.com",
  select_department: "Select Department",
  select_location: "Select Location",
  reporting_head: "Reporting Head",
  select_reporting_head: "Select Reporting Head",
  permanent_address: "Permanent Address",
  address_line_one: "Address line 1",
  address_line_two: "Address line 2",
  city: "City",
  ex_kolkata: "Ex: Kolkata",
  select_country: "Select Country",
  select_state: "Select State",
  pin: "PIN",
  ex_pin: "Ex: 700001",
  correspondence_address: "Correspondence Address",
  same_as_permanent_address: "Same as Permanent Address",
  probation_confirmation_period: "Probation / Confirmation period",
  probation_days: "Probation Days",
  address_change_notice: "Address Change Notice(in days)",
  prior_leave_notice: "Prior Leave Notice",
  termination_conditions: "Termination Conditions",
  continuous_absent_days: "Continuous Absent Days",
  notice_period: "Notice Period(in days)",
  salary_in_lieu_of_notice: "Salary in Lieu of Notice (in months' salary)",
  enter_number_of_months: "Enter number of months",
  define_salary_breakup: "Define Salary Breakup",
  employment_type: "Employment Type",
  on_payroll: "On Payroll",
  fixed_term_contracts: "Fixed-term contracts",
  gross_per_month: "Gross Per Month (GPM)",
  gpm_ex: "GPM Ex: 20000",
  cost_to_company: "Cost To Company (CTC)",
  ctc_ex: "CTC Ex: 300000",
  other_terms_and_conditions: "Other Terms And Conditions",
  ex_add_new_terms_and_conditions: "Ex: add new Terms and Conditions",
  terms_and_condition_list: "Terms And Condition List",
  save_for_this_future_appointment_letter:
    "Save for this future Appointment letter",
  loi_candidate_demography: "LOI Candidate Demography",
  loi_expiry_date: "LOI Expiry Date",
  define_salary_breakup: "Define Salary Breakup",
  document_required: "Document Required",
  document_name: "Document Name",
  ex_relieving_certificate_from_the_previous_employer:
    "Ex: Relieving certificate from the previous employer",
  document_list: "Document List",
  save_for_this_future_loi: "Save for this future LOI",
  replace_with: "Replace With",
  item_name: "Item Name",
  category: "Category",
  type: "Type",
  hsncd: "HSNCD",
  uom: "UOM",
  manufacturer: "Manufacturer",
  expirable: "Expirable",
  status: "Status",
  verified: "Verified",
  replace: "Replace",
  amount: "Amount",
  view_budget: "View Budget",
  view_leave_rules_details: "View Leave Rules Details",
  search_employee: "Search Employee",
  present: "Present",
  absent: "Absent",
  weekend: "Weekend",
  holiday: "Holiday",
  leave: "Leave",
  download: "Download",
  employee_info: "Employee Info",
  payable_days: "Payable Days",
  no_attendance_data_found: "No Attendance Data Found",
  update_attendance: "Update Attendance",
  mark_as: "Mark As",
  comments: "Comments",
  search_budget: "Search Budget",
  no_budget_define_yet: "No Budget Define yet !!",
  click_add_budget_button_to_create_new_budget:
    "Click Add Budget Button to create new Budget",
  sl_no: "Sl. No.",
  budget_name: "Budget Name",
  budget_for: "Budget For",
  unit_name: "Unit Name",
  start_date: "Start Date",
  end_date: "End Date",
  budget_amount: "Budget Amount",
  consolidated: "Consolidated",
  unit: "Unit",
  N_A: "N/A",
  search_contract: "Search Contract",
  no_contract_added_yet: "No Contract added yet !!",
  click_add_contract_button_to_create_new_contract:
    "Click Add Contract Button to create new Contract",
  contract_name: "Contract Name",
  contract_type: "Contract Type",
  responsible_person: "Responsible Person",
  contract_owner_name: "Contract Owner Name",
  action: "Action",
  search_user: "Search User",
  no_external_user_define_yet: "No External User Define yet !!",
  click_add_external_user_button_to_create_new_external_user:
    "Click Add External User Button to create new External User",
  user_name: "User Name",
  type: "Type",
  email: "Email",
  phone: "Phone",
  address: "Address",
  fax: "FAX",
  gst: "GST",
  do_you_want_to_delete_this_external_user:
    "Do you want to delete this External user ?",
  search_holiday: "Search Holiday",
  no_holiday_define_yet: "No Holiday Define yet !!",
  click_add_holiday_button_to_create_new_holiday:
    "Click Add Holiday Button to create new Holiday",
  date: "Date",
  do_you_want_to_delete_this_holiday: "Do you want to delete this Holiday ?",
  items_list: "Items List",
  search_items: "Search Items",
  add_items: "Add Items",
  verify: "Verify",
  replace: "Replace",
  search_leave_rules: "Search Leave Rules",
  leave_balance: "Leave Balance",
  no_leave_rule_define_yet: "No Leave Rule Define yet !!",
  click_add_leave_rule_button_to_create_leave_rule:
    " Click Add Leave Rule Button to create Leave Rule",
  leave_type: "Leave Type",
  gender: "Gender",
  employee_status: "Employee Status",
  employment_type: "Employment Type",
  minimum_leaves: "Minimum Leaves",
  min_employment_days: "Min. Employment Days",
  min_notice_days: "Min. Notice Days",
  do_you_want_to_delete_this_leave_rule:
    "Do you want to delete this Leave Rule ?",
  employee_payroll: "Employee Payroll",
  search_employee: "Search Employee",
  no_employee_payroll_added_yet: "No Employee Payroll added yet !!",
  employee_name: "Employee Name",
  id: "Id",
  position: "Position",
  salary_type: "Salary Type",
  employement_status: "Employement Status",
  ctc: "CTC",
  action: "Action",
  salary_structure: "Salary Structure",
  pay_slip: "Pay Slip",
  employee_pay_slip: "Employee Pay Slip",
  name: "Name",
  salary_type: "Salary Type",
  employement_status: "Employement Status",
  current_ctc: "Current CTC",
  past_salary: "Past Salary",
  select_month: " Select Month",
  month: "Month",
  net_pay: "Net Pay",
  deduction: "Deduction",
  employee_salary_structure: "Employee Salary Structure",
  account_details: "Account Details",
  account_no: "Account No",
  confirm_account_no: "Confirm Account No",
  bank_name: "Bank Name",
  ifsc_code: "IFSC Code",
  beneficiary_name: "Beneficiary Name",
  salary_structure: "Salary Structure",
  give_a_reasons_for_edit: "Give a reasons for edit",
  effective_date: "Effective Date",
  select_to_add_new_head: "Select to add new head",
  annual_ctc: "Annual CTC",
  amount: "Amount",
  salary_head: "Salary head",
  monthly: "Monthly",
  annually: "Annually",
  configuration_head: "Configuration Head",
  total: "Total",
  salary_head_configuration: "Salary head configuration",
  employee: "Employee",
  search_user: "Search User",
  no_employee_define_yet: "No Employee Define yet !!",
  click_add_employee_button_to_create_new_employee:
    "Click Add Employee Button to create new Employee",
  employee_name: "Employee Name",
  joining_date: "Joining Date",
  status: "Status",
  salary_type: "Salary Type",
  function_department: "Function/Department",
  primary_position: "Primary Position",
  reporting_head: "Reporting Head",
  do_you_want_to_deactivate_this_employee:
    "Do you want to Deactivate this Employee ?",
  please_select_a_date_to_confirm_deactivation_of_this_employee:
    "Please select a date to confirm deactivation of this employee",
  routine_work: "Routine Work",
  search_activity: "Search Activity",
  activity_name: "Activity Name",
  date_time: "Date Time",
  attendance_report: "Attendance Report",
  username_gose_here: "username goes here",
  select_month: "Select Month",
  download: "Download",
  date: "Date",
  check_in: "Check In",
  check_out: "Check Out",
  hours: "Hours",
  status: "Status",
  weekend_present: " Weekend (Present)",
  weekend_absent: "Weekend (Absent)",
  sending_positive_vibes_for_the_weekend_to_you_enjoy_your_well_deserved_break:
    "Sending positive vibes for the weekend to you, Enjoy your well-deserved break.",
  weekend: "Weekend",
  happy_holidays: "Happy Holidays",
  leave: "Leave",
  leave_balance: "Leave Balance",
  search_leave_rules: "Search Leave Rules",
  download: "Download",
  upload: "Upload",
  employee_name: "Employee Name",
  employee_id: "Employee Id",
  privilege_leave: "Privilege Leave (PL)",
  casual_leave: "Casual Leave (CL)",
  sick_leave: "Sick Leave (SL)",
  maternity_leave: "Maternity Leave (ML)",
  Comp_off: "Comp-off",
  marriage_leave: "Marriage Leave",
  paternity_leave: "Paternity Leave",
  bereavement_leave: "Bereavement Leave",
  loss_of_pay: "Loss of Pay (LOP)",
  uploaded_file_data: "Uploaded File Data",
  edit_leave_balance: "Edit Leave Balance",
  leave_type: "Leave Type",
  leave_balance: "Leave Balance",
  please_enter_budget_name: "Please Enter Budget Name",
  please_enter_valid_amounts_for_budget_heads:
    "Please enter valid amounts for budget heads.",
  file_size_cannot_exceed_five_mb: "File size cannot exceed 5MB",
  please_enter_contract_name: "Please Enter Contract Name",
  please_enter_responsible_person: "Please Enter Responsible Person",
  please_enter_contract_type: "Please Enter Contract Type",
  please_enter_contract_owner: "Please Enter Contract Owner",
  please_select_user_type: "Please Select User Type",
  please_select_title: "Please Select Title",
  please_enter_first_name: "Please Enter First Name",
  please_enter_last_name: "Please Enter Last Name",
  please_enter_email_id: "Please Enter Email Id",
  please_check_the_email_id: "Please check the Email Id",
  please_select_country_code: "Please Select Country Code",
  please_enter_contact_number: "Please Enter Contact Number",
  please_enter_address: "Please Enter Address",
  please_enter_valid_phone_number_for_country_code:
    "Please enter valid phone number for Country Code",
  please_enter_holiday_name: "Please Enter Holiday Name",
  please_enter_item_type: "Please Enter Item Type",
  please_provide_item_category: "Please Provide Item Category",
  please_enter_category_name: "Please Enter Category Name",
  please_enter_item_uom: "Please Enter Item UOM",
  please_enter_item_manufacturer: "Please Enter Item Manufacturer",
  please_provide_item_name: "Please Provide Item Name",
  please_provide_item_description: "Please Provide Item Description",
  please_provide_category: "Please Provide Category",
  please_provide_item_type: "Please Provide Item Type",
  please_provide_uom: "Please Provide UOM",
  please_provide_item_manufacturer: "Please Provide Item Manufacturer",
  please_select_or_enter_a_leave_type: "Please select or enter a leave type",
  please_select_at_least_one_gender: "Please select at least one gender",
  please_select_at_least_one_employee_status:
    "Please select at least one employee status",
  please_select_at_least_one_employment_type:
    "Please select at least one employment type",
  maximum_encashment_allowed_during_fandf_cannot_be_more_than_three_digit:
    "Maximum Encashment Allowed during F&F Cannot be more than 3 Digit",
  maximum_number_of_days_allowed_for_encashment_cannot_more_the_three_digit:
    "Maximum Number Of Days Allowed For Encashment Cannot be More Than 3 Digit",
  minimum_leave_balance_needed_after_encashment_cannot_more_the_three_digit:
    "Minimum Leave balance needed after Encashment Cannot be More Than 3 Digit",
  please_enter_email: "Please Enter Email",
  please_enter_correct_email:
    "The Email You Have Entered is Invalid Email , Please Enter Correct Email",
  please_select_country_code: "Please Select Country Code",
  please_enter_phone_number: "Please Enter phone Number",
  please_enter_a_valid_phone_number: "Please enter a valid Phone Number",
  please_enter_salary_type: "Please enter salary type",
  please_fill_employment_id: "Please Enter Employment Id",
  please_select_unit: "Please Select Unit",
  please_select_position: "Please Select Position",
  employee_added_successfully: "Employee Added Successfully",
  please_enter_address_one: "Please Enter Address 1",
  please_enter_address_two: "Please Enter Address 2",
  please_enter_city: "Please Enter City",
  please_enter_state: "Please Enter State",
  please_enter_country: "Please Enter Country",
  please_enter_pin: "Please Enter Pin",
  please_enter_department: "Please Enter Department",
  please_enter_position: "Please Enter Position",
  please_enter_email: "Please Enter Email",
  please_enter_valid_emailid: "Please Enter Valid EmailId",
  please_enter_gross_per_month: "Please Enter Gross Per Month",
  please_enter_ctc: "Please Enter CTC",
  please_provide_salary_informations: "Please Provide Salary Informations",
  please_select_location: "Please Select Location",
  please_select_reporting_head: "Please Select Reporting Head",
  please_select_probation_time: "Please Select Probation Time",
  please_select_address_change_notice_period:
    "Please Select Address Change Notice Period",
  please_select_leave_notice_period: "Please Select Leave Notice Period",
  please_properly_fill_the_corresponding_address:
    "Please Properly fill the corresponding address",
  please_fill_corresponding_address_one:
    "Please fill the corresponding address1",
  please_fill_the_corresponding_city: "Please fill the corresponding city",
  please_fill_the_corresponding_country:
    "Please fill the corresponding country",
  please_fill_the_corresponding_state: "Please fill the corresponding state",
  please_provide_salary_informations: "Please Provide Salary Informations",
  please_provide_absent_days: "Please Provide Absent Days",
  please_provide_notice_period: "Please Provide Notice Period",
  please_enter_shift_name: "Please Enter Shift Name",
  shift_timing_added_successFully: "shift Timing Added SuccessFully",
  shift_timing_deleted_successFully: "shift Timing Deleted SuccessFully",
  shift_marked_as_default_successfully: "Shift marked as default successfully",
  all_updates_have_been_successfully_applied:
    "All updates have been successfully applied.",
  please_select_salary_head: "Please Select Salary Head",
  please_select_percent: "please Select Percent",
  please_select_gross_or_basic: "please Select Gross Or Basic",
  please_select_amount: "please Select Amount",
  please_select_a_status: "Please select a status",
  please_give_some_comments: "Please give some comments",
  attendance_data_is_empty_unable_to_export:
    "Attendance data is empty, unable to export",
  please_select_new_head: "Please Select New Head",
  salary_head_already_exists: "Salary head already exists",
  please_enter_account_number: "Please enter Account Number",
  account_number_and_confirm_account_number_should_be_the_same:
    "Account Number and Confirm Account Number should be the same",
  please_enter_the_bank_name: "Please enter the bank name",
  please_enter_the_ifsc_code: "Please enter the IFSC code",
  please_wait: "Please wait ....",
  employee_bank_details_updated_successfully:
    "Employee Bank Details Updated successfully",
  failed_to_update_employee_bank_details:
    "Failed to Update Employee Bank Details",
  an_error_occurred_while_updating_employee_bank_details:
    "An error occurred while updating Employee Bank Details",
  please_enter_the_reason_for_edit: "Please enter the Reason For Edit",

  /////////////////////////////////////////////////////////////////////
  select_health_care_profession: "Select Health Care Profession",
  select_menu: "--Select Menu--",
  doctor: "Doctor",
  nurse: "Nurse",
  lab_technician: "Lab Technician",
  activate: "Activate",
  health_care_profession: "Health Care Profession",
  deactivate_health_care_professional: "Deactivate Health Care Professional",
  qualification: "Qualification",
  enter_qualification: "Enter Qualification",
  specialization: "Specialization",
  practice_start_date: "Practice Start Date",
  year_of_experience: "Year Of Experience",
  enter_year_of_experience: "Enter Year Of Experience",
  languages: "Languages",
  currency: "Currency",
  fees: "Fees",
  enter_fees: "Enter Fees",
  select_doctor_available_units: "Select Doctor available units",
  doctor_available_in_units: "Doctor available in units",
  license_issuing_authority: "License Issuing Authority",
  enter_license_issuing_authority: "Enter License Issuing Authority",
  medical_license_number: "Medical License Number",
  enter_medical_license_number: "Enter Medical License Number",
  attach_file: "Attach File",
  file_added: "File Added",
  no_sign_avalable: "No sign avalable!!",
  click_here_to_upload_sign: "Click here to upload sign",
  signature: "Signature",
  re_upload_sign: " Re-Upload Sign",
  select_languages: "Select Languages",
  you_cannot_remove_your_unit: "You cannot remove your unit",
  invalid_file_format_allowed_formats_png_jpg_jpeg:
    "Invalid file format. Allowed formats: PNG, JPG, JPEG",
  file_size_must_be_between_ten_kb_and_two_hundred_kb:
    "File size must be between 10KB and 200KB",
  image_height_details: "Image height must be between 50px and 512px",
  an_error_occurred_during_file_upload_Please_try_again_later:
    "An error occurred during file upload. Please try again later",
  please_enter_qualification: "Please Enter Qualification",
  please_select_specialization: "please Select Specialization",
  please_select_languages: "Please Select Languages",
  you_cannot_add_more_then_three_languages:
    "You Can't add more then 3 Languages",
  please_select_currency: "Please Select Currency",
  please_enter_fees: "Please Enter Fees",
  please_enter_license_issuing_authority:
    "Please Enter License Issuing Authority",
  please_enter_medical_license_number: "Please Enter Medical License Number",
  please_upload_a_medical_license_attachment:
    "Please upload a medical license attachment",
  please_upload_doctor_signature: "Please upload Doctor Signature",
  you_cannot_select_more_than_three_languages:
    "You Cannot Select More Than 3 Languages",
  an_error_occurred_while_deactivating_the_account:
    "An error occurred while deactivating the account",
  an_error_occurred_while_activating_the_account:
    "An error occurred while activating the account",
  details_of_file_to_upload_in_sign:
    "Allowed File PNG, JPG, or JPEG format and size range of 2 KB to 200 KB. Image dimension, height 50 px to 512 px, with background either transparent or white",
  dr: "Dr.",
  Prof: "Prof.",
  employee_type: "Employee Type",

  ////////////////////////////////////patient in dashboard  //////////////
  appointment_settings: "Appointment Settings",
  select_time_zone: "Select Time Zone",
  booking_window_in_days: "Booking Window in Days",
  seven_days: "7 Days",
  fifteen_days: "15 Days",
  thirty_days: "30 Days",
  forty_five_days: "45 Days",
  sixty_days: "60 Days - (2 Month)",
  seventy_five_days: "75 Days",
  ninety_days: "90 Days - (3 Month)",
  one_hundred_eighty_days: "180 Days - (6 Month )",
  set_slots_for_book_appointment: "Set slots for book appointment",
  start_time: "Start Time",
  end_time: "End Time",
  slot_duration_in_minutes: "Slot Duration in minutes",
  select_week: "Select Week",
  unavailable: "Unavailable",
  book_doctor_appointment: "Book doctor appointment",
  search_by_dr: "Search by Dr",
  search_by_dr_speciality: "Search by Dr Speciality",
  no_booked_doctor_slots_found: "No Booked Doctor Slots Found",
  more_slots: "More Slots",
  select_doctor: "Select Doctor",
  slots_date_from: "Slots date from",
  available: "Available",
  slots: "slots",

  //////////////////////////////////////////

  ////////////////////////////////////patient queue////////////////////////
  start_consultation: "Start Consultation",
  complaints: "Complaints",
  refer_by: "Refer By",
  delegate: "Delegate",
  concern: "Concern",
  link_task: "Link Task",
  meeting: "Meeting",
  user: "User",
  type: "Type",
  vitals: "Vitals",
  patient_audio_message: "Patient Audio Message",
  past_diseases: "Past Diseases",
  date: "Date",
  status: "Status",
  investigation: "Investigation",
  add_parameter: "Add Parameter",
  parameter_name: "Parameter Name",
  test_name: "Test Name",
  value: "Value",
  bio_ref_interval: "Bio. Ref Interval",
  audio_message: "Audio Message",
  files: "Files",

  insurance: "Insurance",
  bmi: "BMI",
  patient_complaints: "Patient complaints",
  past_medication_file_pdf: "Past medication file.pdf",
  do_you_want_to_continue: "Do you want to continue?",
  do_you_want_to_continue_with_given_parameters:
    "Do you want to continue with given parameters",
  edit: "Edit",
  symptoms: "Symptoms",
  symptom_name: "Symptom Name",
  severity: "Severity",
  since: "Since",
  examination_and_sign: "Examination and Sign",
  note: "Note",
  enter_sign: "Enter Sign",
  notes: "Notes",
  enter_notes: "Enter Notes",
  comorbidity: "Comorbidity",
  enter_diseases: "Enter Diseases",
  history_risk_factor: " History & Risk Factor",
  search_history_and_risk_factor: "Search History and risk factor",
  past_illness: "Past illness",
  reported_date: "Reported Date",
  add_past_illness: "Add Past illness",
  select_date: "Select Date",
  previous_procedure: "Previous procedure",
  add_previous_procedure: "Add Previous procedure",
  surgery_date: "Surgery Date",
  patient_is_allergic_to_the_following_medication:
    "Patient is allergic to the following medication",
  generic: "Generic",
  brand: "Brand",
  search_medicine_brand_generic: "Search medicine Brand / Generic",
  search_by_brand: "Search by Brand",
  food_allergy: "Food Allergy",
  relevant_family_history: "Relevant Family History",
  relationship: "Relationship",
  family_disease_history: "Family Disease History",
  possible_conditions: "Possible Conditions",
  these_features_are_experimental_it_would_be_best_if_you_reviewed_and_recommended_any_necessary_changes_the_data_has_been_generated_using_ai:
    "This is an experimental AI feature. It would be best if you reviewed and recommended any necessary changes.",
  run_differential_diagnosis: "Run Differential Diagnosis",
  protocol: "Protocol",
  search_diseases_name: "Search Diseases Name",
  possible_investigations: "Possible Investigations",
  go_for_test: "Go for Test",
  search_investigations: "Search Investigations",
  test_result: "Test Result",
  diagnosis: "Diagnosis",
  provisional: "Provisional",
  confirm: "Confirm",
  prognosis: "Prognosis",
  disease_name: "Disease Name",
  medication: "Medication",
  generic_name: "Generic Name",
  dosage: "Dosage",
  rout: "Route",
  form: "Form",
  frequency: "Frequency",
  duration: "Duration",
  timing: "Timing",
  time: "Time",
  drug_safety_check: "DRUG SAFETY CHECK",
  edit_medication: "Edit Medication",
  recommendations: "Recommendations",
  diet_recommendations: "Diet Recommendations",
  diet_name: "Diet Name",
  lifestyle_recommendations: "Lifestyle Recommendations",
  life_style_name: "Life Style Name",
  others_options: "Others Options",
  parameter_to_be_monitored: "Parameter to be Monitored",
  select_parameters: "Select Parameters",
  doctor_note: "Doctor Note",
  procedure_surgery: "Procedure/Surgery",
  add_procedure: "Add procedure",
  refer: "Refer",
  refer_type: "Refer Type",
  select_refer_type: "Select Refer Type",
  advise_higher_facility: "Advise higher facility",
  advise_immediate_hospitalization: "Advise immediate hospitalization",
  refer_to_other_speciality: "Refer to other speciality",
  refer_to_medico_legal: "Refer to medico legal ",
  enter_remarks_here: "Enter Remarks here",
  treatment_plan: "Treatment Plan",
  select_speciality: "Select Speciality",
  select_hospital: "Select Hospital",
  acute_care: "Acute Care",
  anaesthesia: "Anaesthesia",
  general_surgeon: "General Surgeon",
  cvts: "CVTS",
  cardiologist: "Cardiologist",
  cardiologist_cvs_cardiac_surgeon: "Cardiologist/ Cvs/ Cardiac Surgeon",
  chief_cardio_vascular_thoracic_surgeon:
    "Chief Cardio Vascular Thoracic Surgeon",
  clinical_cardiologist: "Clinical Cardiologist",
  clinical_psychologist: "Clinical Psychologist",
  dermatologist_hair_transplant_surgeon:
    "Dermatologist & Hair transplant Surgeon",
  doctor_name_optional: "Doctor Name (Optional)",
  enter_doctor_name: "Enter Doctor Name",
  review: "Review",
  next_review: "Next Review",
  review_date: "Review Date",
  prescription: "Prescription",
  symptoms_cannot_be_empty: "Symptoms cannot be empty",
  please_select_doctor_speciality: "Please Select Doctor Speciality",
  both_sistolic_and_diastolic_value_must_be_present:
    "Both sistolic and diastolic value must be present",
  sistolic_pressure_cannot_be_empty: "Sistolic pressure cannot be empty",
  diastolic_pressure_cannot_be_empty: "diastolic pressure cannot be empty",
  sistolic_and_diastolic_pressure_difference_must_be_greater_than_thirty:
    "Sistolic and diastolic pressure difference must be greater than 30",
  temparature_must_be_within_thirty_two_and_forty_one:
    "Temparature must be within 32.22 to 41.10",
  temparature_must_be_within_ninety_to_one_hundred_six:
    "Temparature must be within 90 to 106",
  please_provide_disease_name: "Please Provide Disease Name",
  this_brand_generic_name_is_not_present_in_the_table:
    "This Brand's Generic name is not present in the table",
  please_provide_drug_name: "Please Provide Drug Name",
  please_provide_family_disease_name: "Please Provide Family Disease Name",
  please_select_relationship: "Please Select Relationship",
  please_provide_food_name: "Please Provide Food Name",
  please_provide_risk_factor: "Please Provide Risk Factor",
  please_provide_illness_name: "Please Provide Illness Name",
  please_provide_procedure_name: "Please Provide Procedure Name",
  please_provide_investigation_name: "Please Provide Investigation Name",
  please_select_medicine_name: "Please Select Medicine Name",
  please_select_dosage: "Please Select Dosage",
  please_select_frequency: "Please Select Frequency",
  please_select_duration: "Please Select Duration",
  could_not_found_diet_list_fetching_it:
    "Could not found diet list. Fetching it.",
  diet_recommendation_cannot_be_empty: "Diet Recommendation Cannot be empty",
  please_provide_lifestyle: "Please Provide Lifestyle",
  please_provide_sign_name: "Please Provide Sign Name ",
  please_provide_user_note: "Please Provide User Note",
  please_select_symptom: "Please select symptom",
  please_select_severity: "Please Select severity",

  technician: "Technician",
  contact: "Contact",
  consultation_with: "Consultation With",
  prescription_type: "Prescription Type",
  confirm_prescription: "Confirm prescription",
  provisional_prescription: "Provisional prescription",
  for: "For",
  risk_factor: "Risk Factor",
  possible_diagnosis: "Possible Diagnosis",
  investigations: "Investigations",
  diagnosis_condition: "Diagnosis/Condition",
  procedure: "Procedure",
  advice: "Advice",
  quantity_dose: "Quantity/Dose",
  frequency_timing: "Frequency/Timing",
  duration: "Duration",
  refered_by: "Refered by",
  patient_info: "Patient info",

  ///////////////////////////////////////Chat//////////////////////////////////

  select_user_to_add_group: "Select user to add Group (Minimum {{minUsers}})",
  search_name_to_add_contact: "Search name to add contact",
  add_user: "Add User",
  group_info: "Group Info",
  change_group_icon: "Change Group Icon",
  group_name: "Group Name",
  group_members: "Group members",
  you: "You",
  remove: "Remove",
  make_group_admin: "Make Group Admin",
  dismiss_as_admin: "Dismiss As Admin",
  make_admin: "Make admin",
  profile_photo: "Profile Photo",
  add_group_icon: "Add Group Icon",
  forward_chat: "Forward Chat",
  search_or_find: "Search or find",
  create_group: "Create Group",
  filtered_by_unread: "Filtered by Unread",
  no_unread_chat_available: "No unread chat available",
  clear_filter: "Clear filter",
  contacts: "contacts",
  forwarded: "Forwarded",
  unread: "Unread",
  reply: "Reply",
  forward: "Forward",
  copy: "Copy",
  delete: "Delete",
  download: "Download",
  insert_emoji: "Insert Emoji",
  allowed_file_types: "Allowed File Types",
  maximum_file_size: "Maximum file size {{maxFileSize}} MB",
  type_your_message_here: "Type your message here!",
  click_to_stop: "Click to stop",
  click_to_start: "Click to start",
  selected_message_count: "{{count}} Selected",
  no_preview_available: " No preview available",
  type_your_message_here: "Type your message here",
  drop_files_here: "Drop Files Here",
  new_chat: "New Chat",
  search_name_or_email: "Search name or email",
  create_new_group: "Create New Group",
  contacts_on: "Contacts on",
  something_went_wrong_please_try_again:
    "Something went wrong. Please try again !!",
  make_admin_successfully: "Make admin successfully",
  admin_successfully_updated: "Admin successfully updated",
  could_not_dismiss_admin_user: "Could not dismiss admin user",
  user_already_selected: "{{userName}} is already selected",
  file_size_error: "Please upload a file less than {{maxFileSize}}MB",
  message_forwarded_successfully: "Message forwarded successfully",
  please_select_user: "Please select user",
  you_have_been_removed_from_this_group:
    "You have been removed from this group",
  maximum_ten_files_can_be_uploaded_at_a_time:
    "Maximum 10 files can be uploaded at a time",
  invalid_file_type:
    "Invalid file type. Valid file types are {{validFileTypes}}",
  please_type_message: "Please type message",
  no_voice_recorded: "No voice recorded",
  group_created_successfully: "Group Created Successfully",
  failed_to_create_group: "Failed to create group",

  //////////////////////// Admin //////////////

  add_salary_head_group: "Add Salary Head / Group",
  select_orders: "Select Orders",
  select_salary_group: "Select Salary Group",
  calculation_basis: "Calculation basis",
  enter_percentage: "Enter Percentage (%)",
  add_access_permission: "Add Access Permission",
  select_user: "Select User",
  select_all: "Select All",
  enable: "Enable",
  disable: "Disable",
  add_activity_template: "Add Activity Template",
  upload_files: "Upload Files",
  file_restrictions:
    "Maximum File Size Allowed: {{maxFileSize}}MB, Extensions Allowed: {{validFileTypes}}",
  drag_and_drop_files_here: "Drag and drop files here",
  select_files: "Select files",
  template_added: "Template Added",
  edit_committee: "Edit Committee",
  add_committee: "Add Committee",
  committee_name: "Committee Name",
  add_roles: "Add Roles",
  roles_added: "Roles Added",
  generate_discount_coupon: "Generate Discount Coupon",
  purpose: "Purpose",
  discount_precentage: "Discount Precentage",
  discount_percent: "discount (1-100 %)",
  no_of_use: "No of Use",
  no_of_use_times: "No of Use(1-1000)",
  expires_on: "Expires On",
  receiver_name: "Receiver Name",
  receiver_email_id: "Receiver Email Id",
  custom_code: "Custom Code",
  edit_department: "Edit Department",
  add_department: "Add Department",
  department_name: "Department Name",
  department_description: "Department Description",
  edit_designation_hierarchy: "Edit Designation / Hierarchy",
  add_designation_hierarchy: "Add Designation / Hierarchy",
  hierarchy_name: "Hierarchy Name",
  designation_name: "Designation Name",
  band_label: "Band / Label",
  edit_expenditure_power: "Edit Expenditure Power",
  add_expenditure_power: "Add Expenditure Power",
  expense_type: "Expense Type",
  min_amount: "Min Amount",
  max_amount: "Max Amount",
  edit_critical_rights: "Edit Critical Rights",
  add_critical_rights: "Add Critical Rights",
  select_rights: "Select Rights",
  add_policy: "Add Policy",
  enter_policy_name: "Enter Policy Name",
  is_needed_while_onboarding: "Is needed while onboarding",
  upload_file: "Upload File",
  policies_added: "Policies Added",
  add_salary_component: "Add salary component",
  component_name: "Component Name",
  component_category: "Component Category",
  add_new_component_category: "Add New Component Category",
  periodicity: "Periodicity",
  calculation: "Calculation",
  flat_amount: "Flat Amount",
  percentage_of_ctc: "Percentage of CTC",
  percentage_of_basic: "Percentage of Basic",
  enter_amount: "Enter Amount",
  configuration: "Configuration",
  update_component_category: "Update Component Category",
  add_component_category: "Add Component Category",
  enter_category_name: "Enter Category Name",
  categories: "Categories",
  no_categories_found: "No categories found",
  add_organization_storage: "Add Organization Storage",
  current: "Current",
  storage: "Storage",
  upgraded: "Upgraded",
  to: "To",
  drive: "Drive",
  add_more_storage: "Add More Storage",
  item_name: "Item Name",
  unit_price: "Unit Price ($)",
  amount: "Amount",
  new_storage_added: "New Storage added",
  total: "Total",
  upgrade_now: "Upgrade Now",
  add_statutory_and_insurance: "Add Statutory and Insurance",
  select_name: "Select Name",
  select_category: "Select Category",
  due_renewal_date_frequency: "Due / Renewal Date Frequency",
  quarterly: "Quarterly",
  yearly: "Yearly",
  no_specific_date: "No Specific Date",
  execute_on_last_date_of_month: "Execute On Last Date Of Month",
  first_quarter: "1st Quarter",
  second_quarter: "2nd Quarter",
  third_quarter: "3rd Quarter",
  fourth_quarter: "4th Quarter",
  notify_to_positions: "Notify to Positions",
  notify_lead_time: "Notify Lead Time",
  days: "Days",
  upload_document_if_any: "Upload Document if any",
  add_terms_and_conditions: "Add Terms and Conditions",
  t_and_c_for: "T&C FOR",
  t_and_c_type: "T&C TYPE",
  t_and_c_heading: "T&C Heading",
  terms_and_condition: "Terms And Condition",
  serial_number: "Serial Number",
  add_units: "Add Units",
  unit_name: "Unit Name",
  gps_location: "GPS Location",
  latitude: "Latitude",
  longitude: "Longitude",
  fetch_gps_longitude_and_latitude: "Fetch GPS Longitude & Latitude",
  unit_location: "Unit Location",
  house_no_building_name: "House No, Building Name",
  area_colony: "Area, Colony",
  land_mark: "Land mark",
  city: "City",
  select_country: "Select Country",
  select_state: "Select State",
  pin: "PIN",
  cost_center: "Cost Center",
  profit_center: "Profit Center",
  maintains_own_balance_Sheet: "Maintains Own balance Sheet",
  statutory_compliances: "Statutory compliances",
  local_tax_applicable: "Local tax applicable",
  default_currency: "Default Currency",
  select_parent_unit: " Select Parent Unit(If Any)",
  contact_details_optional: "Contact Details (Optional)",
  phone_no: "Phone No",
  access_permission: "Access Permission",
  no_access_permission_added_yet: "No Access Permission added yet !!",
  click_add_access_permission_button_to_create_new_access_permission:
    "Click Add Access Permission Button to create new Access Permission",
  no_activity_added_yet: "No Activity added yet !!",
  click_add_button_to_create_new_activity:
    "Click on Add Button to create New Activity",
  activity_added: "Activity Added",
  activity_template: "Activity Template",
  template: "Template",
  no_template_define_yet: "No Template Define yet !!",
  click_add_template_button_to_create_new_template:
    "Click Add Template Button to create New Template",
  template_list: "Template List",
  do_you_want_to_delete_this_activity: "Do you want to delete this Activity",
  search_committee: "Search Committee",
  committee: "Committee",
  no_committee_added_yet: "No Committee added yet !!",
  click_add_committee_button_to_create_new_committee:
    "Click Add Committee Button to create new Committee",
  committee_roles: "Committee Roles",
  do_you_want_to_delete_this_committee: "Do you want to delete this Committee",
  search_coupon: "Search Coupon",
  add_coupon: "Add Coupon",
  no_coupon_added_yet: "No Coupon added yet !!",
  click_add_coupon_button_to_create_new_coupon:
    "Click Add Coupon Button to create new Coupon",
  discount: "Discount",
  exp_date: "Exp. Date",
  name: "Name",
  assigned_to: "Assigned to",
  code: "Code",
  status: "Status",
  time: "Time",
  active: "Active",
  deactive: "Deactive",
  critical_rights: "Critical Rights",
  rights: "Rights",
  position: "Position",
  notify_to: "Notify To",
  add_remove: "Add/Remove",
  save_critical_right: "Save Critical Right",
  delete_right: "Delete Right",
  do_you_want_to_delete: "Do You Want to delete ?",
  search_department: "Search Department",
  add_departmment: "Add Departmment",
  no_department_added_yet: "No Department added yet !!",
  click_add_department_button_to_create_new_department:
    "Click Add Department Button to create new Department",
  action: "Action",
  do_you_want_to_delete_this_department:
    " Do you want to delete this Department",
  capital_expenditure: "Capital Expenditure",
  enter_expenditure_items: "Enter expenditure Items",
  no_capital_expenditure_added_yet: "No Capital Expenditure added yet !!",
  select_and_add_capital_expenditure: " Select & Add Capital Expenditure",
  ce_items_added: "CE Items Added",
  operating_expenditure: "Operating Expenditure",
  no_operating_expenditure_added_yet: "No Operating Expenditure added yet !!",
  select_and_add_operating_expenditure: "Select & Add Operating Expenditure",
  oe_items_added: "OE Items Added",
  organization_details: "Organization Details",
  no_organization_details_not_define_yet:
    "No Organization details not define yet !!",
  registered_office_address: "Registered Office Address",
  corresponding_office_address: "Corresponding Office Address",
  organization_email_id: "Organization Email Id",
  organization_contact_no: "Organization Contact No.",
  organization_type: "Organization Type",
  date_of_incorporation: "Date Of Incorporation",
  authorized_person: "Authorized Person",
  authorized_person_contact_number: "Authorized Person Contact Number",
  trade_license: "Trade License",
  company_identification_number: "Company Identification Number",
  tax_number: "Tax Number",
  time_zone: "Time Zone",
  unit_name: "Unit Name",
  statutory_compliances: "Statutory compliances",
  local_tax: " Local Tax",
  pan: "Pan",
  tan: "Tan",
  epf_no: "Epf No",
  esi_no: "Esi No",
  gst_no: "Gst No",
  edit_organization: "Edit Organization",
  otp_service_name: "OTP Service Name",
  api_name: "API Name",
  search_service: "Search Service",
  performance_management_system: "Performance Management System (PMS)",
  pms_cycle_from: "PMS Cycle From",
  select_max_rating: "Select max rating",
  evaluation_metrics: "Evaluation Metrics",
  new_metrics: "New Metrics",
  metric_name: "Metric Name",
  rating: "Rating",
  feedback: "Feedback",
  both: "Both",
  search_policy: "Search Policy",
  policy: "Policy",
  no_policy_added_yet: "No Policy added yet !!",
  click_add_policy_button_to_create_new_policy:
    "Click Add Policy Button to create new Policy",
  policy_name: "Policy Name",
  onboard_checklist: "Onboard Checklist",
  do_you_want_to_delete_this_policy: "Do you want to delete this Policy",
  search_component: "Search Component",
  component: "Component",
  no_salary_component_added_yet: "No Salary Component added yet !!",
  click_add_component_button_to_create_new_salary_component:
    "Click Add Component Button to create new Salary Component",
  category: "Category",
  do_you_want_to_activate_this_salary_component:
    "Do you want to activate this salary component",
  do_you_want_to_deactivate_this_salary_component:
    "Do you want to deactivate this salary component",
  add_designation: "Add Designation",
  edit_designation: "Edit Designation",
  add_ctc: "Add CTC",
  min_wage: "Min Wage",
  max_wage: "Max Wage",
  view_edit_departments: "View / Edit Departments",
  no_departments_available: "No departments available",
  organization_position: "Organization position",
  select_unit: "Select Unit",
  designation: "Designation",
  add_new_designation: "Add New Designation",
  department: "Department",
  view_edit_department: "View / Edit Department",
  reporting_to: "Reporting to",
  annual_ctc_band: "Annual CTC Band",
  add_new_ctc_band: "Add New CTC Band",
  jd: "JD",
  kra: "KRA",
  no_unit_is_defined_please_add_a_new_unit:
    " No unit is defined. Please add a new unit",
  no_position_defined_please_add_a_new_position:
    "No Position defined. Please add a new Position",
  position: "Position",
  or: "OR",
  select_unit_from_where_to_copy: "Select Unit from where to copy",
  select_unit: "Select Unit",
  copy_positions: "Copy Positions",
  select_department: "Select Department",
  select_reporting_head: "Select Reporting Head",
  none: "None",
  search_user: "Search User",
  organization_storage:
    "Organization Storage - {{totalUsedStorage}} GB out of {{totalStorage}} GB is used",
  storage: "Storage",
  no_storage_define_yet: "No Storage Define yet !!",
  click_add_storage_button_to_create_new_storage:
    "Click Add Storage Button to create new Storage",
  user_name: "User Name",
  storage: "Storage",
  used: "Used",
  modify: "Modify",
  search: "Search",
  name: "Name",
  category: "Category",
  due_date_renewal_date: "Due Date / Renewal Date",
  last_payment_date: "Last Payment date",
  last_payment_amount: "Last Payment Amount",
  notify_to_positions: "Notify to Positions",
  lead_time: "Lead Time",
  terms_and_conditions: "Terms & Conditions",
  no_terms_and_condition_define_yet: "No Terms & Condition Define yet !!",
  click_add_terms_and_condition_button_to_create_new_terms_and_condition:
    "Click Add Terms & Condition Button to create new Terms & Condition",
  t_and_c_heading: "T&C Heading",
  t_and_c_for: "T&C FOR",
  t_and_c_type: "T&C TYPE",
  do_you_want_to_delete_this_terms_and_conditions:
    "Do you want to delete this Terms & Conditions",
  unit_list: "Unit List",
  unit_tree: "Unit Tree",
  add_units: "Add Units",
  no_units_define_yet: "No Units Define yet !!",
  click_add_units_button_to_create_new_units:
    "Click Add Units Button to create new Units",
  cost_center: "Cost Center",
  profit_center: "Profit Center",
  balance_sheet_maintain_by_own: "Balance sheet maintain by own",
  statutory_compliances: "Statutory compliances",
  local_tax_applicable: "Local tax applicable",
  default_currency: "Default Currency",
  delete_unit: "Delete Unit",
  edit_unit: "Edit Unit",
  do_you_want_to_delete_this_unit: "Do you want to delete this Unit",
  job_description: "Job Description",
  job_summary: "Job Summary",
  key_responsibilities: "Key Responsibilities",
  enter_title: "Enter Title",
  enter_description: "Enter Description",
  add_new_description: "Add New Description",
  qualifications: "Qualifications",
  education: "Education",
  add_new_education: "Add New Education",
  delete_education: "Delete Education",
  experience: "Experience",
  add_new_experience: "Add New Experience",
  delete_experience: "Delete Experience",
  certificates: "Certificates",
  add_new_certificate: "Add New Certificate",
  skills_and_attributes: "Skills and Attributes",
  enter_title_skill_or_attribute: "Enter Title(Skills or Attribute)",
  enter_skills_or_attributes: "Enter Skills or Attributes",
  add_new_skills_or_attributes: "Add New Skills or Attributes",
  title_conflict_title_already_exist_consider_changing:
    "Title Conflict! Title Already Exist Consider Changing",
  please_add_jd_data: "Please Add JD Data",
  job_kra: "Job KRA",
  kpi: "KPI",
  enter_kpi: "Enter KPI",
  add_new_targets: "Add New Targets",
  targets: "Targets",
  enter_targets: "Enter Targets",
  add_kra_data: "Add KRA Data",
  units_tree: "Unit Tree",
  edit_access_permission: "Edit Access Permission",
  menus: "Menus",
  edit_organization: "Edit Organization",
  change_logo: "Change Logo",
  organization_name: "Organization Name",
  common_name: "Common Name",
  registered_address: "Registered Address",
  country: "Country",
  corresponding_address: "Corresponding Address",
  organization_info: "Organization Info",
  organization_email_id: "Organization Email Id",
  organization_type: "Organization Type",
  none: "None",
  public_limited_company: "Public Limited Company",
  private_limited_company: "Private Limited Company",
  joint_venture_company: "Joint-Venture Company",
  partnership_firm: "Partnership Firm",
  one_person_company: "One Person Company",
  sole_proprietorship: "Sole Proprietorship",
  branch_office: "Branch Office",
  non_government_organization: "Non-Government Organization (NGO)",
  date_of_incorporation: "Date Of Incorporation",
  authorized_person: "Authorized Person",
  authorized_person_contact_no: "Authorized Person Contact No",
  trade_license_no: "Trade License No",
  company_identification_no: "Company Identification No",
  tax_no: "Tax No",
  facebook_link: "Facebook Link",
  instagram_link: "Instagram Link",
  linkedin_link: "Linkedin Link",
  twitter_link: "Twitter Link",
  unit_info: "Unit Info",
  balance_sheet_maintain_by_own: "Balance sheet maintain by own",
  statutory_compliances: "Statutory compliances",
  local_tax_applicable: "Local tax applicable",
  default_currency: "Default Currency",
  unit_name: "Unit Name",
  pan_id: "PAN Id",
  tan_id: "TAN Id",
  epf_no: "EPF No",
  esi_no: "ESI No",
  gst_no: "GST No",
  edit_salary_component: "Edit salary component",
  edit_terms_and_conditions: "Edit Terms and Conditions",
  edit_units: "Edit Units",
  view_activity_template: "View Activity Template",
  failed_to_fetch_mapped_menus: "Failed to fetch mapped menus",
  please_select_user: "Please Select User",
  please_type_to_search: "Please type to search",
  please_enter_salary_head_name: "Please Enter Salary Head Name",
  salary_head_name: "Salary Head Name",
  file_size_warning: "'{{fileName}}' needs to be less than {{maxFileSize}}MB",
  invalid_file_type_warning: "Invalid File Type of '{{fileName}}'",
  please_select_work: "Please Select Work",
  Please_upload_at_least_one_file: "Please upload at least one file",
  please_enter_a_role: "Please Enter A Role ",
  please_enter_committee_name: "Please Enter Committee Name",
  please_add_at_least_one_role: "Please add at least one role",
  please_enter_purpose: "Please Enter Purpose",
  please_enter_discount_precentage: "Please Enter Discount Precentage",
  discount_precentage_cannot_be_less_then_one:
    "Discount Precentage Cannot Be Less Then 1",
  discount_precentage_cannot_be_more_then_hundred:
    "Discount Precentage Cannot Be  More Then 100 ",
  please_enter_no_of_use: "Please Enter No of Use",
  useable_time_cannot_be_less_then_one: "Useable Time Cannot Be Less Then 1",
  please_enter_receiver_name: "Please Enter Receiver Name ",
  please_enter_receiver_email_id: "Please Enter Receiver Email Id",
  please_enter_valid_receiver_email_id: "Please enter valid Receiver Email Id",
  please_enter_custom_code: "Please Enter Custom Code",
  custom_code_cannot_be_less_then_five_character:
    "Custom Code Cannot Be Less Then 5 Character",
  custom_code_cannot_be_more_then_ten_character:
    "Custom Code Cannot Be More Then 10 Character",
  please_enter_department_name: "Please enter Department  Name",
  please_enter_department_description: "Please Enter Department Description",
  please_enter_hierarchy_name: "Please Enter Hierarchy Name",
  please_enter_designation_name: "Please Enter Designation  Name",
  please_select_expense_type: "Please Select Expense Type",
  please_select_a_position: "Please Select a Position",
  please_give_minimum_amount: "Please Give Minimum Amount",
  please_give_maximum_amount: "Please Give Maximum Amount",
  maximum_amount_should_be_greater_than_minimum_amount:
    "Maximum Amount should be greater than Minimum Amount",
  please_select_rights: "Please Select Rights",
  please_enter_policy_name: "Please Enter policy Name",
  please_upload_a_pdf_file: "Please Upload a Pdf file",
  please_provide_only_pdf_file: "Please Provide only pdf file",
  please_enter_component_name: "Please Enter Component Name",
  salary_component_name_is_already_present:
    "Salary Component Name is already present",
  please_enter_percentage: "Please Enter Percentage",
  please_enter_a_valid_percentage: "Please Enter a Valid Percentage (0-100)",
  please_enter_category_name: "Please Enter Category Name",
  the_category_name_already_exists: "The category name already exists",
  an_error_occurred_while_adding_the_category:
    "An error occurred while adding the category",
  category_updated_successfully: "Category Updated successfully",
  failed_to_update_category: "Failed to Update category",
  an_error_occurred_while_updating_the_category:
    "An error occurred while updating the category",
  category_deleted_successfully: "Category Deleted successfully",
  failed_to_delete_category: "Failed to Delete Category",
  an_error_occurred_while_deleting_the_category:
    "An error occurred while deleting the category",
  please_provide_name: "Please Provide Name",
  please_select_category: "Please Select Category",
  please_select_monthly_period: "Please Select Monthly Period",
  please_select_quarterly_period: "Please Select Quarterly Period",
  please_select_notify_to_position: "Please Select Notify To Position",
  please_select_lead_notify_lead_time: "Please Select Lead Notify Lead Time",
  please_select_t_and_c_for: "Please Select T&C FOR",
  please_select_t_and_c_type: "Please Select T&C TYPE",
  please_enter_terms_and_condition: "Please Enter Terms And Condition",
  terms_and_conditions_created_successfully:
    "Terms and Conditions Created Successfully",
  please_enter_unit_name: "Please Enter Unit Name",
  please_enter_or_fetch_current_latitude:
    "Please Enter Or Fetch current latitude",
  please_enter_or_fetch_current_longitude:
    "Please Fill Or Fetch current longitude",
  please_enter_house_no_building_name: "Please Enter House No, Building Name",
  please_enter_area_colony: "Please Enter Area, Colony",
  please_enter_city: "Please Enter City",
  please_enter_pin: "Please Fill PIN",
  please_enter_valid_email: "Please Enter Valid Email",
  please_select_country_code: "Please Select Country Code",
  please_enter_phone_number: "Please Enter Phone Number",
  please_enter_a_valid_phone_number: "Please enter a valid Phone Number",
  please_enter_subactivity_work_name: "Please Enter Activity Name",
  minimum_amount_cannot_be_greater_than_maximum_amount:
    "Minimum amount cannot be greater than maximum amount",
  please_select_primary_position: "Please Select Primary Position",
  please_save_modification_before_you_proceed:
    "Please save modification before you proceed",
  new_rights: "New Rights",
  please_enter_capital_expenditure_item:
    "Please Enter Capital Expenditure Item",
  duplicate_capital_expenditure_item_please_add_a_new_item:
    "Duplicate Capital Expenditure Item. Please Add a New Item",
  capital_expenditure_added_successfully:
    "Capital Expenditure Added Successfully",
  please_enter_operating_expenditure_item:
    "Please Enter Operating Expenditure Item",
  duplicate_operating_expenditure_item_please_add_a_new_item:
    "Duplicate Operating Expenditure Item. Please Add a New Item",
  operating_expenditure_added_successfully:
    "Operating Expenditure Added Successfully",
  balance_sheet_maintain_by_own: "Balance sheet maintain by own",
  an_error_occurred_while_updating_the_otp_service_status:
    "An error occurred while updating the OTP service status",
  please_select_max_rating: "Please select max rating",
  please_select_option: "Please select any option for {{metrics}}",
  data_updated_successfully: "Data updated successfully",
  to: "To",
  salary_component_activated_successfully:
    "Salary Component Activated successfully",
  failed_to_activated_salary_component: "Failed to Activated Salary Component",
  an_error_occurred_while_activated_salary_component:
    "An error occurred while Activated Salary Component",
  salary_component_deactivated_successfully:
    "Salary Component Deactivated successfully",
  failed_to_deactivated_salary_component:
    "Failed to Deactivated Salary Component",
  an_error_occurred_while_deactivated_salary_component:
    "An error occurred while Deactivated Salary Component",
  n_a: "N/A",
  designation_edit_unsuccessful: "Designation Edit Unsuccessful",
  designation_edited_successfully: "Designation Edited Successfully",
  please_enter_department_name: "Please Enter department name",
  department_created_successfully: "Department created successfully",
  salary_cannot_be_negative: "Salary cannot be negative",
  minimum_salary_cannot_be_more_than_maximum_salary:
    "Minimum salary cannot be more than maximum salary",
  please_select_current_salary: "Please select current salary",
  could_not_fetch_data_something_went_wrong:
    "Could not fetch data. Something went wrong",
  please_select_unit_from_where_position_is_To_be_copied:
    "Please Select Unit From Where Position is To be Copied",
  designation_copy_unsuccessful: "Designation copy unsuccessful",
  designation_copied_successFully: "Designation Copied SuccessFully",
  please_add_designation: "Please Add Designation",
  please_select_department: "Please select Department",
  please_define_designation_name: "Please define designation Name",
  please_select_department_name: "Please select department Name",
  data_submitted_successfully: "Data submitted successfully",
  please_save_current_position: "Please Save Current Position",
  item_already_present: "Item already present",
  please_provide_description: "Please Provide Description",
  please_enter_target:
    "Please enter target for {{label}} {{title}} at index {{index}}",
  key_already_present: "key already present",
  add_new_title: "Add New Title",
  please_enter_title_index:
    "Please enter Title in 'Key Responsibility' at index {{index}}",
  please_enter_desscription_index:
    "Plese Enter Description  at index {{index}}",
  please_enter_the_descriptions: "Please Enter The Descriptions",
  please_provide_proper_skills_and_attribute:
    "Please Provide Proper Skills and Attribute",
  please_enter_job_summary: "Please Enter Job Summary",
  title_is_needed_in_key_responsibility:
    "Title is needed in 'Key Responsibility'",
  title_already_present: "Title Already present",
  enter_summary: "Enter Summary",
  delete_certificate: "Delete Certificate",
  please_enter_target_value: "Please Enter target",
  please_provide_title: "Please provide title",
  please_enter_title_at_index: "Please enter title at index {{index}}",
  please_provide_kpi_for: "Please provide KPI for {{title}}",
  please_select_user: "Please Select User",
  orglogo: "orgLogo",
  minimum_twenty_five_gb_is_needed_for_storage_upgrade:
    "Minimum 25 GB is needed for storage upgrade",
  storage_upgraded_successfully: "{{userName}} Storage upgraded successfully!",
  terms_and_conditions_updated_successfully:
    "Terms and Conditions Updated Successfully",
  event_updated_successfully: "Event Updated Successfully",

  organization: "Organization",
  position: "Position",
  salary_component: "Salary Component",
  committee: "Committee",
  budget_head: "Budget Head",
  critical_rights: "Critical Rights",
  statutory_and_insurance: "Statutory & Insurance",
  storage: "Storage",
  coupon: "Coupon",
  activity: "Activity",
  policy: "Policy",
  terms_and_conditions: "Terms & Conditions",
  access_control: "Access Control",
  website: "Website",
  pms: "PMS",
  otp_service: "OTP Service",
  healthcare_config: "Healthcare Config",

  ////////////////////// Scheduler /////////////////////////////////

  virtual: "Virtual",
  physical: "Physical",
  hybrid: "Hybrid",
  add_guests: "Add Guests",
  belongs_to_your_organization: "Belongs to your organization",
  new_guest: "New Guest",
  add_a_new_guest: "Add a new guest",
  enter_user_details: "Enter User Details",
  user_name: "User Name",
  user_email: "User Email",
  convener: "Convener",
  chairperson: "Chairperson",
  make_convener: "Make Convener",
  make_chairperson: "Make Chairperson",
  confirmation: "Confirmation",
  remove_from_guest_list: "Remove {{userName}} from guest list?",
  please_select_atleast_two_participants_for_viewing_convenient_time:
    "Please select atleast two participants for viewing convenient time",
  select_a_convenient_time: "Select a convenient time",
  to: "To",
  exclude_me: "Exclude me",
  add_title: "Add Title",
  agenda: "Agenda",
  information: "Information",
  max_files_that_can_be_uploaded: "Max Files that can be Uploaded",
  max_size_per_file: "Max Size per File",
  enter_agenda_here: "Enter Agenda here",
  enter_agenda_description: "Enter Agenda Description",
  upload_new_file: "Upload New File",
  polling: "Polling",
  show_of_hand: "Show of Hand",
  add_agenda: "Add Agenda",
  special_resolution_will_be_proposed: "Special resolution(s) will be proposed",
  special_resolution_name: "Special resolution name",
  add_resolution: "Add Resolution",
  additional_documents_for_meeting: "Additional Documents for Meeting",
  additional_document_name: "Additional Document Name",
  add_other_document: "Add Other Document",
  test_observation: "Test Observation",
  leave_type: "Leave Type",
  available_leave_balance: "Available leave balance",
  enter_purpose_of_leave: "Enter Purpose of leave",
  purpose_of_leave: "Purpose of leave",
  half_day: "Half Day",
  file_name: "File Name",
  upload: "Upload",
  max_file_size_allowed: "Maximum File Size Allowed {{size}}MB",
  extensions_allowed: "Extension Allowed {{extensions}}",
  hide_leave_details: "Hide Leave Details",
  show_leave_details: "Show Leave Details",
  no_leave_history_found: " No Leave History Found",
  do_you_want_to_cancel_this_leave_request:
    "Do You Want To Cancel This Leave Request",
  do_you_want_to_withdraw_this_leave_request:
    "Do You Want To Withdraw This Leave Request",
  events_between: "Events Between",
  withdraw: "Withdraw",
  audio_note_recorded: "Audio Note Recorded",
  Webinar_title: "Webinar Title",
  webinar_description: "Webinar Description",
  webinar_slug: "webinar Slug",
  select_days: "select Days",
  select_all: "Select All",
  event_repeat_until_date: "Event Repeat Until Date",

  create_an_event: "Create an Event",
  apply_for_a_leave: "Apply for a Leave",
  create_a_webinar: "Create a Webinar",
  event: "Event",
  webinar: "Webinar",
  one_time_event: "One Time Event",
  recurring_event: "Recurring Event",
  annual_general_meeting: "Annual General Meeting",
  board_of_director_meeting: "Board of Director's Meeting",
  special_bod_meeting: "Special BOD Meeting",
  extraordinary_general_meeting: "Extraordinary General Meeting",
  task_review_meeting: "Task Review Meeting",
  client_meeting: "Client Meeting",
  review_meeting: "Review Meeting",
  vendor_meeting: "Vendor Meeting",
  general_meeting: "General Meeting",
  scheduler: "Scheduler",
  create: "Create",
  leave_request: "Leave Request",
  today: "Today",
  my_calendar: "My Calendar",
  google: "Google",
  weekend: "Weekend",
  holiday: "Holiday",
  leave: "Leave",
  day: "Day",
  week: "Week",
  month: "Month",
  agenda: "Agenda",
  calendar_settings: "Calendar Settings",
  sync_with_google_calendar: "Sync with Google Calendar",
  re_sync_needed: " (re-sync needed)",
  last_synced: "Last Synced: ",
  first_day_of_the_week: "First day of the week",
  select_days: "Select Days",
  work_week: "Work week",
  time_slot: "Time slot",
  select_slot_duration: "Select Slot Duration",
  please_enter_user_name: "Please Enter User Name",
  please_enter_user_email: "Please Enter User Email",
  please_enter_valid_email: "Please Enter Valid Email",
  participant_with_this_email_already_exists:
    "Participant with this email already exists",
  new_guest_added_successfully: "New Guest added successfully",
  please_select_file_less_than:
    "Please select a file less than {{maxFileSize}}MB",
  invalid_file_type: "Invalid File Type",
  you_can_upload_a_maximum_of_five_files: "You can upload a maximum of 5 files",
  please_enter_event_location: "Please Enter Event Location",
  select_participants: "Select participants",
  this_meeting_needs_at_least_two_participants:
    "This meeting needs at least 2 participants",
  please_enter_event_title: "Please Enter Event Title",
  you_have_to_add_meeting_agenda: "You Have To Add Meeting Agenda",
  you_have_exceeded_the_current_time_please_select_the_next_time_slot:
    "You have exceeded the current time. Please select the next time slot",
  all_guests_added_successfully: "All guests added successfully",
  failed_to_add_some_guests: "Failed to add some guests",
  file_attached: "File Attached",
  please_enter_the_purpose: "Please Enter The Purpose",
  please_upload_all_the_required_files: "Please Upload All The Required Files",
  you_cannot_take_more_leave_than_your_leave_balance:
    "You cannot take more leave than your leave balance",
  please_enter_event_description: "Please Enter Event Description",
  event_created_successfully: "Event Created Successfully",
  please_enter_webinar_title: "please Enter Webinar Title",
  please_enter_webinar_description: "Please Enter Webinar Description",
  Please_fill_blogSlug: "Please Fill blogSlug ",
  webinar_created_successfully: "Webinar Created Successfully",
  syncing_please_wait: "Syncing, please wait...",
  google_calendar_data_synced_successfully:
    "Google calendar data synced successfully",
  view_details_of: "View details of {{title}}",
  please_change_first_day_of_week:
    "Please change {{day}} from the first day of the week before removing it from the working day",
  mark_as_working_day: "Mark As Working Day",
  mark_attendance_is_unavailable_as_you_have_denied_location_access_to_this_site_please_allow_access_from_your_browser_settings:
    "Mark attendance is unavailable as you have denied location access to this site, please allow access from your browser settings",
  please_provide_location_access_to_mark_attendance:
    "Please provide location access to mark attendance",
  mark_attendance: "Mark Attendance",
  face_liveness_test_failed_please_try_again:
    "Face Liveness test Failed, Please try again....",
  facial_biometrics_added_to_account_successfully:
    "Facial Biometrics added to account successfully",
  please_register_your_facial_biometrics_to_mark_attendance:
    "Please register your facial biometrics to Mark Attendance",
  please_provide_your_facial_biometrics_to_mark_attendance:
    "Please provide your facial biometrics to Mark Attendance",
  select_the_days: "Select the Days",
  please_select_current_time: "Please Select Current Time",
  invite_sent_successfully: "Invite sent successfully",

  /////// week days ////

  sun: "Sun",
  mon: "Mon",
  tue: "Tue",
  wed: "Wed",
  thu: "Thu",
  fri: "Fri",
  sat: "Sat",

  /////////////////////////health care////////////////
  register_patient: "Register Patient",
  this_month: "This Month",
  search_by_patient_id: "Search by patient Id",
  dob: "DOB",
  years: "Years",
  months: "Months",
  civil_status: "Civil status",
  contact_number: "Contact Number",
  proceed_to_book_consultation: "Proceed to book consultation",
  proceed_to_book_appointment: "Proceed to book appointment",
  proceed_to_book_investigation: "Proceed to book investigation",
  scan_face: "Scan Face",
  matched_face: "Matched Face",
  present_image: "Present Image",
  matched_image: "Matched Image",
  recapture: "Recapture",
  patient_documents: "Patient Documents",
  document_type: "Document Type",
  no_patient_document_data_is_present: "No Patient Document Data is Present",
  patient_name: "Patient Name",
  search_patient: "Search Patient",
  consulting_doctor: "Consulting Doctor",
  consulting_start_time: "Consulting Start Time",
  consulting_end_time: "Consulting End Time",
  document: "Document",
  book_appointment: "Book Appointment",
  view_appointment: "View Appointment",
  appointment: "Appointment",
  book_consultation: "Book Consultation",
  unit_queue: "Unit Queue",
  queue: "Queue",
  consultations: "Consultations",
  count: "count",
  patient_registrations: "Patient Registrations",
  no_of_registraton: "No. Of Registraton",
  booked: "Booked",
  slots_unavailable: "Slots Unavailable",
  please_wait_file_is_getting_get_downloaded:
    "Please wait. The file is being downloaded",
  the_file_cannot_be_downloaded: "The file cannot be downloaded",
  download_prescription: "Download the Prescription",
  appointment_booking: "Appointment Booking",
  booking_date_time: "Booking Date Time",
  add_or_search_by_mail_id: "Add or search by mail id",
  confirm_booking: "Confirm Booking",
  please_select_gender: "Please select Gender",
  please_enter_a_valid_email: "Please enter a valid Email",
  appoinment_booked_successfully: "Appoinment booked successfully",
  appoinment_booking_unsuccessful: "Appoinment booking unsuccessful",
  fetching_facial_biometrics_data:
    "Fetching facial biometrics data, please wait...",
  facial_biometrics_data_found:
    "Facial biometrics data found, patient details fetched successfully",
  facial_biometrics_data_not_found:
    "Facial biometrics data not found, please continue to register as new patient",
  please_provide_facial_biometrics: "Please provide facial biometrics",
  please_enter_patient_first_name: "Please enter patient first name",
  please_enter_patient_last_name: "Please enter patient last name",
  please_enter_patient_gender: "Please enter patient gender",
  please_enter_patient_date_of_birth: "Please enter patient date of birth",
  please_enter_patient_email_id: "Please enter patient email id",
  please_enter_a_valid_patient_email_id:
    "Please enter a valid patient email id",
  processing_please_wait: "Processing, please wait...",
  success_please_continue_to_book_consultation:
    "Success, please continue to book consultation",
  patient_registered_successfully_please_continue_to_book_consultation:
    "Patient Registered successfully, please continue to book consultation",
  start_time_less_than_end_time:
    "'Start Time' should be less than 'End Time' for {{dayName}}",
  time_ranges_overlap: "Time ranges overlap for {{dayName}} in week {{week}}",

  referral_note: "Referral Note",

  doctor_info: "Doctor Info",
  register_face: "Register Face",
  add_to_queue: "Add to queue",
  cancel_appoinment: "Cancel appoinment",
  blood_pressure: "Blood Pressure",
  sys: "Sys",
  heart_rate: "Heart Rate",
  example_eighty: "eg:80",
  respiration_rate: "Respiration Rate",
  eg_12: "eg:12",
  spo2: "SPO2",
  eg_98: "eg:98",
  temperature: "Temperature",
  eg_96_6: "eg:96.6",
  eg_35: "eg:35",
  weight: "Weight",
  eg_80: "eg:80",
  height: "Height",
  eg_182: "eg:182",
  head_circumference: "Head Circumference",
  eg_34_5: "eg:34.5",
  upper_arm_circumference: "Upper Arm Circumference",
  eg_12_5: "eg:12.5",
  lmp: "LMP",
  get_vitals_from_device: "Get Vitals From Device",
  systemic_illness: "Systemic Illness",
  substance_abuse: "Substance Abuse",
  reference_details: "Reference Details",
  referred_by: "Referred By",
  reschedule_date: "Reschedule Date",
  continue: "Continue",
  please_give_patient_complaints: "Please Give Patient Complaints",
  submitting_details_please_wait: "Submitting details, please wait...",
  sistolic_pressure_must_be_greater_than_diastolic:
    "Systolic pressure must be greater than Diastolic pressure",
  sistolic_and_diastolic_pressure_difference_must_be_greater_than_30:
    "The difference between Systolic and Diastolic pressure must be greater than 30",
  temperature_range_error_in_celsius:
    "Temperature must be within 32.22 to 41.10",
  temperature_range_error_in_fahrenheit: "Temparature must be within 90 to 106",
  fetching_facial_biometrics_data_please_wait:
    "Fetching facial biometrics data, please wait...",
  facial_biometrics_data_found_patient_details_fetched_successfully:
    "Facial biometrics data found, patient details fetched successfully",
  facial_biometrics_not_found_please_register_patient_first:
    "Facial biometrics not found, please register the patient first",
  please_select_patient: "Please Select Patient",
  appoinment_canceled: "Appoinment cancelled",
  appoinment_could_not_be_canceled: "Appoinment could not be cancelled",
  appoinment_rescheduled_successfully: "Appoinment rescheduled successfully",
  appoinment_could_not_be_rescheduled: "Appoinment could not be rescheduled",
  please_select_doctor: "Please Select Doctor",
  this_brands_generic_name_is_not_present_in_the_table:
    "This Brand's Generic name is not present in the table",
  food_name: "Food Name",
  medical_history: "Medical History",
  medicine: "Medicine",
  could_not_fetch_generic_ranking: "Could not fetch generic ranking",
  could_not_fetch_generic_data: "Could not fetch generic data",
  cancel_facial_biometrics_registration_process:
    "Cancel facial biometrics registration process?",
  mic: "Mic",
  video: "Video",
  share_screen: "Share Screen",
  white_board: "White Board",
  meeting_agenda: "Meeting Agenda",
  meeting_chat: "Meeting Chat",
  raise_hand: "Raise hand",
  invite_user: "Invite user",
  start_live_stream: "Start Live Stream",
  stop_live_stream: "Stop Live Stream",
  leave_call: "Leave Call",
  more: "More",
  you_have_entered_a_room_number_please_use_join:
    "You have entered a room number, please use join",
  no_agenda_available: "No Agenda Available",
  transcription_details: "Transcription Details",
  select_spoken_languages: "Select Spoken Languages",
  participants: "Participants",
  audio_devices: "Audio Devices",
  grid_view: "Grid View",
  virtual_background: "Virtual Background",
  file_share: "File Share",
  breakout_rooms: "Breakout Rooms",
  leave_end_webinar: "Leave/End Webinar",
  leave_webinar: "Leave Webinar",
  end_the_webinar_for_all_users_the_webinar_will_be_closed_and_you_will_not_be_able_to_start_it_again:
    "End the webinar for all users, the webinar will be closed and you will not be able to start it again",
  end_webinar_for_all: "End webinar for all",
  speakers: "Speakers",
  video_devices: "Video Devices",
  video_camera: "Video Camera",
  enter_room_number: "Enter Room Number *",
  meeting_agendas: "Meeting Agendas",
  subject: "Subject",
  details: "Details",
  p_s: "P/S",
  record_patient_complaints: "Record Patient complaints",
  patient_profile: "patient-profile",
  dys: "Dys",
  select: "Select",
  sos: "SOS",
  no_doctor_is_online: "No Doctor is online",
  registered_patients: "Register Patients",
  please_enter_other_terms_and_conditions:
    "Please Enter Other Terms and Conditions",
  please_enter_document_name: "Please Enter Document Name",
  face_details_found_please_associate_face_with_the_provided_data:
    "Face details found, please associate face with the provided data",
  face_details_not_found_please_associate_face_with_the_provided_data:
    "Face details not found, please associate face with the provided data",
  adding_facial_biometrics_data_to_account_please_wait:
    "Adding facial biometrics data to account, please wait...",
  facial_biometrics_data_added_to_account_successfully:
    "Facial biometrics data added to account successfully",
  patient_booked_appointment_details_changes_successfully:
    "Patient booked appointment details changes successfully",
  slot_already_booked_select_another_slot:
    "Slot already booked. Select another slot",
  doctor_image: "Doctor Image",
  patient_image: "Patient Image",
  no_slot_selected: "No slot selected",
  the_provided_facial_biometrics_are_associated_with_this_account:
    "The provided facial biometrics are associated with this account",
  profile_image: "Profile-Image",
  age: "Age",
  contact_no: "Contact No",
  selected_files: "Selected Files",
  previously_uploaded_files: "Previously Uploaded Files",
  invalidFileType: "Invalid File Type Of '{{fileName}}'",
  fileSizeWarning:
    "'{{fileName}}' file needs to be less than {{maxFileSize}}MB",
  fileAlreadyUploaded: "'{{fileName}}' is already uploaded",
  selectStartTime: "Please select 'Start Time' for {{dayName}}",
  selectEndTime: "Please Select 'End Time' for {{dayName}}",
  selectDuration: "Please Select 'Duration' for {{dayName}}",
  search_doctor: "Search doctor",
  successfully_added_unit_for_test: "Successfully Added Unit For Test",
  error_in_adding_unit_for_test: "Error in Adding Unit For Test",
  conduct_investigation: "Conduct Investigation",
  investigations_from_prescriptions: "Investigations from prescriptions",
  add_new_investigation: "Add New Investigation",
  outsource_report: "Outsource Report",
  external_report: "External Report",
  collection_date_time: "Collection Date Time",
  sample_type: "Sample Type",
  blood: "Blood",
  urine: "Urine",
  sputum: "Sputum",
  reagent: "Reagent",
  enter_reagent: "Enter Reagent",
  eg_120: "eg:120",
  desirable: "Desirable",
  borderline_high: "Borderline High",
  high: "High",
  very_high: "Very High",
  method: "Method",
  enter_method: "Enter Method",
  reference_range: "Reference Range",
  upload_report: "Upload Report",
  test_is_not_available_in_this_center: "Test is not available in this center",
  please_select_investigation: "Please Select Investigation",
  the_provided_email_id_is_currently_linked_with_the_patient_details_shown_below:
    "The provided email id is currently linked with the patient details shown below",
  on_clicking_continue_this_data_will_be_deleted_and_the_new_data_with_facial_biometrics_will_be_created:
    "On clicking continue this data will be deleted and the new data with facial biometrics will be created",
  email_id_is_linked_with_another_account:
    "Email id is linked with another account",
  review_patient: "Review Patient",
  add_test_data: "Add Test Data",
  connect_to_doctor: "Connect To Doctor",
  do_you_want_to_go_to_go_to_consultation:
    "Do you want to go to go to consultation",
  doctor_consultations: "Doctor Consultations",
  appointment_booked: "Appointment Booked",
  investigation_admin: "Investigation - Admin",
  investigation_unit: "Investigation - Unit",
  conduct: "Conduct",
  review_and_referred: "Review & Referred",
  referred_patient: "Referred Patient",
  search_name: "Search name",
  admin_rights_granted: "Admin rights granted",
  advisory_board_consultant: "Advisory Board Consultant",
  last_employee_ids: "Last Few Employee ids",
  please_enter_valid_latitude_range:
    "Please enter a valid latitude range between -90 and 90",
  please_enter_valid_longitude_range:
    "Please enter a valid longitude range between -180 and 180",

  ////////view doctor site ////////

  conversation: "Conversation",
  update_comment_here: "Update comment here",
  please_provide_a_rating_for_your_overall_experience_with_the_video_consultation:
    "Please provide a rating for your overall experience with the video consultation",
  please_select_below_issues_you_faces_during_consultation:
    " Please select below issues you faces during consultation",
  please_leave_your_valuable_feedback: "Please leave your valuable feedback",
  please_leave_your_valuable_feedback_regarding_your_overall_experience_with_the_video_consultation:
    "Please leave your valuable feedback regarding your overall experience with the video consultation",
  submit_your_feedback: "Submit your feedback",
  please_select_rating: "Please select rating",
  feedback_submitted_successfully: "Feedback submitted successfully",
  feedback_submission_unsuccessful: "Feedback submission unsuccessful",
  filter_patient: "Filter Patient",
  no_data_found: "No data found !!",
  patient_leave_center: "Patient leave center",
  reason: "Reason",
  please_enter_a_reason_for_cancellation:
    "Please enter a reason for cancellation",
  Consultation_canceled_successfully: "Consultation canceled successfully",
  failed_to_cancel_the_consultation_please_try_again:
    "Failed to cancel the consultation. Please try again.",
  an_unexpected_error_occurred_please_try_again_later:
    "An unexpected error occurred. Please try again later",
  reschedule_appointment: "Reschedule Appointment",
  reschedule_date_on_before_after: "Reschedule Date on/Before/After",
  mark_as_unavailable: "Mark as Unavailable",
  remarks: "Remarks",
  mark_as_available: "Mark as Available",
  mark_slots_for_unavailability: "Mark slots for Unavailability",
  select_all_for_unavailability: "Select All For Unavailability",
  please_check_reschedule_before_proceeding:
    "Please check Reschedule before proceeding",
  please_enter_a_reason_for_reschedule: "Please Enter A Reason For Reschedule",
  request_slot_reschedule_successful: "Request Slot Reschedule Successful",
  failed_to_reschedule_slot: "Failed to reschedule slot",
  an_error_occurred_while_rescheduling_the_slot:
    "An error occurred while rescheduling the slot",
  please_check_mark_as_unavailable_before_proceeding:
    "Please check Mark as Unavailable before proceeding",
  please_enter_remarks: "Please Enter Remarks",
  slot_marked_as_unavailable_successfully:
    "Slot marked as unavailable successfully",
  failed_to_mark_slot_as_unavailable: "Failed to mark slot as unavailable",
  an_error_occurred_while_making_the_slot_unavailable:
    "An error occurred while making the slot unavailable",
  slotAlreadyBooked:
    "Slot {{fromTime}} - {{toTime}} is already booked, please reschedule it",
  slot_availability_unavailability_is_updated:
    "Slot availability/unavailability is updated",
  an_error_occurred_while_updating_slots:
    "An error occurred while updating slots",
  please_check_mark_as_available_before_proceeding:
    "Please check Mark as Available before proceeding",
  slot_marked_as_available_successfully:
    "Slot marked as Available successfully",
  failed_to_mark_slot_as_available: "Failed to mark slot as Available",
  conversation: "Conversation",
  update_comment_here: "Update comment here",
  please_provide_a_rating_for_your_overall_experience_with_the_video_consultation:
    "Please provide a rating for your overall experience with the video consultation",
  please_select_below_issues_you_faces_during_consultation:
    " Please select below issues you faces during consultation",
  please_leave_your_valuable_feedback: "Please leave your valuable feedback",
  please_leave_your_valuable_feedback_regarding_your_overall_experience_with_the_video_consultation:
    "Please leave your valuable feedback regarding your overall experience with the video consultation",
  submit_your_feedback: "Submit your feedback",
  please_select_rating: "Please select rating",
  feedback_submitted_successfully: "Feedback submitted successfully",
  feedback_submission_unsuccessful: "Feedback submission unsuccessful",
  filter_patient: "Filter Patient",
  no_data_found: "No data found !!",
  patient_leave_center: "Patient leave center",
  reason: "Reason",
  please_enter_a_reason_for_cancellation:
    "Please enter a reason for cancellation",
  Consultation_canceled_successfully: "Consultation canceled successfully",
  failed_to_cancel_the_consultation_please_try_again:
    "Failed to cancel the consultation. Please try again.",
  an_unexpected_error_occurred_please_try_again_later:
    "An unexpected error occurred. Please try again later",
  reschedule_appointment: "Reschedule Appointment",
  reschedule_date_on_before_after: "Reschedule Date on/Before/After",
  mark_as_unavailable: "Mark as Unavailable",
  remarks: "Remarks",
  mark_as_available: "Mark as Available",
  mark_slots_for_unavailability: "Mark slots for Unavailability",
  select_all_for_unavailability: "Select All For Unavailability",
  please_check_reschedule_before_proceeding:
    "Please check Reschedule before proceeding",
  please_enter_a_reason_for_reschedule: "Please Enter A Reason For Reschedule",
  request_slot_reschedule_successful: "Request Slot Reschedule Successful",
  failed_to_reschedule_slot: "Failed to reschedule slot",
  an_error_occurred_while_rescheduling_the_slot:
    "An error occurred while rescheduling the slot",
  please_check_mark_as_unavailable_before_proceeding:
    "Please check Mark as Unavailable before proceeding",
  please_enter_remarks: "Please Enter Remarks",
  slot_marked_as_unavailable_successfully:
    "Slot marked as unavailable successfully",
  failed_to_mark_slot_as_unavailable: "Failed to mark slot as unavailable",
  an_error_occurred_while_making_the_slot_unavailable:
    "An error occurred while making the slot unavailable",
  slotAlreadyBooked:
    "Slot {{fromTime}} - {{toTime}} is already booked, please reschedule it",
  slot_availability_unavailability_is_updated:
    "Slot availability/unavailability is updated",
  an_error_occurred_while_updating_slots:
    "An error occurred while updating slots",
  please_check_mark_as_available_before_proceeding:
    "Please check Mark as Available before proceeding",
  slot_marked_as_available_successfully:
    "Slot marked as Available successfully",
  failed_to_mark_slot_as_available: "Failed to mark slot as Available",
  switch_doctor: "Switch Doctor",
  search_doctor: "Search doctor",
  unable_to_process_switch_request_as_no_doctors_of_the_same_specialty_are_currently_online_please_try_again_later:
    "Unable to process switch request as no doctors of the same specialty are currently online, please try again later...",
  please_select_a_doctor_before_submitting:
    "Please select a doctor before submitting",
  doctor_switched_successfully: "Doctor switched successfully",
  failed_to_switch_doctor_please_try_again:
    "Failed to switch doctor. Please try again",
  an_unexpected_error_occurred_please_try_again_later:
    "An unexpected error occurred. Please try again later",
  experience: "Experience",
  fees: "Fees",
  language: "Language",
  patient_queue: "Patient Queue",
  search_patient: "Search Patient",
  book_consultation: "Book Consultation",
  no_patient_queue: "No Patient Queue",
  click_add_book_consultation_button_to_create_new_patient_queue:
    "Click add Book Consultation Button to create new Patient Queue",
  sl_no: "Sl. No",
  patient: "Patient",
  check_in_time: "Check-in Time",
  doctor_queue: "Doctor Queue",
  ewt: "EWT",
  status: "Status",
  remark: "Remark",
  action: "Action",
  appointment_consultation: "Appointment Consultation",
  patient_left: "Patient Left",
  patient_waiting_in_video: "Patient Waiting in Video",
  doctor_writing_prescription: "Doctor Writing Prescription",
  doctor_cancelled: "Doctor Cancelled",
  patient_switched: "Patient Switched",
  start_conversation: "Start Conversation",
  patient_leave_center: "Patient leave center",
  patient_back_to_center: "Patient back to center",
  download_prescription: "Download Prescription",
  switch_doctor: "Switch Doctor",
  add_test_result: "Add Test Result",
  reconnect_doctor: "Reconnect Doctor",
  no_patient_found: "No Patient Found",
  connect_to_doctor: "Connect to Doctor",
  consultation_in_progress: "Consultation In Progress",
  doctor_canceled_patient_updating_queue:
    "Doctor Canceled patient. Updating Queue",
  doctor_switched_patient_updating_queue:
    "Doctor Switched patient(s). Updating Queue",
  doctor_switched_patient: "Doctor switched patient",
  please_wait_the_file_is_being_downloaded:
    "Please wait. The file is being downloaded",
  file_cannot_get_downloaded: "File cannot get downloaded",
  connecting_to_the_doctor: "Connecting to the Doctor",
  error_in_connecting_to_the_doctor: "error in connecting to the Doctor",
  successfully_logged_in_for_consultation:
    "Successfully logged in for consultation",
  failed_to_log_in_for_consultation_please_try_again:
    "Failed to log in for consultation. Please try again.",
  an_unexpected_error_occurred_please_try_again_later:
    "An unexpected error occurred. Please try again later",
  estimated_wait_time: "Estimated wait time",
  emergency_patient: "Emergency Patient",
  please_select_date_of_birth : "please Select Date of Birth" ,
  consultationMessage: "{{patientName}} has scheduled a consultation with {{doctorName}} on {{date}} at {{fromTime}} - {{toTime}}. Please ensure you reach the center at least 15 minutes before your appointment time to avoid any delays and to allow for a smooth consultation process.",
  eg_35 : "eg: 35" ,
  audio : "Audio" ,
  search_patient_name : "Search Patient Name" ,
  age : "Age" ,
  gender : "Gender" ,
  prescription : "Prescription" ,
  investigation : "Investigation" ,
  generate_report : "Generate Report" ,
  referred_patient : "Referred Patient" ,
  select_date : "Select Date" ,
  search : "Search" ,
  no_refer_patient_data_is_found : "No Refer Patient Data is found" ,
  patient_name : "Patient Name" ,
  doctor_name : "Doctor Name" ,
  doctor_note : "Doctor Note" ,
  referral_note : "Referral Note" ,
  no_doctor_notes : "No Doctor Notes" ,
  no_referral_notes : "No Referral Notes" ,
  review_patients : "Review Patients" ,
  no_patient_review_data_found : "No Patient Review Data Found" ,
  patient_name : "Patient Name" ,
  contact_info : "Contact Info" ,
  review_date : "Review Date" ,
  doctor_info : "Doctor Info" ,
  consulted_on : "Consulted On" ,
  notes : "Notes" ,
  last_consulted_with : "Last Consulted With" ,
  last_consulted_date : "Last Consulted Date" ,
  no_notes_available : "No notes available" ,
  process : "Process" ,
  view_appointments : "View Appointments" ,
  search_doctor_or_speciality : "Search Doctor or speciality" ,
  select_date : "Select Date",
  no_appointments_found : "No appointments found" ,
  investigation : "Investigation" ,
  search_test_name : "Search Test Name" ,
  no_investication_admin_data_found : "No Investication Admin Data Found" ,
  test_group : "Test Group" ,
  test_name : "Test Name" ,
  gender : "Gender" ,
  action : "Action" ,
  both : "Both" ,
  male : "Male" ,
  female : "Female" ,
  link_units : "Link Units" ,
  view_booked_appointment : "View Booked appointment" ,
  slots_date_from : "Slots date from" ,
  booked : "Booked" ,
  slots_unavailable : "Slots Unavailable",
  slots : "slots" ,
  no_of_slots : "No. of Slots",
  consultation_list : "Consultation List" ,
  from_date : "From Date" ,
  to_date : "To Date" ,
  no_consultation_data_is_found : " No Consultation Data is found" ,
  unit_name : "Unit Name" ,
  unit_contact_number : "Unit Contact Number",
  consultation_start_time : "Consultation Start Time" ,
  consultation_end_time : "Consultation End Time" ,
  prescription_time : "Prescription Time" ,
  waiting_time : "Waiting Time" ,
  search_employee : "Search Employee" ,
};
