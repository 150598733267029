import { toast } from "react-toastify";
import validator from "validator";
import { apiEndPoint } from "../constants/url_provider";

const AppUtils = {
  async googleTranslate(text, fromLang, toLang) {
    // let fromLang = "en";
    // let toLang = "no"; // translate to norwegian
    // let text = "something to translate";

    // const location = window.location.hostname;

    if (
      fromLang.substring(0, 2).toLowerCase() ===
      toLang.substring(0, 2).toLowerCase()
    ) {
      return text;
    }

    const settings = {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    const API_KEY = ["AIzaSyC1pCongXtK2efJYIEmPeh_JzwzQjtKDxQ"];

    let url = `https://translation.googleapis.com/language/translate/v2?key=${API_KEY}`;
    url += "&q=" + encodeURI(text);
    url += `&source=${fromLang}`;
    url += `&target=${toLang}`;

    try {
      const fetchResponse = await fetch(url, settings);
      const response = await fetchResponse.json();
      console.log(response);
      let translatedText =
        response.data.translations[0].translatedText.replaceAll("&#39;", "'");
      // translatedText.replaceAll()
      return translatedText;
    } catch (e) {
      return e;
    }
  },
  isNumeric: function (str) {
    const regex = /^[0-9]+$/;
    return regex.test(str);
  },

  maxSizePerFile: 100 * 1024 * 1024,

  commonFileType: [
    "jpeg",
    "jpg",
    "png",
    "mp4",
    "gif",
    "mp3",
    "svg",
    "tiff",
    "tif",
    "ogg",
    "mp3",
    "wav",
    "7z",
    "arj",
    "pkg",
    "rar",
    "rpm",
    "tar",
    "zip",
    "bin",
    "dmg",
    "iso",
    "csv",
    "dat",
    "sql",
    "tar",
    "xml",
    "log",
    "apk",
    "bin",
    "jar",
    "py",
    "bmp",
    "webp",
    "asp",
    "aspx",
    "ppt",
    "pptx",
    "odp",
    "ods",
    "xls",
    "xlsm",
    "xlsx",
    "pdf",
    "txt",
    "odt",
    "rtf",
    "tex",
    "doc",
    "docx",
  ],

  isValidExtention: function (extention) {
    return this.commonFileType.includes(extention);
  },

  getValidExtentions: function () {
    let str = "";
    for (let count = 0; count < this.commonFileType.length; count++) {
      str = str + "." + this.commonFileType[count] + ",";
    }
    return str;
  },

  isValidFolderName: function (folderName) {
    var folderNameRegex = /^[a-zA-Z0-9_\-]*$/;
    return folderNameRegex.test(folderName);
  },

  blobToBase64: (blob) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        const base64DataUrl = event.target.result;
        const base64Data = base64DataUrl.split(",")[1];
        console.log(base64Data);
        resolve(base64Data);
      };
      reader.onerror = (event) => {
        reject(event.target.error);
      };
      reader.readAsDataURL(blob);
    });
  },

  getValidExtentionsForDisplay: function (displayedExtensions) {
    let str = "";
    for (let count = 0; count < this.commonFileType.length; count++) {
      if (!displayedExtensions.includes(this.commonFileType[count])) {
        str = str + "." + this.commonFileType[count] + ", ";
      }
    }
    return str.substring(0, str.length - 2);
    // str = str + "image/*";
    // console.log(str);
  },
  isPersonal(loginUser) {
    if (loginUser.orgId === "ORG000000000000") {
      return true;
    } else {
      return false;
    }
  },

  subtractPercentFromNumber: function (number, percentToSubtract) {
    if (
      typeof percentToSubtract !== "number" ||
      percentToSubtract < 0 ||
      percentToSubtract > 100
    ) {
      throw new Error("Invalid percentage value");
    }

    // Calculate the discount amount
    const discountAmount = number * (percentToSubtract / 100);

    // Calculate the new total amount after applying the discount
    const discountedTotalAmount = number - discountAmount;

    // Round the discounted total amount to 2 decimal places
    const roundedDiscountedTotalAmount = parseFloat(
      discountedTotalAmount.toFixed(2)
    );

    return roundedDiscountedTotalAmount;
  },
  validateSubdomain: function (email) {
    const subDomainRegex = /^[A-Za-z0-9]*$/;
    return subDomainRegex.test(String(email).toLowerCase());
  },

  imageUrlToBase64: async function (imageUrl) {
    return new Promise((resolve, reject) => {
      fetch(imageUrl)
        .then((response) => {
          if (!response.ok) {
            throw new Error("Failed to fetch image");
          }
          return response.blob();
        })
        .then((blob) => {
          const reader = new FileReader();
          reader.readAsDataURL(blob);
          reader.onloadend = () => {
            resolve(reader.result);
          };
          reader.onerror = reject;
        })
        .catch((error) => {
          console.error("Error converting image to Base64:", error);
          reject(error);
        });
    });
  },
  validateEmail: function (email) {
    return validator.isEmail(email);
  },
  downloadFileAws: function (fileName, folderName) {
    toast.info("Please wait . File is getting get downloaded", {
      position: toast.POSITION.TOP_RIGHT,
    });
    const newLink = fileName.replace(/\//g, "~");
    let newFolderLink = folderName.replace(/\//g, "~");
    window.open(
      apiEndPoint + `/appTest/downloadFileTest/${newLink}/${newFolderLink}`,
      "_blank",
      "noopener,noreferrer"
    );
  },
};

export default AppUtils;
