import React, { useEffect, useState } from "react";
import {
  Visibility,
  VisibilityOff,
  Cancel,
  CheckCircle,
} from "@mui/icons-material";
import {
  Box,
  Button,
  FormControl,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  Modal,
  TextField,
} from "@mui/material";
import { jwtDecode } from "jwt-decode";
import { toast } from "react-toastify";
import DashboardServices from "../../../services/DashboardServices";
import LoginSignupService from "../../../services/LoginSignupService";

const FirstTimeLoginReset = ({ openModal, closeModal }) => {
  const [containCapital, setContainCapital] = useState(false);
  const [containSpecialChar, setContainSpecialChar] = useState(false);
  const [containDigit, setContainDigit] = useState(false);
  const [containSmallChar, setContainSmallChar] = useState(false);

  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");

  const [showPassword1, setShowPassword1] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);

  const handleClickShowPassword1 = () => setShowPassword1((show) => !show);
  const handleClickShowPassword2 = () => setShowPassword2((show) => !show);

  const handleMouseDownPassword1 = (event) => {
    event.preventDefault();
  };

  const handleMouseDownPassword2 = (event) => {
    event.preventDefault();
  };

  const handlePasswordChange1 = (e) => {
    const capitalRegex = /[A-Z]/;
    const specialCharRegex = /[!@#$%^&*(),.?":{}|<>]/;
    const digitRegex = /\d/;
    const smallLetterRegex = /[a-z]/;
    const spaceRegex = /[ ]/;

    if (spaceRegex.test(e.target.value)) {
      toast.error("Password cannot contain spaces", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }

    setNewPassword(e.target.value);

    setContainCapital(capitalRegex.test(e.target.value));
    setContainSpecialChar(specialCharRegex.test(e.target.value));
    setContainDigit(digitRegex.test(e.target.value));
    setContainSmallChar(smallLetterRegex.test(e.target.value));
  };

  const handlePasswordChange2 = (e) => {
    setConfirmNewPassword(e.target.value);
  };

  useEffect(() => {
    const userDetails = jwtDecode(localStorage.getItem("token"));
    console.log("User details", userDetails);
  }, []);

  const handelSubmitChangePassword = () => {
    const userDetails = jwtDecode(localStorage.getItem("token"));
    if (!newPassword) {
      toast.error("Please enter a password", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else if (!confirmNewPassword) {
      toast.error("Please enter confirm your password", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else if (newPassword.length < 8) {
      toast.error("Password should be at least 8 characters long", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
    // else if (newPassword.length > 20) {
    //   toast.error("Password should be a maximum of 20 characters long", {
    //     position: toast.POSITION.TOP_RIGHT,
    //   });
    // }
    else if (!containCapital) {
      toast.error("Please include at least one capital letter", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else if (!containSmallChar) {
      toast.error("Please include at least one small letter", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else if (!containDigit) {
      toast.error("Please include at least one digit", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else if (!containSpecialChar) {
      toast.error("Please include at least one special character", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else if (newPassword !== confirmNewPassword) {
      toast.error("Passwords do not match", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      DashboardServices.changePasswordByUserId(
        userDetails.userId,
        newPassword
      ).then((response) => {
        if (response.data.returnValue === "0") {
          toast.error(response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
          const loginReqDto = {
            username: `${userDetails.userEmailId.toLowerCase()}~${
              userDetails.orgId
            }`,
            password: newPassword,
          };
          LoginSignupService.userLogin(loginReqDto)
            .then((loginResponse) => {
              if (
                loginResponse.request.status === 401 ||
                loginResponse.data === "INVALID_CREDENTIALS"
              ) {
                toast.error("Invalid Credentials!", {
                  position: toast.POSITION.TOP_RIGHT,
                });
              } else {
                localStorage.setItem("token", loginResponse.data.token);
                closeModal();
                toast.success("Password changed successfully", {
                  position: toast.POSITION.TOP_RIGHT,
                });
              }
            })
            .catch(() => {
              toast.error("Invalid Credentials!", {
                position: toast.POSITION.TOP_RIGHT,
              });
            });
        }
      });
    }
  };

  return (
    <>
      <Box className="ModelBox" sx={{ boxShadow: 24, p: 4 }}>
        <div className="addMoreAgendaModel">
          <div className="modelTitle">Reset Password</div>

          <div className="elementFormContainer">
            <div className="formElement">
              <FormControl className="formControl">
                <TextField
                  className="modelTextFild"
                  label="New Password"
                  variant="outlined"
                  //   multiline
                  //   rows={3}
                  //   maxRows={7}
                  type={showPassword1 ? "text" : "password"}
                  value={newPassword}
                  onChange={handlePasswordChange1}
                  autoComplete="off"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword1}
                          onMouseDown={handleMouseDownPassword1}
                        >
                          {showPassword1 ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </FormControl>
            </div>

            <div className="formElement">
              <FormControl className="formControl">
                <TextField
                  className="modelTextFild"
                  label="Confirm New Password"
                  variant="outlined"
                  //   multiline
                  //   rows={3}
                  //   maxRows={1}
                  type={showPassword2 ? "text" : "password"}
                  value={confirmNewPassword}
                  onChange={handlePasswordChange2}
                  autoComplete="off"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword2}
                          onMouseDown={handleMouseDownPassword2}
                        >
                          {showPassword2 ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </FormControl>
            </div>

            <div className="passwordPolocy">
              <div className="passwrdPlcyCheckGrp">
                <div
                  className="passwrdPlcyCheckHead"
                  style={{ color: "black" }}
                >
                  Password should contain
                </div>

                <div className="passwrdPlcyCheck">
                  <span id="one-upper-case-check">
                    {newPassword.match(/[A-Z]/) <= 1 ? (
                      <Cancel className="cancelIcon" style={{ color: "red" }} />
                    ) : (
                      <CheckCircle
                        className="checkIcon"
                        style={{ color: "green" }}
                      />
                    )}
                  </span>
                  <span style={{ color: "black" }}>One upper case letter</span>
                </div>

                <div className="passwrdPlcyCheck">
                  <span id="one-lower-case-check">
                    {newPassword.match(/[a-z]/) <= 1 ? (
                      <Cancel className="cancelIcon" style={{ color: "red" }} />
                    ) : (
                      <CheckCircle
                        className="checkIcon"
                        style={{ color: "green" }}
                      />
                    )}
                  </span>
                  <span style={{ color: "black" }}>One lower case letter</span>
                </div>

                <div className="passwrdPlcyCheck">
                  <span id="one-number-check">
                    {newPassword.match(/[0-9]/) < 1 ? (
                      <Cancel className="cancelIcon" style={{ color: "red" }} />
                    ) : (
                      <CheckCircle
                        className="checkIcon"
                        style={{ color: "green" }}
                      />
                    )}
                  </span>
                  <span style={{ color: "black" }}>One Number</span>
                </div>

                <div className="passwrdPlcyCheck">
                  <span id="one-specialChar-check">
                    {/[!@#$%^&*(),.?":{}|~<>]/.test(newPassword) < 1 ? (
                      <Cancel className="cancelIcon" style={{ color: "red" }} />
                    ) : (
                      <CheckCircle
                        className="checkIcon"
                        style={{ color: "green" }}
                      />
                    )}
                  </span>
                  <span style={{ color: "black" }}>Special character</span>
                </div>

                <div className="passwrdPlcyCheck">
                  <span id="min-length-check">
                    {newPassword.length < 8 ? (
                      <Cancel className="cancelIcon" style={{ color: "red" }} />
                    ) : (
                      <CheckCircle
                        className="checkIcon"
                        style={{ color: "green" }}
                      />
                    )}
                  </span>
                  <span style={{ color: "black" }}>Min 8 characters</span>
                </div>

                <div className="passwrdPlcyCheck">
                  <span id="pass-match-check">
                    {newPassword === "" ||
                    newPassword !== confirmNewPassword ? (
                      <Cancel className="cancelIcon" style={{ color: "red" }} />
                    ) : (
                      <CheckCircle
                        className="checkIcon"
                        style={{ color: "green" }}
                      />
                    )}
                  </span>
                  <span style={{ color: "black" }}>Passwords mismatch</span>
                </div>
              </div>
            </div>
          </div>

          <div className="modActionBtnGrp">
            <Button
              className="dfultPrimaryBtn"
              onClick={handelSubmitChangePassword}
            >
              Submit
            </Button>
            <Button className="dfultDarkSecondaryBtn" onClick={closeModal}>
              Cancel
            </Button>
          </div>
        </div>
      </Box>
    </>
  );
};

export default FirstTimeLoginReset;
