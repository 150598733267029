import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import React from "react";
import { NumericFormatCustom } from "./NumericFormatCustom";
import { Controller, useForm } from "react-hook-form";

export const AdvanceForm = ({ register, budgetHeadList, control }) => {
  // const { register, watch, setValue, control } = useForm({
  //   defaultValues: {
  //     transportCost: 0,
  //     accomodationCost: 0,
  //     mealCost: 0,
  //     otherExpense: 0,
  //   },
  // });
  //   const { transportCost, accomodationCost, mealCost, otherExpenses } = watch();
  return (
    <>
      <div className="formElementGroup">
        <div className="formElement">
          <FormControl className="formControl">
            <TextField
              label="Advance"
              name="numberformat"
              id="Transport_Cost"
              InputProps={{
                inputComponent: NumericFormatCustom,
              }}
              variant="outlined"
              defaultValue={0}
              className="formTextFieldArea"
              {...register("advance")}
            />
          </FormControl>
        </div>
        <div className="formElement">
          <FormControl className="formControl">
            <TextField
              label="Expected Cost"
              name="numberformat"
              id="Transport_Cost"
              InputProps={{
                inputComponent: NumericFormatCustom,
              }}
              variant="outlined"
              defaultValue={0}
              className="formTextFieldArea"
              {...register("expectedCost")}
            />
          </FormControl>
        </div>
        <div className="formElement">
          <FormControl className="formControl">
            <InputLabel id="selectLedgerHead">Budget Head*</InputLabel>

            <Controller
              name="budgetHead"
              control={control}
              defaultValue={""}
              render={({ field }) => (
                <Select
                  className="formInputField"
                  variant="outlined"
                  labelId="selectLedgerHead"
                  id="select-LedgerHead"
                  label="Budget Head"
                  {...field}
                >
                  {budgetHeadList.length > 0 &&
                    budgetHeadList.map((itemName, index) => (
                      <MenuItem
                        value={itemName.id}
                        key={itemName.head + "_" + index}
                      >
                        {itemName.head}
                      </MenuItem>
                    ))}
                </Select>
              )}
            />
          </FormControl>
        </div>
      </div>
    </>
  );
};
