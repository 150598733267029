import { Verified } from "@mui/icons-material";
import { Box, Button, IconButton, Tooltip } from "@mui/material";
import React from "react";
import { useEffect } from "react";

const AssigneeModal = ({ assigneeList, handleClose }) => {
  useEffect(() => {
    console.log("assignee list = ", assigneeList);
  }, []);
  return (
    <>
      <Box className="ModelBox" sx={{ boxShadow: 24, p: 4 }}>
        <div className="addMoreAgendaModel">
          <div className="modelTitle">Assignee of Task</div>
          <div className="metGustLstGrp">
            {assigneeList.map((participantDto) => {
              return (
                <>
                  <div className={`metGustLstInd`} style={{ cursor: "unset" }}>
                    <div className="metGstImg">
                      <img src={participantDto.profileUrl} alt="" />
                      {/* <span className="metConVerd">
                            <CheckCircle />
                          </span> */}
                    </div>
                    <div className="metGstName" style={{ color: "black" }}>
                      {participantDto.userName}
                      <span style={{ padding: "5px" }}>
                        <Tooltip title={"Belongs to your organization"}>
                          {/* <BadgeCheck /> */}
                          <Verified />
                        </Tooltip>
                      </span>
                    </div>
                  </div>
                </>
              );
            })}
          </div>
          <div className="modActionBtnGrp">
            {/* <Button
              className="dfultPrimaryBtn"
              onClick={(e) => {
                handleSubmitTransferTask();
              }}
            >
              Submit
            </Button> */}
            <Button className="dfultDarkSecondaryBtn" onClick={handleClose}>
              Cancel
            </Button>
          </div>
        </div>
      </Box>
    </>
  );
};

export default AssigneeModal;
