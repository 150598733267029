import axios from "axios";
import authHeader from "../config/auth-header-config";
import http from "../config/http-common";

class ZhAIService {
  getDifferentialDiagnosis(reqDto) {
    let authHeaderVal = authHeader().Authorization;
    const config = {
      headers: {
        "Content-type": "text/plain",
        Authorization: authHeaderVal,
      },
    };
    return http.put(`zhAi/getDifferentialDiagnosis`, reqDto, config);
  }
  getDrugSafetyData(reqDto) {
    return http.put(`zhAi/getDrugSafetyData`, reqDto, {
      headers: authHeader(),
    });
  }
}

const zhAIService = new ZhAIService();
export default zhAIService;
