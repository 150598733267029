import { Box, Grid } from "@mui/material";
import React, { useState, useEffect, lazy } from "react";
import "../css/analyticsDashboard.css";
import RightFlotingAnalyticsDashboard from "./RightFlotingAnalyticsDashboard";
import AddPeople from "./component/AddPeople";
import CardPeople from "./component/card/CardPeople";
import ViewPeople from "./component/ViewPeople";
import CardLeaveRules from "./component/card/CardLeaveRules";
import AddLeaveRules from "./component/AddLeaveRules";
import CardBudget from "./component/card/CardBudget";
import AddBudget from "./component/AddBudget";
import CardItems from "./component/card/CardItems";
import CardPolicy from "./component/card/CardPolicy";
import AddPolicies from "./component/AddPolicies";
import ViewPolicies from "./component/ViewPolicies";
import CardRoutineWork from "./component/card/CardRoutineWork";
import ViewRoutineWork from "./component/ViewRoutineWork";
import AddRoutineWork from "./component/AddRoutineWork";
import AddItems from "./component/AddItems";
import ViewItems from "./component/ViewItems";
import ViewLeaveRules from "./component/ViewLeaveRules";
import EditLeaveRules from "./component/EditLeaveRules";
import CardAttendance from "./component/card/CardAttendance";
// import ViewAttendance from "./component/ViewAttendance";
import SelectedViewLeaveRule from "./component/SelectedViewLeaveRule";
import ViewUserAttendance from "./component/ViewUserAttendance";
import CardHolidayWorkingday from "./component/card/CardHolidayWorkingday";
import ViewHolidayWorkingday from "./component/ViewHolidayWorkingday";
import AddHoliday from "./component/AddHoliday";
import AddWorkingDays from "./component/AddWorkingDays";
import EditHoliday from "./component/EditHoliday";
import ViewSelectedHoliday from "./component/ViewSelectedHoliday";
import CardExternalUser from "./component/card/CardExternalUser";
import ViewExternalUser from "./component/ViewExternalUser";
import AddExternalUser from "./component/AddExternalUser";
import EditExternalUser from "./component/EditExternalUser";
import {
  getDashboardDataNew,
  getDashboardDataOfItem,
  getMenusWithModule,
  getUserMenus,
} from "../../../services/AdminService";
import { jwtDecode } from "jwt-decode";
import ViewBudget from "./component/ViewBudget";
import SelectedViewBudget from "./component/SelectedViewBudget";
import CardContract from "./component/card/CardContract";
import AddContract from "./component/AddContract";
import ViewContract from "./component/ViewContract";
import EditPeople from "./component/EditPeople";
import AddShift from "./component/AddShift";
import CardPayroll from "./component/card/CardPayroll";
import ViewPayroll from "./component/ViewPayroll";
import ViewPayrollSalaryStructure from "./component/ViewPayrollSalaryStructure";
import ViewPayrollPayslip from "./component/ViewPayrollPayslip";
import CardRegisterPatient from "./component/healthcare/CardRegisterPatient";
import ViewRegisterPatient from "./component/healthcare/ViewRegisterPatient";
import AddRegisterPatient from "./component/healthcare/AddRegisterPatient";
import { useTranslation } from "react-i18next";
import CardBookConsultation from "./component/healthcare/CardBookConsultation";
import ViewBookConsultation from "./component/healthcare/ViewBookConsultation";
import AddBookConsultation from "./component/healthcare/AddBookConsultation";
import CardPatientDocument from "./component/healthcare/CardPatientDocument";
import ViewPatientDocument from "./component/healthcare/ViewPatientDocument";
import CardBookAppointment from "./component/healthcare/CardBookAppointment";
import ViewBookAppointment from "./component/healthcare/ViewBookAppointment";
import AddAppointmentSettings from "./component/healthcare/AddAppointmentSettings";

import CardDrBookAppointment from "./component/healthcare/CardDrBookAppointment";
import ViewBookAppointmentDrList from "./component/healthcare/ViewBookAppointmentDrList";
import AddAppointmentBooking from "./component/healthcare/AddAppointmentBooking";
import ViewBookedAppointmentConsultation from "./component/healthcare/ViewBookedAppointmentConsultation";
import AddNewAppointBooking from "./component/healthcare/AddNewAppointBooking";
import ViewNewAppointmentBooked from "./component/healthcare/ViewNewAppointmentBooked";
import ResheduleDoctorAppoinment from "./component/healthcare/ResheduleDoctorAppoinment";
import CardReviewReferredPatient from "./component/healthcare/CardReviewReferredPatient";
import ViewReferredPatient from "./component/healthcare/ViewReferredPatient";
import ViewReviewPatient from "./component/healthcare/ViewReviewPatient";
import CardInvestigationAdmin from "./component/healthcare/CardInvestigationAdmin";
import CardInvestigationUnits from "./component/healthcare/CardInvestigationUnits";
import CardAnalyticsAdmin from "./component/healthcare/CardAnalyticsAdmin";
import CardAnalyticsUnits from "./component/healthcare/CardAnalyticsUnits";
import CardDoctorConsultations from "./component/healthcare/CardDoctorConsultations";
import CardDoctorEarnings from "./component/healthcare/CardDoctorEarnings";
import ViewDoctorConsultationList from "./component/healthcare/ViewDoctorConsultationList";
import { AddTestData } from "./component/healthcare/AddTestData";
import AddInvestigationUnits from "./component/healthcare/AddInvestigationUnits";
import DashboardServices from "../../../services/DashboardServices";
import { AddReviewPatientToQueue } from "./component/healthcare/AddReviewPatientToQueue";
import { Conversation } from "./component/healthcare/DoctorPatientConversation";
import ViewInvestigationAdmin from "./component/healthcare/ViewInvestigationAdmin";
import AddInvestigationAdmin from "./component/healthcare/AddInvestigationAdmin";
import AddInvestigationAdminUnit from "./component/healthcare/AddInvestigationAdminUnit";
import AddInvestigationParameter from "./component/healthcare/AddInvestigationParameter";
import { webApp } from "../../../constants/url_provider";
import CardManagementFeedback from "./component/healthcare/CardManagementFeedback";
import CardManagementDisease from "./component/healthcare/CardManagementDisease";
import CardManagementDoctor from "./component/healthcare/CardManagementDoctor";
import CardManagementConsultation from "./component/healthcare/CardManagementConsultation";
import CardManagementTrends from "./component/healthcare/CardManagementTrends";
import CardManagementCenter from "./component/healthcare/CardManagementCenter";
const ViewAttendance = lazy(() => import("./component/ViewAttendance"));

export default function AnalyticsDashboard() {
  const userDetails = jwtDecode(localStorage.getItem("token"));

  const { t } = useTranslation();

  const [chartDataOfItem, setChartDataOfItem] = useState([]);
  const [menuModuleList, setMenuModuleList] = useState(false);
  const [userMenuMap, setUserMenuMap] = useState(null);
  const [userMenuList, setUserMenuList] = useState(false);
  const [dataForpatient, setDataForPatient] = useState({
    patientGenderWiseRegistration: {},
    patientAgeGroupWiseRegistration: {},
    totalPatientRegistered: 0,
    totalPatientRegisteredThisMnth: 0,
    todayHourlyUnitAppointment: [],
    todayDoctorWiseUnitAppointment: [],
    todayUnitAppointmentCount: 0,
    todaySpecilizationWiseUnitConsultation: {},
    dayWiseUnitConsultation: [],
    thisMnthUnitConsultation: 0,
    todayUnitConsultation: 0,
    todayUnitQueue: 0,
  });

  const [reviewConsultationData, setReviewConsultationData] = useState(null);
  const [conversationData, setConversationData] = useState(null);

  useEffect(() => {
    fetchDashBoardData();
    fetchDashBoardDataForPatient();
    fetchrefreshUnitReferReviewData();
    fetchrefreshUnitInvestigationData();
  }, [userDetails.orgId]);

  useEffect(() => {
    fetchMenuData();
  }, []);

  const fetchMenuData = () => {
    getMenusWithModule().then((menusModuleResponse) => {
      setMenuModuleList(menusModuleResponse.data);
      getUserMenus(userDetails.userId).then((userMenuResponse) => {
        const map = new Map();
        userMenuResponse.data.forEach((item) => {
          const foundItem = menusModuleResponse.data.find(
            (element) => element.menuId === item.menuId
          );
          if (!map.has(foundItem.moduleId)) {
            map.set(foundItem.moduleId, [item.menuId]);
          } else {
            map.get(foundItem.moduleId).push(item.menuId);
          }
        });

        // if(map.has())
        setUserMenuMap(map);
        setUserMenuList(userMenuResponse.data);
      });
    });
  };

  // const fetchDashBoardData = () => {
  //   getDashboardDataOfItem(userDetails.orgId, userDetails.userId).then(
  //     (response) => {
  //
  //       setChartDataOfItem(response.data);
  //     }
  //   );
  // };

  const fetchDashBoardData = () => {
    const reqDto = {
      unitId:
        userDetails?.category === "Management" ? 0 : userDetails.signleUnitId,
      orgId: userDetails.orgId,
      userId: userDetails.userId,
      periodDailyMnthly: "Daily",
      dataLimit: 12,
    };
    getDashboardDataNew(reqDto).then((response) => {
      setChartDataOfItem(response.data || []);
    });
  };

  const fetchDashBoardDataForPatient = () => {
    const reqDto = {
      unitId: userDetails.signleUnitId,
      orgId: userDetails.orgId,
      userId: userDetails.userId,
    };
    getDashboardDataNew(reqDto).then((response) => {
      setDataForPatient({
        patientGenderWiseRegistration:
          response.data.patientGenderWiseRegistration,
        patientAgeGroupWiseRegistration:
          response.data.patientAgeGroupWiseRegistration,
        totalPatientRegistered: response.data.totalPatientRegistered,
        totalPatientRegisteredThisMnth:
          response.data.totalPatientRegisteredThisMnth,
        todayHourlyUnitAppointment: response.data.todayHourlyUnitAppointment,
        todayDoctorWiseUnitAppointment:
          response.data.todayDoctorWiseUnitAppointment,
        todayUnitAppointmentCount: response.data.todayUnitAppointmentCount,
        todaySpecilizationWiseUnitConsultation:
          response.data.todaySpecilizationWiseUnitConsultation,
        dayWiseUnitConsultation: response.data.dayWiseUnitConsultation,
        thisMnthUnitConsultation: response.data.thisMnthUnitConsultation,
        todayUnitConsultation: response.data.todayUnitConsultation,
        todayUnitQueue: response.data.todayUnitQueue,
        todayUnitRxMedInvRefCount: response.data.todayUnitRxMedInvRefCount,
        todayUnitDiagonistic: response.data.todayUnitDiagonistic,
        todayDoctorRxMedInvRefCount: response.data.todayDoctorRxMedInvRefCount,
        dayWiseDoctorConsultation: response.data.dayWiseDoctorConsultation,
        docAppointment: response.data.docAppointment,
        todayHourlyDoctorAppointment:
          response.data.todayHourlyDoctorAppointment,
        todayDoctorAppointmentCount: response.data.todayDoctorAppointmentCount,
        todayUnitWiseTestCount: response.data.todayUnitWiseTestCount,
      });
    });
  };

  const [refreshUnitInvestigationData, setrefreshUnitInvestigationData] =
    useState([]);
  const [unitRefreshReferReviewData, setUnitRefreshReferReviewData] = useState(
    []
  );

  const fetchrefreshUnitReferReviewData = () => {
    const reqDto = {
      unitId: userDetails.signleUnitId,
      orgId: userDetails.orgId,
      userId: userDetails.userId,
    };
    DashboardServices.refreshUnitReferReview(reqDto).then((response) => {
      setUnitRefreshReferReviewData(response.data);
    });
  };

  const fetchrefreshUnitInvestigationData = () => {
    const reqDto = {
      unitId: userDetails.signleUnitId,
      orgId: userDetails.orgId,
      userId: userDetails.userId,
    };
    DashboardServices.refreshUnitInvestigation(reqDto).then((response) => {
      setrefreshUnitInvestigationData(response.data);
    });
  };

  const [showAnalyticsDashboard, setAnalyticsDashboard] = useState(true);
  const [showViewPeople, setViewPeople] = useState(false);
  const [showViewPolicies, setViewPolicies] = useState(false);
  const [showAddPeople, setAddPeople] = useState(false);
  const [showAddLeaveRules, setAddLeaveRules] = useState(false);
  const [showViewLeaveRules, setViewLeaveRules] = useState(false);
  const [showAddBudget, setAddBudget] = useState(false);
  const [showViewBudget, setViewBudget] = useState(false);
  // const [showAddPolicies, setAddPolicies] = useState(false);

  const [showViewRoutineWork, setViewRoutineWork] = useState(false);
  const [showAddRoutineWork, setAddRoutineWork] = useState(false);

  const [showViewItems, setViewItems] = useState(false);
  const [showAddItems, setAddItems] = useState(false);

  const [refresh, setRefresh] = useState(false);
  const [editItem, setEditItem] = useState(null);
  const [shouldItemUpdateList, setShouldItemUpdateList] = useState(1);
  const [shouldUpdateQueueList, setShouldUpdateQueueList] = useState(1);

  const [showViewAttendance, setViewAttendance] = useState(false);
  const [viewUserAttendanceData, setShowViewUserAttendanceData] =
    useState(null);

  const [showViewHoliday, setViewHoliday] = useState(false);
  const [showAddHoliday, setAddHoliday] = useState(false);
  const [showAddWorkingday, setAddWorkingday] = useState(false);
  const [showEditHoliday, setEditHoliday] = useState(false);
  const [selecttedHolidayData, setSelectedHolidayData] = useState(null);
  const [refreshHoliday, setRefreshHoliday] = useState(false);
  const [showSelectedViewHoliday, setShowSelectedViewHoliday] = useState(false);
  const [selecttedViewHolidayData, setSelectedViewHolidayData] = useState(null);

  const [showViewExternalUser, setViewExternalUser] = useState(false);
  const [showAddExternalUser, setAddExternalUser] = useState(false);

  const [refreshBudget, setRefreshBudget] = useState(false);
  const [viewSelectedBudget, setViewSelectedBudget] = useState(false);
  const [viewSelectedBudgetData, setViewSelectedBudgetData] = useState(null);
  const [selectedDocId, setSelectedDocId] = useState("");
  const [refreshReschduleDocAppoint, setRefreshReschduleDocAppoint] =
    useState(false);

  const [refreshBookedAppoinments, setRefreshBookedAppoinments] = useState(1);
  const [viewNewAppointMentBookedVal, setViewNewAppointMentBooked] =
    useState(false);

  const refreshData = () => {
    setRefresh((prevRefresh) => !prevRefresh);
  };
  //ExternalUser start
  const [refreshExternalUser, setRefreshExternalUser] = useState(false);

  const refreshExternalUserData = () => {
    setRefreshExternalUser(
      (prevRefreshExternalUser) => !prevRefreshExternalUser
    );
  };

  const handelOpenViewExternalUser = () => {
    setViewExternalUser(true);
    setAnalyticsDashboard(false);
  };
  const handelCloseViewExternalUser = () => {
    setViewExternalUser(false);
    setAnalyticsDashboard(true);
  };

  const handelOpenAddExternalUser = () => {
    setAddExternalUser(true);
  };
  const handelCloseAddExternalUser = () => {
    setAddExternalUser(false);
  };

  const [showSelectedExternalUser, setShowSelectedExternalUser] =
    useState(false);
  const [selecttedViewExternalUser, setSelectedViewExternalUser] =
    useState(null);

  const handelOpenEditExternaluser = (userData) => {
    setSelectedViewExternalUser(userData);
    setShowSelectedExternalUser(true);
  };

  const handelCloseEditExternalUser = () => {
    setShowSelectedExternalUser(false);
    setSelectedViewExternalUser(null);
  };

  //ExternalUser end
  // holiday start

  const refresHolidayData = () => {
    setRefreshHoliday((prevRefreshHoliday) => !prevRefreshHoliday);
  };

  const handelOpenViewHoliday = () => {
    setViewHoliday(true);
    setAnalyticsDashboard(false);
  };
  const handelCloseViewHoliday = () => {
    setViewHoliday(false);
    setAnalyticsDashboard(true);
  };

  const handelOpenAddHoliday = () => {
    setAddHoliday(true);
  };
  const handelCloseAddHoliday = () => {
    setAddHoliday(false);
  };

  const handelOpenEditHoliday = (holidayData) => {
    setSelectedHolidayData(holidayData);
    setEditHoliday(true);
  };

  const handelCloseEditHoliday = () => {
    setEditHoliday(false);
    setSelectedHolidayData(null);
  };

  const handelOpenSelectedviewHoliday = (holidayData) => {
    setSelectedViewHolidayData(holidayData);
    setShowSelectedViewHoliday(true);
  };

  const handelCloseSelectedViewHoliday = () => {
    setShowSelectedViewHoliday(false);
    setSelectedViewHolidayData(null);
  };

  const handelOpenAddWorkingday = () => {
    setAddWorkingday(true);
  };
  const handelCloseAddWorkingday = () => {
    setAddWorkingday(false);
  };

  const [addWorkingShift, setAddWorkingShift] = useState(false);

  const handleOpenAddWorkingShift = () => {
    setAddWorkingShift(true);
  };

  const handleCloseAddWorkingShift = () => {
    setAddWorkingShift(false);
  };
  // holiday end

  // attandance start
  const handelOpenViewAttendance = () => {
    setViewAttendance(true);
    setAnalyticsDashboard(false);
  };
  const handelCloseViewAttendance = () => {
    setViewAttendance(false);
    setAnalyticsDashboard(true);
  };

  const handelOpenViewUserAttend = (data) => {
    setShowViewUserAttendanceData(data);
    setViewAttendance(true);
    setAnalyticsDashboard(false);
  };
  const handelCloseViewUserAttend = () => {
    setShowViewUserAttendanceData(null);
    setViewAttendance(true);
    setAnalyticsDashboard(false);
  };
  // attandance end

  // add items start
  const handelOpenAddItems = () => {
    setAddItems(true);
  };
  const handelCloseAddItems = () => {
    setAddItems(false);
  };

  const handelOpenViewItems = () => {
    setViewItems(true);
    setAnalyticsDashboard(false);
  };
  const handelCloseViewItems = () => {
    setViewItems(false);
    setAnalyticsDashboard(true);
  };
  // add items end
  // recurring task start
  const handelOpenAddRoutineWork = () => {
    setAddRoutineWork(true);
  };
  const handelCloseAddRoutineWork = () => {
    setAddRoutineWork(false);
  };

  const handelOpenViewRoutineWork = () => {
    setViewRoutineWork(true);
    setAnalyticsDashboard(false);
  };
  const handelCloseViewRoutineWork = () => {
    setViewRoutineWork(false);
    setAnalyticsDashboard(true);
  };
  // recurring task end
  // const handelOpenAddPolicies = () => {
  //   setAddPolicies(true);
  // };
  // const handelCloseAddPolicies = () => {
  //   setAddPolicies(false);
  // };

  const handelOpenViewPolicies = () => {
    setViewPolicies(true);
    setAnalyticsDashboard(false);
  };
  const handelCloseViewPolicies = () => {
    setViewPolicies(false);
    setAnalyticsDashboard(true);
  };

  const [refreshViewPeople, setRefreshViewPeople] = useState(false);

  const refreshViewPeopleData = () => {
    setRefreshViewPeople((prevRefreshViewPeople) => !prevRefreshViewPeople);
  };

  const handelOpenViewPeople = () => {
    setViewPeople(true);
    setAnalyticsDashboard(false);
  };
  const handelCloseViewPeople = () => {
    setViewPeople(false);
    setAnalyticsDashboard(true);
  };

  const handelOpenAddPeople = () => {
    setAddPeople(true);
  };
  const handelCloseAddPeople = () => {
    setAddPeople(false);
  };

  const [editEmployee, setEditEmployee] = useState(false);
  const [editEmployeesData, setEditEmployeesData] = useState(null);

  const handelOpenEditEmployee = (userData) => {
    setEditEmployeesData(userData);
    setEditEmployee(true);
  };

  const handelCloseEditEmployee = () => {
    setEditEmployee(false);
    setEditEmployeesData(null);
  };

  const [refreshAddLeaveRules, setRefreshAddLeaveRules] = useState(false);

  const refreshAddLeaveRulesData = () => {
    setRefreshAddLeaveRules(
      (prevRefreshAddLeaveRules) => !prevRefreshAddLeaveRules
    );
  };

  const handelOpenAddLeaveRules = () => {
    setAddLeaveRules(true);
  };
  const handelCloseAddLeaveRules = () => {
    setAddLeaveRules(false);
  };

  const [updateLeaveRule, setUpdateLeaverule] = useState(false);
  const [selectedUpdateLeaveRule, setSelectedUpdateLeaveRule] = useState(null);

  const handleCloseUpdateLeaveRule = () => {
    setUpdateLeaverule(false);
    setSelectedUpdateLeaveRule(null);
  };
  const handleOpenUpdateLeaveRule = (userData) => {
    setSelectedUpdateLeaveRule(userData);
    setUpdateLeaverule(true);
  };

  const [viewSelectedLeaveRule, setViewSelectedLeaveRule] = useState(false);
  const [viewSelectedLeaveRuleData, setViewSelectedLeaveRuleData] =
    useState(null);

  const handleCloseViewSelectedLeaveRule = () => {
    setViewSelectedLeaveRule(false);
    setViewSelectedLeaveRuleData(null);
  };
  const handleOpenViewSelectedLeaveRule = (userData) => {
    setViewSelectedLeaveRuleData(userData);
    setViewSelectedLeaveRule(true);
  };

  const handelOpenViewLeaveRules = () => {
    setViewLeaveRules(true);
    setAnalyticsDashboard(false);
  };
  const handelCloseViewLeaveRules = () => {
    setViewLeaveRules(false);
    setAnalyticsDashboard(true);
  };

  const handleCloseViewSelectedBudget = () => {
    setViewSelectedBudget(false);
    setViewSelectedBudgetData(null);
  };
  const handleOpenViewSelectedBudget = (userData) => {
    setViewSelectedBudgetData(userData);
    setViewSelectedBudget(true);
  };

  const refreshBudgetData = () => {
    setRefreshBudget((prevRefreshBudget) => !prevRefreshBudget);
  };

  const handelOpenAddBudget = () => {
    setAddBudget(true);
  };
  const handelCloseAddBudget = () => {
    setAddBudget(false);
  };

  const handelOpenViewBudget = () => {
    setViewBudget(true);
    setAnalyticsDashboard(false);
  };
  const handelCloseViewBudget = () => {
    setViewBudget(false);
    setAnalyticsDashboard(true);
  };

  const [showViewContract, setViewContract] = useState(false);
  const [showAddContract, setAddContract] = useState(false);

  const [showViewPayroll, setViewPayroll] = useState(false);
  const [showDetailPayroll, setDetailPayroll] = useState(false);
  const [showPayrollPayslip, setPayrollPayslip] = useState(false);
  const [selectedEmPayroll, setSelectedEmPayroll] = useState(null);

  const [refreshContract, setRefreshContract] = useState(false);

  const refreshContractData = () => {
    setRefreshContract((prevRefreshContract) => !prevRefreshContract);
  };

  const handelOpenViewPayroll = () => {
    setViewPayroll(true);
    setAnalyticsDashboard(false);
  };
  const handelCloseViewPayroll = () => {
    setViewPayroll(false);
    setAnalyticsDashboard(true);
  };

  const handelOpenDetailPayroll = (data) => {
    setDetailPayroll(true);
    setSelectedEmPayroll(data);
  };
  const handelCloseDetailPayroll = () => {
    setDetailPayroll(false);
    setSelectedEmPayroll(null);
  };
  const handelOpenPayrollPayslip = () => {
    setPayrollPayslip(true);
  };
  const handelClosePayrollPayslip = () => {
    setPayrollPayslip(false);
  };

  const handelOpenViewContract = () => {
    setViewContract(true);
    setAnalyticsDashboard(false);
  };
  const handelCloseViewContract = () => {
    setViewContract(false);
    setAnalyticsDashboard(true);
  };

  const handelOpenAddContract = () => {
    setAddContract(true);
  };
  const handelCloseAddContract = () => {
    setAddContract(false);
  };

  const closeAll = () => {
    setAddPeople(false);
    setAddLeaveRules(false);
    setUpdateLeaverule(false);
    setViewSelectedLeaveRule(false);
    setAddBudget(false);
    setAddItems(false);
    setAddRoutineWork(false);
    setAddHoliday(false);
    setEditHoliday(false);
    setShowSelectedViewHoliday(false);
    setAddWorkingday(false);
    setAddWorkingShift(false);
    setAddExternalUser(false);
    setShowSelectedExternalUser(false);
    setAddContract(false);
    setEditEmployee(false);
    setDetailPayroll(false);
  };

  const [refreshEmployPayroll, setRefreshEmployPayroll] = useState(false);

  const refreshEmployPayrollData = () => {
    setRefreshEmployPayroll(
      (prevRefreshEmployPayroll) => !prevRefreshEmployPayroll
    );
  };
  // healthcare coponent start ///////////////////////////////////////////////////////////
  const [viewRegisterPatient, setViewRegisterPatient] = useState(false);
  const [addRegisterPatient, setAddRegisterPatient] = useState(false);
  const [addAppointmentSettings, setAddAppointmentSettings] = useState(false);
  const [viewBookConsultation, setViewBookConsultation] = useState(false);
  const [viewPatientDocument, setViewPatientDocument] = useState(false);
  const [addBookConsultation, setAddBookConsultation] = useState(false);
  const [viewBookAppointment, setViewBookAppointment] = useState(false);
  const [viewBookAppointmentDrList, setViewBookAppointmentDrList] =
    useState(false);
  const [addAppointmentBooking, setAddAppointmentBooking] = useState(false);
  const [appoinmentData, setAppoinmentData] = useState(null);
  const [
    viewBookedAppointmentConsultation,
    setViewBookedAppointmentConsultation,
  ] = useState(false);
  const [openAddTestData, setOpenAddTestData] = useState(false);
  const [consultationData, setConsultationData] = useState();
  const handleOpenAddTestResult = (data) => {
    setOpenAddTestData(true);
    setConsultationData(data);
  };
  const handleCloseAddTestResult = () => {
    setOpenAddTestData(false);
  };
  const handelOpenViewRegisterPatient = () => {
    setViewRegisterPatient(true);
    setAnalyticsDashboard(false);
  };
  const handelCloseViewRegisterPatient = () => {
    setViewRegisterPatient(false);
    setAnalyticsDashboard(true);
  };

  const handelOpenViewBookAppointment = () => {
    setViewBookAppointment(true);
    setAnalyticsDashboard(false);
  };
  const handelCloseViewBookAppointment = () => {
    setViewBookAppointment(false);
    setAnalyticsDashboard(false);
    setViewBookAppointmentDrList(true);
  };

  const handelCloseAllViewBookAppointment = () => {
    setViewBookAppointment(false);
    setViewBookAppointmentDrList(false);
    setAnalyticsDashboard(true);
  };

  const handelOpenViewBookAppointmentDrList = () => {
    setViewBookAppointmentDrList(true);
    setAnalyticsDashboard(false);
  };

  const handelCloseViewBookAppointmentDrList = () => {
    setViewBookAppointmentDrList(false);
    setAnalyticsDashboard(true);
  };

  const handelOpenViewBookedAppointmentConsultation = () => {
    setViewBookedAppointmentConsultation(true);
    setAnalyticsDashboard(false);
  };
  const handelCloseViewBookedAppointmentConsultation = () => {
    setViewBookedAppointmentConsultation(false);
    setAnalyticsDashboard(true);
  };

  const handelOpenAddRegisterPatient = () => {
    setAddRegisterPatient(true);
  };
  const handelCloseAddRegisterPatient = () => {
    setAddRegisterPatient(false);
  };
  const handelOpenAppointmentSettings = () => {
    setAddAppointmentSettings(true);
  };
  const handelCloseAppointmentSettings = () => {
    setAddAppointmentSettings(false);
  };

  const [dataForAppointBook, setDataForAppointBook] = useState(null);
  const [addAppointmBook, setAddAppointBook] = useState(false);

  const [refreshbookAppointMent, setRefreshBookAppointment] = useState(false);
  const [viewReferredPatient, setViewReferredPatient] = useState(false);
  const [viewReviewPatient, setViewReviewPatient] = useState(false);
  const [investigationUnits, setInvestigationUnits] = useState(false);
  const [viewInvestigationAdmin, setViewInvestigationAdmin] = useState(false);
  const [addInvestigationAdmin, setAddInvestigationAdmin] = useState(false);
  const [addInvestigationParameter, setAddInvestigationParameter] =
    useState(false);

  const refreshBookAppointmentChange = () => {
    setRefreshBookAppointment(
      (prevRefreshbookAppointment) => !prevRefreshbookAppointment
    );
  };

  const handelOpenAppointmentBooking = () => {
    setAddAppointmentBooking(true);
  };
  const handelCloseAppointmentBooking = () => {
    setAddAppointmentBooking(false);
  };

  const [bookAppointClass, setBookAppointClass] = useState(false);

  const handelOpenAppointBook = (data) => {
    setAddAppointBook(true);
    setDataForAppointBook(data);
    setBookAppointClass(true);
  };

  const handelCloseAppointBooking = () => {
    setAddAppointBook(false);
    setDataForAppointBook(null);
    setBookAppointClass(false);
  };

  const handelOpenViewBookConsultation = () => {
    setViewBookConsultation(true);
    setAnalyticsDashboard(false);
  };
  const handelOpenViewPatientDocument = () => {
    setViewPatientDocument(true);
    setAnalyticsDashboard(false);
  };
  const handelCloseViewPatientDocument = () => {
    setViewPatientDocument(false);
    setAnalyticsDashboard(true);
  };
  const handelCloseViewBookConsultation = () => {
    setViewBookConsultation(false);
    setAnalyticsDashboard(true);
  };

  const handelOpenAddBookConsultation = () => {
    setAddBookConsultation(true);
  };
  const handelCloseAddBookConsultation = () => {
    setAddBookConsultation(false);
  };

  const openViewFullSlot = (data) => {
    setViewBookAppointmentDrList(false);
    setViewBookAppointment(true);
    setSelectedDocId(data);
  };

  const handleOpenViewNewAppointMentBooked = () => {
    setViewNewAppointMentBooked(true);
    setAnalyticsDashboard(false);
  };

  const handleCloseViewNewAppointMentBooked = () => {
    setViewNewAppointMentBooked(false);
    setAnalyticsDashboard(true);
  };

  const handelOpenAddInvestigationUnit = () => {
    setInvestigationUnits(true);
  };

  const handelCloseAddInvestigationUnit = () => {
    setInvestigationUnits(false);
  };

  const handleOpenViewInvestigationAdmin = () => {
    setViewInvestigationAdmin(true);
    setAnalyticsDashboard(false);
  };

  const handleCloseViewInvestigationAdmin = () => {
    setViewInvestigationAdmin(false);
    setAnalyticsDashboard(true);
  };

  const handelOpenAddInvestigationAdmin = () => {
    setAddInvestigationAdmin(true);
  };

  const handelCloseAddInvestigationAdmin = () => {
    setAddInvestigationAdmin(false);
  };

  const [resheduleDrAppointment, setResheduleDrAppointment] = useState(false);
  const [resheduleDrAppointmentData, setResheduleDrAppointmentData] =
    useState(null);
  const [selectSlot, setSelectSlot] = useState(true);

  const handleOpenResheduleDrAppointment = (data) => {
    setResheduleDrAppointment(true);
    setResheduleDrAppointmentData(data);
    setSelectSlot(true);
  };

  const handleCloseResheduleDrAppointment = () => {
    setResheduleDrAppointment(false);
    setResheduleDrAppointmentData(null);
    setSelectSlot(false);
  };

  const handleOpenViewReferredPatient = () => {
    setViewReferredPatient(true);
    setAnalyticsDashboard(false);
  };
  const handleCloseViewReferredPatient = () => {
    setViewReferredPatient(false);
    setAnalyticsDashboard(true);
  };

  const handelOpenViewReviewPatient = () => {
    setViewReviewPatient(true);
    setAnalyticsDashboard(false);
  };
  const handelCloseViewReviewPatient = () => {
    setViewReviewPatient(false);
    setAnalyticsDashboard(true);
  };

  const refresReschduleDocAppointData = () => {
    setRefreshReschduleDocAppoint(
      (prevRefreshReschduleDocAppoint) => !prevRefreshReschduleDocAppoint
    );
  };

  const [viewDoctorConsultList, setViewDoctorConsultList] = useState(false);

  const handleOpenViewDoctorConsultList = () => {
    setViewDoctorConsultList(true);
    setAnalyticsDashboard(false);
  };
  const handleCloseViewDoctorConsultList = () => {
    setViewDoctorConsultList(false);
    setAnalyticsDashboard(true);
  };

  const [addUnitInvestigationAdmin, setUnitInvestigationAdmin] =
    useState(false);
  const [addUnitInvestigationAdminData, setUnitInvestigationAdminData] =
    useState(null);

  const handleOpenUnitInvestigationAdmin = (data) => {
    setUnitInvestigationAdmin(true);
    setUnitInvestigationAdminData(data);
  };
  const handleCloseUnitInvestigationAdmin = () => {
    setUnitInvestigationAdmin(false);
    setUnitInvestigationAdminData(null);
  };
  const handelCloseAddInvestigationParameter = () => {
    setAddInvestigationParameter(false);
  };
  const handelOpenAddInvestigationParameter = () => {
    setAddInvestigationParameter(true);
  };

  const [registeredPatient, setRegisteredPatient] = useState(null);
  // healthcare coponent end /////////////////////////////////////////////////////////////
  return (
    <>
      <Box
        className="AnalyticsDashboardContainerArea"
        sx={{ bgcolor: "dark.pageBg" }}
      >
        <Grid container spacing={0} className="AnalyticsDashboardContainArea">
          <Grid item xs={12} className="pRelative">
            {showAnalyticsDashboard && (
              <div className="AnalyticsDashboardContainer">
                <div className="AnalyticsDashboardHeader">{t("dashboard")}</div>
                <div className="anDashCardContainArea">
                  {userMenuMap !== null && userMenuMap.size === 0 ? (
                    <div class="noDataCard ">
                      <span>{t("oops")}</span>
                      <br />
                      <span className="sallMsg">
                        {t("permission_for_dashboard_modules")}
                      </span>
                    </div>
                  ) : (
                    ""
                  )}
                  {userMenuMap && userMenuMap.has(1) && (
                    <CardPeople
                      handelOpenAddPeople={handelOpenAddPeople}
                      handelOpenViewPeople={handelOpenViewPeople}
                      chartDataOfItem={chartDataOfItem}
                      menuModuleList={menuModuleList}
                      userMenuList={userMenuMap.get(1)}
                    />
                  )}
                  {userMenuMap && userMenuMap.has(2) && (
                    <CardLeaveRules
                      handelOpenAddLeaveRules={handelOpenAddLeaveRules}
                      handelOpenViewLeaveRules={handelOpenViewLeaveRules}
                      chartDataOfItem={chartDataOfItem}
                      menuModuleList={menuModuleList}
                      userMenuList={userMenuMap.get(2)}
                    />
                  )}
                  {userMenuMap && userMenuMap.has(3) && (
                    <CardBudget
                      handelOpenAddBudget={handelOpenAddBudget}
                      handelOpenViewBudget={handelOpenViewBudget}
                      chartDataOfItem={chartDataOfItem}
                      menuModuleList={menuModuleList}
                      userMenuList={userMenuMap.get(3)}
                    />
                  )}
                  {userMenuMap && userMenuMap.has(4) && (
                    <CardItems
                      handelOpenAddItems={handelOpenAddItems}
                      handelOpenViewItems={handelOpenViewItems}
                      chartDataOfItem={chartDataOfItem}
                      menuModuleList={menuModuleList}
                      userMenuList={userMenuMap.get(4)}
                    />
                  )}
                  {/* 
                  <CardPolicy
                    handelOpenAddPolicies={handelOpenAddPolicies}
                    handelOpenViewPolicies={handelOpenViewPolicies}
                  /> */}
                  {userMenuMap && userMenuMap.has(5) && (
                    <CardRoutineWork
                      handelOpenAddRoutineWork={handelOpenAddRoutineWork}
                      handelOpenViewRoutineWork={handelOpenViewRoutineWork}
                      chartDataOfItem={chartDataOfItem}
                      menuModuleList={menuModuleList}
                      userMenuList={userMenuMap.get(5)}
                    />
                  )}
                  {userMenuMap && userMenuMap.has(6) && (
                    <CardAttendance
                      handelOpenViewAttendance={handelOpenViewAttendance}
                      chartDataOfItem={chartDataOfItem}
                      menuModuleList={menuModuleList}
                      userMenuList={userMenuMap.get(6)}
                    />
                  )}
                  {userMenuMap && userMenuMap.has(7) && (
                    <CardHolidayWorkingday
                      handelOpenViewHoliday={handelOpenViewHoliday}
                      handelOpenAddHoliday={handelOpenAddHoliday}
                      chartDataOfItem={chartDataOfItem}
                      menuModuleList={menuModuleList}
                      userMenuList={userMenuMap.get(7)}
                    />
                  )}
                  {userMenuMap && userMenuMap.has(8) && (
                    <CardExternalUser
                      handelOpenViewExternalUser={handelOpenViewExternalUser}
                      handelOpenAddExternalUser={handelOpenAddExternalUser}
                      chartDataOfItem={chartDataOfItem}
                      menuModuleList={menuModuleList}
                      userMenuList={userMenuMap.get(8)}
                    />
                  )}
                  {userMenuMap && userMenuMap.has(9) && (
                    <CardContract
                      handelOpenViewContract={handelOpenViewContract}
                      handelOpenAddContract={handelOpenAddContract}
                      chartDataOfItem={chartDataOfItem}
                      menuModuleList={menuModuleList}
                      userMenuList={userMenuMap.get(9)}
                    />
                  )}
                  {userMenuMap &&
                    userMenuMap.has(10) &&
                    webApp !== "zoyel.health" && (
                      <CardPayroll
                        handelOpenViewPayroll={handelOpenViewPayroll}
                        chartDataOfItem={chartDataOfItem}
                        menuModuleList={menuModuleList}
                        userMenuList={userMenuMap.get(10)}
                      />
                    )}
                  {/* dr dashaboard start */}
                  {/* {commented by Prashant for deploying in live server} */}
                  {userMenuMap && userMenuMap.has(21) && (
                    <CardRegisterPatient
                      handelOpenViewRegisterPatient={
                        handelOpenViewRegisterPatient
                      }
                      handelOpenAddRegisterPatient={
                        handelOpenAddRegisterPatient
                      }
                      chartDataOfItem={dataForpatient}
                      fetchDashBoardData={fetchDashBoardDataForPatient}
                    />
                  )}
                  {userMenuMap && userMenuMap.has(22) && (
                    <CardBookConsultation
                      handelOpenViewBookConsultation={
                        handelOpenViewBookConsultation
                      }
                      handelOpenAddBookConsultation={
                        handelOpenAddBookConsultation
                      }
                      chartDataOfItem={dataForpatient}
                      fetchDashBoardData={fetchDashBoardDataForPatient}
                    />
                  )}
                  {userMenuMap && userMenuMap.has(23) && (
                    <CardPatientDocument
                      handelOpenViewPatientDocument={
                        handelOpenViewPatientDocument
                      }
                      chartDataOfItem={dataForpatient}
                      fetchDashBoardData={fetchDashBoardDataForPatient}
                    />
                  )}
                  {userMenuMap && userMenuMap.has(24) && (
                    <CardBookAppointment
                      handelOpenViewBookedAppointmentConsultation={
                        handelOpenViewBookedAppointmentConsultation
                      }
                      handelOpenViewBookAppointmentDrList={
                        handelOpenViewBookAppointmentDrList
                      }
                      chartDataOfItem={dataForpatient}
                      fetchDashBoardData={fetchDashBoardDataForPatient}
                    />
                  )}
                  {userMenuMap && userMenuMap.has(26) && (
                    <CardDrBookAppointment
                      handleOpenViewNewAppointMentBooked={
                        handleOpenViewNewAppointMentBooked
                      }
                      handelOpenAppointmentSettings={
                        handelOpenAppointmentSettings
                      }
                      chartDataOfItem={dataForpatient}
                      fetchDashBoardData={fetchDashBoardDataForPatient}
                    />
                  )}
                  {userMenuMap && userMenuMap.has(27) && (
                    <CardReviewReferredPatient
                      handleOpenViewReferredPatient={
                        handleOpenViewReferredPatient
                      }
                      handelOpenViewReviewPatient={handelOpenViewReviewPatient}
                      chartDataOfItem={unitRefreshReferReviewData}
                      fetchDashBoardData={fetchrefreshUnitReferReviewData}
                    />
                  )}
                  {userMenuMap && userMenuMap.has(28) && (
                    <CardInvestigationUnits
                      handelOpenAddInvestigationUnit={
                        handelOpenAddInvestigationUnit
                      }
                      chartDataOfItem={refreshUnitInvestigationData}
                      fetchDashBoardData={fetchrefreshUnitInvestigationData}
                    />
                  )}
                  {userMenuMap && userMenuMap.has(29) && (
                    <CardInvestigationAdmin
                      handleOpenViewInvestigationAdmin={
                        handleOpenViewInvestigationAdmin
                      }
                      chartDataOfItem={dataForpatient}
                      fetchDashBoardData={fetchDashBoardDataForPatient}
                    />
                  )}

                  {userMenuMap && userMenuMap.has(30) && (
                    <CardDoctorConsultations
                      handleOpenViewDoctorConsultList={
                        handleOpenViewDoctorConsultList
                      }
                      chartDataOfItem={dataForpatient}
                      fetchDashBoardData={fetchDashBoardDataForPatient}
                    />
                  )}
                  {/* <CardAnalyticsAdmin />
                  <CardAnalyticsUnits />
                  <CardDoctorEarnings /> */}
                  {/* , userMenuMap.get(31).includes(53))} */}
                  {userMenuMap &&
                    userMenuMap.get(31) &&
                    userMenuMap.get(31).includes(53) && (
                      <CardManagementConsultation
                        chartDataOfItem={chartDataOfItem}
                      />
                    )}
                  {userMenuMap &&
                    userMenuMap.get(31) &&
                    userMenuMap.get(31).includes(58) && (
                      <CardManagementFeedback />
                    )}
                  {userMenuMap &&
                    userMenuMap.get(31) &&
                    userMenuMap.get(31).includes(55) && (
                      <CardManagementTrends />
                    )}
                  {userMenuMap &&
                    userMenuMap.get(31) &&
                    userMenuMap.get(31).includes(54) && (
                      <CardManagementDoctor />
                    )}
                  {userMenuMap &&
                    userMenuMap.get(31) &&
                    userMenuMap.get(31).includes(57) && (
                      <CardManagementDisease />
                    )}
                  {userMenuMap &&
                    userMenuMap.get(31) &&
                    userMenuMap.get(31).includes(56) && (
                      <CardManagementCenter />
                    )}
                  {/* dr dashaboard end */}
                </div>
              </div>
            )}
            {showViewPayroll && (
              <ViewPayroll
                handelOpenDetailPayroll={handelOpenDetailPayroll}
                handelOpenPayrollPayslip={handelOpenPayrollPayslip}
                handelCloseViewPayroll={handelCloseViewPayroll}
                closeAll={closeAll}
                refreshEmployPayroll={refreshEmployPayroll}
              />
            )}
            {showViewPeople && (
              <ViewPeople
                handelCloseViewPeople={handelCloseViewPeople}
                handelOpenAddPeople={handelOpenAddPeople}
                refreshViewPeople={refreshViewPeople}
                handelOpenEditEmployee={handelOpenEditEmployee}
                closeAll={closeAll}
              />
            )}
            {showViewBudget && (
              <ViewBudget
                handelCloseViewBudget={handelCloseViewBudget}
                handelOpenAddBudget={handelOpenAddBudget}
                refreshBudget={refreshBudget}
                handleOpenViewSelectedBudget={handleOpenViewSelectedBudget}
                closeAll={closeAll}
              />
            )}
            {viewSelectedBudget && (
              <RightFlotingAnalyticsDashboard>
                <SelectedViewBudget
                  refreshAddLeaveRulesData={refreshAddLeaveRulesData}
                  handleCloseViewSelectedBudget={handleCloseViewSelectedBudget}
                  viewSelectedBudgetData={viewSelectedBudgetData}
                />
              </RightFlotingAnalyticsDashboard>
            )}
            {showViewAttendance && (
              <ViewAttendance
                handelCloseViewAttendance={handelCloseViewAttendance}
                handelOpenViewUserAttend={handelOpenViewUserAttend}
              />
            )}
            {showViewLeaveRules && (
              <ViewLeaveRules
                handelOpenAddLeaveRules={handelOpenAddLeaveRules}
                handelCloseViewLeaveRules={handelCloseViewLeaveRules}
                refreshAddLeaveRules={refreshAddLeaveRules}
                handleOpenUpdateLeaveRule={handleOpenUpdateLeaveRule}
                handleOpenViewSelectedLeaveRule={
                  handleOpenViewSelectedLeaveRule
                }
                closeAll={closeAll}
              />
            )}
            {showViewItems && (
              <ViewItems
                handelOpenAddItems={handelOpenAddItems}
                handelCloseViewItems={handelCloseViewItems}
                setEditItem={setEditItem}
                shouldUpdateItemList={shouldItemUpdateList}
                closeAll={closeAll}
              />
            )}
            {/* {showViewPolicies && (
              <ViewPolicies
                handelCloseViewPolicies={handelCloseViewPolicies}
                handelOpenAddPolicies={handelOpenAddPolicies}
                refresh={refresh}
              />
            )} */}
            {showViewRoutineWork && (
              <ViewRoutineWork
                handelCloseViewRoutineWork={handelCloseViewRoutineWork}
                handelOpenAddRoutineWork={handelOpenAddRoutineWork}
                closeAll={closeAll}
              />
            )}
            {showViewHoliday && (
              <ViewHolidayWorkingday
                handelCloseViewHoliday={handelCloseViewHoliday}
                handelOpenAddHoliday={handelOpenAddHoliday}
                handelOpenAddWorkingday={handelOpenAddWorkingday}
                refreshHoliday={refreshHoliday}
                handelOpenEditHoliday={handelOpenEditHoliday}
                handelOpenSelectedviewHoliday={handelOpenSelectedviewHoliday}
                handleOpenAddWorkingShift={handleOpenAddWorkingShift}
                closeAll={closeAll}
              />
            )}
            {showViewExternalUser && (
              <ViewExternalUser
                handelCloseViewExternalUser={handelCloseViewExternalUser}
                handelOpenAddExternalUser={handelOpenAddExternalUser}
                refreshExternalUser={refreshExternalUser}
                handelOpenEditExternaluser={handelOpenEditExternaluser}
                closeAll={closeAll}
              />
            )}
            {showViewContract && (
              <ViewContract
                handelCloseViewContract={handelCloseViewContract}
                handelOpenAddContract={handelOpenAddContract}
                refreshContract={refreshContract}
                closeAll={closeAll}
              />
            )}
            {/* healthcare start /////////////////////////////////////////////////////////////////////////////// */}
            {/* {viewRegisterPatient && (
              <ViewRegisterPatient
                handelCloseViewRegisterPatient={handelCloseViewRegisterPatient}
                handelOpenAddRegisterPatient={handelOpenAddRegisterPatient}
                closeAll={closeAll}
              />
            )} */}
            {viewBookConsultation && (
              <ViewBookConsultation
                handelCloseViewBookConsultation={
                  handelCloseViewBookConsultation
                }
                handelOpenAddBookConsultation={handelOpenAddBookConsultation}
                handleOpenAddTestResult={handleOpenAddTestResult}
                closeAll={closeAll}
                shouldUpdateQueueList={shouldUpdateQueueList}
                openConversation={(data) => {
                  setConversationData(data);
                }}
              />
            )}
            {viewPatientDocument && (
              <ViewPatientDocument
                handelCloseViewPatientDocument={handelCloseViewPatientDocument}
                closeAll={closeAll}
              />
            )}
            {viewBookAppointment && (
              <ViewBookAppointment
                handelCloseViewBookAppointment={handelCloseViewBookAppointment}
                closeAll={closeAll}
                handelOpenAppointmentBooking={handelOpenAppointBook}
                selectedDocId={selectedDocId}
                refreshbookAppointMent={refreshbookAppointMent}
                bookAppointClass={bookAppointClass}
              />
            )}
            {openAddTestData && (
              <RightFlotingAnalyticsDashboard>
                <AddTestData
                  handelCloseAddTestData={handleCloseAddTestResult}
                  consultationData={consultationData}
                />
              </RightFlotingAnalyticsDashboard>
            )}
            {viewNewAppointMentBookedVal && (
              <ViewNewAppointmentBooked
                handleCloseViewNewAppointMentBooked={
                  handleCloseViewNewAppointMentBooked
                }
                closeAll={closeAll}
                handleOpenResheduleDrAppointment={
                  handleOpenResheduleDrAppointment
                }
                refreshReschduleDocAppoint={refreshReschduleDocAppoint}
                selectSlot={selectSlot}
              />
            )}

            {viewDoctorConsultList && (
              <ViewDoctorConsultationList
                handleCloseViewDoctorConsultList={
                  handleCloseViewDoctorConsultList
                }
              />
            )}

            {addRegisterPatient && (
              <RightFlotingAnalyticsDashboard>
                <AddRegisterPatient
                  handelCloseAddRegisterPatient={handelCloseAddRegisterPatient}
                  setSelectedPatientData={(patientData, bookingType) => {
                    if (bookingType === "bookConsultation") {
                      if (patientData.rowPatientId > 0) {
                        setRegisteredPatient(patientData);
                        handelOpenAddBookConsultation();
                        handelCloseAddRegisterPatient();
                      }
                    } else if (bookingType === "bookAppointment") {
                      if (patientData.rowPatientId > 0) {
                        setRegisteredPatient(patientData);
                        handelOpenViewBookAppointmentDrList();
                        handelCloseAddRegisterPatient();
                      }
                    } else if (bookingType === "bookInvestigation") {
                      if (patientData.rowPatientId > 0) {
                        setRegisteredPatient(patientData);
                        handelOpenAddInvestigationUnit();
                        handelCloseAddRegisterPatient();
                      }
                    }
                  }}
                  fetchDashBoardData={fetchDashBoardData}
                />
              </RightFlotingAnalyticsDashboard>
            )}
            {addBookConsultation && (
              <RightFlotingAnalyticsDashboard>
                <AddBookConsultation
                  handelCloseAddBookConsultation={() => {
                    handelCloseAddBookConsultation();
                    setRegisteredPatient(null);
                  }}
                  registeredPatient={registeredPatient}
                  closeBookConsultation={() => {
                    setAddBookConsultation(false);
                    // setViewBookConsultation(true);
                    handelOpenViewBookConsultation();
                    setShouldUpdateQueueList((prev) => prev + 1);
                  }}
                />
              </RightFlotingAnalyticsDashboard>
            )}
            {addAppointmentSettings && (
              <RightFlotingAnalyticsDashboard>
                <AddAppointmentSettings
                  handelCloseAppointmentSettings={
                    handelCloseAppointmentSettings
                  }
                />
              </RightFlotingAnalyticsDashboard>
            )}
            {viewBookAppointmentDrList && (
              <ViewBookAppointmentDrList
                handelCloseViewBookAppointmentDrList={() => {
                  handelCloseViewBookAppointmentDrList();
                  setRegisteredPatient(null);
                }}
                closeAll={closeAll}
                // openViewFullSlot={() => {
                //   setViewBookAppointmentDrList(false);
                //   setViewBookAppointment(true);
                // }}
                openViewFullSlot={openViewFullSlot}
                handelOpenAppointmentBooking={handelOpenAppointBook}
                refreshbookAppointMent={refreshbookAppointMent}
                bookAppointClass={bookAppointClass}
                registeredPatient={registeredPatient}
              />
            )}
            {viewBookedAppointmentConsultation && (
              <ViewBookedAppointmentConsultation
                handelCloseViewBookedAppointmentConsultation={
                  handelCloseViewBookedAppointmentConsultation
                }
                closeAll={closeAll}
                openAppoinmentBooking={(data) => {
                  setAddAppointmentBooking(true);
                  setAppoinmentData(data);
                }}
                refreshBookedAppoinments={refreshBookedAppoinments}
              />
            )}
            {addAppointmentBooking && (
              <RightFlotingAnalyticsDashboard>
                <AddAppointmentBooking
                  handelCloseAppointmentBooking={handelCloseAppointmentBooking}
                  appoinmentData={appoinmentData}
                  updateBookAppoinments={() => {
                    setRefreshBookedAppoinments((prev) => prev + 1);
                  }}
                  closeBookConsultation={() => {
                    // setAddBookConsultation(false);
                    handelCloseAppointmentBooking();
                    setViewBookConsultation(true);
                    setShouldUpdateQueueList((prev) => prev + 1);
                  }}
                />
              </RightFlotingAnalyticsDashboard>
            )}
            {addAppointmBook && (
              <RightFlotingAnalyticsDashboard>
                <AddNewAppointBooking
                  handelCloseAppointmentBooking={handelCloseAppointBooking}
                  appoinmentData={dataForAppointBook}
                  refreshBookAppointmentChange={refreshBookAppointmentChange}
                  handelCloseAllViewBookAppointment={
                    handelCloseAllViewBookAppointment
                  }
                />
              </RightFlotingAnalyticsDashboard>
            )}
            {viewReferredPatient && (
              <ViewReferredPatient
                handleCloseViewReferredPatient={handleCloseViewReferredPatient}
                closeAll={closeAll}
              />
            )}

            {resheduleDrAppointment && (
              <RightFlotingAnalyticsDashboard>
                <ResheduleDoctorAppoinment
                  handleCloseResheduleDrAppointment={
                    handleCloseResheduleDrAppointment
                  }
                  appoinmentData={resheduleDrAppointmentData}
                  refresReschduleDocAppointData={refresReschduleDocAppointData}
                />
              </RightFlotingAnalyticsDashboard>
            )}

            {viewReviewPatient && (
              <ViewReviewPatient
                handelCloseViewReviewPatient={handelCloseViewReviewPatient}
                closeAll={closeAll}
                handleBookConsultationData={(data) => {
                  setReviewConsultationData(data);
                }}
              />
            )}

            {reviewConsultationData && (
              <RightFlotingAnalyticsDashboard>
                <AddReviewPatientToQueue
                  data={reviewConsultationData}
                  handleCloseAddReviewPatientToQueue={() => {
                    setReviewConsultationData(null);
                  }}
                  closeAll={closeAll}
                />
              </RightFlotingAnalyticsDashboard>
            )}

            {conversationData && (
              <RightFlotingAnalyticsDashboard>
                <Conversation
                  data={conversationData}
                  handleCloseConversation={() => {
                    setConversationData(null);
                  }}
                  closeAll={closeAll}
                />
              </RightFlotingAnalyticsDashboard>
            )}

            {investigationUnits && (
              <RightFlotingAnalyticsDashboard>
                <AddInvestigationUnits
                  registeredPatient={registeredPatient}
                  handelCloseAddInvestigationUnit={() => {
                    handelCloseAddInvestigationUnit();
                    setRegisteredPatient(null);
                  }}
                />
              </RightFlotingAnalyticsDashboard>
            )}

            {viewInvestigationAdmin && (
              <ViewInvestigationAdmin
                handleCloseViewInvestigationAdmin={
                  handleCloseViewInvestigationAdmin
                }
                closeAll={closeAll}
                handelOpenAddInvestigationAdmin={
                  handelOpenAddInvestigationAdmin
                }
                handleOpenUnitInvestigationAdmin={
                  handleOpenUnitInvestigationAdmin
                }
                handelOpenAddInvestigationParameter={
                  handelOpenAddInvestigationParameter
                }
              />
            )}

            {addInvestigationAdmin && (
              <RightFlotingAnalyticsDashboard>
                <AddInvestigationAdmin
                  handelCloseAddInvestigationAdmin={
                    handelCloseAddInvestigationAdmin
                  }
                />
              </RightFlotingAnalyticsDashboard>
            )}

            {addUnitInvestigationAdmin && (
              <RightFlotingAnalyticsDashboard>
                <AddInvestigationAdminUnit
                  handleCloseUnitInvestigationAdmin={
                    handleCloseUnitInvestigationAdmin
                  }
                  addUnitInvestigationAdminData={addUnitInvestigationAdminData}
                />
              </RightFlotingAnalyticsDashboard>
            )}

            {addInvestigationParameter && (
              <RightFlotingAnalyticsDashboard>
                <AddInvestigationParameter
                  handelCloseAddInvestigationParameter={
                    handelCloseAddInvestigationParameter
                  }
                />
              </RightFlotingAnalyticsDashboard>
            )}

            {/* healthcare end  /////////////////////////////////////////////////////////////////////// */}
            {showDetailPayroll && (
              <RightFlotingAnalyticsDashboard>
                <ViewPayrollSalaryStructure
                  handelCloseDetailPayroll={handelCloseDetailPayroll}
                  selectedEmPayroll={selectedEmPayroll}
                  refreshEmployPayrollData={refreshEmployPayrollData}
                />
              </RightFlotingAnalyticsDashboard>
            )}
            {showPayrollPayslip && (
              <RightFlotingAnalyticsDashboard>
                <ViewPayrollPayslip
                  handelClosePayrollPayslip={handelClosePayrollPayslip}
                />
              </RightFlotingAnalyticsDashboard>
            )}
            {showAddPeople && (
              <RightFlotingAnalyticsDashboard>
                <AddPeople
                  handelCloseAddPeople={handelCloseAddPeople}
                  refreshViewPeopleData={refreshViewPeopleData}
                  fetchDashBoardData={fetchDashBoardData}
                />
              </RightFlotingAnalyticsDashboard>
            )}
            {showAddLeaveRules && (
              <RightFlotingAnalyticsDashboard>
                <AddLeaveRules
                  handelCloseAddLeaveRules={handelCloseAddLeaveRules}
                  refreshAddLeaveRulesData={refreshAddLeaveRulesData}
                  fetchDashBoardData={fetchDashBoardData}
                />
              </RightFlotingAnalyticsDashboard>
            )}
            {updateLeaveRule && (
              <RightFlotingAnalyticsDashboard>
                <EditLeaveRules
                  handleCloseUpdateLeaveRule={handleCloseUpdateLeaveRule}
                  refreshAddLeaveRulesData={refreshAddLeaveRulesData}
                  selectedUpdateLeaveRule={selectedUpdateLeaveRule}
                />
              </RightFlotingAnalyticsDashboard>
            )}
            {viewSelectedLeaveRule && (
              <RightFlotingAnalyticsDashboard>
                <SelectedViewLeaveRule
                  refreshAddLeaveRulesData={refreshAddLeaveRulesData}
                  handleCloseViewSelectedLeaveRule={
                    handleCloseViewSelectedLeaveRule
                  }
                  viewSelectedLeaveRuleData={viewSelectedLeaveRuleData}
                />
              </RightFlotingAnalyticsDashboard>
            )}
            {showAddBudget && (
              <RightFlotingAnalyticsDashboard>
                <AddBudget
                  handelCloseAddBudget={handelCloseAddBudget}
                  fetchDashBoardData={fetchDashBoardData}
                  refreshBudgetData={refreshBudgetData}
                />
              </RightFlotingAnalyticsDashboard>
            )}
            {showAddItems && (
              <RightFlotingAnalyticsDashboard>
                <AddItems
                  handelCloseAddItems={handelCloseAddItems}
                  editItem={editItem}
                  resetEditItem={(e) => {
                    setEditItem(null);
                  }}
                  updateItemList={() => {
                    setShouldItemUpdateList((prev) => prev + 1);
                  }}
                  fetchDashBoardData={fetchDashBoardData}
                />
              </RightFlotingAnalyticsDashboard>
            )}
            {/* {showAddPolicies && (
              <RightFlotingAnalyticsDashboard>
                <AddPolicies
                  handelCloseAddPolicies={handelCloseAddPolicies}
                  refreshData={refreshData}
                />
              </RightFlotingAnalyticsDashboard>
            )} */}
            {showAddRoutineWork && (
              <RightFlotingAnalyticsDashboard>
                <AddRoutineWork
                  handelCloseAddRoutineWork={handelCloseAddRoutineWork}
                />
              </RightFlotingAnalyticsDashboard>
            )}
            {viewUserAttendanceData != null && (
              <RightFlotingAnalyticsDashboard>
                <ViewUserAttendance
                  handelCloseViewUserAttend={handelCloseViewUserAttend}
                  viewUserAttendanceData={viewUserAttendanceData}
                />
              </RightFlotingAnalyticsDashboard>
            )}
            {showAddHoliday && (
              <RightFlotingAnalyticsDashboard>
                <AddHoliday
                  handelCloseAddHoliday={handelCloseAddHoliday}
                  refresHolidayData={refresHolidayData}
                  fetchDashBoardData={fetchDashBoardData}
                />
              </RightFlotingAnalyticsDashboard>
            )}
            {showEditHoliday && (
              <RightFlotingAnalyticsDashboard>
                <EditHoliday
                  handelCloseEditHoliday={handelCloseEditHoliday}
                  refresHolidayData={refresHolidayData}
                  selecttedHolidayData={selecttedHolidayData}
                />
              </RightFlotingAnalyticsDashboard>
            )}
            {showSelectedViewHoliday && (
              <RightFlotingAnalyticsDashboard>
                <ViewSelectedHoliday
                  handelCloseSelectedViewHoliday={
                    handelCloseSelectedViewHoliday
                  }
                  refresHolidayData={refresHolidayData}
                  selecttedViewHolidayData={selecttedViewHolidayData}
                />
              </RightFlotingAnalyticsDashboard>
            )}
            {showAddWorkingday && (
              <RightFlotingAnalyticsDashboard>
                <AddWorkingDays
                  handelCloseAddWorkingday={handelCloseAddWorkingday}
                />
              </RightFlotingAnalyticsDashboard>
            )}
            {addWorkingShift && (
              <RightFlotingAnalyticsDashboard>
                <AddShift
                  handleCloseAddWorkingShift={handleCloseAddWorkingShift}
                />
              </RightFlotingAnalyticsDashboard>
            )}
            {showAddExternalUser && (
              <RightFlotingAnalyticsDashboard>
                <AddExternalUser
                  handelCloseAddExternalUser={handelCloseAddExternalUser}
                  refreshExternalUserData={refreshExternalUserData}
                  fetchDashBoardData={fetchDashBoardData}
                />
              </RightFlotingAnalyticsDashboard>
            )}
            {showSelectedExternalUser && (
              <RightFlotingAnalyticsDashboard>
                <EditExternalUser
                  handelCloseEditExternalUser={handelCloseEditExternalUser}
                  refreshExternalUserData={refreshExternalUserData}
                  selecttedViewExternalUser={selecttedViewExternalUser}
                />
              </RightFlotingAnalyticsDashboard>
            )}
            {showAddContract && (
              <RightFlotingAnalyticsDashboard>
                <AddContract
                  handelCloseAddContract={handelCloseAddContract}
                  refreshContractData={refreshContractData}
                  fetchDashBoardData={fetchDashBoardData}
                />
              </RightFlotingAnalyticsDashboard>
            )}
            {editEmployee && (
              <RightFlotingAnalyticsDashboard>
                <EditPeople
                  handelCloseEditEmployee={handelCloseEditEmployee}
                  refreshViewPeopleData={refreshViewPeopleData}
                  fetchDashBoardData={fetchDashBoardData}
                  editEmployeesData={editEmployeesData}
                />
              </RightFlotingAnalyticsDashboard>
            )}
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
