import React, { useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
} from "@mui/material";
import { Add } from "@mui/icons-material";
import { XLg, Trash } from "react-bootstrap-icons";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { v4 as uuidv4 } from "uuid";
import DashboardServices from "../../../../../services/DashboardServices";
import { jwtDecode } from "jwt-decode";
import {
  getAllTimeZones,
  getOrgByOrgId,
} from "../../../../../services/AdminService";
import { getAllDays } from "../../../../../services/SchedulerService";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

const ITEM_HEIGHT = 58;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const weeksNo = ["1st Week", "2nd Week", "3rd Week", "4th Week", "5th Week"];
const daysOfWeek = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

export default function AddAppointmentSettings({
  handelCloseAppointmentSettings,
}) {
  const userDetails = jwtDecode(localStorage.getItem("token"));
  const { t } = useTranslation();
  const [timeZone, setTimeZone] = useState("");
  const [bookingWindow, setBookingWindowe] = useState(0);
  const [slotDuration, setSlotDuration] = useState(0);
  const [doctorRosterData, setDoctorRosterData] = useState([]);
  const [doctorRosterDataVal, setDoctorRosterDataVal] = useState([]);
  const [timeZoneData, setTimeZoneData] = useState([]);
  const [checkedDays, setCheckedDays] = useState({});
  const [monthSelect, setMonthSelect] = useState("");
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [dayRows, setDayRows] = useState({});
  const [weekDaysData, setWeekDaysData] = useState([]);
  const [loadingData, setLoadingData] = useState(true);

  // const [monthSelect, setMonthSelect] = useState(
  //   new Date(new Date().setDate(new Date().getDate() + 90))
  // );
  // const [startTime, setStartTime] = useState(
  //   new Date(new Date().setHours(10, 0, 0, 0))
  // );
  // const [endTime, setEndTime] = useState(
  //   new Date(new Date().setHours(16, 0, 0, 0))
  // );
  // const [checkedDays, setCheckedDays] = useState({
  //   Sunday: false,
  //   Monday: true,
  //   Tuesday: true,
  //   Wednesday: true,
  //   Thursday: true,
  //   Friday: true,
  //   Saturday: false,
  // });
  // const [dayRows, setDayRows] = useState(
  //   daysOfWeek.reduce((acc, day) => {
  //     acc[day] = [{ id: uuidv4(), weeks: weeksNo, isNew: false }]; // Initialize with one row per day
  //     return acc;
  //   }, {})
  // );

  // const handleChangeweeksno = (day, index) => (event) => {
  //   const {
  //     target: { value },
  //   } = event;
  //   setDayRows((prevRows) => ({
  //     ...prevRows,
  //     [day]: prevRows[day].map((row, i) =>
  //       i === index
  //         ? {
  //             ...row,
  //             weeks: typeof value === "string" ? value.split(",") : value,
  //           }
  //         : row
  //     ),
  //   }));
  // };

  const handleMonthSelect = (newDate) => {
    setMonthSelect(newDate);
  };

  const handleChangetimeZone = (event) => {
    setTimeZone(event.target.value);
  };
  const handleChangebookingWindow = (event) => {
    setBookingWindowe(event.target.value);
  };

  // const handleChangeSlotDuration = (event) => {
  //   setSlotDuration(event.target.value);
  // };

  // const handleCheckboxChange = (day) => (event) => {
  //   setCheckedDays((prevCheckedDays) => ({
  //     ...prevCheckedDays,
  //     [day]: event.target.checked,
  //   }));
  // };

  // const addRow = (day) => {
  //   setDayRows((prevRows) => ({
  //     ...prevRows,
  //     [day]: [...prevRows[day], { id: uuidv4(), weeks: weeksNo, isNew: true }],
  //   }));
  // };

  // const removeRow = (day, index) => {
  //   setDayRows((prevRows) => ({
  //     ...prevRows,
  //     [day]: prevRows[day].filter((_, i) => i !== index),
  //   }));
  // };

  useEffect(() => {
    fetchDoctorRoasterData();
  }, []);

  const fetchDoctorRoasterData = () => {
    DashboardServices.getDoctorRoaster(userDetails.userId).then((response) => {
      // console.log("fetchDoctorRoasterData", response.data);
      setDoctorRosterData(response.data);
      setDoctorRosterDataVal(response.data.doctorRoaster);
    });
  };

  const [orgDetailsData, setOrgDetailsData] = useState(null);

  useEffect(() => {
    fetchgetOrgDetails();
  }, []);

  const fetchgetOrgDetails = () => {
    getOrgByOrgId(userDetails.orgId)
      .then((response) => {
        // console.log("getOrgDetails", response.data);
        setOrgDetailsData(response.data);
        setLoadingData(false);
      })
      .catch((error) => {
        // console.error("Error fetching getOrgDetails", error);
      });
  };

  // const initializeDayRows = (rosterData) => {
  //   const initialRows = {};
  //   rosterData.forEach((day) => {
  //     initialRows[day.dayId] = [{ id: uuidv4(), weeks: weeksNo, isNew: false }];
  //   });
  //   setDayRows(initialRows);
  // };

  // useEffect(() => {
  //   const initialRows = doctorRosterDataVal.reduce((acc, day) => {
  //     acc[day.dayId] = [{
  //       id: uuidv4(),
  //       fromTime: new Date(`2000-01-01T$${day.fromTime}`),
  //       toTime: new Date(`2000-01-01T$${day.toTime}`),
  //       duration: day.duration,
  //       weeks: day.weeks.split(','),
  //       isNew: false
  //     }];
  //     return acc;
  //   }, {});
  //   console.log('initialRows' , initialRows)
  //   setDayRows(initialRows);
  // }, []);

  useEffect(() => {
    getAllDays().then((response) => {
      // console.log("getAllDays", response.data);
      setWeekDaysData(response.data);
    });
  }, []);

  const [dayIdToName, setDayIdToName] = useState({});

  useEffect(() => {
    // console.log("weekDaysData", weekDaysData);
    const mapping = weekDaysData.reduce((acc, { id, name }) => {
      // console.log("name", name);
      // console.log("id", id);

      acc[id] = name;
      return acc;
    }, {});
    // console.log("mapping", mapping);
    setDayIdToName(mapping);
  }, [weekDaysData]);

  useEffect(() => {
    // console.log("weekDaysData", weekDaysData);
    // console.log("doctorRosterDataVal", doctorRosterDataVal);

    if (!weekDaysData || !doctorRosterDataVal) return;

    const initialCheckedDays = weekDaysData.reduce((acc, day) => {
      const isChecked = doctorRosterDataVal.some(
        (roster) => roster.dayId.toString() === day.id
      );
      acc[day.id] = isChecked;
      return acc;
    }, {});

    // console.log("initialCheckedDays", initialCheckedDays);

    setCheckedDays(initialCheckedDays);
  }, [doctorRosterDataVal, weekDaysData]);

  useEffect(() => {
    // console.log("doctorRosterDataVal", doctorRosterDataVal);
    // console.log("dayIdToName", dayIdToName);

    // Ensure both doctorRosterDataVal and dayIdToName are populated
    if (!doctorRosterDataVal.length || !Object.keys(dayIdToName).length) {
      return;
    }

    const parseTime = (timeStr) => {
      if (!timeStr || typeof timeStr !== "string") {
        // console.error("Invalid time string provided:", timeStr);
        return null;
      }

      const [time, modifier] = timeStr.split(" ");
      if (!time || !modifier || (modifier !== "AM" && modifier !== "PM")) {
        // console.error("Invalid time format provided:", timeStr);
        return null;
      }

      let [hours, minutes] = time.split(":");
      if (!hours || !minutes) {
        // console.error("Invalid hours or minutes provided:", timeStr);
        return null;
      }

      hours = parseInt(hours, 10);
      minutes = parseInt(minutes, 10);

      if (isNaN(hours) || isNaN(minutes)) {
        // console.error("Invalid numeric values in time:", timeStr);
        return null;
      }

      if (hours === 12) {
        hours = 0;
      }

      if (modifier === "PM") {
        hours += 12;
      }

      const date = new Date(
        `2000-01-01T${hours.toString().padStart(2, "0")}:${minutes
          .toString()
          .padStart(2, "0")}:00`
      );

      if (isNaN(date.getTime())) {
        // console.error("Generated invalid Date object from:", timeStr);
        return null;
      }

      return date;
    };

    const initialRows = doctorRosterDataVal.reduce((acc, day) => {
      const dayEntry = {
        id: uuidv4(),
        fromTime: parseTime(day.fromTime),
        toTime: parseTime(day.toTime),
        duration: day.duration,
        weeks: day.weeks.split(","),
        isNew: acc[day.dayId] ? true : false,
        dayId: day.dayId,
        dayName: dayIdToName[day.dayId] || "",
        availabilityId: day.availabilityId || 0,
      };

      if (acc[day.dayId]) {
        acc[day.dayId].push(dayEntry);
      } else {
        acc[day.dayId] = [dayEntry];
      }

      return acc;
    }, {});

    // console.log("initialRows", initialRows);
    setDayRows(initialRows);
  }, [doctorRosterDataVal, dayIdToName]);



  useEffect(() => {
    if (doctorRosterData.bookingWindow) {
      setBookingWindowe(doctorRosterData.bookingWindow);
    } else {
      setBookingWindowe(90);
    }
  }, [doctorRosterData.bookingWindow]);
  

  useEffect(() => {
    getAllTimeZones().then((response) => {
      // console.log("Zone Data", response.data);
      setTimeZoneData(response.data);
    });
  }, []);


  useEffect(() => {
    if (doctorRosterData.timeZone) {
      setTimeZone(doctorRosterData.timeZone);
    } else if (orgDetailsData && orgDetailsData.timeZone) {
      setTimeZone(orgDetailsData.timeZone);
    }
  }, [doctorRosterData.timeZone, orgDetailsData]);
  

  // const handleCheckboxChange = (dayId) => (event) => {
  //   const isChecked = event.target.checked;

  //   setCheckedDays((prevCheckedDays) => ({
  //     ...prevCheckedDays,
  //     [dayId]: isChecked,
  //   }));

  //   setDayRows((prevRows) => {
  //     const existingRows = prevRows[dayId] || [];
  //     const isRowPresent = existingRows.length > 0;

  //     if (isChecked) {
  //       if (!isRowPresent) {
  //         return {
  //           ...prevRows,
  //           [dayId]: [
  //             ...existingRows,
  //             {
  //               id: uuidv4(),
  //               fromTime: null,
  //               toTime: null,
  //               duration: 0,
  //               weeks: ["1","2","3" ,"4" ,"5"],
  //               isNew: false,
  //               dayId: dayId,
  //               dayName: dayIdToName[dayId] || "",
  //               availabilityId: 0,
  //             },
  //           ],
  //         };
  //       }
  //     } else {
  //       if (isRowPresent) {
  //         return {
  //           ...prevRows,
  //           [dayId]: existingRows.filter((row) => row.isNew),
  //         };
  //       }
  //     }

  //     return prevRows;
  //   });
  // };

  // const handleCheckboxChange = (dayId) => (event) => {
  //   const isChecked = event.target.checked;
  
  //   setCheckedDays((prevCheckedDays) => ({
  //     ...prevCheckedDays,
  //     [dayId]: isChecked,
  //   }));
  
  //   setDayRows((prevRows) => {
  //     const existingRows = prevRows[dayId] || [];
  //     const isRowPresent = existingRows.length > 0;
  
  //     if (isChecked) {
  //       if (!isRowPresent) {
  //         const previousRow = prevRows[dayId-1]?.find(row => !row.isNew) || {}; 
  //         console.log('previousRow' , previousRow)
  
  //         return {
  //           ...prevRows,
  //           [dayId]: [
  //             {
  //               id: uuidv4(),
  //               fromTime: previousRow.fromTime || "",   
  //               toTime: previousRow.toTime || "",       
  //               duration: previousRow.duration || 0, 
  //               weeks: previousRow.weeks || ["1", "2", "3", "4", "5"], 
  //               isNew: false,
  //               dayId: dayId,
  //               dayName: dayIdToName[dayId] || "",
  //               availabilityId: 0,
  //             },
  //           ],
  //         };
  //       }
  //     } else {
  //       if (isRowPresent) {
  //         return {
  //           ...prevRows,
  //           [dayId]: existingRows.filter((row) => row.isNew),
  //         };
  //       }
  //     }
  
  //     return prevRows;
  //   });
  // };

  // const handleCheckboxChange = (dayId) => (event) => {
  //   const isChecked = event.target.checked;
  
  //   setCheckedDays((prevCheckedDays) => ({
  //     ...prevCheckedDays,
  //     [dayId]: isChecked,
  //   }));
  
  //   setDayRows((prevRows) => {
  //     const existingRows = prevRows[dayId] || [];
  //     const isRowPresent = existingRows.length > 0;
  
  //     if (isChecked) {
  //       if (!isRowPresent) {
  //         const previousRow = prevRows[dayId - 1]?.find((row) => !row.isNew) || {};
  //         // console.log('previousRow', previousRow);
  
  //         return {
  //           ...prevRows,
  //           [dayId]: [
  //             {
  //               id: uuidv4(),
  //               fromTime: previousRow.fromTime || "",   
  //               toTime: previousRow.toTime || "",       
  //               duration: previousRow.duration || 0, 
  //               weeks: ["1", "2", "3", "4", "5"], 
  //               isNew: false,
  //               dayId: dayId,
  //               dayName: dayIdToName[dayId] || "",
  //               availabilityId: 0,
  //             },
  //           ],
  //         };
  //       }
  //     } else {
  //       // Remove all data matching the dayId
  //       const { [dayId]: _, ...remainingRows } = prevRows;
  //       return remainingRows;
  //     }
  
  //     return prevRows;
  //   });
  // };

  const handleCheckboxChange = (dayId) => (event) => {
    const isChecked = event.target.checked;
  
    setCheckedDays((prevCheckedDays) => ({
      ...prevCheckedDays,
      [dayId]: isChecked,
    }));
  
    setDayRows((prevRows) => {
      const existingRows = prevRows[dayId] || [];
      const isRowPresent = existingRows.length > 0;
  
      if (isChecked) {
        if (!isRowPresent) {
          let previousRow = {};
          for (let i = 1; i <= 6; i++) {
            if (prevRows[dayId - i]) {
              previousRow = prevRows[dayId - i].find((row) => !row.isNew) || {};
              if (Object.keys(previousRow).length > 0) {
                break;
              }
            }
          }
  
          return {
            ...prevRows,
            [dayId]: [
              {
                id: uuidv4(),
                fromTime: previousRow.fromTime || "",   
                toTime: previousRow.toTime || "",       
                duration: previousRow.duration || 0, 
                weeks: previousRow.weeks || ["1", "2", "3", "4", "5"], 
                isNew: false,
                dayId: dayId,
                dayName: dayIdToName[dayId] || "",
                availabilityId: 0,
              },
            ],
          };
        }
      } else {
        // Remove all data matching the dayId
        const { [dayId]: _, ...remainingRows } = prevRows;
        return remainingRows;
      }
  
      return prevRows;
    });
  };
  
  
  

  const handleStartTimeChange = (date, dayId, index) => {
    setDayRows((prevRows) => ({
      ...prevRows,
      [dayId]: prevRows[dayId].map((row, idx) =>
        idx === index ? { ...row, fromTime: date } : row
      ),
    }));
  };

  const handleEndTimeChange = (date, dayId, index) => {
    setDayRows((prevRows) => ({
      ...prevRows,
      [dayId]: prevRows[dayId].map((row, idx) =>
        idx === index ? { ...row, toTime: date } : row
      ),
    }));
  };

  const handleChangeSlotDuration = (event, dayId, index) => {
    const newDuration = event.target.value;

    setDayRows((prevRows) => ({
      ...prevRows,
      [dayId]: prevRows[dayId].map((row, idx) =>
        idx === index ? { ...row, duration: newDuration } : row
      ),
    }));
  };

  const handleChangeweeksno = (dayId, index) => (event) => {
    const {
      target: { value },
    } = event;
    const weekNumbers = weeksNo.reduce((acc, week, i) => {
      if (value.includes(week)) {
        acc.push((i + 1).toString());
      }
      return acc;
    }, []);

    setDayRows((prevRows) => ({
      ...prevRows,
      [dayId]: prevRows[dayId].map((row, i) =>
        i === index ? { ...row, weeks: weekNumbers } : row
      ),
    }));
  };

  // const addRow = (dayId) => {
  //   setDayRows((prevRows) => ({
  //     ...prevRows,
  //     [dayId]: [
  //       ...(prevRows[dayId] || []),
  //       {
  //         id: uuidv4(),
  //         fromTime: null,
  //         toTime: null,
  //         duration: 0,
  //         weeks: ["1","2","3" ,"4" ,"5"],
  //         isNew: true,
  //         dayId: dayId,
  //         dayName: dayIdToName[dayId] || "",
  //         availabilityId: 0,
  //       },
  //     ],
  //   }));
  // };

  const addRow = (dayId) => {
    setDayRows((prevRows) => {
      const existingRows = prevRows[dayId] || [];
      const lastRow = existingRows.length > 0 ? existingRows[existingRows.length - 1] : {}; // Get the last existing row
  
      return {
        ...prevRows,
        [dayId]: [
          ...existingRows,
          {
            id: uuidv4(),
            fromTime: null,   
            toTime: null,
            duration: lastRow.duration || 0, 
            weeks: ["1", "2", "3", "4", "5"],
            isNew: true,
            dayId: dayId,
            dayName: dayIdToName[dayId] || "",
            availabilityId: 0,
          },
        ],
      };
    });
  };
  

  const removeRow = (dayId, index) => {
    setDayRows((prevRows) => {
      const updatedRows = prevRows[dayId].filter((_, i) => i !== index);

      // console.log("Removing row at index", index);
      // console.log("Updated rows for dayId", dayId, updatedRows);

      return {
        ...prevRows,
        [dayId]: updatedRows,
      };
    });
  };

  const formatTime = (dateString) => {
    const date = new Date(dateString);

    const options = {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    };

    return new Intl.DateTimeFormat("en-US", options).format(date);
  };

  const handleSubmit = () => {
    console.log("handleSubmit");
    setLoadingData(true);

    console.log("dayRows", dayRows);

    const doctorRoaster = Object.entries(dayRows).flatMap(([dayId, rows]) =>
      rows.map((row) => ({
        dayId: parseInt(dayId, 10),
        dayName: row.dayName,
        fromTime: row.fromTime !== "" ? formatTime(row.fromTime) : "",
        toTime: row.toTime !== "" ? formatTime(row.toTime) : "",
        duration: row.duration,
        weeks: row.weeks.join(","),
        availabilityId: row.availabilityId,
      }))
    );

    console.log("doctorRoaster:", doctorRoaster);

    for (const entry of doctorRoaster) {

      if (!entry.fromTime) {
        // toast.error(
        //   `Please Select 'Start Time' for ${entry.dayName}`,
        //   {
        //     position: toast.POSITION.TOP_RIGHT,
        //   }
        // );
        toast.error(
          t('selectStartTime', { dayName: entry.dayName }),
          {
            position: toast.POSITION.TOP_RIGHT,
          }
        );
        setLoadingData(false);
        return;
      }

      if (!entry.toTime) {
        toast.error(
          // `Please Select 'End Time' for ${entry.dayName}`,
          t('selectEndTime' , {dayName: entry.dayName}) ,
          {
            position: toast.POSITION.TOP_RIGHT,
          }
        );
        setLoadingData(false);
        return;
      }

      if (!entry.duration) {
        toast.error(
          // `Please Select 'Duration' for ${entry.dayName}`,
          t('selectDuration' , {dayName : entry.dayName}),
          {
            position: toast.POSITION.TOP_RIGHT,
          }
        );
        setLoadingData(false);
        return;
      }

      const fromTime = new Date(
        `1970-01-01T${
          new Date("1970-01-01 " + entry.fromTime).toISOString().split("T")[1]
        }`
      );
      const toTime = new Date(
        `1970-01-01T${
          new Date("1970-01-01 " + entry.toTime).toISOString().split("T")[1]
        }`
      );

      // if (fromTime >= toTime) {
      //   toast.error(
      //     `'Start Time' should be less than 'End Time' for ${entry.dayName}`,
      //     {
      //       position: toast.POSITION.TOP_RIGHT,
      //     }
      //   );
      //   setLoadingData(false);
      //   return;
      // }
      if (fromTime >= toTime) {
        toast.error(
          t("start_time_less_than_end_time", { dayName: entry.dayName }),
          {
            position: toast.POSITION.TOP_RIGHT,
          }
        );
        setLoadingData(false);
        return;
      }
    }

    const entriesByWeek = {};

    doctorRoaster.forEach((entry) => {
      const { dayId, dayName, fromTime, toTime, weeks } = entry;
      const weeksArray = weeks.split(",");
      weeksArray.forEach((week) => {
        const key = `${dayId}-${dayName}-${week}`;
        if (!entriesByWeek[key]) {
          entriesByWeek[key] = [];
        }
        entriesByWeek[key].push({ fromTime, toTime });
      });
    });

    console.log("entriesByWeek:", entriesByWeek);

    let hasOverlap = false;

    for (const [key, timeRanges] of Object.entries(entriesByWeek)) {
      for (let i = 0; i < timeRanges.length; i++) {
        for (let j = i + 1; j < timeRanges.length; j++) {
          const start1 = new Date(
            `1970-01-01T${
              new Date("1970-01-01 " + timeRanges[i].fromTime)
                .toISOString()
                .split("T")[1]
            }`
          );
          const end1 = new Date(
            `1970-01-01T${
              new Date("1970-01-01 " + timeRanges[i].toTime)
                .toISOString()
                .split("T")[1]
            }`
          );
          const start2 = new Date(
            `1970-01-01T${
              new Date("1970-01-01 " + timeRanges[j].fromTime)
                .toISOString()
                .split("T")[1]
            }`
          );
          const end2 = new Date(
            `1970-01-01T${
              new Date("1970-01-01 " + timeRanges[j].toTime)
                .toISOString()
                .split("T")[1]
            }`
          );

          // console.log(`Comparing time ranges for key ${key}:`, {
          //   start1,
          //   end1,
          //   start2,
          //   end2,
          // });

          // if (start1 < end2 && end1 > start2) {
          //   const [dayId, dayName, week] = key.split("-");
          //   toast.error(`Time ranges overlap for ${dayName} in week ${week}`, {
          //     position: toast.POSITION.TOP_RIGHT,
          //   });
          //   setLoadingData(false);
          //   hasOverlap = true;
          //   break;
          // }
          if (start1 < end2 && end1 > start2) {
            const [dayId, dayName, week] = key.split("-");
            toast.error(
              t("time_ranges_overlap", { dayName: dayName, week: week }),
              {
                position: toast.POSITION.TOP_RIGHT,
              }
            );
            setLoadingData(false);
            hasOverlap = true;
            break;
          }
        }
        if (hasOverlap) break;
      }
      if (hasOverlap) break;
    }

    if (!hasOverlap) {
      const reqDto = {
        doctorId: userDetails.userId,
        timeZone: timeZone,
        bookingWindow: bookingWindow,
        doctorRoaster,
      };

      console.log("reqDto", reqDto);

      // return

      DashboardServices.saveDoctorRoaster(userDetails.userId, reqDto).then(
        (response) => {
          // console.log("response", response.data);
          if (response.data.returnValue === 1) {
            response.data.message.forEach((msg) => {
              toast.success(msg, {
                position: toast.POSITION.TOP_RIGHT,
              });
            });
            fetchDoctorRoasterData();
            setLoadingData(false);
          } else {
            response.data.message.forEach((msg) => {
              toast.error(msg, {
                position: toast.POSITION.TOP_RIGHT,
              });
            });
            setLoadingData(false);
          }
        }
      );
    }
  };

  return (
    <>
      <div className="rightFlotingPanel">
        <div className="rightFlotingContainer">
          <div className="rfContHeader">
            <div className="rfcHeadText">{t("appointment_settings")}</div>
            <div className="rfcHActionBtnGrp">
              <div className="actionBtn closeBtnD10">
                <IconButton onClick={() => handelCloseAppointmentSettings()}>
                  <XLg />
                </IconButton>
              </div>
            </div>
          </div>

          <div className="rfContContain">
            {loadingData ? (
              <div className="meetingVEContainer">
                <div className="center-progress" style={{ height: "65vh" }}>
                  <CircularProgress sx={{ marginTop: "180px" }} />
                </div>
              </div>
            ) : (
              <div className="rfContContainDetails">
                <div className="elementFormContainer">
                  <div className="formElementGrp">
                    <div className="formElement">
                      <FormControl className="formControl">
                        <InputLabel id="timeZone-select-label">
                        {t("select_time_zone")}
                        </InputLabel>
                        <Select
                          labelId="timeZone-select-label"
                          id="timeZone-select"
                          value={timeZone}
                          label={t("select_time_zone")}
                          onChange={handleChangetimeZone}
                          className="formInputField"
                          variant="outlined"
                          size="small"
                        >
                          {/* <MenuItem value="Asia">Asia</MenuItem>
                        <MenuItem value="USA">USA</MenuItem>
                        <MenuItem value="UAE">UAE</MenuItem> */}
                          {timeZoneData.map((timeZoneVal) => (
                            <MenuItem
                              key={timeZoneVal.id}
                              value={timeZoneVal.name}
                            >
                              {timeZoneVal.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                    <div className="formElement">
                      <FormControl className="formControl">
                        <InputLabel id="bookingWindow-select-label">
                        {t("booking_window_in_days")}
                        </InputLabel>
                        <Select
                          labelId="bookingWindow-select-label"
                          id="bookingWindow-select"
                          value={bookingWindow}
                          label={t("booking_window_in_days")}
                          onChange={handleChangebookingWindow}
                          className="formInputField"
                          variant="outlined"
                          size="small"
                        >
                          <MenuItem value={7}>{t("seven_days")}</MenuItem>
                          <MenuItem value={15}>{t("fifteen_days")}</MenuItem>
                          <MenuItem value={30}>{t("thirty_days")}</MenuItem>
                          <MenuItem value={45}>{t("forty_five_days")}</MenuItem>
                          <MenuItem value={60}>{t("sixty_days")}</MenuItem>
                          <MenuItem value={75}>{t("seventy_five_days")}</MenuItem>
                          <MenuItem value={90}>{t("ninety_days")}</MenuItem>
                          <MenuItem value={180}>{t("one_hundred_eighty_days")}</MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                    {/* <div className="formElement">
                    <FormControl className="formControl selectMonth">
                      <InputLabel
                        id="monthSelect"
                        className="setCompletionDate"
                      >
                        Booking Window
                      </InputLabel>
                      <ReactDatePicker
                        toggleCalendarOnIconClick
                        yearDropdownItemNumber={100}
                        scrollableYearDropdown
                        showIcon
                        labelId="monthSelect"
                        className="formDatePicker "
                        selected={monthSelect}
                        minDate={new Date()}
                        maxDate={
                          new Date(
                            new Date().setMonth(new Date().getMonth() + 6)
                          )
                        }
                        dateFormat="dd/MM/yyyy"
                        onChange={(date) => {
                          handleMonthSelect(date);
                        }}
                      />
                    </FormControl>
                  </div> */}
                  </div>
                  <div className="tskElemHeding mt10">
                  {t("set_slots_for_book_appointment")}
                  </div>

                  <div className="weekDaysGrp">
                    {weekDaysData.map((day) => (
                      <div className="weekDaysIndv" key={day.id}>
                        <div className="checkBoxDays">
                          <Checkbox
                            checked={checkedDays[day.id] || false}
                            onChange={handleCheckboxChange(day.id)}
                          />
                        </div>

                        <div className="checkWeekControngrp">
                          <div className="checkDays">{day.name}</div>
                          {checkedDays[day.id] ? (
                            <>
                              <div className="multislots">
                                {dayRows[day.id]?.map((row, index) => (
                                  <div
                                    className="checkDaysSettings"
                                    key={row.id}
                                  >
                                    <div className="setopGrp">
                                      <div className="settingsOption">
                                        <div className="formElement startTime width120">
                                          <FormControl className="formControl selectTime">
                                            <InputLabel
                                              id="startTime"
                                              className="setCompletionDate"
                                            >
                                              {t("start_time")}
                                            </InputLabel>
                                            <ReactDatePicker
                                              toggleCalendarOnIconClick
                                              labelId="startTime"
                                              selected={row.fromTime}
                                              onChange={(date) =>
                                                handleStartTimeChange(
                                                  date,
                                                  day.id,
                                                  index
                                                )
                                              }
                                              showTimeSelect
                                              showTimeSelectOnly
                                              timeIntervals={15}
                                              timeCaption="Time"
                                              dateFormat="h:mm aa"
                                              className="formDatePicker "
                                              showIcon
                                              minTime={new Date().setHours(
                                                0,
                                                0
                                              )}
                                              maxTime={new Date().setHours(
                                                23,
                                                45
                                              )}
                                            />
                                          </FormControl>
                                        </div>
                                        <div className="formElement endTime width120">
                                          <FormControl className="formControl selectTime">
                                            <InputLabel
                                              id="endTime"
                                              className="setCompletionDate"
                                            >
                                              {t("end_time")}
                                            </InputLabel>
                                            <ReactDatePicker
                                              toggleCalendarOnIconClick
                                              labelId="endTime"
                                              selected={row.toTime}
                                              onChange={(date) =>
                                                handleEndTimeChange(
                                                  date,
                                                  day.id,
                                                  index
                                                )
                                              }
                                              showTimeSelect
                                              showTimeSelectOnly
                                              timeIntervals={15}
                                              timeCaption="Time"
                                              dateFormat="h:mm aa"
                                              className="formDatePicker "
                                              showIcon
                                              minTime={new Date().setHours(
                                                0,
                                                0
                                              )}
                                              maxTime={new Date().setHours(
                                                23,
                                                45
                                              )}
                                            />
                                          </FormControl>
                                        </div>
                                        <div className="formElement">
                                          <FormControl className="formControl">
                                            <InputLabel id="slotDuration-select-label">
                                            {t("slot_duration_in_minutes")}
                                            </InputLabel>
                                            <Select
                                              labelId="slotDuration-select-label"
                                              id="slotDuration-select"
                                              value={row.duration}
                                              label={t("slot_duration_in_minutes")}
                                              onChange={(event) =>
                                                handleChangeSlotDuration(
                                                  event,
                                                  day.id,
                                                  index
                                                )
                                              }
                                              className="formInputField"
                                              variant="outlined"
                                              size="small"
                                            >
                                              <MenuItem value={5}>5</MenuItem>
                                              <MenuItem value={10}>10</MenuItem>
                                              <MenuItem value={15}>15</MenuItem>
                                              <MenuItem value={20}>20</MenuItem>
                                              <MenuItem value={25}>25</MenuItem>
                                              <MenuItem value={30}>30</MenuItem>
                                              <MenuItem value={45}>45</MenuItem>
                                              <MenuItem value={60}>60</MenuItem>
                                              {/* <MenuItem value={75}>75</MenuItem> */}
                                              <MenuItem value={90}>90</MenuItem>
                                              {/* <MenuItem value={105}>
                                                105
                                              </MenuItem> */}
                                              <MenuItem value={120}>
                                                120
                                              </MenuItem>
                                            </Select>
                                          </FormControl>
                                        </div>
                                      </div>
                                      <div className="settingsOption">
                                        <div className="formElement">
                                          <FormControl className="formControl">
                                            <InputLabel id="SelectWeekLabel">
                                            {t("select_week")}
                                            </InputLabel>
                                            <Select
                                              labelId="SelectWeekLabel"
                                              id={`${day}-SelectWeekLabelCheckBox`}
                                              multiple
                                              className="formInputField"
                                              // value={row.weeks}
                                              // onChange={handleChangeweeksno(
                                              //   day,
                                              //   index
                                              // )}
                                              // onChange={handleChangeweeksno(day.dayId, index)}
                                              value={row.weeks.map(
                                                (weekNum) =>
                                                  weeksNo[parseInt(weekNum) - 1]
                                              )} // Convert week numbers to week strings
                                              onChange={handleChangeweeksno(
                                                day.id,
                                                index
                                              )}
                                              input={
                                                <OutlinedInput label="Select Week" />
                                              }
                                              renderValue={(selected) => (
                                                <div>{selected.join(", ")}</div>
                                              )}
                                              MenuProps={MenuProps}
                                            >
                                              {/* {weeksNo.map((week) => (
                                              <MenuItem key={week} value={week}>
                                                <Checkbox
                                                  checked={
                                                    row.weeks.indexOf(week) > -1
                                                  }
                                                />
                                                <ListItemText primary={week} />
                                              </MenuItem>
                                            ))} */}
                                              {weeksNo.map((week, index) => (
                                                <MenuItem
                                                  key={week}
                                                  value={week}
                                                >
                                                  <Checkbox
                                                    checked={row.weeks
                                                      .map(
                                                        (num) =>
                                                          weeksNo[
                                                            parseInt(num) - 1
                                                          ]
                                                      )
                                                      .includes(week)}
                                                  />
                                                  <ListItemText
                                                    primary={week}
                                                  />
                                                </MenuItem>
                                              ))}
                                            </Select>
                                          </FormControl>
                                        </div>
                                      </div>
                                    </div>
                                    {!row.isNew && (
                                      <div className="checkAdd">
                                        <IconButton
                                          onClick={() => addRow(day.id)}
                                        >
                                          <Add />
                                        </IconButton>
                                      </div>
                                    )}
                                    {row.isNew && (
                                      <div className="checkTrash">
                                        <IconButton
                                          color="error"
                                          onClick={() =>
                                            removeRow(day.id, index)
                                          }
                                        >
                                          <Trash />
                                        </IconButton>
                                      </div>
                                    )}
                                  </div>
                                ))}
                              </div>
                            </>
                          ) : (
                            <div className="unavailableText">{t("unavailable")}</div>
                          )}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="rfContFooter">
            <div className="doctorSelectWthSubBtn">
              <div className="formBtnElement">
                <Button
                  className="dfultPrimaryBtn"
                  onClick={() => handleSubmit()}
                  disabled={loadingData}
                >
                  {loadingData ? (
                    <CircularProgress size={24} color="inherit" />
                  ) : (
                    <>{t("submit")}</>
                  )}
                </Button>
                {!loadingData && (
                  <Button
                    className="dfultDarkSecondaryBtn"
                    onClick={() => handelCloseAppointmentSettings()}
                  >
                    {t("cancel")}
                  </Button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
