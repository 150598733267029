import authHeader from "../config/auth-header-config";
import http from "../config/http-common";
import { apiEndPointSSE } from "../constants/url_provider";

export const generateJWTToken = (data) => {
  //console.log(data);
  return http.get(`/video/getVideoRoomToken/` + data, {
    headers: authHeader(),
  });
};

export const getUserRoleBasedJWTToken = (roomId, userId) => {
  // console.log(data);
  return http.get(`/video/getUserRoleBasedJWTToken/${roomId}/${userId}`, {
    headers: authHeader(),
  });
};

export const getUserRoleBasedJWTTokenNew = (
  roomId,
  userId,
  personDto,
  isAllowed,
  isGuest
) => {
  // console.log(data);
  return http.put(
    `/video/getUserRoleBasedJWTTokenNew/${roomId}/${userId}/${isAllowed}/${isGuest}`,
    personDto,
    {
      headers: authHeader(),
    }
  );
};

export const getUserRoleBasedJWTTokenGuest = (roomId, reqDto) => {
  // console.log(data);
  return http.put(`/video/getUserRoleBasedJWTTokenGuest/${roomId}`, reqDto, {
    headers: authHeader(),
  });
};

export const addParticipantToMeetingRoom = (reqDto) => {
  console.log(reqDto);
  return http.post(`/meeting/addParticipantToRoom/`, reqDto, {
    headers: authHeader(),
  });
};

export const removeParticipantFromMeetingRoom = (reqDto) => {
  //console.log(reqDto);
  return http.post(`/meeting/removeParticipantFromRoom/`, reqDto, {
    headers: authHeader(),
  });
};

export const createInstantMeeting = (data) => {
  //console.log(data);
  return http.get(`/meeting/createInstantMeeting/` + data, {
    headers: authHeader(),
  });
};

export const getContacts = (userId) => {
  return http.get(`contacts/getContacts/${userId}`, {
    headers: authHeader(),
  });
};

export const getMeetingAgendas = (eventId) => {
  return http.get(`calendar/getMeetingAgendas/${eventId}`, {
    headers: authHeader(),
  });
};

export const endMeeting = (roomId) => {
  //console.log(roomId);
  return http.get(
    `/sse/endMeeting/` + roomId,
    { baseURL: apiEndPointSSE },
    { headers: authHeader() }
  );
};

export const sendMeetingInvite = (reqDto, meetingId, userId) => {
  //console.log(reqDto);
  return http.post(`/meeting/sendInvite/${meetingId}/${userId}`, reqDto, {
    headers: authHeader(),
  });
};

export const sendCallInvitePersonal = (
  reqDto,
  meetingId,
  userId,
  eventType
) => {
  //console.log(reqDto);
  return http.post(
    `/meeting/sendCallInvitePersonal/${meetingId}/${userId}/${eventType}`,
    reqDto,
    {
      headers: authHeader(),
    }
  );
};

export const downloadFile = (fileName, folderName) => {
  //console.log(reqDto);
  return http.get(`/appTest/downloadFileTest/${fileName}/${folderName}`, {
    headers: authHeader(),
  });
};

export const getUserEventDetailsByDate = (reqDto, date, orgId) => {
  //console.log(reqDto);
  return http.put(
    `/meeting/getUserEventDetailsByDate/${date}/${orgId}`,
    reqDto,
    {
      headers: authHeader(),
    }
  );
};

export const sendMeetingInviteFromOrgInternalUser = (
  reqDto,
  meetingId,
  userId
) => {
  //console.log(reqDto);
  return http.post(
    `/meeting/sendMeetingInviteFromOrgInternalUser/${meetingId}/${userId}`,
    reqDto,
    {
      headers: authHeader(),
    }
  );
};

export const getEventAudioNote = (eventId) => {
  return http.get(`calendar/getEventAudioNote/${eventId}`, {
    headers: authHeader(),
  });
};

export const generatePreSignedUrlS3Object = (fileName, folderName) => {
  return http.get(
    `misc/generatePreSignedUrlS3Object/${fileName}/${folderName}/${3 * 3600}`,
    {
      headers: authHeader(),
    }
  );
};

export const getPrivateNotes = (userId, eventId) => {
  return http.get(`calendar/getPrivateNotes/${userId}/${eventId}`, {
    headers: authHeader(),
  });
};

export const addPrivateNotes = (userId, eventId, reqString) => {
  let authHeaderVal = authHeader().Authorization;
  const config = {
    headers: {
      "Content-type": "text/plain",
      Authorization: authHeaderVal,
    },
  };
  return http.post(
    `calendar/addPrivateNotes/${userId}/${eventId}`,
    reqString,
    config
  );
};

export const deletePrivateNotes = (userId, eventNoteId) => {
  return http.put(`calendar/deletePrivateNotes/${userId}/${eventNoteId}`, {
    headers: authHeader(),
  });
};

export const getParticipantsOfEvent = (eventId) => {
  return http.get(`calendar/getParticipantsOfEvent/${eventId}`, {
    headers: authHeader(),
  });
};

export const getSubtitlesOfMeeting = (meetingId) => {
  //console.log(reqDto);
  return http.get(`/meeting/getSubtitlesOfMeeting/${meetingId}`, {
    headers: authHeader(),
  });
};

export const getBcp47LanguageCodeNameOfUser = (userId) => {
  //console.log(reqDto);
  return http.get(`/person/getBcp47LanguageCodeNameOfUser/${userId}`, {
    headers: authHeader(),
  });
};

export const getOrgImageBase64 = (orgId) => {
  return http.get(`/org/getOrgImageBase64/${orgId}`, {
    headers: authHeader(),
  });
};

export const getBcp47CodeName = () => {
  //console.log(reqDto);
  return http.get(`/misc/getBcp47CodeName`, {
    headers: authHeader(),
  });
};

export const addUpdateUserLanguage = (reqDto, userId) => {
  //console.log(reqDto);
  return http.put(`/person/addUpdateUserLanguage/${userId}`, reqDto, {
    headers: authHeader(),
  });
};

export const addContacts = (reqDto, userId) => {
  return http.post(`contacts/addContacts/${userId}`, reqDto, {
    headers: authHeader(),
  });
};

export const sendInvite = (reqDto, userId) => {
  return http.put(`contacts/sendInvite/${userId}`, reqDto, {
    headers: authHeader(),
  });
};

export const getParticipantsForExternalUser = (userId) => {
  return http.get(
    `person/getParticipantsForExternalUser/{userId}?userId=${userId}`,
    {
      headers: authHeader(),
    }
  );
};

export const getAllFilesUploaded = (meetingId) => {
  //console.log(reqDto);
  return http.get(`/meeting/getAllFilesUploaded/` + meetingId, {
    headers: authHeader(),
  });
};

export const getEventDetailsByMeetingId = (meetingId) => {
  return http.get(`meeting/getEventDetailsByMeetingId/${meetingId}`);
};

export const grantModeratorRights = (meetingId, jitsiParticipantId, userId) => {
  //console.log(reqDto);
  return http.put(
    `/meeting/grantModeratorRights/${meetingId}/${jitsiParticipantId}/${userId}`,
    {
      headers: authHeader(),
    }
  );
};

export const checkDoctorFace = (doctorId, patientVisitId, reqDto) => {
  return http.post(
    `appTest/checkDoctorFace/${doctorId}/${patientVisitId}`,
    reqDto,
    {
      headers: authHeader(),
    }
  );
};
