import {
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import { XLg } from "react-bootstrap-icons";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import DateUtils from "../../../../utils/DateUtils";
import {validateEmail} from "../../../../utils/Utils";
import { toast } from "react-toastify";
import {generateCouponCode} from "../../../../services/AdminService";
import { jwtDecode } from "jwt-decode";
import { useTranslation } from "react-i18next";

export default function CreateCoupon({ handelCloseCreateCouponCode , refreshCouponCodeData }) {

  const userDetails = jwtDecode(localStorage.getItem("token"));
  const { t } = useTranslation();

  const [loading , setLoading] = useState(false);


  const [couponPurpose , setCouponPurpose] = useState('');

  const handleCouponPurpose = (e) =>{
    console.log('handleCouponPurpose' , e.target.value);
    setCouponPurpose(e.target.value);
  };

  const [couponDiscountPercentage , setCouponDiscountPercentage] = useState('');

  const handleDiscountPercentage = (event) =>{
    console.log('handleDiscountPercentage' , event.target.value);
    const numericValue = event.target.value.replace(/[^0-9]/g, "");
    setCouponDiscountPercentage(numericValue);
  };

  const [couponNumberOfUse , setCouponNumberOfUse] = useState('');

  const handleNumberOfUse = (event) =>{
    console.log('handleNumberOfUse' , event.target.value);
    const numericValue = event.target.value.replace(/[^0-9]/g, "");
    setCouponNumberOfUse(numericValue);
  };

  const [startDate, setStartDate] = useState(new Date());

  const changeCurrentDate = (newDate) => {
    console.log("changeCurrentDate", newDate);
    setStartDate(newDate);
  };

  const [couponUserName , setCouponUserName] = useState('');

  const handleUserName = (e) =>{
    console.log('handleUserName' , e.target.value);
    setCouponUserName(e.target.value);
  };

  const [couponUserEmail , setCouponUserEmail] = useState('');

  const handleUserEmail = (e) =>{
    console.log('handleUserEmail' , e.target.value);
    setCouponUserEmail(e.target.value);
  };

  const [couponCustomCode , setCouponCustomCode] = useState('');

  const handleCustomCode = (e) =>{
    console.log('handleCustomCode' , e.target.value.toUpperCase());
    setCouponCustomCode(e.target.value.toUpperCase());
  };

  const finalSubmit = () =>{
    console.log('finalSubmit');
    setLoading(true);

    if (couponPurpose.trim() === "") {
      toast.error(t("please_enter_purpose") , {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoading(false);
      return;
    };

    if (couponDiscountPercentage.trim() === "") {
      toast.error(t("please_enter_discount_precentage"), {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoading(false);
      return;
    };

    if (couponDiscountPercentage < 1) {
      toast.error(t("discount_precentage_cannot_be_less_then_one") , {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoading(false);
      return;
    };

    if (couponDiscountPercentage > 100) {
      toast.error(t("discount_precentage_cannot_be_more_then_hundred") , {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoading(false);
      return;
    };

    if (couponNumberOfUse.trim() === "") {
      toast.error(t("please_enter_no_of_use"), {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoading(false);
      return;
    };

    if (couponNumberOfUse < 1) {
      toast.error(t("useable_time_cannot_be_less_then_one"), {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoading(false);
      return;
    };

    if (couponUserName.trim() === "") {
      toast.error(t("please_enter_receiver_name"), {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoading(false);
      return;
    };

    if (couponUserEmail.trim() === "") {
      toast.error(t("please_enter_receiver_email_id"), {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoading(false);
      return;
    };

    if (!validateEmail(couponUserEmail)) {
      toast.error(t("please_enter_valid_receiver_email_id"), {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoading(false);
      return;
    };

    if (couponCustomCode && couponCustomCode.trim() === "") {
      toast.error(t("please_enter_custom_code") , {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoading(false);
      return;
    };

    if (couponCustomCode && couponCustomCode.length < 5) {
      toast.error(t("custom_code_cannot_be_less_then_five_character"), {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoading(false);
      return;
    };

    if (couponCustomCode && couponCustomCode.length > 10) {
      toast.error(t("custom_code_cannot_be_more_then_ten_character"), {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoading(false);
      return;
    };

    const reqDto = {
      purpose: couponPurpose,
      discountPc: couponDiscountPercentage,
      noOfTimesUseable: couponNumberOfUse,
      expireDate:DateUtils.getDateInDDMMYYYY(startDate),
      couponReceiverName : couponUserName ,
      couponReceiverMailId: couponUserEmail,
      transType: "ORGANIZATION_ONBOARD",
      coustomCode: couponCustomCode,
      activeFlag: "Y"
    }

    console.log('reqDto',reqDto);

    // return;

    generateCouponCode(
      userDetails.userId,
      reqDto
    ).then((response) => {
      console.log(response.data);
      setLoading(false);
      if (response.data.returnValue === "1") {
        toast.success(response.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        refreshCouponCodeData();
        setCouponPurpose('');
        setCouponDiscountPercentage('');
        setCouponNumberOfUse('');
        setStartDate(new Date());
        setCouponUserName('');
        setCouponUserEmail('');
        setCouponCustomCode('');
      } else {
        toast.error(response.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    });
  }


  return (
    <>
      <div className="flotingAreaContainer">
        <div className="flotingAreaClose">
          <div className="facHeader">{t("generate_discount_coupon")}</div>
          <IconButton
            className="CloseBtn"
            onClick={() => handelCloseCreateCouponCode()}
          >
            <XLg />
          </IconButton>
        </div>

        {loading ? (
          <div className="meetingVEContainer">
            <div className="center-progress" style={{ height: "65vh" }}>
              <CircularProgress sx={{ marginTop: "180px" }} />
            </div>
          </div>
        ) : (

        <div className="elementFormContainer">
          <div className="formElement">
            <FormControl className="formControl">
              <TextField
                label={t("purpose")}
                required
                variant="outlined"
                value={couponPurpose}
                className="formTextFieldArea"
                onChange={handleCouponPurpose}
              />
            </FormControl>
          </div>
          <div className="formElementGrp">
            <div className="formElement">
              <FormControl className="formControl">
                <TextField
                  label={t("discount_precentage")}
                  required
                  variant="outlined"
                  value={couponDiscountPercentage}
                  className="formTextFieldArea"
                  onChange={handleDiscountPercentage}
                  placeholder={t("discount_percent")}
                  inputProps={{
                    inputMode: "numeric",
                    pattern: "[0-9]*",
                  }}
                />
              </FormControl>
            </div>
            <div className="formElement">
              <FormControl className="formControl">
                <TextField
                  label={t("no_of_use")}
                  required
                  variant="outlined"
                  value={couponNumberOfUse}
                  className="formTextFieldArea"
                  onChange={handleNumberOfUse}
                  placeholder={t("no_of_use_times")}
                  inputProps={{
                    inputMode: "numeric",
                    pattern: "[0-9]*",
                  }}
                />
              </FormControl>
            </div>
            <div className="formElement">
              <FormControl className="formControl">
                <InputLabel id="fromDate" className="setCompletionDate">
                {t("expires_on")}
                </InputLabel>
                <ReactDatePicker
                  labelId="fromDate"
                  className="formDatePicker"
                  dateFormat="dd/MM/yyyy"
                  showIcon
                  minDate={new Date()}
                  selected={startDate}
                  onChange={(date) => changeCurrentDate(date)}
                />
              </FormControl>
            </div>
          </div>
          <div className="formElement">
            <FormControl className="formControl">
              <TextField
                label={t("receiver_name")}
                required
                variant="outlined"
                value={couponUserName}
                className="formTextFieldArea"
                onChange={handleUserName}
              />
            </FormControl>
          </div>
          <div className="formElement">
            <FormControl className="formControl">
              <TextField
                label={t("receiver_email_id")}
                required
                variant="outlined"
                value={couponUserEmail}
                className="formTextFieldArea"
                onChange={handleUserEmail}
              />
            </FormControl>
          </div>
          <div className="formElement">
            <FormControl className="formControl">
              <TextField
                label={t("custom_code")}
                variant="outlined"
                value={couponCustomCode}
                className="formTextFieldArea"
                onChange={handleCustomCode}
                placeholder="Enter code (5-10 characters)"
              />
            </FormControl>
          </div>
        </div>

        )}

        <div className="elementFooter">
          <div className="formBtnElement">
            <Button className="dfultPrimaryBtn" onClick={()=>finalSubmit()}  disabled={loading}>
            {loading ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                <>{t("save")}</>
              )}
              </Button>
              {!loading && (
            <Button
              className="dfultDarkSecondaryBtn"
              onClick={() => handelCloseCreateCouponCode()}
            >
              {t("cancel")}
            </Button>
              )}
          </div>
        </div>
      </div>
    </>
  );
}
