import {
  AdminPanelSettings,
  ChatBubble,
  Email,
  Mic,
  MicOff,
  PlaylistAdd,
  Speaker,
  Storage,
  Videocam,
  CorporateFare,
  VideocamOff,
  BlurOn,
  Web,
  CalendarMonth,
  Close,
  CloseRounded,
  Cancel,
  CancelOutlined,
  DesignServices,
  Clear,
  Tab,
  Dashboard,
  DocumentScannerOutlined,
  FileOpen,
  FileOpenOutlined,
  FilePresent,
  Note,
  NoteAlt,
  InboxTwoTone,
  Apps,
  ArrowDownward,
  Help,
} from "@mui/icons-material";
import HomeIcon from "@mui/icons-material/Home";
import { Box, Tooltip } from "@mui/material";
import React, { createContext, useEffect, useRef, useState } from "react";
import "../css/tabs.css";
import { jwtDecode } from "jwt-decode";

import { Plus } from "iconoir-react";
import FlotingContainer from "./component/FlotingContainer";
import { useDrag, useDrop } from "react-dnd";
import TabWrapper from "./TabWrapper";
import {
  ArrowDown,
  CalendarFill,
  Circle,
  File,
  FiletypeDoc,
  ListTask,
  VolumeMute,
  VolumeMuteFill,
} from "react-bootstrap-icons";
import { getLoginUserDetails } from "../../../utils/Utils";
import TabContent from "./TabContent";
import NewTabContent from "./NewTabContent";
import { v4 as uuidv4 } from "uuid";
import { toast } from "react-toastify";

export default function Tabs({
  tabs,
  setTabs,
  selectedEvent,
  setSelectedEvent,
  selectedEventWB,
  setSelectedEventWB,
  activeTab,
  setActiveTab,
  loginMode,
  state,
}) {
  // console.log("DriveDetails", DriveDetails);

  const currentTabNumber = useRef(null);
  const currentDragging = useRef(null);
  const shouldComputeDragAfter = useRef(true);

  const [{ isOver, canDrop }, tabDrop] = useDrop({
    accept: ["FLOATING_DIV"],
    drop: () => {
      // Handle drop event here
      console.log("Tab dropped!");
      if (currentDragging.current !== null) {
        const tempFloatingList = [...flotingList];
        const temp = tempFloatingList.splice(currentDragging.current, 1);

        setTabs([...tabs, ...temp]);
        setFlotingList(tempFloatingList);
        currentDragging.current = null;
      }
    },
    hover: (item, monitor) => {
      // Handle dragenter event here
      shouldComputeDragAfter.current = false;
    },
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
      canDrop: !!monitor.canDrop(),
    }),
  });

  const minimizeFloatingContainer = (index) => {
    if (index !== null) {
      const tempFloatingList = [...flotingList];
      const temp = tempFloatingList.splice(index, 1);
      setTabs([...tabs, ...temp]);
      setFlotingList(tempFloatingList);
      currentDragging.current = null;
    }
  };

  // const handleCloseFloatingContainer = (floatingKey) => {
  //   const updatedFloatingList = flotingList.filter(
  //     (floating) => floating.key !== floatingKey
  //   );
  //   setFlotingList(updatedFloatingList);
  // };

  useEffect(() => {
    if (!isOver && canDrop) {
      shouldComputeDragAfter.current = true;
    }
  }, [isOver, canDrop]);

  const [{ isOverBody, canDropBody }, dropBody] = useDrop({
    accept: "TAB_DIV",
    drop: () => {
      console.log("Tab dropped! on body");
      console.log("dragged index= ", currentDragging.current);
      if (currentDragging.current !== null) {
        const temp = [...tabs];
        const elementValue = temp.splice(currentDragging.current, 1);

        setTabs(temp);
        setFlotingList([...flotingList, ...elementValue]);
        setActiveTab(0);
        currentDragging.current = null;
      }
    },
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
      canDrop: !!monitor.canDrop(),
    }),
  });

  useEffect(() => {
    console.log("tabs-->>>", tabs);
    if (selectedEventWB) {
      setActiveTab(tabs.length - 1);
    }
  }, [selectedEventWB]);

  useEffect(() => {
    console.log("tabs-->>>", tabs);
    if (selectedEvent) {
      setActiveTab(tabs.length - 1);
    }
  }, [selectedEvent]);

  const [flotingList, setFlotingList] = useState([]);

  const handleTabClick = (index) => {
    console.log("tab clikced");
    setActiveTab(index);
  };

  const handleTabClose = (index) => {
    console.log("tab close clicked");
    const newTabs = [...tabs];
    newTabs.splice(index, 1);

    setTabs(newTabs);
    if (activeTab === index && index > 0) {
      setActiveTab(activeTab - 1);
    } else if (activeTab === index && index === 0 && newTabs.length > 0) {
      setActiveTab(0);
    } else if (activeTab > index) {
      setActiveTab(activeTab - 1);
    }
  };

  const handleNewTab = () => {
    const tabTitle =
      currentTabNumber.current === null
        ? `Tab ${tabs.length + 1}`
        : `Tab ${currentTabNumber.current + 1}`;

    currentTabNumber.current
      ? currentTabNumber.current++
      : (currentTabNumber.current = tabs.length + 1);

    const newTabs = [
      ...tabs,
      {
        key: uuidv4(),
        title: tabTitle,
        content: <NewTabContent tabIndex={tabs.length} tabs={tabs} />,
        isDraggable: true,
        type: "PlusAdd",
      },
    ];

    setTabs(newTabs);
    setActiveTab(newTabs.length - 1);
  };

  const [refresh, setRefresh] = useState(0);

  // useEffect(() => {
  //   console.log("useEffect", tabs.length);
  //   if (tabs?.length === 2) {
  //     addAppsTab();
  //   }
  // }, [tabs]);

  // const addAppsTab = () => {
  //   // const tabTitle =
  //   //   currentTabNumber.current === null
  //   //     ? `Tab ${tabs.length + 1}`
  //   //     : `Tab ${currentTabNumber.current + 1}`;

  //   // currentTabNumber.current
  //   //   ? currentTabNumber.current++
  //   //   : (currentTabNumber.current = tabs.length + 1);

  //   const newTabs = [
  //     ...tabs,
  //     {
  //       key: uuidv4(),
  //       title: "Apps",
  //       content: <NewTabContent tabIndex={tabs.length} />,
  //       isDraggable: true,
  //       type: "Apps",
  //     },
  //   ];

  //   setTabs(newTabs);
  // };

  return (
    <>
      <Box
        className="tabs-container hcFixed"
        sx={{ bgcolor: "dark.primaryNav" }}
      >
        <div className="tabs" ref={tabDrop}>
          {tabs.map((tab, index) => (
            <TabWrapper
              handleDragStart={(e) => {
                currentDragging.current = index;
              }}
              isDraggable={tab.isDraggable}
              key={tab.key}
            >
              <div
                key={index}
                className={`tab ${index === activeTab ? "active" : ""}`}
                onClick={() => handleTabClick(index)}
                onDragStart={(e) => {
                  console.log("drag start = ");
                  currentDragging.current = index;
                }}
              >
                <span className="tab-icon">
                  {tab.title === "Home" && <HomeIcon />}
                  {tab.title === "Inbox" && <InboxTwoTone />}
                  {tab.title === "Apps" && <Apps />}
                  {tab.title === "Dashboard" && <Dashboard />}
                  {tab.title === "Chat" && <ChatBubble />}
                  {tab.title === "Email" && <Email />}
                  {(tab.title === "Meeting Room" || tab.type === "Meeting") && (
                    <Videocam />
                  )}
                  {tab.title === "Task" && <ListTask />}
                  {tab.title === "Drive" && <Storage />}
                  {tab.title === "Create Task" && <PlaylistAdd />}
                  {tab.title === "Scheduler" && <CalendarMonth />}
                  {tab.title === "Admin" && <AdminPanelSettings />}
                  {tab.title === "Create Organization" && <CorporateFare />}
                  {tab.title === "Support Dashboard" && <Help />}
                  {/* {tab.type === "Meeting" && <Videocam />} */}
                  {tab.title === "Onboarding" && <BlurOn />}
                  {tab.title === "Select Template" && <Web />}
                  {tab.type === "Whiteboard" && <DesignServices />}
                  {tab.type === "PlusAdd" && <Tab />}
                  {tab.type === "File Viewer" && <FilePresent />}
                  {tab.type === "Transcription" && <NoteAlt />}
                </span>
                <Tooltip title={tab.title}>
                  <span className="tabsText"> {tab.title}</span>
                </Tooltip>
                {tab.type === "Meeting" && (
                  <>
                    {console.log(
                      "OnCLoseTab2",
                      tab.content.props.children.props.eventData
                    )}
                    <span className="tab-icon action">
                      <div
                        class="recording-indicator-circle"
                        title={"Video call in progress"}
                      >
                        {<div class="recording-indicator-dot-blinking"></div>}
                        {/* {tab.content.props.children.props.eventData
                          ._renderAudio && (
                          <div class="recording-indicator-dot-blinking"></div>
                        )} */}
                      </div>
                    </span>
                  </>
                )}
                {/* <span
                  className="close closeTab"
                  onClick={(e) => {
                    console.log("Meetingssssssssssssss-->>>", tab.content);
                    e.stopPropagation();
                    handleTabClose(index);
                  }}
                >
                  <Clear />
                </span> */}

                <span
                  className="close closeTab"
                  onClick={(e) => {
                    console.log("OnCLoseTab", tab.content);
                    const driveData = localStorage.getItem("driveData");
                    if (driveData) {
                      const DriveDetails = JSON.parse(driveData);
                      console.log("DriveDetails tab", tab.title === "Drive");

                      if (tab.title === "Drive") {
                        if (
                          tab.content.props.tabkey === DriveDetails.key &&
                          DriveDetails.loading == true
                        ) {
                          toast.error(
                            "You cannot close the drive until the file upload is complete",
                            {
                              position: toast.POSITION.TOP_RIGHT,
                            }
                          );
                        } else {
                          e.stopPropagation();
                          handleTabClose(index);
                        }
                      } else {
                        e.stopPropagation();
                        handleTabClose(index);
                      }
                    } else {
                      e.stopPropagation();
                      handleTabClose(index);
                    }
                  }}
                >
                  <Clear />
                </span>
              </div>
            </TabWrapper>
          ))}
          {/* {loginMode !== "GUEST" && (
            <div className="new-tab" onClick={handleNewTab}>
              <Plus />
            </div>
          )} */}
        </div>
        <div className="tab-content" ref={dropBody}>
          {tabs.map((tab, index) => (
            <TabContent
              title={tab.title}
              content={tab.content}
              isActive={index === activeTab}
              type={tab.type}
              key={tab.key}
            />
          ))}
          {/* {tabs[activeTab].content} */}

          {flotingList &&
            flotingList.length > 0 &&
            flotingList.map((floatingComponent, floatIndex) => (
              <FlotingContainer
                shouldComputeDragAfter={shouldComputeDragAfter}
                setCurrentDragging={() => {
                  currentDragging.current = floatIndex;
                }}
                floatingComponent={floatingComponent}
                minimize={() => minimizeFloatingContainer(floatIndex)}
              >
                {floatingComponent.content}
              </FlotingContainer>
            ))}
        </div>
      </Box>
    </>
  );
}
