import {
  ArrowBackIosNew,
  ArrowForwardIos,
  Cancel,
  Close,
  CloudUploadOutlined,
  DescriptionOutlined,
  Search,
  Verified,
  Undo,
  VisibilityOff,
  Visibility,
} from "@mui/icons-material";
import ReactTable from "react-table-6";
import "react-table-6/react-table.css";
import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Popover,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Tooltip,
  Checkbox,
  Modal,
  CircularProgress,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { Play, Plus, Trash, XLg } from "react-bootstrap-icons";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { getLoginUserDetails } from "../../../utils/Utils";
import { jwtDecode } from "jwt-decode";
import useFileUpload from "react-use-file-upload";
import DateUtils from "../../../utils/DateUtils";
import { toast } from "react-toastify";
import {
  MAX_FILE_SIZE_LEAVE_REQUEST,
  VALID_FILE_EXTENSION_LEAVE_REQUEST,
} from "../../../constants/const_values";

import { convertFileToBase64_Split } from "../../../utils/FileUtils";
import { useTranslation } from "react-i18next";

import {
  getLeaveRulesOfUser,
  getLeaveBalance,
  leaveRequest,
  getMyEventsInDateRanges,
  getLeaveRequestHistory,
  cancelLeaveRequest,
  withdrawalLeaveRequest,
  getHolidaysList,
  getLeaveList,
} from "../../../services/SchedulerService";

const LeaveRequest = ({ handleCloseMenuButton, loading, setLoading }) => {
  const userDetails = jwtDecode(localStorage.getItem("token"));
  const { t } = useTranslation();
  console.log("userDetails", userDetails);

  const [userLeaveRuleList, setUserLeaveRuleList] = useState([]);
  const [holidayList, setHolidayList] = useState([]);
  const [leaveList, setLeaveList] = useState([]);

  useEffect(() => {
    getLeaveRulesOfUser(userDetails.userId).then((response) => {
      if (response.data && response.data.length > 0) {
        setUserLeaveRuleList(response.data);
        console.log("response", response.data);
      }
    });

    getHolidaysList(loginUser.orgId).then((response) => {
      if (response.data) {
        if (response.data) {
          const temp = response.data.map((item) => {
            const dateComp = item.holidayDate.split("-");
            const dt = new Date();
            dt.setDate(dateComp[0]);
            dt.setMonth(parseInt(dateComp[1]) - 1);
            dt.setFullYear(dateComp[2]);
            return dt;
          });
          setHolidayList(temp);
        }
      }
    });

    getLeaveList(loginUser.userId).then((response) => {
      if (response.data) {
        setLeaveList(response.data);
      }
    });
  }, []);

  const {
    files,
    fileNames,
    fileTypes,
    totalSize,
    totalSizeInBytes,
    handleDragDropEvent,
    clearAllFiles,
    createFormData,
    setFiles,
    removeFile,
  } = useFileUpload();

  const inputRef = useRef(null);

  const inputFileRef = useRef(null);

  const [fileList, setFileList] = React.useState([]);
  const [uploading, setUploading] = React.useState(false);

  // const [loading, setLoading] = useState(false);

  const [documentSize, setDocumentSize] = useState(0);
  const [documentFor, setDocumentFor] = useState("");

  const [documents, setDocuments] = useState([]);

  const handelChangeFileUpload = async (e) => {
    console.log("handelChangeFileUpload");
    const file = e.target.files[0];
    console.log("file", file);
    if (
      !VALID_FILE_EXTENSION_LEAVE_REQUEST.includes(file.name.split(".").pop())
    ) {
      toast.error(t("invalid_file_type"), {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else if (file.size > 1024 * 1024 * MAX_FILE_SIZE_LEAVE_REQUEST) {
      toast.error(
        // `Please Upload Less Then ${MAX_FILE_SIZE_LEAVE_REQUEST}MB File`,
        t("please_select_file_less_than", { maxFileSize: MAX_FILE_SIZE_LEAVE_REQUEST }) ,
        {
          position: toast.POSITION.TOP_RIGHT,
        }
      );
    } else {
      let tmpFileList = documents;
      if (tmpFileList.length === 0) {
        tmpFileList.push({
          fileFor: documentFor,
          fileName: file.name,
          fileData: await convertFileToBase64_Split(file),
        });
      } else {
        if (tmpFileList.find(({ fileFor }) => fileFor === documentFor)) {
          tmpFileList = tmpFileList.filter(
            (file) => file.fileFor !== documentFor
          );
          tmpFileList.push({
            fileFor: documentFor,
            fileName: file.name,
            fileData: await convertFileToBase64_Split(file),
          });
        } else {
          tmpFileList.push({
            fileFor: documentFor,
            fileName: file.name,
            fileData: await convertFileToBase64_Split(file),
          });
        }
      }
      toast.info(t("file_attached"), {
        position: toast.POSITION.TOP_RIGHT,
      });
      setDocuments(tmpFileList);
      setDocumentSize(tmpFileList.length);
      setDocumentFor("");
      inputFileRef.current.value = null;
    }
  };

  //////////////////////////////////////////////////////

  const [dataHistory, setDataHistory] = useState([]);
  const [showCancelModal, setShowCancelModal] = useState(false);

  const [anchorElRP, setAnchorElRP] = React.useState(null);
  const [allUsersList, setAllUsersList] = useState([]);
  const [selectedGuest, setSelectedGuest] = useState(null);
  const [selectedGuestInput, setSelectedGuestInput] = useState("");
  const openRP = Boolean(anchorElRP);
  const idRP = openRP ? "simple-popover" : undefined;
  const buttonRPRef = useRef(null);
  const buttonRPCancelRef = useRef(null);
  const buttonApplyForAllRec = useRef(null);
  const buttonApplyForAllRecReschedule = useRef(null);
  const buttonApplyForAllRecAddParticipant = useRef(null);
  const buttonApplyForAllRecRemoveParticipant = useRef(null);

  const handleClickRP = (event) => {
    setAnchorElRP(event.currentTarget);
  };

  const handleCloseRP = () => {
    setAnchorElRP(null);
  };
  const [meetingParticipants, setMeetingParticipants] = useState([]);
  const [selectedParticipant, setSelectedParticipant] = React.useState(null);
  ////////////////////////

  ////////////////////////////////////private notes
  //add pvt note pop over
  const [userPrivateNotes, setUserPrivateNotes] = useState([]);
  const [anchorElPN, setAnchorElPN] = React.useState(null);

  const handleClickPN = (event) => {
    setAnchorElPN(event.currentTarget);
  };

  const handleClosePN = () => {
    setAnchorElPN(null);
  };

  const openPN = Boolean(anchorElPN);
  const idPN = openPN ? "add-pvtNotePopOver" : undefined;
  // const [openAddPvtNote, setOpenAddPvtNote] = useState(false);

  // const handleOpenAddPvtNote = () => {
  //   setOpenAddPvtNote(true);
  // };

  // const handleClosePvtNote = () => {
  //   setOpenAddPvtNote(false);
  // };

  const addPNRef = useRef(null);
  const cancelPNRef = useRef(null);
  const inputPNRef = useRef(null);
  const [pvtNote, setPvtNote] = useState(null);

  const onClickAddPvtNote = () => {
    console.log(pvtNote);
    if (pvtNote === null || pvtNote.trim() === "") {
      console.log("pvtNote");
      inputPNRef.current.style.borderColor = "red";
      inputPNRef.current.placeholder = "Please add note...";
      return;
    }
    // return;
    addPNRef.current.disabled = true;
    const userNotesDto = {
      eventNoteId: null,
      notes: pvtNote,
      notingTime: new Date().getTime(),
    };
    setUserPrivateNotes((prevList) => [...prevList, userNotesDto]);
    handleClosePN();
    setPvtNote(null);
    // setAnchorElPN(null);
    return;

    // addPrivateNotes(loginUser.userId, selectedEvent).then((response) => {
    //   console.log(response.data);
    //   getPrivateNotes(loginUser.userId, selectedEvent.eventId).then(
    //     (response) => {
    //       setUserPrivateNotes(response.data);
    //     }
    //   );
    // });
  };
  ///////////////////////////////////private notes end

  const loginUser = getLoginUserDetails();

  // const [startDateselect, setStartDateselect] = useState("");
  const [eventType, setEventType] = useState("Event");
  const [descriptionVisible, setDescriptionVisible] = useState(true);

  // const [selectEventType, setSelectEventType] = useState("onetime");

  // const handleEventType = (event) => {
  //   setSelectEventType(event.target.value);
  // };

  const [selectEventMode, setSelectEventMode] = useState("virtual");

  const handleEventMode = (event) => {
    setSelectEventMode(event.target.value);
  };
  const elementRef = useRef(null);
  const [arrowDisable, setArrowDisable] = useState(true);
  const [tempDivWidth, setTempDivWidth] = useState(0);
  useEffect(() => {
    if (elementRef.current) {
      setTempDivWidth(elementRef.current.offsetWidth);
    }
  }, [elementRef]);

  const handleHorizantalScroll = (element, speed, distance, step) => {
    let scrollAmount = 0;
    const slideTimer = setInterval(() => {
      element.scrollLeft += step;
      scrollAmount += Math.abs(step);
      if (scrollAmount >= distance) {
        clearInterval(slideTimer);
      }
      if (element.scrollLeft === 0) {
        setArrowDisable(true);
      } else {
        setArrowDisable(false);
      }
    }, speed);
  };

  const handleEventTypeChange = (event) => {
    setEventType(event.target.value);

    // Update visibility of description based on selected event type
    setDescriptionVisible(event.target.value === "Event");
  };
  ////////////////////////////////////////////////////////////////////////////////

  const [startDate, setStartDate] = useState(new Date());
  const [startDateselect, setStartDateselect] = useState("");
  const handleSetDate = (event) => {
    setStartDateselect(event.target.value);
  };

  ////////////////////////////////date////////////////////////////////////////////////////////

  const [startDay, setStartDay] = useState(new Date());
  const [endDay, setEndDay] = useState(new Date());
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [timeOptions, setTimeOptions] = useState([]);
  const [timeEndOptions, setTimeEndOptions] = useState([]);
  const [currentDay, setCurrentDay] = useState(getCurrentDay(startDay));
  const [currentEndDay, setEndCurrentDay] = useState(getCurrentDay(endDay));

  function getCurrentDay(date) {
    return date.toLocaleDateString("en-US", { weekday: "long" });
  }

  const handleStartDateChange = (date) => {
    console.log("date", date);
    setStartDay(date);
    setCurrentDay(getCurrentDay(date));
    // Ensure end date is not less than start date
    if (endDay < date) {
      setEndDay(date);
      setEndCurrentDay(getCurrentDay(date));
    }
    getCurrentTimeOptions(date);
    setEventStartdate(date);
    setEventEndDate(date);
  };

  const getLastDayOfMonth = (year, month) => {
    // Months are zero-based in JavaScript dates
    const lastDay = new Date(year, month + 1, 0);
    return lastDay;
  };

  const handleEndDateChange = (date) => {
    console.log("date", date);
    // Ensure end date is not less than start date
    if (date < startDay) {
      setStartDay(date);
      setCurrentDay(getCurrentDay(date));
    }
    setEndDay(date);
    setEndCurrentDay(getCurrentDay(date));
    setEventEndDate(date);
  };

  const handleStartTimeChange = (event) => {
    setStartTime(event.target.value);
  };

  const handleEndTimeChange = (event) => {
    setEndTime(event.target.value);
  };

  const getCurrentTimeOptions = (selectedDate) => {
    const options = [];
    const currentDate = new Date();

    let startHour = 0;
    let startMinute = 0;

    if (selectedDate.toDateString() === currentDate.toDateString()) {
      // If the selected date is today, start from the current time
      startHour = currentDate.getHours();
      startMinute = Math.ceil(currentDate.getMinutes() / 15) * 15;
    }

    for (let hour = startHour; hour <= 23; hour++) {
      const startMin =
        hour === startHour ? Math.ceil(startMinute / 15) * 15 : 0;
      for (let minute = startMin; minute < 60; minute += 15) {
        const formattedHour = (
          hour === 0 ? "00" : hour % 12 === 0 ? 12 : hour % 12
        ).toString();
        const formattedMinute = minute < 10 ? `0${minute}` : `${minute}`;
        const period = hour < 12 ? "AM" : "PM";
        const timeString = `${formattedHour}:${formattedMinute} ${period}`;
        options.push(timeString);
      }
    }

    setTimeOptions(options);
  };

  useEffect(() => {
    getCurrentTimeOptions(startDay);
  }, [startDay]);

  /////////////////////////////////////////////////////////////////////////////////////////

  const [selectEventType, setSelectEventType] = useState({});
  const [leaveBalance, setLeaveBalance] = useState(0);

  const handleEventType = (event) => {
    // setSelectEventType(event.target.value);
    const val = JSON.parse(event.target.value);

    if (val) {
      setSelectEventType(val);
      getLeaveBalance(userDetails.userId, val.id).then((response) => {
        setLeaveBalance(response.data);
      });
    }
  };

  const [isHalfDay, setIsHalfDay] = useState(false);

  const handleCheckboxChange = (event) => {
    setIsHalfDay(event.target.checked);
  };

  const [isHalfEndDay, setIsHalfEndDay] = useState(false);

  const handleCheckboxChangeEndDay = (event) => {
    setIsHalfEndDay(event.target.checked);
  };

  ///////////////////////////////////////////////////////////api////////////////
  const [eventList, setEventList] = useState([]);
  const [showEventModal, setShowEventModal] = useState(false);

  const [purpose, setPurpose] = useState("");
  const [eventStartDate, setEventStartdate] = useState(new Date());
  const [eventEndDate, setEventEndDate] = useState(new Date());
  const submitDto = useRef(undefined);

  const [historyStartDate, setHistoryStartDate] = useState();

  const handleClickSubmitButton = () => {
    setLoading(true);
    // const formattedStartDate = eventStartDate ? new Date(eventStartDate).toDateString() : null;
    // const formattedEndDate = eventEndDate ? new Date(eventEndDate).toDateString() : null;

    // console.log("eventStartDate", formattedStartDate);
    // console.log("eventEndDate", formattedEndDate);
    let base64List = [];
    const formData = createFormData();
    console.log("files", files);
    // console.log("files", formData);
    files.forEach((data) => {
      console.log("files", data);
    });

    if (purpose.trim() === "") {
      toast.error(t("please_enter_the_purpose"), {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoading(false);
      return;
    }

    if (
      selectEventType &&
      selectEventType.fileRequired &&
      selectEventType.fileRequired.split(",").length !== documents.length
    ) {
      toast.error(t("please_upload_all_the_required_files"), {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoading(false);
      return;
    }

    if (
      DateUtils.getDifferenceInDays(
        new Date(eventStartDate),
        new Date(eventEndDate)
      ) > leaveBalance
    ) {
      toast.error(t("you_cannot_take_more_leave_than_your_leave_balance"), {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoading(false);
      return;
    }

    toast.info(t("please_wait"), {
      position: toast.POSITION.TOP_RIGHT,
    });

    const reqDto = {
      leaveRuleId: selectEventType && selectEventType.id,
      startDate: DateUtils.getDateInDDMMYYYY(new Date(eventStartDate)),
      endDate: DateUtils.getDateInDDMMYYYY(new Date(eventEndDate)),
      duration: DateUtils.getDifferenceInDays(
        new Date(eventStartDate),
        new Date(eventEndDate)
      ),
      startDateHalfDate: isHalfDay ? "Y" : "N",
      endDateHalfDate: isHalfEndDay ? "Y" : "N",
      leaveType: selectEventType && selectEventType.leaveTypeDisplay,
      // eventDate: DateUtils.getDateInDDMMYYYY(eventDate.$d),
      purpose,
      documents,
    };

    console.log("reqDto", reqDto);
    submitDto.current = reqDto;

    getMyEventsInDateRanges(
      userDetails.userId,
      DateUtils.getDateInYYYYMMDD(new Date(eventStartDate)),
      DateUtils.getDateInYYYYMMDD(new Date(eventEndDate))
    ).then((response) => {
      console.log("getMyEventsInDateRanges", response.data);
      if (response.data) {
        setEventList(response.data);
        console.log("setEventList", response.data);
        if (response.data.length > 0) {
          setShowEventModal(true);
          // console.log("line 375");
        } else {
          submitData(reqDto);
        }
      }
    });
    setPurpose("");
    // submitData(reqDto);
  };

  const submitData = () => {
    console.log("submitDto.current", submitDto.current);
    leaveRequest(userDetails.userId, userDetails.orgId, submitDto.current).then(
      (response) => {
        console.log("response");
        setLoading(false);
        if (response.data && response.data.returnValue === "1") {
          toast.success(response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          fetchdata();
          handleCloseMenuButton();
        } else {
          toast.error(response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      }
    );
  };

  const fetchdata = () => {
    console.log("eventStartDate", eventStartDate);
    console.log("eventEndDate", eventEndDate);
    console.log("filterStartDate", filterStartDate);
    console.log("filterEndDate", filterEndDate);
    const fromDt = DateUtils.getDateInDDMMYYYY(new Date(filterStartDate));
    const toDt = DateUtils.getDateInDDMMYYYY(new Date(filterEndDate));
    let userDtl = userDetails;

    getLeaveRequestHistory(userDtl.userId, fromDt, toDt).then((response) => {
      console.log("getLeaveRequestHistory", response.data);
      setDataHistory(response.data);
      console.log("dataHistory", dataHistory);
    });
  };

  useEffect(() => {
    fetchdata();
  }, []);

  // const data = [
  //   {
  //     leavetype: "casual",
  //     fromdate: "12/04/24",
  //     todate: "14/04/24",
  //     status: "success",
  //     cancel:""
  //   },
  // ];

  ///////////////////////////////////////////////////////////////////////////

  const handleDeleteUser = () => {
    console.log();

    cancelLeaveRequest(leaveRequestId, userDetails.userId).then((response) => {
      if (response.data && response.data.returnValue === "1") {
        toast.success(response.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        fetchdata();
        // this.setState({ leaveRequestId: 0, showCancelModal: false });
      } else {
        toast.error(response.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    });
    setShowCancelModal(false);
  };

  const handleWithDrawRequest = () => {
    withdrawalLeaveRequest(leaveRequestId, userDetails.userId).then(
      (response) => {
        if (response.data && response.data.returnValue === "1") {
          toast.success(response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          fetchdata();
          // this.setState({ leaveRequestId: 0, showWithdrawModal: false });
        } else {
          toast.error(response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      }
    );
  };

  ////////////////////////////////////////////////////////////////////////////

  const [leaveRequestId, setLeaveRequestId] = useState(0);

  const [showWithdrawModal, setShowWithdrawModal] = useState(false);
  const [filtered, setFiltered] = useState([]);
  const [filterStartDate, setFilterStartDate] = useState(new Date());
  const [filterEndDate, setFilterEndDate] = useState(new Date());

  const data = dataHistory;

  const columns = [
    {
      id: "leaveType",
      Header: t("leave_type"),
      filterable: true,
      accessor: (row) => `${row.leaveType}`,
      filterMethod: (filter, row) =>
        row._original.leaveType &&
        row._original.leaveType
          .toLowerCase()
          .startsWith(filter.value.toLowerCase()),
    },
    {
      id: "startDate",
      Header: t("from_date"),
      filterable: true,
      minWidth: 100,
      maxWidth: 100,
      accessor: (row) => `${row.startDate}`,
      filterMethod: (filter, row) =>
        row._original.startDate &&
        row._original.startDate
          .toLowerCase()
          .startsWith(filter.value.toLowerCase()),
    },
    {
      id: "endDate",
      Header: t("to_date") ,
      filterable: true,
      minWidth: 100,
      maxWidth: 100,
      accessor: (row) => `${row.endDate}`,
      filterMethod: (filter, row) =>
        row._original.endDate &&
        row._original.endDate
          .toLowerCase()
          .startsWith(filter.value.toLowerCase()),
    },
    {
      id: "leaveStatus",
      Header: t("status") ,
      filterable: true,
      minWidth: 80,
      maxWidth: 80,
      accessor: (row) => `${row.leaveStatus}`,
      filterMethod: (filter, row) =>
        row._original.leaveStatus &&
        row._original.leaveStatus
          .toLowerCase()
          .startsWith(filter.value.toLowerCase()),
    },
    {
      id: "deleteIcon",
      Header: t("cancel"),
      filterable: false,
      sortable: false,
      minWidth: 80,
      maxWidth: 80,
      Cell: (props) => (
        <>
          {props.original.leaveStatus && (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {props.original.leaveStatus.toLowerCase() === "pending" && (
                <Tooltip title={t("cancel")} placement="left">
                  <IconButton
                    onClick={() => {
                      setLeaveRequestId(props.original.leaveRequestId);
                      setShowCancelModal(true);
                    }}
                  >
                    <Cancel color="red" />
                  </IconButton>
                </Tooltip>
              )}

              {props.original.leaveStatus.toLowerCase() === "approved" && (
                <Tooltip title={t("withdraw")} placement="left">
                  <IconButton
                    onClick={() => {
                      setLeaveRequestId(props.original.leaveRequestId);
                      setShowWithdrawModal(true);
                    }}
                  >
                    <Undo />
                  </IconButton>
                </Tooltip>
              )}
            </div>
          )}
        </>
      ),
    },
  ];

  const handleFilterStartDate = (date) => {
    setFilterStartDate(date);
  };

  const handleFilterEndDate = (date) => {
    setFilterEndDate(date);
  };

  ////////////////////////////////////////////////////////////////////////////////////////////////////////
  // show hide past leave
  const [pastLeaveVisible, setPastLeaveVisible] = useState(false);

  const togglePastLeave = () => {
    setPastLeaveVisible(!pastLeaveVisible);
  };
  ////////////////////////////////////////////////////////////////////////////////////////

  return (
    <>
      {loading ? (
        <div className="meetingVEContainer">
          <div className="center-progress" style={{ height: "65vh" }}>
            <CircularProgress sx={{ marginTop: "180px" }} />
          </div>
        </div>
      ) : (
        <>
          <div className="formElementGroup">
            <div className="formElement">
              <FormControl className="formControl">
                <InputLabel id="setContract">{t("leave_type")}</InputLabel>
                <Select
                  label={t("leave_type")}
                  required
                  className="formInputField"
                  variant="outlined"
                  labelId="setContract"
                  value={JSON.stringify(selectEventType)}
                  onChange={handleEventType}
                >
                  {/* <MenuItem value={"casualleave"}>Casual Leave</MenuItem>
            <MenuItem value={"earnedleave"}>Earned Leave</MenuItem>
            <MenuItem value={"compensatoryleave"}>Compensatory Leave</MenuItem>
            <MenuItem value={"paternityleave"}>Paternity Leave</MenuItem> */}
                  <MenuItem value="" disabled>
                  {t("select")}
                  </MenuItem>
                  {userLeaveRuleList.length > 0 &&
                    userLeaveRuleList.map((item) => {
                      return (
                        <MenuItem value={JSON.stringify(item)} key={item.id}>
                          {item.leaveTypeDisplay}
                        </MenuItem>
                      );
                    })}
                </Select>
              </FormControl>
            </div>

            <div className="formElement">
              <FormControl className="formControl">
                <TextField
                  // className="MainTitleTexbox"
                  className="formTextFieldArea "
                  hiddenLabel
                  label={t("available_leave_balance")}
                  variant="outlined"
                  // placeholder=" Leave Balance"
                  value={leaveBalance}
                />
              </FormControl>
            </div>
          </div>

          <div className="formElement">
            <FormControl className="formControl pRelative">
              <TextField
                label={t("purpose_of_leave")}
                variant="outlined"
                className="descptionTextFild"
                multiline
                rows={4}
                maxRows={7}
                required
                placeholder={t("enter_purpose_of_leave")}
                value={purpose}
                onChange={(e) => setPurpose(e.target.value)}
              ></TextField>
            </FormControl>
          </div>

          {selectEventType &&
          selectEventType.allowHalfDay &&
          selectEventType.allowHalfDay === "Y" ? (
            <>
              <div className="formElement">
                <div className="medDateTime">
                  <div
                    className="frmDateTime"
                    style={{
                      display: "flex !important",
                      flexWrap: "wrap",
                      alignItems: "center",
                      justifyContent: "flex-start !important",
                    }}
                  >
                    <FormControl className="formControl widthAuto">
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={isHalfDay}
                            onChange={handleCheckboxChange}
                            color="primary"
                            style={{ color: "white" }}
                          />
                        }
                        label={t("half_day")}
                        style={{ color: "white" }}
                      />
                    </FormControl>

                    <div className="frmDateInput">
                      <div className="frmDaysPick">{currentDay}</div>
                      {/* START TIME */}
                      <ReactDatePicker
                        className="frmdatePick"
                        selected={startDay}
                        // onChange={(date) => setStartDate(date)}
                        onChange={handleStartDateChange}
                        minDate={new Date()}
                        maxDate={new Date().getTime() + 1051200 * 60000}
                        dateFormat="dd-MMM-yyyy"
                        excludeDates={holidayList}
                        // minDate={new Date()}
                        // value={eventStartDate}
                        // onChange={(e) => setEventStartdate(e.target.value)}
                      />
                    </div>
                  </div>

                  {/* <span className="spanElement">To</span> */}

                  <div
                    className="toDateTime"
                    style={{
                      flexWrap: "wrap",
                      alignItems: "center",
                      justifyContent: "flex-start !important",
                    }}
                  >
                    <FormControl className="formControl widthAuto">
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={isHalfEndDay}
                            onChange={handleCheckboxChangeEndDay}
                            color="primary"
                            style={{ color: "white" }}
                          />
                        }
                        label={t("half_day")}
                        style={{ color: "white" }}
                      />
                    </FormControl>
                    <div className="frmDateInput">
                      <div className="frmDaysPick">{currentEndDay}</div>
                      {/* END TIME */}
                      <ReactDatePicker
                        className="frmdatePick"
                        selected={endDay}
                        // onChange={(date) => setStartDate(date)}
                        onChange={handleEndDateChange}
                        minDate={new Date()}
                        maxDate={new Date().getTime() + 1051200 * 60000}
                        dateFormat="dd-MMM-yyyy"
                        excludeDates={holidayList}
                        // value={eventEndDate}
                      />
                    </div>
                    {/* <div className="toTimeInput">
              <Select
                className="slctFrmTime"
                value={endTime}
                onChange={handleEndTimeChange}
                sx={{ maxWidth: "115px !important" }}
              >
                {timeEndOptions.map((time, index) => (
                  <MenuItem key={index} value={time}>
                    {time}
                  </MenuItem>
                ))}
              </Select>
            </div> */}
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="formElement">
                <div className="medDateTime">
                  <div
                    className="frmDateTime"
                    style={{
                      display: "flex !important",
                      flexWrap: "wrap",
                      justifyContent: "center !important",
                    }}
                  >
                    <div className="frmDateInput">
                      <div className="frmDaysPick">{currentDay}</div>
                      {/* START TIME */}
                      <ReactDatePicker
                        className="frmdatePick"
                        selected={startDay}
                        // onChange={(date) => setStartDate(date)}
                        onChange={handleStartDateChange}
                        minDate={new Date()}
                        maxDate={new Date().getTime() + 1051200 * 60000}
                        dateFormat="dd-MMM-yyyy"
                        // value={eventStartDate}
                        // onChange={(e) => setEventStartdate(e.target.value)}
                      />
                    </div>
                    {/* <div className="frmTimeInput">
              <Select
                className="slctFrmTime"
                value={startTime}
                onChange={handleStartTimeChange}
                sx={{ maxWidth: "115px !important" }}
              >
                {timeOptions.map((time, index) => (
                  <MenuItem key={index} value={time}>
                    {time}
                  </MenuItem>
                ))}
              </Select>
            </div> */}
                  </div>

                  <span className="spanElement">{t("to")}</span>

                  <div
                    className="toDateTime"
                    style={{
                      flexWrap: "wrap",
                      justifyContent: "center !important",
                    }}
                  >
                    <div className="frmDateInput">
                      <div className="frmDaysPick">{currentEndDay}</div>
                      {/* END TIME */}
                      <ReactDatePicker
                        className="frmdatePick"
                        selected={endDay}
                        // onChange={(date) => setStartDate(date)}
                        onChange={handleEndDateChange}
                        minDate={new Date()}
                        maxDate={new Date().getTime() + 1051200 * 60000}
                        dateFormat="dd-MMM-yyyy"
                        // value={eventEndDate}
                      />
                    </div>
                    {/* <div className="toTimeInput">
              <Select
                className="slctFrmTime"
                value={endTime}
                onChange={handleEndTimeChange}
                sx={{ maxWidth: "115px !important" }}
              >
                {timeEndOptions.map((time, index) => (
                  <MenuItem key={index} value={time}>
                    {time}
                  </MenuItem>
                ))}
              </Select>
            </div> */}
                  </div>
                </div>
              </div>
            </>
          )}

          {Object.keys(selectEventType).length > 0 &&
            selectEventType.fileRequired &&
            selectEventType.fileRequired.split(",").length > 0 && (
              <>
                <div class="tskElemHeding">{t("upload_files")}</div>

                {/*<div className="form-container">
                  <div>
                    <ul>
                      {fileNames.map((name) => (
                        <li key={name}>
                          <span style={{ color: "white" }}>{name}</span>

                          <span
                            style={{ cursor: "pointer" }}
                            onClick={() => removeFile(name)}
                          >
                            &nbsp;&nbsp;
                            <font style={{ color: "red" }}>&#x2715;</font>
                          </span>
                        </li>
                      ))}
                    </ul>

      
                  </div> 
                  
                </div>*/}
                <div className="indentFormtable">
                  <div className="indtableResponsive">
                    <table>
                      <thead>
                        <tr>
                          <th>{t("file_name")}</th>
                          <th>{t("upload")}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {selectEventType.fileRequired.split(",").map((item) => {
                          return (
                            <>
                              <tr key={item}>
                                <td>{item}</td>
                                <td>
                                  {documentSize >= 0 && (
                                    <Tooltip
                                      title={
                                        documents.find(
                                          (x) => x.fileFor === item
                                        ) ? (
                                          `${
                                            documents.filter(
                                              (x) => x.fileFor === item
                                            )[0].fileName
                                          }`
                                        ) : (
                                          <>
                                            <ul>
                                              {/* <li>{`Maximum File Size Allowed ${MAX_FILE_SIZE_LEAVE_REQUEST}MB`}</li> */}
                                              <li>{t("max_file_size_allowed", { size: MAX_FILE_SIZE_LEAVE_REQUEST })}</li>
                                              {/* <li>{`Extension Allowed ${VALID_FILE_EXTENSION_LEAVE_REQUEST.map(
                                                (ex) => ` ${ex}`
                                              )}`}</li> */}
                                              <li>
                                            {t("extensions_allowed", { extensions: VALID_FILE_EXTENSION_LEAVE_REQUEST.join(", ") })}
                                             </li>

                                            </ul>
                                          </>
                                        )
                                      }
                                    >
                                      <div className="taskVDContBtn ">
                                        <Button
                                          variant="contained"
                                          startIcon={<CloudUploadOutlined />}
                                          onClick={() => {
                                            setDocumentFor(item);
                                            inputFileRef.current.click();
                                          }}
                                        >
                                          {t("upload")}
                                        </Button>
                                      </div>
                                    </Tooltip>
                                  )}
                                </td>
                              </tr>
                            </>
                          );
                        })}
                        <input
                          hidden
                          type="file"
                          ref={inputFileRef}
                          onChange={handelChangeFileUpload}
                        />
                      </tbody>
                    </table>
                  </div>
                </div>
              </>
            )}

          <div className="taskElementGrp">
            <Button onClick={togglePastLeave} className="showHideLeaveDtlBtn">
              {pastLeaveVisible ? (
                <>
                  <VisibilityOff /> {t("hide_leave_details")}
                </>
              ) : (
                <>
                  <Visibility /> {t("show_leave_details")}
                </>
              )}
            </Button>
          </div>
          {pastLeaveVisible && (
            <>
              <div className="formElement">
                <div className="medDateTime">
                  <div
                    className="frmDateTime"
                    style={{
                      display: "flex !important",
                      flexWrap: "wrap",
                      justifyContent: "center !important",
                    }}
                  >
                    <div className="frmDateInput">
                      <div className="frmDaysPick">{currentDay}</div>
                      {/* START TIME */}
                      <ReactDatePicker
                        className="frmdatePick"
                        selected={filterStartDate}
                        // onChange={(date) => setStartDate(date)}
                        onChange={handleFilterStartDate}
                        dateFormat="dd-MMM-yyyy"
                        // minDate={new Date()}
                        // value={eventStartDate}
                        // onChange={(e) => setEventStartdate(e.target.value)}
                      />
                    </div>
                    {/* <div className="frmTimeInput">
              <Select
                className="slctFrmTime"
                value={startTime}
                onChange={handleStartTimeChange}
                sx={{ maxWidth: "115px !important" }}
              >
                {timeOptions.map((time, index) => (
                  <MenuItem key={index} value={time}>
                    {time}
                  </MenuItem>
                ))}
              </Select>
            </div> */}
                  </div>

                  <span className="spanElement">{t("to")}</span>

                  <div
                    className="toDateTime"
                    style={{
                      flexWrap: "wrap",
                      justifyContent: "center !important",
                    }}
                  >
                    <div className="frmDateInput">
                      <div className="frmDaysPick">{currentEndDay}</div>
                      {/* END TIME */}
                      <ReactDatePicker
                        className="frmdatePick"
                        selected={filterEndDate}
                        // onChange={(date) => setStartDate(date)}
                        onChange={handleFilterEndDate}
                        dateFormat="dd-MMM-yyyy"
                        // minDate={startDay}
                        // value={eventEndDate}
                      />
                    </div>
                  </div>
                  <div className="searchLeaveBtn">
                    <IconButton onClick={() => fetchdata()}>
                      <Search />
                    </IconButton>
                  </div>
                </div>
              </div>

              <ReactTable
                data={data}
                columns={columns}
                defaultSorted={[
                  {
                    id: "leaveType",
                    desc: false,
                  },
                ]}
                onFilteredChange={(filtered) => setFiltered(filtered)}
                defaultPageSize={5}
                className="viewLeaveListTD -striped -highlight"
                NoDataComponent={() =>
                  data.length === 0 ? (
                    <div
                      style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        color: "#fff",
                      }}
                    >
                     {t("no_leave_history_found")}
                    </div>
                  ) : null
                }
              />
            </>
          )}
        </>
      )}

      <div className="fixedButtonsContainer">
        <div className="formBtnElement">
          {/* <Button
            className="dfultPrimaryBtn"
            onClick={() => handleClickSubmitButton()}
          >
            Submit
          </Button> */}
          <Button
            className="dfultPrimaryBtn"
            onClick={() => handleClickSubmitButton()}
            // disabled={loading}
          >
            {loading ? (
              <CircularProgress size={24} color="inherit" />
            ) : (
              <>{t("submit")}</>
            )}
            {/* Submit */}
          </Button>
          {!loading && (
            <Button
              className="dfultDarkSecondaryBtn"
              onClick={() => handleCloseMenuButton()}
            >
              {t("cancel")}
            </Button>
          )}
        </div>
      </div>

      {showCancelModal && (
        <>
          <Modal
            open={showCancelModal}
            onClose={() => setShowCancelModal(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className="coustomModel"
          >
            <Box
              className="createEvent responsiveModelAdmin"
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                maxHeight: "70vh",
                width: "400px",
                bgcolor: "background.paper",
                boxShadow: 24,
                p: 4,
              }}
            >
              <div>
                <h3 style={{ marginBottom: "40px" }}>
                {t("do_you_want_to_cancel_this_leave_request")} ?
                </h3>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: 2,
                  }}
                >
                  <Button
                    // variant="contained"
                    // color="primary"
                    className="dfultPrimaryBtn"
                    onClick={() => {
                      handleDeleteUser();
                    }}
                  >
                  {t("yes")}
                  </Button>
                  <Button
                    // variant="contained"
                    // color="secondary"
                    className="dfultDarkSecondaryBtn"
                    onClick={() => setShowCancelModal(false)}
                  >
                  {t("no")}
                  </Button>
                </div>
              </div>
            </Box>
          </Modal>
        </>
      )}

      {showWithdrawModal && (
        <>
          <Modal
            open={showWithdrawModal}
            onClose={() => setShowWithdrawModal(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className="coustomModel"
          >
            <Box
              className="createEvent responsiveModelAdmin"
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                maxHeight: "70vh",
                width: "400px",
                bgcolor: "background.paper",
                boxShadow: 24,
                p: 4,
              }}
            >
              <div>
                <h3 style={{ marginBottom: "40px" }}>
                {t("do_you_want_to_withdraw_this_leave_request")} ?
                </h3>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: 2,
                  }}
                >
                  <Button
                    // variant="contained"
                    // color="primary"
                    className="dfultPrimaryBtn"
                    onClick={handleWithDrawRequest}
                  >
                    {t("yes")}
                  </Button>
                  <Button
                    // variant="contained"
                    // color="secondary"
                    className="dfultDarkSecondaryBtn"
                    onClick={() => setShowWithdrawModal(false)}
                  >
                    {t("no")}
                  </Button>
                </div>
              </div>
            </Box>
          </Modal>
        </>
      )}

      {showEventModal && (
        <Modal
          open={showEventModal}
          onClose={() => setShowEventModal(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          className="coustomModel"
        >
          <Box
            className="createEvent responsiveModelAdmin"
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              maxHeight: "80vh",
              width: "800px",
              bgcolor: "background.paper",
              boxShadow: 24,
              p: 4,
            }}
          >
            <div>
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                mb={1}
              >
                {t("events_between")}{" "}
                {DateUtils.getDateInDDMMYYYY(new Date(filterStartDate))} {t("to")}{" "}
                {DateUtils.getDateInDDMMYYYY(new Date(eventEndDate))}
              </Typography>

              <ReactTable
                data={eventList}
                columns={[
                  {
                    id: "title",
                    // Header: "Title",
                    Header: (
                      <div style={{ color: "black", textAlign: "left" }}>
                        {t("title")}
                      </div>
                    ),
                    filterable: true,
                    accessor: (row) => row.title && `${row.title}`,
                    filterMethod: (filter, row) =>
                      row._original.title
                        .toLowerCase()
                        .startsWith(filter.value.toLowerCase()),
                  },
                  {
                    id: "start",
                    // Header: "Start Time",
                    Header: (
                      <div style={{ color: "black", textAlign: "left" }}>
                        {t("start_time")}
                      </div>
                    ),
                    filterable: true,
                    accessor: (row) =>
                      row.start &&
                      `${DateUtils.getDateInDDMMYYYYHH12MM(
                        new Date(row.start)
                      )}`,
                    filterMethod: (filter, row) =>
                      row._original.start
                        .toLowerCase()
                        .includes(filter.value.toLowerCase()),
                  },
                  {
                    id: "end",
                    // Header: "End Time",
                    Header: (
                      <div style={{ color: "black", textAlign: "left" }}>
                        {t("end_time")}
                      </div>
                    ),
                    filterable: true,
                    accessor: (row) =>
                      row.end &&
                      `${DateUtils.getDateInDDMMYYYYHH12MM(new Date(row.end))}`,
                    filterMethod: (filter, row) =>
                      row._original.end
                        .toLowerCase()
                        .includes(filter.value.toLowerCase()),
                  },
                ]}
                getTrProps={() => ({
                  style: {
                    backgroundColor: "white",
                  },
                })}
                onFilteredChange={(filtered) => this.setState({ filtered })}
                // defaultPageSize={
                //   this.props.dataList.length < 5
                //     ? this.props.dataList.length
                //     : 5
                // }
                className="-striped -highlight"
                style={{
                  // border: "1px solid #d1d1d1",
                  // borderRadius: "12px",
                  // color: "black",
                  maxHeight: "calc(100vh - 288px)",
                }}
              />

              <Button
                // variant="contained"
                // color="primary"
                className="dfultPrimaryBtn"
                onClick={() => {
                  submitData();
                  setShowEventModal(false);
                }}
              >
                {t("yes")}
              </Button>
              <Button
                // variant="contained"
                // color="secondary"
                className="dfultDarkSecondaryBtn"
                style={{ marginLeft: "590px", position: "fixed" }}
                // onClick={() => setShowEventModal(false)}
                onClick={() => {
                  setShowEventModal(false);
                  setLoading(false);
                }}
              >
                {t("no")}
              </Button>
            </div>
          </Box>
        </Modal>
      )}
    </>
  );
};

export default LeaveRequest;
