import { DragIndicator } from "@mui/icons-material";
import {
  Autocomplete,
  Button,
  CircularProgress,
  FormControl,
  FormControlLabel,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
} from "@mui/material";
import React from "react";
import { useState, useEffect } from "react";
import { XLg } from "react-bootstrap-icons";
import {
  // getPersonsOfOrg,
  getOrgEmployees,
  getMappedUnmappedMenusOfUser,
  mapMenusToUser,
} from "../../../../services/AdminService";
import { jwtDecode } from "jwt-decode";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

export default function UpdateAccessControl({
  handleCloseUpdatedAccessControl,
  refreshAccessControlData,
  updatedAccessControlData,
}) {
  console.log("updatedAccessControlData", updatedAccessControlData);

  const userDetails = jwtDecode(localStorage.getItem("token"));
  const { t } = useTranslation();
  const [userNameData, setUserNameData] = useState([]);
  const [userData, setUserData] = useState([]);

  const [selectAllCheck, setSelectAllCheck] = useState(false);
  const [selectNoneCheck, setSelectNoneCheck] = useState(false);

  const [loading, setLoading] = useState(false);
  // const [selectedUserId, setSelectedUserId] = useState('');

  const [selectedUserName, setSelectedUserName] = useState(
    updatedAccessControlData.userName
  );

  useEffect(() => {
    getOrgEmployees(userDetails.orgId).then((response) => {
      console.log("response data=", response.data);
      setUserData(response.data);
      const val = response.data.map((item) => item.userName);
      console.log("val", val);
      setUserNameData(val);
    });
  }, [userDetails.orgId]);

  const handleUserNameChange = (event, value) => {
    console.log("handleUserNameChange", value);
    setSelectedUserName(value);
  };
  // const [isChecked, setIsChecked] = useState(false);

  // const handleChange = (event) => {
  //   setIsChecked(event.target.checked);
  // };

  const [mapDataOfUser, setMapDataOfUser] = useState([]);
  const [switchStates, setSwitchStates] = useState([]);

  // useEffect(() => {
  //   getMappedUnmappedMenusOfUser(userDetails.userId).then((response) => {
  //     console.log("getMappedUnmappedMenusOfUser", response.data);
  //     setMapDataOfUser(response.data);
  //     const initialSwitchStates = response.data.map(
  //       (item) => item.mapped === "Y"
  //     );
  //     setSwitchStates(initialSwitchStates);
  //   });
  // }, [userDetails.userId]);

  useEffect(() => {
    fetchMapMenuForUser();
  }, []);

  //   const fetchMapMenuForUser = () => {
  //     getMappedUnmappedMenusOfUser(userDetails.userId).then((response) => {
  //       console.log("getMappedUnmappedMenusOfUser", response.data);
  //       setMapDataOfUser(response.data);
  //       const initialSwitchStates = response.data.map(
  //         (item) => item.mapped === "Y"
  //       );
  //       setSwitchStates(initialSwitchStates);
  //     });
  //   }

  const fetchMapMenuForUser = () => {
    const itemsString = updatedAccessControlData.menus;
    const itemsArray = itemsString.split(",");
    console.log("itemsArray", itemsArray);

    getMappedUnmappedMenusOfUser(userDetails.userId).then((response) => {
      console.log("getMappedUnmappedMenusOfUser", response.data);
      setMapDataOfUser(response.data);

      // Initialize switch states based on whether each menu in response.data is in itemsArray
      const initialSwitchStates = response.data.map((item) =>
        itemsArray.includes(item.menuName)
      );

      console.log("initialSwitchStates", initialSwitchStates);

      const allTrue = initialSwitchStates.every((state) => state === true);

      if (allTrue) {
        setSwitchStates(initialSwitchStates);
        setSelectAllCheck(true);
      } else {
        setSwitchStates(initialSwitchStates);
      }
    });
  };

  // Function to handle changes in switch state
  const handleChange = (index) => (event) => {
    const newState = [...switchStates];
    newState[index] = event.target.checked;
    setSwitchStates(newState);

    const allFalse = newState.every((state) => !state);
    setSelectAllCheck(allFalse);

    const allTrue = newState.every((state) => state);
    setSelectAllCheck(allTrue);

    const selectNone = newState.every((state) => {
      return state === false;
    });
    setSelectNoneCheck(selectNone);
  };

  console.log("switchStates", switchStates);

  const handleSelectAll = (event) => {
    console.log("handleSelectAll triggered", event.target.checked);
    const val = event.target.checked;
    if (val) {
      setSwitchStates(new Array(switchStates.length).fill(true));
      setSelectAllCheck(true);
    } else {
      setSwitchStates(new Array(switchStates.length).fill(false));
      setSelectAllCheck(false);
    }
    console.log("switchStates after select all:", switchStates);
  };

  // const handleSelectAll = () => {
  //   console.log("handleSelectAll triggered");
  //   setSwitchStates(new Array(switchStates.length).fill(true));
  //   setSelectAllCheck(true);
  //   setSelectNoneCheck(false);
  //   console.log("switchStates after select all:", switchStates);
  // };

  const handleSelectNone = () => {
    console.log("handleSelectNone triggered");
    setSwitchStates(new Array(switchStates.length).fill(false));
    setSelectNoneCheck(true);
    setSelectAllCheck(false);
    console.log("switchStates after select none:", switchStates);
  };

  // Data for five list items
  const listItems = [
    "External User - List Of External User",
    "Functions - Add / Delete Activity",
    "Functions - Add / Delete Routine Activity",
    "Functions - Add / Delete Subactivity",
    "Functions - Add / Edit Functions",
    "Holiday / Working Schedule - Add Holiday",
    "Holiday / Working Schedule - Holiday List",
    "Holiday / Working Schedule - Working Days List",
    "Items - Add Items",
    "Items - Non Verified Items",
    "Items - Verified Items",
    "Leave Rules - Add Leave Balance",
    "Leave Rules - Add Rule",
    "Leave Rules - List Of Leave Rules",
    "Organization - Edit",
  ];
  const userlist = [
    { label: "Sanket Santra (IT)" },
    { label: "Avijit Roy (HR)" },
    { label: "Rakesh Kumar (R&D)" },
  ];

  const handleSubmit = () => {
    console.log("handleSubmit");
    setLoading(true);

    if (selectedUserName.trim() === "") {
      toast.error(t("please_select_user"), {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoading(false);
      return;
    }

    let selectedUserId = "";

    const selectedUser = userData.find(
      (user) => user.userName === selectedUserName
    );

    console.log("selectedUser", selectedUser);

    if (selectedUser) {
      // If user is found, set the selectedUserId
      selectedUserId = selectedUser.userId;
    }

    const reqDto = mapDataOfUser.map((item, index) => ({
      menuId: item.menuId,
      menuName: item.menuName,
      menuNameWithModule: item.menuNameWithModule,
      mapped: switchStates[index] ? "Y" : "N",
    }));

    console.log("reqDto", reqDto);

    console.log("selectedUserId", selectedUserId);

    const reqDtoFiltered = reqDto.filter((item) => item.mapped === "Y");

    console.log("reqDtoFiltered", reqDtoFiltered);

    const newId = selectedUserId !== "" ? selectedUserId : userDetails.userId;
    // return;

    mapMenusToUser(newId, userDetails.userId, reqDtoFiltered).then(
      (response) => {
        console.log("mapMenusToUser", response.data);
        if (response.data.returnValue === "1") {
          toast.success(response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          refreshAccessControlData();
          handleCloseUpdatedAccessControl();
          setSelectedUserName("");
          fetchMapMenuForUser();
          // setSwitchStates([]);
          setLoading(false);
        } else {
          toast.error(response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          setLoading(false);
        }
      }
    );
  };

  return (
    <>
      <div className="flotingAreaContainer">
        <div className="flotingAreaClose">
          <div className="facHeader">{t("edit_access_permission")}</div>
          <IconButton
            className="CloseBtn"
            onClick={() => handleCloseUpdatedAccessControl()}
          >
            <XLg />
          </IconButton>
        </div>

        {loading ? (
          <div className="meetingVEContainer">
            <div className="center-progress" style={{ height: "65vh" }}>
              <CircularProgress sx={{ marginTop: "180px" }} />
            </div>
          </div>
        ) : (
          <div className="elementFormContainer">
            <div className="formElement">
              <FormControl className="formControl">
                <Autocomplete
                  labelId="selctUsr"
                  noOptionsText={t("please_type_to_search")}
                  //  multiple
                  className="formAutocompleteField"
                  variant="outlined"
                  disablePortal
                  freeSolo
                  id="selectUser"
                  options={userNameData}
                  value={selectedUserName}
                  onChange={handleUserNameChange}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      className="formAutoComInputField "
                      label={t("select_user")}
                    />
                  )}
                  disabled
                />
              </FormControl>
            </div>

            {/* <div class="tskElemHeding mt10">Role based access control</div> */}

            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                gap: "80px",
                alignItems: "center",
              }}
            >
              <div className="tskElemHeding mt10">{t("menus")}</div>
              <div
                className="tskElemHeding mt10"
                style={{ display: "flex", alignItems: "center", gap: "5px" }}
              >
                {t("select_all")}
                <input
                  type="checkbox"
                  checked={selectAllCheck}
                  onChange={handleSelectAll}
                />
              </div>
              {/* <div className="tskElemHeding mt10" style={{display: 'flex', alignItems: 'center', gap: '5px'}}>
            Unselect All <input type="checkbox" checked={selectNoneCheck} onChange={handleSelectNone} />
            </div> */}
            </div>

            <ul className="accessControlList">
              {mapDataOfUser.map((menuItem, index) => (
                <li key={index}>
                  <div className="acsConLstInd">
                    <div className="accessName">
                      <span className="acnIoc">
                        <DragIndicator />
                      </span>
                      <span className="acnName">{menuItem.menuName}</span>
                    </div>
                    <div className="accessControlBtn tblActionBtnGrp">
                      <FormControlLabel
                        className="couponStatusSwitch"
                        control={
                          <Switch
                            checked={switchStates[index]}
                            onChange={handleChange(index)}
                            color="primary"
                          />
                        }
                        label={switchStates[index] ? t("enable") : t("disable")}
                      />
                    </div>
                  </div>
                </li>
              ))}
              {/* <li>
                    <div className='acsConLstInd'>
                        <div className='accessName'>
                            <span className='acnIoc'><DragIndicator/></span>
                            <span className='acnName'>Accounts and Invoices - Add Accounts and Invoices</span>
                        </div>
                        <div className='accessControlBtn tblActionBtnGrp'>
                            <FormControlLabel
                                className="couponStatusSwitch"
                                control={<Switch checked={isChecked} onChange={handleChange} color="primary" />}
                                label={isChecked ? "Enable" : "Disable"}
                            />
                         </div>
                    </div>
                </li> */}
            </ul>
          </div>
        )}

        <div className="elementFooter">
          <div className="formBtnElement">
            <Button
              className="dfultPrimaryBtn"
              onClick={() => handleSubmit()}
              disabled={loading}
            >
              {loading ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                <>{t("save")}</>
              )}
            </Button>
            {!loading && (
              <Button
                className="dfultDarkSecondaryBtn"
                onClick={() => handleCloseUpdatedAccessControl()}
              >
                {t("cancel")}
              </Button>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
