import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import React, { useMemo } from "react";
import ReactDatePicker from "react-datepicker";
import { Controller, useForm } from "react-hook-form";
import {
  getAllDepartment,
  getCountryList,
  getOrgDetailsByOrgId,
  getPositionsOfOrganization,
  getRoutineActivity,
  getStateList,
  getStaticDDOfOrg,
  getUnits,
} from "../../../../services/AdminService";
import { useEffect } from "react";
import { useState } from "react";
import { getLoginUserDetails } from "../../../../utils/Utils";
import { NumericFormatCustom } from "../../../task/view/component/Forms/NumericFormatCustom";
import { SalaryTable } from "./SalaryTable";
import { Add } from "@mui/icons-material";
import { Trash } from "react-bootstrap-icons";
import { forwardRef } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

export const LOIForm = forwardRef(
  ({ formData, setFormData, handleShowPreview }, loiRef) => {
    const loginUserDetails = getLoginUserDetails();
    const [staticDodList, setStaticDodList] = useState([]);
    const [staticDodListCopy, setStaticDodListCopy] = useState([]);
    const [salaryInfoList, setSalaryInfoList] = useState([]);
    const [documentName, setDocumentName] = useState("");
    const [positionList, setPositionList] = useState([]);
    const [departmentList, setDepartmentList] = useState([]);
    const [countryList, setCountryList] = useState([{}]);
    const [stateList, setStateList] = useState([]);
    const [unitList, setUnitList] = useState([]);
    const [orgData, setOrgData] = useState(null);
    const { t } = useTranslation();

    const showFutureCheckbox = useMemo(() => {
      //
      if (staticDodList.length !== staticDodListCopy.length) {
        return true;
      }
      for (let i = 0; i < staticDodList.length; i++) {
        if (staticDodList[i] !== staticDodListCopy[i]) {
          return true;
        }
      }
      return false;
    }, [staticDodList, staticDodListCopy]);

    const [joiningdate, setjoiningdate] = useState(new Date());
    const handleJoiningDate = (date) => {
      setjoiningdate(date);
    };

    const [loiexpdate, setLoiexpdate] = useState(new Date());
    const handleLoiexpdate = (date) => {
      setLoiexpdate(date);
    };

    const { register, handleSubmit, watch, control, getValues, setValue } =
      useForm();

    const { country, gpm } = watch();

    useEffect(() => {
      if (country && country.countryCode) {
        getStateList(country.countryCode).then((response) => {
          if (response.data) {
            setStateList(response.data);
          }
        });
      }
    }, [country]);

    const shouldUpdateLoi = () => {
      // staticDodListCopy.forEach(item)
    };

    useEffect(() => {
      getStaticDDOfOrg(loginUserDetails.orgId, "LOI_DOC").then((response) => {
        if (response.data) {
          setStaticDodList(response.data);
          setStaticDodListCopy(response.data);
        }
      });

      getOrgDetailsByOrgId(loginUserDetails.orgId).then((response) => {
        if (response.data) {
          setOrgData(response.data);
        }
      });

      getUnits(loginUserDetails.orgId).then((response) => {
        if (response.data) {
          setUnitList(response.data);
        }
      });

      getPositionsOfOrganization(loginUserDetails.orgId).then((response) => {
        if (response.data) {
          setPositionList(response.data);
        }
      });
      getAllDepartment(loginUserDetails.orgId).then((response) => {
        if (response.data) {
          setDepartmentList(response.data);
        }
      });
      getCountryList(loginUserDetails.orgId).then((response) => {
        if (response.data) {
          setCountryList(response.data);
          // setCountryList([
          //   {
          //     countryCode: "4",
          //     countryName: "Afghanistan",
          //     currencyAlphaCd: null,
          //     currencyNumCd: null,
          //     callingCd: null,
          //     timezone: null,
          //   },
          // ]);
        }
      });
    }, []);

    return (
      <>
        <div class="tskElemHeding mt10">{t("loi_candidate_demography")}</div>

        <div className="formElementGrp">
          <div className="formElement mxW-100">
            <FormControl className="formControl">
              <InputLabel id="employeeTitle">{t("title")}</InputLabel>
              <Controller
                name="employeeTitle"
                control={control}
                defaultValue={"Mr"}
                render={({ field }) => (
                  <Select
                    labelId="employeeTitle"
                    id="selectemployeeTitle"
                    label="Title"
                    className="formInputField"
                    variant="outlined"
                    {...field}
                  >
                    <MenuItem value="Mr">{t("mr")}</MenuItem>
                    <MenuItem value="Ms">{t("ms")}</MenuItem>
                    <MenuItem value="Mrs">{t("mrs")}</MenuItem>
                    <MenuItem value="Miss">{t("miss")}</MenuItem>
                  </Select>
                )}
              />
            </FormControl>
          </div>

          <div className="formElement">
            <FormControl className="formControl">
              <TextField
                // hiddenLabel
                label={t("first_name")}
                required
                placeholder={t("first_name")}
                variant="outlined"
                className="formTextFieldArea"
                {...register("firstName")}
              />
            </FormControl>
          </div>

          <div className="formElement">
            <FormControl className="formControl">
              <TextField
                // hiddenLabel
                label={t("middle_name")}
                placeholder={t("middle_name")}
                variant="outlined"
                className="formTextFieldArea"
                {...register("middleName")}
              />
            </FormControl>
          </div>

          <div className="formElement">
            <FormControl className="formControl">
              <TextField
                // hiddenLabel
                required
                label={t("last_name")}
                placeholder={t("last_name")}
                variant="outlined"
                className="formTextFieldArea"
                {...register("lastName")}
              />
            </FormControl>
          </div>
        </div>
        <div className="formElementGrp flex-wrap">
          <div className="formElement width13">
            <FormControl className="formControl">
              <TextField
                // hiddenLabel
                label={t("address_line_one")}
                required
                placeholder={t("address_line_one")}
                variant="outlined"
                className="formTextFieldArea"
                {...register("address1")}
              />
            </FormControl>
          </div>

          <div className="formElement width13">
            <FormControl className="formControl">
              <TextField
                // hiddenLabel
                label={t("address_line_two")}
                required
                placeholder={t("address_line_two")}
                variant="outlined"
                className="formTextFieldArea"
                {...register("address2")}
              />
            </FormControl>
          </div>
          <div className="formElement width13">
            <FormControl className="formControl">
              <TextField
                required
                // hiddenLabel
                label={t("city")}
                placeholder={t("ex_kolkata")}
                variant="outlined"
                className="formTextFieldArea"
                {...register("city")}
              />
            </FormControl>
          </div>

          <div className="formElement width13">
            <FormControl className="formControl">
              <InputLabel id="SelectCountry">{t("select_country")}*</InputLabel>

              <Controller
                name="country"
                control={control}
                render={({ field }) => (
                  <Select
                    required
                    labelId="SelectCountry"
                    id="Select-Country"
                    label={t("select_country")}
                    className="formInputField"
                    variant="outlined"
                    {...field}
                  >
                    {countryList &&
                      countryList.map((c) => (
                        <MenuItem value={c}>{c.countryName}</MenuItem>
                      ))}
                  </Select>
                )}
              />
            </FormControl>
          </div>

          <div className="formElement width13">
            <FormControl className="formControl">
              <InputLabel id="SelectState">{t("select_state")} *</InputLabel>

              <Controller
                name="state"
                control={control}
                render={({ field }) => (
                  <Select
                    required
                    labelId="SelectState"
                    id="Select-State"
                    label={t("select_state")}
                    className="formInputField"
                    variant="outlined"
                    {...field}
                  >
                    {stateList &&
                      stateList.map((state) => (
                        <MenuItem value={state}>{state.name}</MenuItem>
                      ))}
                    {/* <MenuItem value="Delhi">Delhi</MenuItem>
                                <MenuItem value="Bihar">Bihar</MenuItem> */}
                  </Select>
                )}
              />
            </FormControl>
          </div>

          <div className="formElement width13">
            <FormControl className="formControl">
              <TextField
                // hiddenLabel
                required
                label={t("pin")}
                placeholder={t("ex_pin")}
                variant="outlined"
                className="formTextFieldArea"
                {...register("pin")}
              />
            </FormControl>
          </div>
        </div>
        <div className="formElementGrp">
          <div className="formElement width13">
            <FormControl className="formControl">
              <InputLabel id="employeeJoingDate" className="setCompletionDate">
                {t("joining_date")}*
              </InputLabel>
              <ReactDatePicker
                showIcon
                labelId="employeeJoingDate"
                className="formDatePicker"
                selected={joiningdate}
                // minDate={new Date()}
                minDate={new Date()}
                showYearDropdown
                yearDropdownItemNumber={100}
                scrollableYearDropdown
                showMonthDropdown
                dateFormat="dd/MM/yyyy"
                onChange={(date) => {
                  handleJoiningDate(date);
                }}
              />
            </FormControl>
          </div>

          <div className="formElement width13">
            <FormControl className="formControl">
              <InputLabel id="loiExpDate" className="setCompletionDate">
                {t("loi_expiry_date")}*
              </InputLabel>
              <ReactDatePicker
                showIcon
                labelId="loiExpDate"
                className="formDatePicker"
                selected={loiexpdate}
                minDate={new Date()}
                showYearDropdown
                yearDropdownItemNumber={100}
                scrollableYearDropdown
                showMonthDropdown
                dateFormat="dd/MM/yyyy"
                onChange={(date) => {
                  handleLoiexpdate(date);
                }}
              />
            </FormControl>
          </div>
          <div className="formElement width13">
            <FormControl className="formControl">
              <TextField
                // hiddenLabel
                label={t("email_id")}
                required
                placeholder={t("ex_employe_org_com")}
                variant="outlined"
                className="formTextFieldArea"
                {...register("email")}
              />
            </FormControl>
          </div>
        </div>
        <div className="formElementGrp ">
          <div className="formElement">
            <FormControl className="formControl">
              <InputLabel id="SelectDepartment">
                {t("select_department")} *
              </InputLabel>
              <Controller
                name="department"
                control={control}
                render={({ field }) => (
                  <Select
                    labelId="SelectDepartment"
                    id="Select-department"
                    label={t("select_department")}
                    className="formInputField"
                    variant="outlined"
                    {...field}
                  >
                    {departmentList &&
                      departmentList.map((department) => (
                        <MenuItem value={department}>
                          {department.functionName}
                        </MenuItem>
                      ))}
                    {/* <MenuItem value="hr">Human Resources</MenuItem>
                                <MenuItem value="it">
                                  Information Technology
                                </MenuItem> */}
                  </Select>
                )}
              />
            </FormControl>
          </div>

          <div className="formElement">
            <FormControl className="formControl">
              <InputLabel id="SelectPosition">
                {t("select_position")} *
              </InputLabel>

              <Controller
                name="position"
                control={control}
                render={({ field }) => (
                  <Select
                    labelId="SelectPosition"
                    id="Select-Position"
                    label={t("select_position")}
                    className="formInputField"
                    variant="outlined"
                    {...field}
                  >
                    {positionList.map((position) => (
                      <MenuItem value={position}>{position.position}</MenuItem>
                    ))}
                    {/* <MenuItem value="manager">Manager</MenuItem>
                                <MenuItem value="executive">Executive</MenuItem>
                                <MenuItem value="interns">Interns</MenuItem> */}
                  </Select>
                )}
              />
            </FormControl>
          </div>

          <div className="formElement">
            <FormControl className="formControl">
              <InputLabel id="SelectPosition">
                {t("select_location")} *
              </InputLabel>

              <Controller
                name="location"
                control={control}
                render={({ field }) => (
                  <Select
                    labelId="SelectPosition"
                    id="Select-Position"
                    label={t("select_location")}
                    className="formInputField"
                    variant="outlined"
                    {...field}
                  >
                    {unitList.map((unit) => (
                      <MenuItem value={unit}>
                        {unit.organizationAddressDto.addressDisplay}
                      </MenuItem>
                    ))}
                    {/* <MenuItem value="manager">Manager</MenuItem>
                                <MenuItem value="executive">Executive</MenuItem>
                                <MenuItem value="interns">Interns</MenuItem> */}
                  </Select>
                )}
              />
            </FormControl>
          </div>
        </div>

        <div class="tskElemHeding mt10">{t("define_salary_breakup")}</div>

        <div className="formElementGrp">
          <div className="formElement width13">
            <FormControl className="formControl">
              <InputLabel id="SelectEmploymentType">
                {t("employment_type")}
              </InputLabel>
              <Controller
                name="employmentTitle"
                control={control}
                defaultValue={"Mr"}
                render={({ field }) => (
                  <Select
                    labelId="SelectEmploymentType"
                    id="Select-SelectEmploymentType"
                    label={t("employment_type")}
                    className="formInputField"
                    variant="outlined"
                    {...field}
                  >
                    <MenuItem value="payroll">{t("on_payroll")}</MenuItem>
                    <MenuItem value="FTC">{t("fixed_term_contracts")}</MenuItem>
                  </Select>
                )}
              />
            </FormControl>
          </div>
          <div className="formElement width13">
            <FormControl className="formControl">
              <TextField
                // hiddenLabel
                label={t("gross_per_month")}
                required
                placeholder={t("gpm_ex")}
                variant="outlined"
                className="formTextFieldArea"
                InputProps={{
                  inputComponent: NumericFormatCustom,
                }}
                {...register("gpm")}
              />
            </FormControl>
          </div>

          <div className="formElement width13">
            <FormControl className="formControl">
              <TextField
                // hiddenLabel
                label={t("cost_to_company")}
                required
                placeholder={t("ctc_ex")}
                variant="outlined"
                className="formTextFieldArea"
                InputProps={{
                  inputComponent: NumericFormatCustom,
                }}
                {...register("ctc")}
              />
            </FormControl>
          </div>
        </div>

        <SalaryTable
          salaryInfoList={salaryInfoList}
          setSalaryInfoList={setSalaryInfoList}
          gpm={gpm}
        />

        <div class="tskElemHeding mt10">{t("document_required")}</div>

        <div className="documentAddGrp">
          <div className="formElement">
            <FormControl className="formControl">
              <TextField
                required
                // hiddenLabel
                label={t("document_name")}
                placeholder={t(
                  "ex_relieving_certificate_from_the_previous_employer"
                )}
                variant="outlined"
                className="formTextFieldArea"
                value={documentName}
                onChange={(e) => {
                  setDocumentName(e.target.value);
                }}
              />
            </FormControl>
          </div>
          {/* <Button
            className="addDocBtn"
            variant="contained"
            startIcon={<Add />}
            onClick={(e) => {
              setStaticDodList((prev) => [
                ...prev,
                { id: documentName, name: documentName },
              ]);
              setDocumentName("");
            }}
          >
            {t("add")}
          </Button> */}
          <Button
            className="addDocBtn"
            variant="contained"
            startIcon={<Add />}
            onClick={() => {
              if (!documentName.trim()) {
                toast.error(t("please_enter_document_name"));
                return;
              }

              setStaticDodList((prev) => [
                ...prev,
                { id: documentName, name: documentName },
              ]);
              setDocumentName("");
            }}
          >
            {t("add")}
          </Button>

          <Button
            ref={loiRef}
            style={{ display: "none" }}
            onClick={(e) => {
              let data = getValues();
              data.salaryInfoList = salaryInfoList;
              data.staticDodList = staticDodList;
              data.joiningdate = joiningdate;
              data.loiexpdate = loiexpdate;
              data.orgData = orgData;
              // console.log("data value =", data);
              // return;
              handleShowPreview(data);
            }}
          ></Button>
        </div>

        {staticDodList && staticDodList.length > 0 && (
          <>
            <div className="listDocRequierd">
              <div className="docLstHd">{t("document_list")}</div>
              <ul>
                {staticDodList &&
                  staticDodList.map((dodItem, index) => (
                    <li>
                      <div className="listDocREle">
                        <span>{dodItem.name}</span>
                        <span>
                          <IconButton
                            className="removeDoC"
                            onClick={(e) => {
                              const temp = [...staticDodList];
                              temp.splice(index, 1);
                              setStaticDodList(temp);
                            }}
                          >
                            <Trash />
                          </IconButton>
                        </span>
                      </div>
                    </li>
                  ))}
                {/* <li>
                          <div className="listDocREle">
                            <span>
                              Copies of Educational Certificates attested by
                              Gazetted Officer.
                            </span>
                            <span>
                              <IconButton className="removeDoC">
                                <Trash />
                              </IconButton>
                            </span>
                          </div>
                        </li>
                        <li>
                          <div className="listDocREle">
                            <span>
                              Appointment Letter and Salary Certificates of
                              Earlier Companies
                            </span>
                            <span>
                              <IconButton className="removeDoC">
                                <Trash />
                              </IconButton>
                            </span>
                          </div>
                        </li> */}
              </ul>
            </div>

            {showFutureCheckbox && (
              <div className="formElement">
                <FormControl className="formControl">
                  <Controller
                    name="saveReqDocs"
                    control={control}
                    render={({ field }) => (
                      <FormGroup
                        aria-label="position"
                        row
                        className="feformGroup"
                      >
                        <FormControlLabel
                          className="formCheckBox"
                          // value="Cost Center"
                          control={<Checkbox {...field} />}
                          label={t("save_for_this_future_loi")}
                          labelPlacement="End"
                        />
                      </FormGroup>
                    )}
                  />
                </FormControl>
              </div>
            )}
          </>
        )}
      </>
    );
  }
);
